<template>
    <component :is="svgComponent"
        style="stroke: #fff !important;fill: #fff !important;"
        class="iconify" v-if="svgComponent"></component>
    <div v-else>
        <p>SVG not found</p>
    </div>
</template>

<style scoped lang="scss">
.iconify svg path {
    stroke: #fff !important;
    fill: #fff !important;
}
.iconify svg  {
    height: 50px;
    width: 50px;
}
</style>
<script>

export default {
    props: ['img'],
    data() {
        return {
            svgComponent: null
        };
    },
    async mounted() {
       await this.loadSvgIcon();
    },
    methods: {
        async loadSvgIcon() {
            try {
                const svgComponent = (await import(`@/assets/svg/${this.img}.svg`)).default;
                this.svgComponent = svgComponent;
            } catch (error) {
                console.error("Error loading SVG:", error);
                this.svgComponent = null;
            }
        }
    }

}

</script>