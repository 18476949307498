<template>
    <v-container>

        <v-dialog fullscreen v-model="dialogDetail">
            <v-card class="pa-2">
                <v-toolbar dark color="secondary white--text">
                    <v-toolbar-title><v-icon>mdi-calendar-check</v-icon> Détail de la tâche</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogDetail = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <div v-if="taskSelected" class="pa-3">
                    <v-row>
                        <v-spacer></v-spacer>

                        <v-chip class="ma-2" color="primary" rounded>
                            <v-icon left>{{ taskSelected.enabled ?
            "mdi-calendar-check" :
            "mdi-calendar"
                                }}</v-icon>
                            <span class="mr-1">{{ taskSelected.dateRangeFormatted
                                }}</span>
                        </v-chip>
                    </v-row>


                    <v-row class="pa-2">
                        <h3 class="text-h6">
                            <v-icon v-if="taskSelected.important == true" size="30"
                                color="red">mdi-alert-decagram</v-icon>
                            {{ taskSelected.title }}
                        </h3>
                        <v-spacer></v-spacer>
                        <v-switch @change="changeTask(taskSelected)" v-model="taskSelected.enabled" inset
                            :label="`Fait`"></v-switch>
                    </v-row>

                    <v-row dense justify="end" class="pa-2" v-if="taskSelected.parcelles.length">
                        <v-chip label small class="ma-1" color="info">{{ taskSelected.selectedStep }}</v-chip>
                        <v-chip class="ma-1" v-for="parcelle in taskSelected.parcelles" :key="taskSelected._id" label
                            small outlined color="secondary">
                            {{ parcelle.name }}
                        </v-chip>
                    </v-row>



                    <v-row class="pa-2 ma-2">
                        <p v-html="taskSelected.description"></p>
                    </v-row>

                    <v-row v-if="taskSelected.file" justify="end" class="ma-2">
                        <v-btn class="secondary" outlined rounded dark
                            @click="openFile(taskSelected)"><v-icon>mdi-file-eye</v-icon>
                            Voir le
                            fichier
                            joint</v-btn>
                    </v-row>

                    <v-row dense justify="end" class="pa-2" v-if="taskSelected.users.length">
                        <v-col>
                            <v-tooltip bottom v-for="user in taskSelected.users" :key="user._id">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="ma-1" v-on="on" fab small color="primary">
                                        {{ user.firstname[0] + user.lastname[0] }}
                                    </v-btn>
                                </template>
                                <span> {{ user.firstname + user.lastname }}</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>


                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogNotes" max-width="600">
            <template v-slot:activator="{ on }"></template>
            <v-card>
                <v-toolbar class="mb-2" color="primary" dark>
                    <v-toolbar-title><v-icon>mdi-note-multiple</v-icon> Liste de notes</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogNotes = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text>
                    <v-list>
                        <v-list-item two-line v-for="(note, index) in notes" :key="index">
                            <v-list-item-content>
                                <v-list-item-title>{{ note.content }}</v-list-item-title>
                                <v-list-item-subtitle>{{ formatDateHour(note.created) }}</v-list-item-subtitle>
                                <v-divider v-if="index < notes.length - 1"></v-divider>

                            </v-list-item-content>

                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="dialogNotes = false"><v-icon>mdi-close</v-icon> Fermer les
                        notes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-checkbox v-if="!viewTasks" v-model="showOnlyPending" label="Afficher uniquement les tâches non terminées"
            class="no-strikethrough"></v-checkbox>

        <v-text-field block v-if="!viewTasks" v-model="searchQuery" label="Recherche par titre ou
            catégorie" solo></v-text-field>


        <v-timeline ref="timeline" v-if="filteredTasks.length && !viewTasks" align-top
            :dense="$vuetify.breakpoint.smAndDown">
            <v-timeline-item v-for="(task, i) in filteredTasks" :key="i" :color="getEventColor(task)"
                :icon="task.enabled ? 'mdi-check-bold' : 'mdi-close'" fill-dot>

                <v-row slot="opposite">
                    <v-col cols="12">
                        <v-tooltip bottom v-for="user in task.users" :key="user._id">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="ma-1" v-on="on" fab small color="primary">
                                    {{ user.firstname[0] + user.lastname[0] }}
                                </v-btn>
                            </template>
                            <span> {{ user.firstname + user.lastname }}</span>
                        </v-tooltip>
                    </v-col>
                    <v-col cols="12">
                        <v-icon slot="opposite" v-if="task.important == true" size="30"
                            color="orange">mdi-alert-decagram</v-icon>
                        <v-chip :color="getEventColor(task)" dark label>


                            <v-icon left>{{ task.enabled ?
            "mdi-calendar-check" :
            "mdi-calendar"
                                }}</v-icon><span class="mr-1">{{ task.dateRangeFormatted
                                }}</span></v-chip>
                    </v-col>
                </v-row>
                <v-card :max-height="430" :color="getEventColor(task)" dark>

                    <v-card-title class="text-h6">

                        <a class="white--text" @click="viewDetail(task)">{{ task.title }}</a>

                        <v-spacer></v-spacer>
                        <v-switch @change="changeTask(task)" v-model="task.enabled" inset :label="`Fait`"></v-switch>
                    </v-card-title>

                    <v-card-text class="white text--primary pa-2">
                        <v-row dense justify="end" class="pa-1" v-if="task.parcelles.length">
                            <v-chip @click="viewNotes(task)" v-if="task.notes" class="ma-1" label small color="info"> {{
            task.notes.length }}
                                notes
                            </v-chip>
                            <v-chip class="ma-1" v-for="parcelle in task.parcelles" :key="task._id" label small outlined
                                color="secondary">
                                {{ parcelle.name }}
                            </v-chip>
                        </v-row>

                        <v-row>
                            <v-col>
                                <p class="formatHtml" v-html="getDesc(task.description)"></p>
                            </v-col>
                        </v-row>
                        <v-row class="ma-2 d-flex justify-end" v-if="!readOnly">
                            <v-btn class="mr-1" small rounded :color="getEventColor(task)"
                                @click="viewDetail(task)"><v-icon>mdi-eye</v-icon> Voir</v-btn>
                            <v-btn small rounded @click="edit(task)" :color="getEventColor(task)" outlined>
                                <v-icon>mdi-pencil</v-icon> Editer
                            </v-btn>
                        </v-row>
                    </v-card-text>

                    <v-row class="pa-2 d-flex justify-end">
                        <v-col cols="12">
                            <v-btn fab small :color="getEventColor(task)"
                                @click="viewDetail(task)"><v-icon>mdi-eye</v-icon></v-btn>
                            <v-btn class="ml-2" fab small outlined color="white"
                                :href="`https://www.google.com/calendar/render?action=TEMPLATE&text=${task.title}&dates=${formatDated(task.dateRange[0])}/${formatDated(task.dateRange[1])}&details=${task.title}&sf=true&output=xml`"
                                target="_blank" rel="noopener noreferrer"><v-icon>mdi-google</v-icon></v-btn>
                            <v-btn outlined class="ml-2" fab small color="white" @click="addApple(task)" target="_blank"
                                rel="noopener noreferrer"><v-icon>mdi-apple</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-timeline-item>
        </v-timeline>

        <v-alert v-else-if="!filteredTasks.length && !viewTasks" type="info">Vous n'avez pas encore de tâches</v-alert>

        <v-btn v-show="!viewTasks" block color="secondary"
            @click="viewTasks = true; editedTasks = false"><v-icon>mdi-plus-circle-outline</v-icon>Ajouter une
            tâche</v-btn>

        <v-expand-transition>
            <div v-show="viewTasks">
                <v-form ref="form" v-model="valid">
                    <v-row wrap class=" mt-4">
                        <v-col cols="12" lg="8">
                            <div>
                                <v-text-field outlined @change="changes" :rules="taskName"
                                    prepend-inner-icon="mdi-pencil" v-model="task.title"
                                    placeholder="Ecrivez une nouvelle tâche"></v-text-field>
                            </div>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-autocomplete @change="changes" chips prepend-inner-icon="mdi-map-legend" solo multiple
                                v-model="task.parcelles" :items="parcellesLoad" clearable item-text="name"
                                item-value="name" label="Choisissez le(s) parcelle(s) associée(s)" return-object
                                :filter="customFilter"></v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-select @change="changes" :rules="assignedUsers" prepend-inner-icon="mdi-account-group"
                                solo multiple v-model="task.users" :items="operateurs" :item-text="formatName"
                                item-value="id" label="Choisissez le(s) membre(s) associée(s)" return-object></v-select>
                        </v-col>

                        <v-col cols="12" lg="4">
                            <v-select @change="changes" :rules="category" solo v-model="task.selectedStep"
                                :items="steps" label="Sélectionnez une catégorie" item-text="name" item-value="value">
                                <template v-slot:item="{ item }">
                                    <v-row align="center">
                                        <v-col cols="2">
                                            <v-icon :color="item.color">{{ item.icon }}</v-icon>
                                        </v-col>
                                        <v-col cols="10">
                                            {{ item.name }}
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:selection="{ item }">
                                    <v-row align="center">
                                        <v-col cols="2">
                                            <v-icon :color="item.color">{{ item.icon }}</v-icon>
                                        </v-col>
                                        <v-col cols="10">
                                            {{ item.name }}
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-select>
                        </v-col>

                        <v-col cols="12" sm="8">
                            <v-alert border="top" colored-border elevation="2" type="warning" cols="12"  dense>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-switch @change="changes" color="orange" type="warning" inset
                                            label="Cette tâche est urgente" v-model="task.important"></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-switch color="orange" type="warning" inset
                                            label="Rappel à l'équipe par SMS 24h avant" v-model="task.alert"></v-switch>
                                    </v-col>
                                </v-row>

                            </v-alert>
                        </v-col>

                        <v-col cols="12">
                            <h3>Description de la tâche</h3>
                            <wysiwyg @change="changes" v-model="task.description" />
                        </v-col>
                        <v-col cols="12">
                            <v-row justify="center">
                                <v-col cols="12">
                                    <h3>Durée de la tâche</h3>
                                    <v-date-picker full-width label="Sélectionner une période" v-model="task.dateRange"
                                        range @input="menu = false"></v-date-picker>
                                    <v-text-field @change="changes" :rules="datePeriod"
                                        v-model="task.dateRangeFormatted" label="Sélectionner une période"
                                        prepend-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col cols="12">



                            <v-row>
                                <v-col>
                                    <v-file-input v-model="task.file" label="Joindre un fichier"
                                        prepend-icon="mdi-paperclip" 
                                        @change="fileChanged"></v-file-input>
                                </v-col>
                                <v-col>
                                    <v-btn class="secondary" outlined rounded dark
                                        @click="openFile(task)"><v-icon>mdi-file-eye</v-icon>
                                        Voir le
                                        fichier
                                        joint à cette tâche</v-btn>
                                </v-col>
                            </v-row>
                        </v-col>


                    </v-row>
                    <v-row class="ma-2">
                        <v-col cols="12" lg="4">
                            <v-btn v-if="!editedTasks" @click="onEnter" block color="success" class="pa-2" primary>
                                <v-icon>mdi-checkbox-marked-circle-plus-outline</v-icon> Ajouter cette tâche
                            </v-btn>

                            <v-btn v-else @click="onEdit" block color="info" class="pa-2" primary>
                                <v-icon>mdi-pencil</v-icon> Editer cette tâche
                            </v-btn>


                        </v-col>
                        <v-col cols="12" lg="4">
                            <v-btn @click="viewTasks = false;" block color="success" class="pa-2" primary>
                                <v-icon>mdi-list-status</v-icon>Retour à la liste
                            </v-btn>
                        </v-col>
                        <v-col cols="12" lg="4">
                            <v-btn @click="remove(task)" color="secondary" block
                                outlined><v-icon>mdi-trash-can-outline</v-icon>
                                Supprimer cette tâche</v-btn>
                        </v-col>

                    </v-row>
                </v-form>
            </div>
        </v-expand-transition>
    </v-container>
</template>

<script>
import xhr from '../plugins/axios';
export default {
    name: 'Todos',
    components: {
    },
    props: ['selectedInventaire', 'profil', 'uid', 'tasks', 'parcelles', 'operateurs', 'readOnly'],
    data(vm) {
        return {
            dialogDetail: false,
            taskSelected: null,
            dialogNotes: false,
            notes: [],
            changed: false,
            taskName: [
                v => !!v || 'Le nom de la tâche est requis.'
            ],
            category: [
                v => !!v || 'Une catégorie doit être sélectionnée.'
            ],
            assignedUsers: [
                v => v && v.length > 0 || 'Au moins un utilisateur doit être choisi.'
            ],
            datePeriod: [
                v => !!v || 'Une période de date doit être sélectionnée.'
            ],
            valid: false,
            showOnlyPending: false,
            viewTasks: false,
            menu: false,
            parcellesLoad: [],
            date: [],
            usersLoad: [],
            dateRangeText: [],
            dragging: false,
            dialogPosition: { x: 0, y: 0 },
            isFocused: false,
            startDate: new Date().toISOString().substr(0, 10),
            endDate: new Date().toISOString().substr(0, 10),
            startDateFormatted: '',
            editedTasks: false,
            searchQuery: '',
            endDateFormatted: '',
            dateRange: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],
            task: {
                title: "",
                description: "",
                important: false,
                alert: false,
                parcelles: [],
                dateRangeFormatted: '',
                dateRange: [],
                users: [],
                selectedStep: null,
                dateFormatted: [],
                file: null
            },
            steps: [
                { name: 'Taille des vignes', value: 'taille', color: 'amber', icon: 'mdi-ruler' },
                { name: 'Attache des vignes', value: 'attache', color: 'green', icon: 'mdi-attachment' },
                { name: 'Ébourgeonnage', value: 'ebourgeonnage', color: 'purple', icon: 'mdi-sprout' },
                { name: 'Éclaircissage des grappes', value: 'eclaircissage', color: 'blue', icon: 'mdi-flower' },
                { name: 'Gestion des maladies et des ravageurs', value: 'gestion', color: 'brown', icon: 'mdi-shield' },
                { name: 'Irrigation', value: 'irrigation', color: 'orange', icon: 'mdi-water' },
                { name: 'Gestion de la canopée', value: 'canopee', color: 'cyan', icon: 'mdi-tree' },
                { name: 'Récolte', value: 'recolte', color: 'pink', icon: 'mdi-basket' },
                { name: 'Tri des raisins', value: 'tri', color: 'teal', icon: 'mdi-sort' },
                { name: 'Vinification', value: 'vinification', color: 'indigo', icon: 'mdi-wine' }
            ],
        }
    },
    async created() {
        let tab = this.parcelles.map((e) => ({
            ...e.features[0].properties,
            invId: e.invId
        }));
        this.parcellesLoad = tab

        if (this.profil == "forestier") {
            this.steps = [
                { name: 'Martelage', value: 'martelage', color: 'amber', icon: 'mdi-hammer' },
                { name: 'Cubage', value: 'cubage', color: 'green', icon: 'mdi-tape-measure' },
                { name: 'Comptage', value: 'comptage', color: 'purple', icon: 'mdi-calculator' },
                { name: 'Plantation', value: 'plantation', color: 'blue', icon: 'mdi-sprout' },
                { name: 'Entretien', value: 'entretien', color: 'brown', icon: 'mdi-saw-blade' },
                { name: 'Protection ', value: 'protection', color: 'orange', icon: 'mdi-shield' },
                { name: 'Gestion de la faune', value: 'faune', color: 'cyan', icon: 'mdi-paw' },
                { name: 'Récolte', value: 'récolte', color: 'pink', icon: 'mdi-axe' },
                { name: 'Valorisation', value: 'valorisation', color: 'teal', icon: 'mdi-diamond-stone' },
                { name: 'Surveillance ', value: 'surveillance', color: 'red', icon: 'mdi-cctv' },
                { name: 'Plannification', value: 'plannification', color: 'indigo', icon: 'mdi-calendar' },
            ];
        }
    },
    mounted() {
    },
    watch: {
        "task.dateRange"(value) {
            this.normalizeDateRange();

        }
    },
    methods: {
        viewDetail(task) {
            this.taskSelected = task
            this.dialogDetail = true
        },
        getDesc(htmlString) {
            // Suppression de toutes les balises HTML
            const textOnly = htmlString.replace(/<[^>]*>/g, '');

            // Découpage du texte en mots
            const words = textOnly.split(/\s+/);

            // Tronquer à 20 mots
            const firstTwentyWords = words.slice(0, 20).join(' ');

            return firstTwentyWords;
        },
        openFile(task) {
            if (task && task.file) {
                const fileUrl = `${process.env.VUE_APP_BASE_URL}uploads/inventaires/${task.invId}/${encodeURIComponent(task.file)}`;
                window.open(fileUrl, '_blank');
            }
        },
        fileChanged(file) {
            console.log("Fichier sélectionné :", file);
            //  this.task.file = file
        },
        viewNotes(task) {
            this.notes = task.notes
            this.dialogNotes = true
        },
        normalizeDateRange() {
            if (this.task.dateRange.length === 2) {
                const [start, end] = this.task.dateRange.map(date => new Date(date));
                if (start > end) {
                    this.task.dateRange = [end.toISOString().substr(0, 10), start.toISOString().substr(0, 10)];
                }
                this.task.dateBegin = this.task.dateRange[0]
                this.task.dateEnd = this.task.dateRange[1]
                this.formatDateRange();
            }
        },
        formatDate(date) {
            return new Intl.DateTimeFormat('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(date));
        },
        formatDateHour(date) {
            return new Intl.DateTimeFormat('fr-FR', {
                weekday: 'long', // Ajoute le jour de la semaine complet
                day: '2-digit', // Jour du mois en deux chiffres
                month: 'long', // Mois en format long
                year: 'numeric', // Année en quatre chiffres
                hour: '2-digit', // Heures en format 24h
                minute: '2-digit', // Minutes en deux chiffres
                hour12: false // Format 24h
            }).format(new Date(date));
        },
        formatDateRange() {
            if (this.task.dateRange.length === 2) {
                const [start, end] = this.task.dateRange;
                this.task.dateRangeFormatted = `${this.formatDate(start)} à ${this.formatDate(end)}`;
            }
        },
        changes() {
            this.changed = true
        },
        formatName(item) {
            return `${item.firstname} ${item.lastname}`;
        },
        async addApple(task) {

            let res = await xhr.post('/download-ics', {
                title: task.title,
                begin: this.formatDated(task.dateRange[0]),
                end: this.formatDated(task.dateRange[1]),
            });
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${task.title.replace(/ /g, '_')}.ics`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        formatDated(date) {
            return date.replace(/-/g, '')
        },
        async edit(task) {
            if (this.readOnly) return
            this.task = task
            this.editedTasks = true
            this.viewTasks = true
        },
        async remove(task) {
            let conf = confirm("Voulez-vous supprimer cette tâche ?")
            if (conf) {

                let res = await xhr.post('/remove-tasks', {
                    inv: task.invId,
                    id: task._id,
                    task: task,
                });
                if (!res.data == true) return false
                this.snackbar = true
                this.snack = 'Votre tâche a été supprimée'
                this.viewTasks = false
                this.$emit("refreshTasks")
            }
        },
        formatDate(date) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return new Date(date).toLocaleDateString('fr-FR', options);
        },
        getEventColor(event) {
            const step = this.steps.find(step => step.value === event.selectedStep);
            return step ? step.color : 'grey'; // Utilisation de la couleur grise par défaut si aucune correspondance n'est trouvée
        },
        async changeTask(task) {
            let res = await xhr.post('/set-task', {
                task: task,
                inv: this.selectedInventaire._id,
            });
            this.$emit("loadTasks")
            this.$emit("finished", "Votre tâche est accomplie 🚀")
        },
        async onEnter() {
            if (this.$refs.form.validate()) {

                console.log("this.selectedInventaire._id", this.selectedInventaire);

                const formData = new FormData();
                formData.append('task', JSON.stringify(this.task));
                formData.append('inv', this.selectedInventaire._id);
                formData.append('title', this.task.title);
                formData.append('important', this.task.important);
                formData.append('description', this.task.description);
                formData.append('parcelles', this.task.parcelles);
                formData.append('users', this.task.users);
                formData.append('dateRange', this.task.dateRange);
                formData.append('dateRangeFormatted', this.task.dateRangeFormatted);
                formData.append('dateBegin', this.task.dateBegin);
                formData.append('dateEnd', this.task.dateEnd);
                formData.append('selectedStep', this.task.selectedStep);

                // Ajoutez d'autres propriétés de tâche si nécessaire

                if (this.task.file) {
                    formData.append('file', this.task.file);
                }


                let res = await xhr.post('/add-tasks', formData);
                this.$emit("loadTasks")
                // this.tasks = res.data.reverse()

                this.task = {
                    title: "",
                    description: "",
                    important: false,
                    parcelle: [],
                    dateRangeFormatted: "",
                    dateRange: [],
                    users: [],
                    selectedStep: null,
                    dateFormatted: [],
                }

                this.viewTasks = false
                this.$emit("finished")
            }
        },
        async onEdit() {
            if (this.$refs.form.validate()) {


                const formData = new FormData();
                formData.append('task', JSON.stringify(this.task));
                formData.append('invId', this.task.invId);
                formData.append('taskId', this.task._id);
                formData.append('title', this.task.title);
                formData.append('important', this.task.important);
                formData.append('description', this.task.description);
                formData.append('parcelles', this.task.parcelles);
                formData.append('users', this.task.users);
                formData.append('dateRange', this.task.dateRange);
                formData.append('dateRangeFormatted', this.task.dateRangeFormatted);
                formData.append('dateBegin', this.task.dateBegin);
                formData.append('dateEnd', this.task.dateEnd);
                formData.append('selectedStep', this.task.selectedStep);
                formData.append('changed', this.changed);

                // Ajoutez d'autres propriétés de tâche si nécessaire

                if (this.task.file) {
                    formData.append('file', this.task.file);
                }

                let res = await xhr.post('/edit-tasks', formData);
                this.viewTasks = false
                this.changed = false


                this.$emit("loadTasks")
                // this.tasks = res.data.reverse()

                this.task = {
                    title: "",
                    description: "",
                    important: false,
                    parcelle: [],
                    dateRangeFormatted: "",
                    dateRange: [],
                    users: [],
                    selectedStep: null,
                    dateFormatted: [],
                }
                this.editedTasks = false


                this.$emit("finished", "Votre tâche a bien été editée ⚡")
            }
        }
    },
    computed: {
        filteredTasks() {
            // Retourne toutes les tâches si aucune recherche n'est spécifiée et que showOnlyPending est faux
            if (!this.searchQuery.trim() && !this.showOnlyPending) {
                return this.tasks;
            }

            // Filtre les tâches en fonction de la recherche et/ou de l'état de la tâche
            return this.tasks.filter(task => {
                // Vérifie si la tâche correspond à la requête de recherche
                const matchesSearchQuery = this.searchQuery.trim() ? (
                    (task.title && task.title.toLowerCase().includes(this.searchQuery.toLowerCase())) ||
                    (task.selectedStep && task.selectedStep.toLowerCase().includes(this.searchQuery.toLowerCase()))
                ) : true; // Si pas de recherche, tous les éléments correspondent à ce critère

                // Vérifie si la tâche doit être filtrée en fonction de son état (uniquement les tâches non terminées)
                const matchesPendingFilter = !this.showOnlyPending || !task.enabled;

                // Retourne vrai si toutes les conditions sont remplies
                return matchesSearchQuery && matchesPendingFilter;
            });
        },
        dateRangeText() {
            return this.dates.join(' ~ ')
        },
    }
}
</script>
<style lang="scss">
.headline {
    background: deep-purple;
    border-radius: 50%;
    padding: 10px;
    font-size: 12px;
    /* Ajustez la taille de la police selon vos besoins */
}

.formatHtml * {
    font-family: "Questrial", sans-serif !important;
    font-size: 14px !important;
    font-family: "Questrial", sans-serif !important;
}

.formatHtml {
    display: block !important;
    clear: both !important;
    max-height: 150px !important;
    overflow-y: hidden;
    line-height: 1.5 !important;
    word-wrap: break-word !important;
    text-align: left !important;
}

// .my-textarea .v-textarea__slot {
//     min-height: 30px;
// }

// .v-input--checkbox.v-input--is-label-active {
//     label {
//         text-decoration: line-through;
//     }// }</style>