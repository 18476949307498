<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-text-field :disabled="!edit" v-model="legende" label="Légende de la gallerie"
                    prepend-icon="mdi-pencil"></v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col @click="clicked(index)" v-for="image,index in items" :key="index" class="d-flex child-flex" cols="4">
                <v-img :lazy-src="image.src" :src="image.src"></v-img>
            </v-col>
        </v-row>

        <v-row class="thumbs" v-if="edit">
            <v-col v-for="image, index in thumbs" :key="image.alt" cols="4">
                <v-img class="thumb-image" :src="image"></v-img>
            </v-col>
        </v-row>



        <v-container v-if="edit">
            <v-row align="center" justify="center" class="my-4">
                <v-col cols="12">
                    <div class="file-drop-area" @dragover.prevent @dragenter="highlight" @dragleave="unhighlight"
                        @drop.prevent="handleDrop">
                        <span class="file-message">Glissez-Déposer vos images ici</span>
                        <input type="file" @change="handleFileUpload" accept="image/*" multiple ref="fileInput" />
                    </div>
                    <!-- <input label="Drag & Drop Files or Click to Browse" type="file"
                        prepend-icon="mdi-cloud-upload" show-size accept="image/*" multiple :dropzone="true"
                        @change="handleFileUpload"></input> -->
                </v-col>
                <v-col cols="12" class="text-center">
                    <v-btn @click="saveGallery" small block color="primary">
                        <!-- <v-icon>mdi-image-multiple</v-icon> -->
                        Sauvegarder la gallerie
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>


        <!-- <div style="height: 250px; width: 400px;">
            <VueLightboxAdvanced @clicked:index="onClicked" :shuffle="true" :items="title" />
        </div> -->
        <!-- <CoolLightBox loop :fullScreen="true" :useZoomBar="true" :items="items" :index="index" @close="index = null">
        </CoolLightBox>

        <div class="images-wrapper">
            <div class="image" v-for="(image, imageIndex) in items" :key="imageIndex" @click="index = imageIndex"
                :style="{ backgroundImage: 'url(' + image.src + ')' }"></div>
        </div> -->

    </div>
</template>


<style scoped>
.file-drop-area {
    position: relative;
    width: 100%;
    height: 200px;
    border: 2px dashed #cccccc;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: border-color 0.3s ease;
}

.file-drop-area.highlight {
    border-color: #666666;
}

.file-drop-area input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.file-message {
    font-size: 16px;
    color: #666666;
}

.items {
    cursor: pointer;
}

.mapboxgl-popup {
    max-width: 300px;
    max-height: 500px;
}
.thumb {
    margin: 10px;
}

.thumb-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

</style>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import VueLightboxAdvanced from 'vue-lightbox-advanced'
import 'vue-lightbox-advanced/dist/VueLightboxAdvanced.css'
import xhr from '../plugins/axios';

export default {
    components: {
        CoolLightBox,
        VueLightboxAdvanced 
    },
    data() {
        return {
            thumbs: [],
            edit: true,
            selectedImage: null,
            selectedFiles: [],
            showOverlay: false,
            legende: '',
            overlay: false,
            model: 0,
            items: [],
            index: null,
            images: []
        }
    },
    props: ['invId', 'photos', 'longitudeMarker', "latitudeMarker", "gallerie"],
    mounted(){
        // this.images = this.photos.map((e) => e.name)
        // this.items = this.photos.map((e) => e.name)

        if(this.gallerie){
            this.edit = false
            this.legende = this.gallerie.legende
            if(this.gallerie.photos){
                let tab = []
                for (let photo of this.gallerie.photos){
                    tab.push({
                        title: "",
                        src: process.env.VUE_APP_BASE_IMAGE_URL + 'uploads/inventaires/' + this.invId + '/images/' + photo.name
                    });
                }
                this.items = tab
                console.log("tab",tab );
            }

        }
    },

    methods: {
        clicked(index) {
            this.$emit("overlayImg", this.items[index])
        },
        handleFileUpload(event) {
            this.selectedFiles = Array.from(event.target.files);
        },
        handleDrop(event) {
            this.selectedFiles = Array.from(event.dataTransfer.files);
            console.log(" this.selectedFiles", this.selectedFiles);
            this.$refs.fileInput.files = event.dataTransfer.files;
            this.createThumbnails();
        },
        createThumbnails() {
            this.selectedFiles.forEach(file => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.thumbs.push(e.target.result);
                };
                reader.readAsDataURL(file);
            });

            console.log("this.thumbs", this.thumbs);
        },
        highlight(event) {
            event.currentTarget.classList.add('highlight');
        },
        unhighlight(event) {
            event.currentTarget.classList.remove('highlight');
        },
        async uploadImages() {
            // Créez un objet FormData
            const formData = new FormData();

            // Ajoutez les fichiers sélectionnés à FormData
            for (let i = 0; i < this.selectedFiles.length; i++) {
                formData.append('images', this.selectedFiles[i]);
            }

            formData.append("invId", this.invId)
            formData.append("legende", this.legende)
            formData.append("longitudeMarker", this.longitudeMarker)
            formData.append("latitudeMarker", this.latitudeMarker)

            try {
                // Envoyez les fichiers au serveur
                let res = await xhr.post('/inventaires-add-images', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                this.items = []

                let photos = res.data.galleries
                let galleries = res.data.galleries;
                let lastGallery = galleries[galleries.length - 1];
                this.thumbs = []
                lastGallery.photos.forEach(photo => {
                    this.items.push({
                        title: lastGallery.legende,
                        src: process.env.VUE_APP_BASE_IMAGE_URL + 'uploads/inventaires/' + this.invId + '/images/' + photo.name
                    });
                });
             
            } catch (error) {
                console.log("error", error);
            }
        },

        async saveGallery(){
            this.uploadImages()
        },

    }
}
</script>
