import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VSheet,{staticClass:"pa-2 mx-auto",attrs:{"width":"100%"}},[_c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VRow,{attrs:{"justify":"center","align":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"8","md":"6"}},[_c(VCard,{staticClass:"elevation-12",attrs:{"outlined":""}},[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Annulation d'Abonnement")]),_c(VCardText,[_c('p',[_vm._v("Nous sommes désolés de vous voir partir. Si vous avez un moment, dites-nous pourquoi vous annulez votre abonnement, afin que nous puissions continuer à nous améliorer.")]),_c(VTextarea,{attrs:{"outlined":"","label":"Votre Feedback","hint":"Raison de l'annulation","rows":"5"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.submitFeedback}},[_vm._v("Soumettre la raison")]),_c(VBtn,{attrs:{"color":"grey darken-1","text":""},on:{"click":_vm.goBack}},[_vm._v("Annuler")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }