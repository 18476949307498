<template>
    <v-container
        style="background-image: url('https://us.123rf.com/450wm/freeprod/freeprod1608/freeprod160800592/61113898-vignobles-de-champagne-dans-le-d%C3%A9partement-marne-champagne-ardennes-france-europe.jpg?ver=6'); background-size: cover; background-position: center center;"
        class="fill-height " fluid>
        <v-row align="center" justify="center" class="fill-height">
            <v-card elevation-2 v-if="step == 0" class="fade-in d-flex flex-column align-center">
                <v-toolbar style="width: 100%" color="blue darken-2" dark flat>
                    <v-toolbar-title>
                        <v-icon>mdi-doctor</v-icon> L'assistant du Diagnostic
                    </v-toolbar-title>
                </v-toolbar>
                <h3>
                    <v-icon>mdi-head-question</v-icon> Etes-vous sûre des symptômes visibles ?
                </h3>
                <v-card-actions class="d-flex flex-column align-center">
                    <v-btn big color="success" class="ma-2" @click="confirmJaunisse(true)">
                        <v-icon left>mdi-check-circle</v-icon> <!-- Icône à gauche du texte -->
                        Je suis sûre
                    </v-btn>
                    <v-btn big color="warning" class="ma-2" @click="confirmJaunisse(false)">
                        <v-icon left>mdi-help-circle</v-icon> <!-- Icône à gauche du texte -->
                        Je ne suis pas sûre
                    </v-btn>
                </v-card-actions>
            </v-card>

            <v-card elevation-2 class="fade-inpa-4" v-if="step == 1">
                <v-toolbar style="width: 100%" color="blue darken-2" dark flat>
                    <v-toolbar-title>
                        <v-card-title><v-icon>mdi-account-check</v-icon> Mes coordonnées</v-card-title>
                    </v-toolbar-title>
                </v-toolbar>
                <v-container class="mt-4">
                    <v-form ref=" form" @submit.prevent="submitForm">
                        <v-text-field solo :rules="nameRules" v-model="name" label="Nom" required
                            prepend-icon="mdi-account"></v-text-field>
                        <v-text-field :rules="emailRules" solo v-model="email" label="Email" required type="email"
                            prepend-icon="mdi-email"></v-text-field>
                        <v-select solo :items="domainesVignes" v-model="domaineSelectionne"
                            label="Sélectionnez un domaine viticole" item-text="nom" item-value="id" return-object
                            prepend-icon="mdi-glass-wine"></v-select>
                        <v-select solo :items="communes" v-model="communeSelectionnee" label="Sélectionnez une commune"
                            prepend-icon="mdi-home-city"></v-select>

                        <v-row>
                            <v-col cols="6">
                                <v-text-field v-model="numeroDeRang" label="Numéro de rang" required
                                    prepend-icon="mdi-numeric"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="numeroDePied" label="Numéro de pied" required
                                    prepend-icon="mdi-numeric"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>

                <v-card-actions class="d-flex justify-center">
                    <v-btn x-large color="primary" @click="submitForm" rounded><v-icon>mdi-content-save-check</v-icon>
                        J'enregistre mes
                        données</v-btn>
                </v-card-actions>
            </v-card>
            <v-card elevation-2 v-if="step == 2" class="fade-in mx-auto">
                <v-card-title class="text-h5"><v-icon>mdi-virus</v-icon> Les Symptômes visibles</v-card-title>

                <v-divider></v-divider>
                <v-alert v-if="isSuspect == true" type="info">
                    Les 3 symptômes ont été identifiés avec certitude pour une <b>suspicion de
                        la
                        Flavescence Dorée ❌</b>
                </v-alert>

                <v-list dense class="pa-2">
                    <div v-for="(symptom, index) in symptoms" :key="index">
                        <v-list-item @click="toggleSlider(index)" class="ma-2">
                            <v-list-item-avatar color="amber lighten-3">
                                <!-- Ajout d'un avatar avec une couleur -->
                                <span class="text-h5">{{ index + 1 }}</span> <!-- Icône à l'intérieur de l'avatar -->
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-h7" v-if="symptom.value != 'decoloration_intensite'">Je
                                    suis
                                    sûre à {{
                symptom.proba
            }}% <span height="80" class="pa-2" v-if="symptom.proba > 70">🟢</span> <span
                                        height="80" class="pt-2 pb-2" v-else-if="symptom.proba < 40">🔴</span>
                                </v-list-item-title>
                                <v-list-item-title class="text-h7"
                                    v-else-if="symptom.value == 'decoloration_intensite'">Intensité de décoloration
                                    <span v-if="symptom.proba > 70">🚩</span> <span
                                        v-else-if="symptom.proba < 10">👻</span> </v-list-item-title>


                                <v-list-item-subtitle class="font-weight-bold" style="color: #1976D2;"> {{ symptom.title
                                    }}</v-list-item-subtitle>

                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>

                        <v-slide-y-transition>
                            <v-slider v-if="symptom.showSlider" v-model="symptom.proba" class="mt-3" color="secondary"
                                thumb-color="primary" :max="100" :min="0" tick-size="10"
                                @input="updateProba(index, $event)" thumb-label="always">
                                <!-- Contenu personnalisé au début du slider -->
                                <template v-slot:prepend>
                                    <v-icon color="red lighten-1">mdi-account-question</v-icon>
                                </template>

                                <template v-slot:append>
                                    <v-icon color="green">mdi-account-check</v-icon>
                                </template>
                            </v-slider>
                        </v-slide-y-transition>
                    </div>
                </v-list>
                <v-card-actions @click="goPredict" class="justify-center mt-6 mb-4">
                    <v-btn class="pa-2" color="pink" dark rounded x-large><v-icon>mdi-text-box-search-outline</v-icon>
                        Je
                        soumet
                        mes
                        observations</v-btn>
                </v-card-actions>
            </v-card>
            <!-- <Chat @goDiag="goDiag" v-else-if="step == 2" :bigAssistant="true"></Chat> -->

            <v-card v-else-if="step == 3" class="mx-auto mt-4" color="green lighten-1" dark max-width="400">
                <v-card-title class="justify-center">
                    👏 Remerciements
                </v-card-title>
                <v-card-text class="text-center">
                    Merci d'avoir signalé votre suspicion de la Flavescence Dorée. Votre vigilance est essentielle
                    pour la
                    santé de nos vignobles.
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn big dark color="secondary" @click="again">
                        <v-icon>mdi-reload</v-icon>
                        Je recommence
                    </v-btn>
                </v-card-actions>
            </v-card>

            <v-card :loading="loading" v-else-if="step == 4" class="elevation-12">
                <v-toolbar style="width: 100%" color="blue darken-2" dark flat>
                    <v-toolbar-title>
                        <v-card-title class="justify-center">Vos photos 📸</v-card-title>
                    </v-toolbar-title>
                </v-toolbar>
                <v-alert type="warning" class="justify-center">Cette partie n'est pas obligatoire. Elle contribue à la
                    reconaissance
                    d'image sur les symptômes de suspicion.</v-alert>
                <v-card-text class="text-center">
                    <v-file-input v-model="selectedFile" label="Prendre une photo de la feuille" accept="image/*"
                        prepend-icon="mdi-camera" @change="processFile($event)" class="mb-3">
                    </v-file-input>

                    <v-file-input v-model="selectedFile2" label="Prendre une photo du rameau" accept="image/*"
                        prepend-icon="mdi-camera" @change="processFile2($event)" class="mb-3">
                    </v-file-input>

                    <v-file-input v-model="selectedFile3" label="Prendre une photo du pied entier" accept="image/*"
                        prepend-icon="mdi-camera" @change="processFile3($event)" class="mb-3">
                    </v-file-input>



                    <v-container>
                        <v-row justify="center" align="center">
                            <v-col cols="12" sm="4">
                                <v-img v-if="thumbnail" :src="thumbnail" width="250"></v-img>
                            </v-col>
                            <v-img width="50" v-if="loading" src="https://i.gifer.com/CVyf.gif"></v-img>

                            <v-alert type="info" v-if="messageChat">{{ messageChat }}</v-alert>

                            <v-col cols="12" sm="4">
                                <v-img v-if="thumbnail2" :src="thumbnail2" width="250"></v-img>
                            </v-col>

                            <v-alert type="info" v-if="messageChat2">{{ messageChat2 }}</v-alert>


                            <v-col cols="12" sm="4">
                                <v-img v-if="thumbnail3" :src="thumbnail3" width="250"></v-img>
                            </v-col>

                            <v-alert type="info" v-if="messageChat3">{{ messageChat3 }}</v-alert>

                        </v-row>
                    </v-container>

                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="ma-4 pa-2 justify-center">
                    <v-btn x-large color="success" dark @click="finished"><v-icon left>mdi-upload</v-icon>J'upload mes
                        photos</v-btn>
                    <v-btn x-large color="warning" dark outlined @click="finished">Je passe <v-icon
                            right>mdi-skip-forward</v-icon></v-btn>
                </v-card-actions>
            </v-card>

            <v-card v-else-if="step == 5" class="elevation-12" color="green accent-4" dark>
                <v-card-title class="justify-center">Merci 😊</v-card-title>
                <v-card-text class="text-center">
                    <div>Votre rapport a été sauvegardé en base de données. </div>
                    <div>Il y a effectivement une suspicion de la Flavéscence Dorée et le taux de suspicion d'après vos
                        symptômes
                        est de
                        <v-chip dark color="secondary"><strong>
                                {{
                predictionRate
                                }}%</strong> </v-chip> vis à vis de la Communauté.
                    </div>
                    <v-icon class="mt-5" large color="white">mdi-check-circle</v-icon>

                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn color="secondary" dark @click="again"><v-icon>mdi-home</v-icon>Je recommence un
                        diagnostic</v-btn>
                </v-card-actions>
            </v-card>
        </v-row>

    </v-container>
</template>

<script>
// Importez le composant Chat
import Chat from './Chat.vue';
import xhr from '../plugins/axios';

export default {
    name: 'Tchat',
    components: {
        Chat
    },
    mounted() {
        this.getGeoLocation();
        setTimeout(() => {
            this.showFirstButton = true;
        }, 500);
        setTimeout(() => {
            this.showSecondButton = true;
        }, 1000);
    },
    data() {
        return {
            messageChat: null,
            messageChat2: null,
            numeroDeRang: 4,
            numeroDePied: 28,
            messageChat3: null,
            loading: false,
            selectedFile: null,
            thumbnail: null,
            selectedFile2: null,
            thumbnail2: null,
            selectedFile3: null,
            thumbnail3: null,
            showFirstButton: false,
            showSecondButton: false,
            already: false,
            sure: false,
            step: 1,
            position: null,
            predictionRate: 85,
            symptoms: [
                { title: "Décoloration de la feuille et nervures (rougissement ou jaunissement)", value: "decoloration", proba: 0, showSlider: false },
                { title: "Intensite de la décoloration sur 5 feuilles", value: "decoloration_intensite", proba: 0, showSlider: false },
                { title: "Les feuilles sont cassantes", value: "cassantes", proba: 0, showSlider: false },
                { title: "Les feuilles sont enroulées", value: "enroulement", proba: 0, showSlider: false },
                { title: "Flétrissement ou dessèchement des grappes", value: "fletrissement", proba: 0, showSlider: false },
                { title: "Absence de lignification (non-aoûtement)", value: "non_aoutement", proba: 0, showSlider: false },
                { title: "Les grappes sont petites, fermes et tombantes", value: "tomber", proba: 0, showSlider: false },
                { title: "Retard de maturité des baies", value: "retard", proba: 0, showSlider: false },
                { title: "Grappes desséchées", value: "dessechement", proba: 0, showSlider: false },
                { title: "Baisse de la vigueur des plants", value: "vigeur", proba: 0, showSlider: false },
            ],
            selectedSymptomIndex: null,
            certainty: 0,
            departements: [
                { nom: 'Côte-d\'Or', code: '21' },
                { nom: 'Doubs', code: '25' },
                { nom: 'Jura', code: '39' },
                { nom: 'Nièvre', code: '58' },
                { nom: 'Haute-Saône', code: '70' },
                { nom: 'Saône-et-Loire', code: '71' },
                { nom: 'Yonne', code: '89' },
                { nom: 'Territoire de Belfort', code: '90' }
            ],
            domainesVignes: [
                { id: 1, nom: 'Domaine des Bruyères' }, // Domaine par défaut
                { id: 2, nom: 'Domaine Leflaive' },
                { id: 3, nom: 'Domaine Ferret' },
                { id: 4, nom: 'Château de Fuissé' },
                { id: 5, nom: 'Domaine J.A. Ferret' },
                { id: 6, nom: 'Domaine Cordier' },
                { id: 7, nom: 'Domaine de la Bongran' },
                { id: 8, nom: 'Domaine Guffens-Heynen' },
                { id: 9, nom: 'Domaine Thibert' },
                { id: 10, nom: 'Domaine Valette' }
            ],
            domaineSelectionne: 1,
            departementSelectionne: '71',
            city: 'Chapelle-de-Guinchay',
            communes: [
                'Charnay-lès-Mâcon',
                'Vinzelles',
                'Prissé',
                'Solutré-Pouilly',
                'Davayé',
                'La Roche-Vineuse',
                'Hurigny',
                'Verzé',
                'La Chapelle-de-Guinchay', // Commune par défaut
                'Saint-Amour-Bellevue',
            ],
            communeSelectionnee: 'La Chapelle-de-Guinchay',
            name: 'Boyer',
            email: 'julien@taiwa.fr',
            domain: 'Domaine des Bruyères'
        }
    },
    methods: {
        finished() {
            this.step = 5
        },

        processFile(event) {
            console.log("event.target", this.selectedFile);
            const file = this.selectedFile; // Obtenir le fichier sélectionné

            // Vérifier si un fichier a été sélectionné
            if (file) {
                // Créer un objet FileReader pour lire le contenu du fichier
                const reader = new FileReader();

                // Définir une fonction de rappel pour exécuter une fois la lecture terminée
                reader.onload = async () => {
                    this.thumbnail = reader.result; // Stocker l'URL de l'image chargée comme miniature
                    this.loading = true
                    setTimeout(() => {
                        this.loading = false
                        this.messageChat = "Il semblerait que certains symptômes de la flavescence dorée soit présent sur cette photo, on voit le rougissement partiel voir total sur cette photo ainsi qu'un enroulement des feuilles."
                    }, 2000)
                };

                // Lire le contenu du fichier en tant que URL de données (data URL)
                reader.readAsDataURL(file);
            }
        },
        processFile2(event) {
            const file = this.selectedFile2; // Obtenir le fichier sélectionné

            // Vérifier si un fichier a été sélectionné
            if (file) {
                // Créer un objet FileReader pour lire le contenu du fichier
                const reader = new FileReader();
                this.loading = true
                // Définir une fonction de rappel pour exécuter une fois la lecture terminée
                reader.onload = () => {
                    this.thumbnail2 = reader.result;
                    setTimeout(() => {
                        this.loading = false
                        this.messageChat2 = "On voit un non-aôutement des rameaux rovoquant un port retombant sur la photo "
                    }, 2000)
                };

                // Lire le contenu du fichier en tant que URL de données (data URL)
                reader.readAsDataURL(file);
            }
        },
        processFile3(event) {
            const file = this.selectedFile3; // Obtenir le fichier sélectionné

            // Vérifier si un fichier a été sélectionné
            if (file) {
                // Créer un objet FileReader pour lire le contenu du fichier
                const reader = new FileReader();
                this.loading = true
                // Définir une fonction de rappel pour exécuter une fois la lecture terminée
                reader.onload = () => {
                    this.thumbnail3 = reader.result; // Stocker l'URL de l'image chargée comme miniature

                    setTimeout(() => {
                        this.loading = false
                        this.messageChat3 = "On voit que certaines grappes dessèchent et quelques baies flétrissent."
                    }, 2000)
                };

                // Lire le contenu du fichier en tant que URL de données (data URL)
                reader.readAsDataURL(file);
            }
        },
        getGeoLocation() {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        console.log(position, "position");
                        this.position = {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                        };
                    },
                    (error) => {
                        console.error("Erreur de géolocalisation:", error);
                        alert("Impossible d'obtenir votre position. Assurez-vous d'avoir autorisé l'accès à votre position géographique.");
                    }
                );
            } else {
                alert("La géolocalisation n'est pas prise en charge par ce navigateur.");
            }
        },
        toggleSlider(index) {
            this.symptoms[index].showSlider = !this.symptoms[index].showSlider;
        },
        updateProba(index, value) {
            this.symptoms[index].proba = value;
        },
        selectSymptom(symptom) {
            console.log(symptom);
        },
        again() {
            this.step = 0
        },
        confirmJaunisse(ok) {
            this.sure = ok;
            if (this.sure == true) {
                this.step = 1
            }
            else if (!this.sure) {
                this.step = 2
            }
            else {
                this.step = 1
            }
        },
        goPredict() {
            this.symptoms = [
                { title: "Décoloration de la feuille et nervures (rougissement ou jaunissement)", value: "decoloration", proba: 0, showSlider: false },
                { title: "Intensite de la décoloration sur 5 feuilles", value: "decoloration_intensite", proba: 0, showSlider: false },
                { title: "Les feuilles sont cassantes", value: "cassantes", proba: 0, showSlider: false },
                { title: "Feuilles enroulées", value: "enroulement", proba: 0, showSlider: false },
                { title: "Flétrissement ou dessèchement des grappes", value: "fletrissement", proba: 0, showSlider: false },
                { title: "Absence de lignification (non-aoûtement)", value: "non_aoutement", proba: 0, showSlider: false },
                { title: "Les grappes sont petites, fermes et tombantes", value: "tomber", proba: 0, showSlider: false },
                { title: "Retard de maturité des baies", value: "retard", proba: 0, showSlider: false },
                { title: "Grappes desséchées", value: "dessechement", proba: 0, showSlider: false },
                { title: "Baisse de la vigueur des plants", value: "vigeur", proba: 0, showSlider: false },
            ]
            this.step = 4
        },
        validateForm() {
            if (this.$refs.form.validate()) {
                this.submitForm();
            }
        },
        submitForm() {
            this.already = true
            if (this.sure == false) {
                this.step = 2
            } else {
                this.step = 3
            }
        },

    },
    computed: {


        isSuspect() {
            const symptomesPertinents = this.symptoms.filter(symptome => ['decoloration', 'fletrissement', 'non_aoutement'].includes(symptome.value));
            const probaAtteinte = symptomesPertinents.every(symptome => symptome.proba >= 70);
            return probaAtteinte;
        }
    },
}
</script>

<style>
/* Définition de l'animation fade-in */
@keyframes fadeInSlideUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Classe à appliquer pour activer l'animation */
.fade-in {
    /* animation: fadeInSlideUp 0.7s ease-out; */
}

/* CSS personnalisé pour ajuster l'épaisseur de la piste du slider */
.v-slider__track-container,
.v-slider__track-background {
    height: 4px !important;
    /* Ajuster selon le besoin */
}

.v-slider__track {
    height: 8px !important;
    /* Ajuster selon le besoin */
}

.v-slider__tick {
    background-color: transparent !important;
}
</style>

<style scoped>
/* Utilisation des transitions fournies par Vuetify */
.v-btn {
    transition: opacity 0.5s !important;
}
</style>