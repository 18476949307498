<template>
    <v-container>
        <v-sheet class="pa-5" elevation="2">
            <v-subheader class="display-1 pa-3 ma-3"><v-icon>📄</v-icon>Documentation en libre accès pour
                Vigneron</v-subheader>

            <v-expansion-panels>

                <v-expansion-panel v-for="(doc, index) in documents" :key="index">
                    <v-expansion-panel-header>
                        <v-layout align-center>
                            <v-flex class="headline">{{ doc.title }}</v-flex>
                        </v-layout>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p class="body-1">{{ doc.description }}</p>
                        <v-btn rouded color="primary" dark :href="doc.pdfUrl" target="_blank">
                            <v-icon left>mdi-download-circle</v-icon>Ouvrir le Document
                        </v-btn>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-sheet>

    </v-container>
</template>

<script>
export default {
    data() {
        return {
            documents: [
                {
                    title: "Controle des maladies et des ravageurs en viticulture biologique",
                    description: "Document PDF physiosanitaitre de la vigne",
                    pdfUrl: "http://localhost:4000/documentation/1070-viticulture-maladies.pdf"
                },
                {
                    title: "Document 2",
                    description: "Description du document 1",
                    pdfUrl: "http://localhost:4000/documentation/GUIDE_VIGNE_COMPLET.pdf"
                },
                // {
                //     title: "Document 3",
                //     description: "Description du document 1",
                //     pdfUrl: "url_du_pdf_1.pdf"
                // },
                // {
                //     title: "Document 4",
                //     description: "Description du document 1",
                //     pdfUrl: "url_du_pdf_1.pdf"
                // },
                // Ajoutez d'autres documents ici
            ]
        };
    }
};
</script>