import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"disabled":!_vm.edit,"label":"Légende de la gallerie","prepend-icon":"mdi-pencil"},model:{value:(_vm.legende),callback:function ($$v) {_vm.legende=$$v},expression:"legende"}})],1)],1),_c(VRow,_vm._l((_vm.items),function(image,index){return _c(VCol,{key:index,staticClass:"d-flex child-flex",attrs:{"cols":"4"},on:{"click":function($event){return _vm.clicked(index)}}},[_c(VImg,{attrs:{"lazy-src":image.src,"src":image.src}})],1)}),1),(_vm.edit)?_c(VRow,{staticClass:"thumbs"},_vm._l((_vm.thumbs),function(image,index){return _c(VCol,{key:image.alt,attrs:{"cols":"4"}},[_c(VImg,{staticClass:"thumb-image",attrs:{"src":image}})],1)}),1):_vm._e(),(_vm.edit)?_c(VContainer,[_c(VRow,{staticClass:"my-4",attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"file-drop-area",on:{"dragover":function($event){$event.preventDefault();},"dragenter":_vm.highlight,"dragleave":_vm.unhighlight,"drop":function($event){$event.preventDefault();return _vm.handleDrop.apply(null, arguments)}}},[_c('span',{staticClass:"file-message"},[_vm._v("Glissez-Déposer vos images ici")]),_c('input',{ref:"fileInput",attrs:{"type":"file","accept":"image/*","multiple":""},on:{"change":_vm.handleFileUpload}})])]),_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"small":"","block":"","color":"primary"},on:{"click":_vm.saveGallery}},[_vm._v(" Sauvegarder la gallerie ")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }