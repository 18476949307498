<template>
  <div>
    <v-snackbar absolute top color="primary" shaped v-model="snackbar">
      {{ snack }}
    </v-snackbar>
    <v-sheet tile height="54" class="d-flex">
      <v-btn color="primary" icon class="ma-2" @click="$refs.calendar.prev()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-select v-model="type" :items="types" dense outlined hide-details class="ma-2" label="Vue" item-text="text"
        item-value="value"></v-select>
      <v-spacer></v-spacer>
      <v-btn color="primary" class="ma-2" icon @click="$refs.calendar.next()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar ref="calendar" v-model="value" :weekdays="weekday" :type="type" :events="events"
        :event-overlap-mode="mode" :event-overlap-threshold="30" :event-color="getEventColor" @change="getEvents"
        @click:event="showEventDetails">
        <template v-slot:event="{ event }">
          <div class="event-name"><v-icon dark v-if="event.enabled">mdi-check-circle</v-icon><v-icon v-else
              dark>mdi-radiobox-blank</v-icon>{{ event.name }}</div>
        </template>
      </v-calendar>
    </v-sheet>
    <!-- Légende des étapes -->
    <v-row class="mt-1">
      <v-col v-for="step in getSteps" :key="step.value" cols="auto">
        <v-chip :color="step.color" :class="[step.color, { 'white--text': stepClicked === step.name }]"
          :outlined="stepClicked !== step.name" @click="viewDetail(step.name)">
          <v-icon left>{{ step.icon }}</v-icon>{{ capitalize(step.name) }}
        </v-chip>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogEventDetails" max-width="500" hide-overlay>
      <v-card dark :color="getEventColor(selectedEvent)">
        <v-card-title>
          <v-icon left>{{ selectedEvent.enabled == false ? 'mdi-radiobox-blank' : 'mdi-check-circle' }} </v-icon> {{
      selectedEvent.name
    }}
        </v-card-title>
        <v-card-text>
          <div><v-icon>mdi-fruit-grapes</v-icon> Etape: <v-chip outlined dark>{{ selectedEvent.step }}</v-chip></div>
          <div><v-icon>mdi-calendar-check</v-icon> <span>Du {{ formatDate(selectedEvent.start) }} </span>
            <span> au {{ formatDate(selectedEvent.end) }}</span>
          </div>
          <div><v-icon>mdi-check-circle</v-icon> Etat: <b>{{ selectedEvent.enabled ? "Fait" : "Non-fait" }}</b></div>
        </v-card-text>
        <v-card-actions v-if="!readOnly">
          <v-spacer></v-spacer>
          <v-btn small dark outlined color="white" rounded @click="removeEvent(selectedEvent.id)">
            <v-icon>mdi-trash-can-outline</v-icon>
            Supprimer</v-btn>
          <v-btn small color="white" light rounded @click="dialogEventDetails = false">
            <v-icon>mdi-close-circle</v-icon>
            Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import xhr from '../plugins/axios';


export default {
  props: ['id', 'tasks', 'profil', 'readOnly'],
  data: () => ({
    type: 'month',
    snackbar: false,
    stepClicked: '',
    snack: "",
    types: [
      { text: 'Mois', value: 'month' },
      { text: 'Semaine', value: 'week' },
      { text: 'Jour', value: 'day' },
      { text: '4 Jours', value: '4day' }
    ],
    mode: 'stack',
    modes: ['stack', 'column'],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    dialogEventDetails: false,
    selectedEvent: {},
    weekdays: [
      { text: 'Dim - Sam', value: [0, 1, 2, 3, 4, 5, 6] },
      { text: 'Lun - Dim', value: [1, 2, 3, 4, 5, 6, 0] },
      { text: 'Lun - Ven', value: [1, 2, 3, 4, 5] },
      { text: 'Lun, Mer, Ven', value: [1, 3, 5] },
    ],
    value: '',
    filterChip: false,
    events: [],
    steps: [
      { name: 'Martelage', value: 'martelage', color: 'amber', icon: 'mdi-hammer' },
      { name: 'Cubage', value: 'cubage', color: 'green', icon: 'mdi-tape-measure' },
      { name: 'Comptage', value: 'comptage', color: 'purple', icon: 'mdi-calculator' },
      { name: 'Plantation', value: 'plantation', color: 'blue', icon: 'mdi-sprout' },
      { name: 'Entretien', value: 'entretien', color: 'brown', icon: 'mdi-saw-blade' },
      { name: 'Protection ', value: 'protection', color: 'orange', icon: 'mdi-shield' },
      { name: 'Gestion de la faune', value: 'faune', color: 'cyan', icon: 'mdi-paw' },
      { name: 'Récolte', value: 'récolte', color: 'pink', icon: 'mdi-axe' },
      { name: 'Valorisation', value: 'valorisation', color: 'teal', icon: 'mdi-diamond-stone' },
      { name: 'Surveillance ', value: 'surveillance', color: 'red', icon: 'mdi-cctv' },
      { name: 'Plannification', value: 'plannification', color: 'indigo', icon: 'mdi-calendar' },
    ],
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    names: ['Réunion', 'Vacances', 'Congé', 'Voyage', 'Evénement', 'Anniversaire', 'Conférence', 'Fête'],
  }),
  async created() {
    if (this.profil == "vigneron") {
            this.steps = [
                { name: 'Taille des vignes', value: 'taille', color: 'amber', icon: 'mdi-ruler' },
                { name: 'Attache des vignes', value: 'attache', color: 'green', icon: 'mdi-attachment' },
                { name: 'Ébourgeonnage', value: 'ebourgeonnage', color: 'purple', icon: 'mdi-sprout' },
                { name: 'Éclaircissage des grappes', value: 'eclaircissage', color: 'blue', icon: 'mdi-flower' },
                { name: 'Gestion des maladies et des ravageurs', value: 'gestion', color: 'brown', icon: 'mdi-shield' },
                { name: 'Irrigation', value: 'irrigation', color: 'orange', icon: 'mdi-water' },
                { name: 'Gestion de la canopée', value: 'canopee', color: 'cyan', icon: 'mdi-tree' },
                { name: 'Récolte', value: 'recolte', color: 'pink', icon: 'mdi-basket' },
                { name: 'Tri des raisins', value: 'tri', color: 'teal', icon: 'mdi-sort' },
                { name: 'Vinification', value: 'vinification', color: 'indigo', icon: 'mdi-wine' }
            ];
        }
  },
  async mounted() {
  },
  computed: {
    getSteps() {
      // Utiliser un ensemble pour stocker les noms uniques des étapes
      const uniqueStepNames = new Set(this.events.map((e) => e.step));

      // Créer un tableau d'objets avec les noms d'étapes uniques
      const uniqueSteps = [];
      uniqueStepNames.forEach((stepName) => {
        // Trouver la première occurrence de l'étape dans les événements
        const eventWithStep = this.events.find((e) => e.step === stepName);
        // Ajouter l'étape au tableau uniqueSteps
        uniqueSteps.push({ name: stepName, color: eventWithStep.color, icon: eventWithStep.icon });
      });


      return uniqueSteps;
    }
  },
  methods: {
    async removeEvent(id) {
      try {
        let conf = confirm("Voulez-vous supprimer cette tâche ?")
        if (conf) {

          let res = await xhr.post('/remove-tasks', {
            inv: this.id,
            id: id,
          });
          if (!res.data == true) return false
          this.snackbar = true
          this.snack = 'Votre tâche a été supprimée'
          this.$emit('loadTasks')
          this.dialogEventDetails = false
          setTimeout(() => {
            this.loadEvent()
          }, 600)
        }

      } catch (error) {
        console.log("error", error);
      }

    },
    viewDetail(name) {
      this.filterChip = !this.filterChip
      this.stepClicked = name
      if (this.filterChip == false) {
        this.loadEvent()
        this.stepClicked = ''
      } else {
        this.loadEvent(name)
      }
    },
    capitalize(str) {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString('fr-FR', options);
    },
    showEventDetails({ event }) {
      this.selectedEvent = event;
      this.dialogEventDetails = true;
    },
    getEventColor(event) {
      const step = this.steps.find(step => step.value === event.step);
      return step ? step.color : 'grey'; // Utilisation de la couleur grise par défaut si aucune correspondance n'est trouvée
    },
    async loadEvent(filter = null) {
      let events = []
      try {

        let listEvent = this.tasks
        if (listEvent) {
          for (let event of listEvent) {
            const step = this.steps.find(step => step.value === event.selectedStep);
            let color = step ? step.color : 'grey'; // Utilisation de la couleur grise par défaut si aucune correspondance n'est trouvée
            events.push({
              name: event.title,
              start: event.dateRange[0],
              end: event.dateRange[1],
              step: event.selectedStep,
              id: event._id,
              enabled: event.enabled,
              color: color,
              timed: true,
            })
          }

          if (!filter) {
            this.events = events
          }
          else {
            this.events = events.filter((e) => e.step == filter)
          }

          console.log("this.events", this.events);
        }

      } catch (error) {
        console.log("error", error);
      }
    },
    async getEvents({ start, end }) {
      try {
        await this.loadEvent()
        if (this.events.length) {
        }
        else {
          this.snackbar = true
          this.snack = 'Vous avez aucun évenements de prévu'
        }

      } catch (error) {

      }
    },
    dayFormatTitle(day) {
      const options = { weekday: 'long', month: 'long', day: 'numeric' }
      return new Date(day.date).toLocaleDateString('fr-FR', options)
    },
    // Fonction pour formater l'étiquette aria des jours
    dayFormatAriaLabel(day) {
      const options = { weekday: 'long', month: 'long', day: 'numeric' }
      return new Date(day.date).toLocaleDateString('fr-FR', options)
    },
  },
}
</script>


<style>
.event-name {
  padding-left: 5px;
  padding-top: 2px;
  padding-bottom: 5px;
}

.v-calendar .v-event {
  min-height: 30px !important;
}
</style>