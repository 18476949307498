<template>
    <v-card :loading="loading">


        <v-overlay @click="overlayImg = false" :value="overlayImg">
            <v-img max-width="100vw" max-height="100vh" class="resized-img" :src="overlayImgSrc"></v-img>
        </v-overlay>

        <v-dialog dark v-model="dialogMeteo" transition="dialog-top-transition" hide-overlay>
            <v-toolbar v-if="dataMeteo" color="primary" flat>
                <v-toolbar-title><v-icon>mdi-weather-partly-cloudy</v-icon> Météo sur {{ dataMeteoReg
                    }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialogMeteo = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card color="secondary" class="pa-2">
                <v-row>
                    <v-col cols="12">
                        <v-menu ref="menu" v-model="menuMeteo" :close-on-content-click="false"
                            transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="formattedDateMeteo"
                                    label="Sélectionnez une date antérieure de météo" prepend-icon="mdi-calendar"
                                    readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="dateMeteo" @input="fetchWeather"></v-date-picker>

                        </v-menu>
                    </v-col>
                </v-row>
                <v-card-text class="d-flex align-center justify-center">

                    <v-row v-if="dataHistoryMeteo.length">
                        <v-col cols="3" v-for="day, key in dataHistoryMeteo" :key="key">
                            <ul style="list-style-type: none">
                                <img :src="day.condition.icon" alt="">
                                <li>Météo: <b>{{ day.condition.text }}</b></li>
                                <li>Heure: <b>{{ key + 1 }}:00 h</b> <v-icon right
                                        small>mdi-clock-time-four-outline</v-icon></li>
                                <li>Température: <b>{{ day.temp_c }}° c.</b> <v-icon right
                                        small>mdi-coolant-temperature</v-icon></li>
                                <li>Humidité: <b>{{ day.humidity }}%</b> <v-icon right small>mdi-water</v-icon></li>
                                <li>Pression: <b>{{ day.pressure_mb }} mb.</b> <v-icon right
                                        small>mdi-coolant-temperature</v-icon></li>
                                <li>Indice UV: <b>{{ day.uv }} UV</b><v-icon right small>mdi-cloud</v-icon></li>
                                <li>Vent: <b>{{ day.vis_km }} km./h</b> <v-icon right small>mdi-wind-power</v-icon></li>
                            </ul>
                        </v-col>
                    </v-row>

                    <v-row v-else-if="dataMeteo.current">
                        <h3>Météo du jour</h3>
                        <v-col cols="12" sm="6">
                            <img :src="dataMeteo.current.condition.icon" alt="">
                            <ul style="list-style-type: none">
                                <li>Condition: {{ dataMeteo.current.condition.text }}</li>
                                <li>Température: {{ dataMeteo.current.temp_c }}° c.</li>
                                <li>Vent: {{ dataMeteo.current.wind_kph }} km./h</li>
                                <li>Pression: {{ dataMeteo.current.pressure_mb }} millibars</li>
                                <li>Humidité: {{ dataMeteo.current.humidity }} %</li>
                                <li>Couverture nuageuse: {{ dataMeteo.current.cloud }}%</li>
                                <li>Indice UV: {{ dataMeteo.current.uv }} UV Index</li>
                                <li>Raffale de vent: {{ dataMeteo.current.gust_kph }} km./h</li>
                            </ul>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-btn class="text-right" text @click="dialogMeteo = false">
                        Fermer la météo
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div id="postits" v-if="dialogCanvas = true && user.reader != true" class="pa-4">
            <draggable handle=".handle" v-model="postits" @end="updatePostitPositions" class="list_postit"
                :options="{draggable: '.postit'}">
                <div v-for="postit,index in postits" :key="postit.id" class="postit" :id="'postit-' + postit.id"
                    :style="{ top: postit.top + 'px', left: postit.left + 'px', backgroundColor: postit.color }">
                    <div>
                        <a @click="startDrag(postit, $event)" class="handle d-flex flex-row">
                            <v-icon>mdi-drag</v-icon>
                        </a>
                        <v-row cols="12" md="12" class="d-flex justify-space-between">
                            <v-col cols="10">
                                <h4 id="titlePostit" :class="{ 'white--text': isSwitched }" contenteditable>{{
                                    postit.title }}</h4>
                            </v-col>
                            <v-col cols="2">
                                <v-menu :max-height="300" v-model="menuIcon" :close-on-content-click="false"
                                    :nudge-width="200" offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on">
                                            {{ selectedIcon }}
                                        </span>
                                    </template>
                                    <v-card>
                                        <v-list>
                                            <v-list-item v-for="(emoji, key) in iconMap" :key="key"
                                                @click="selectIcon(emoji)">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ emoji }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-card>
                                </v-menu>
                            </v-col>

                        </v-row>

                        <v-row>
                            <v-col>
                                <p id="contentPostit" :class="{ 'white--text': isSwitched, 'pa-2': true }"
                                    contenteditable> {{ postit.content }}
                                </p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-color-picker v-model="postit.color" class="ma-2" elevation="5" hide-inputs
                                    hide-mode-switch></v-color-picker>
                            </v-col>
                            <v-col>
                                <v-switch class="hidable  white--text" v-model="isSwitched"
                                    label="Passer le texte en blanc"></v-switch>
                            </v-col>
                        </v-row>
                    </div>
                    <v-row class="d-flex justify-end mt-2">
                        <v-btn dark small rounded class="secondary"
                            @click="registerPostit"><v-icon>mdi-content-save-check</v-icon>Enregistrer</v-btn>
                        <v-btn dark small rounded class="primary" outlined
                            @click="clearPostit(postit.id)"><v-icon>mdi-close</v-icon>Annuler</v-btn>
                    </v-row>
                </div>
            </draggable>
        </div>


        <v-dialog content-class="dialogTrait" dark v-model="dialogTrait" class="pa-2" transition="dialog-top-transition"
            hide-overlay :width="300">
            <v-card color="secondary" class="pa-2">
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="Nom du trait" v-model="trait.name"
                                prepend-icon="mdi-pencil"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <p>Couleur du trait</p>
                            <v-color-picker dot-size="25" hide-mode-switch show-swatches label="Couleur"
                                swatches-max-height="200" v-model="trait.color"
                                @input="redessinerTrait"></v-color-picker>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Epaisseur" @blur="redessinerTrait" type="number"
                                v-model="trait.thickness" prepend-icon="mdi-pencil"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field cols="6" label="Longueur des traits" @blur="redessinerTrait"
                                placeholder="[2,2]" v-model="trait.length" prepend-icon="mdi-pencil"></v-text-field>
                            <v-text-field cols="6" label="Espace entre les traits" @blur="redessinerTrait"
                                placeholder="[2,2]" v-model="trait.spacing" prepend-icon="mdi-pencil"></v-text-field>
                        </v-col>

                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="!trait.name.length" color="primary" dark class="pa-3" @click="finishTrait">
                        <v-icon>mdi-check</v-icon> Terminer
                    </v-btn>
                    <v-btn text @click="dialogTrait = false">
                        Annuler
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog content-class="dialogTrait" dark v-model="dialogTraitLight" class="pa-2"
            transition="dialog-top-transition" hide-overlay :width="300">
            <v-card color="secondary" class="pa-2">
                <v-card-text>
                    <v-row>
                        <v-col cols="12" v-if="stepTraitLight == 1">
                            <v-text-field label="Trait de début" v-model="traitLight.name"
                                prepend-icon="mdi-pencil"></v-text-field>
                        </v-col>

                        <v-col cols="12" v-else-if="stepTraitLight == 2">
                            <v-text-field label="Trait de fin" v-model="traitLight.name"
                                prepend-icon="mdi-pencil"></v-text-field>
                        </v-col>

                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="stepTraitLight == 1" :disabled="!traitLight.name.length" color="primary" dark
                        class="pa-3" @click="nextTraitLight">
                        <v-icon>mdi-check</v-icon> Valider le début
                    </v-btn>
                    <v-btn v-else-if="stepTraitLight == 2" :disabled="!traitLight.name.length" color="primary" dark
                        class="pa-3" @click="finishTraitLight">
                        <v-icon>mdi-check</v-icon> Valider la fin
                    </v-btn>
                    <v-btn text @click="dialogTraitLight = false">
                        Annuler
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog dark v-model="dialogDescAlert" class="pa-2" transition="dialog-top-transition" 
            :width="600">
            <v-card color="secondary" class="pa-2">
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-textarea label="Description de l'alerte" v-model="alertTxt"
                                prepend-icon="mdi-pencil"></v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark class="pa-3" @click="confDescArrow">
                        <v-icon>mdi-alert-plus</v-icon> Ajouter
                    </v-btn>
                    <v-btn text @click="dialogDescAlert = false">
                        Annuler
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog dark v-model="dialogPied" class="pa-2" transition="dialog-top-transition" hide-overlay :width="500">
            <v-card color="secondary" class="pa-2">
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-select chips prepend-inner-icon="mdi-account-group" multiple v-model="operateursSelected"
                                :items="operateurs" clearable :item-text="itemText" item-value="id"
                                label="Choisissez le(s) membre(s) associée(s)" return-object></v-select>

                            <!-- Ajout de l'input pour l'upload de fichier -->
                            <v-file-input capture="environment" prepend-icon="mdi-camera" label="Ajouter une photo"
                                accept="image/*" v-model="photo" @change="handleFileUpload" outlined
                                dense></v-file-input>
                            <v-textarea label="Message à communiquer" v-model="alertTxt"
                                prepend-icon="mdi-pencil"></v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark class="pa-3" @click="alertPied">
                        <v-icon>mdi-alert-plus</v-icon> Alerter ce pied
                    </v-btn>
                    <v-btn text @click="dialogPied = false">
                        Annuler
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog dark v-model="dialogPoint" class="pa-2" transition="dialog-top-transition" hide-overlay :width="700">
            <v-card color="secondary">
                <v-toolbar color="primary" flat>
                    <v-toolbar-title><v-icon>mdi-fruit-grapes</v-icon> Identifier un pied</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogPoint = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text class="pa-2 pb-6">
                    <v-row>

                        <v-col v-if="isVigneron" cols="12">
                            <v-text-field label="Domaine" v-model="domaineModify"
                                prepend-icon="mdi-map-search"></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field label="Parcelle" v-model="parcelleModify"
                                prepend-icon="mdi-fence"></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field label="Sous-parcelle" v-model="subparcelleModify"
                                prepend-icon="mdi-fence"></v-text-field>
                        </v-col>



                        <v-col v-if="!isVigneron" cols="6">
                            <v-text-field label="Foret" v-model="foretModify" prepend-icon="mdi-pencil"></v-text-field>
                        </v-col>

                        <v-col v-if="!isVigneron" cols="6">
                            <v-text-field label="Coupe" v-model="coupeModify" prepend-icon="mdi-pencil"></v-text-field>
                        </v-col>

                        <v-col cols="6" v-if="!isVigneron">
                            <v-select prepend-icon="mdi-pencil" v-model="essenceModify" :items="essences"
                                label="Essence" outlined dense></v-select>
                        </v-col>

                        <v-col cols="6" v-if="!isVigneron">
                            <v-select prepend-icon="mdi-pencil" v-model="etatModify" :items="qualites" label="Etats"
                                outlined dense></v-select>
                        </v-col>

                        <v-col cols="12" sm="6" v-if="isVigneron">
                            <v-select prepend-icon="mdi-check-circle" v-model="etatModify"
                                :items="Object.keys(colorsEssences)" label="Etats" outlined dense></v-select>
                        </v-col>

                        <v-col sm="6" v-if="isVigneron" cols="12">
                            <v-select prepend-icon="mdi-bottle-wine" v-model="cepageModify" :items="cepages"
                                label="Cépages" outlined dense></v-select>
                        </v-col>

                        <v-col v-if="!isVigneron" cols="4" sm="6">
                            <v-text-field label="Hauteur" v-model="hauteurModify"
                                prepend-icon="mdi-pencil"></v-text-field>
                        </v-col>

                        <v-col v-if="!isVigneron" cols="4" sm="6">
                            <v-text-field label="Diamètre" v-model="diametreModify"
                                prepend-icon="mdi-pencil"></v-text-field>
                        </v-col>

                        <v-col v-if="!isVigneron" cols="12" sm="6">
                            <v-text-field label="Volume" v-model="volumeModify"
                                prepend-icon="mdi-pencil"></v-text-field>
                        </v-col>

                        <v-col v-if="!isVigneron" cols="12" sm="6">
                            <v-text-field label="Tarif" v-model="tarifModify" prepend-icon="mdi-pencil"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" v-if="isVigneron">
                            <v-slider thumb-label="always" :persistent-hint="true" :tick-size="4" :thumb-size="40"
                                color="orange" track-color="green" big v-model="perCepsModify" :min="0" :max="100"
                                step="5" label="% sur Ceps"></v-slider>
                        </v-col>

                        <v-col cols="12" sm="6" v-if="isVigneron">
                            <v-slider thumb-label="always" :persistent-hint="true" :tick-size="4" :thumb-size="40"
                                color="orange" track-color="green" big v-model="perGrappeModify" :min="0" :max="100"
                                step="5" label="% sur Grappe"></v-slider>
                        </v-col>

                        <v-col v-if="isVigneron" cols="3">
                            <v-text-field type="number" label="Allée" v-model="alleeModify"
                                prepend-icon="mdi-land-rows-vertical"></v-text-field>
                        </v-col>

                        <v-col v-if="isVigneron" cols="3">
                            <v-text-field type="number" label="Num du Pied" v-model="pointModify"
                                prepend-icon="mdi-map-marker-check"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="5">
                            <v-text-field label="Opérateur" v-model="operateurModify"
                                prepend-icon="mdi-account-box"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" v-if="isVigneron">
                            <v-textarea label="Ajouter un commentaire (ex: Stade phenologique)" v-model="commentModify"
                                prepend-icon="mdi-chat-processing" />
                        </v-col>

                        <v-col cols="12" sm="12" v-if="isVigneron">
                            <h3>Symptômes visibles</h3>
                            <v-row>
                                <v-col cols="auto">
                                    <v-checkbox v-model="symptomes" :label="allSymptomes[0]"
                                        :value="allSymptomes[0]"></v-checkbox>
                                </v-col>
                                <v-col cols="auto">
                                    <v-checkbox v-model="symptomes" :label="allSymptomes[1]"
                                        :value="allSymptomes[1]"></v-checkbox>
                                </v-col>
                                <v-col cols="auto">
                                    <v-checkbox v-model="symptomes" :label="allSymptomes[2]"
                                        :value="allSymptomes[2]"></v-checkbox>
                                </v-col>
                                <v-col cols="auto">
                                    <v-checkbox v-model="symptomes" :label="allSymptomes[3]"
                                        :value="allSymptomes[3]"></v-checkbox>
                                </v-col>
                                <v-col cols="auto">
                                    <v-checkbox v-model="symptomes" :label="allSymptomes[4]"
                                        :value="allSymptomes[4]"></v-checkbox>
                                </v-col>
                                <v-col cols="auto">
                                    <v-checkbox v-model="symptomes" :label="allSymptomes[5]"
                                        :value="allSymptomes[5]"></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-col>


                        <v-col v-show="false" cols="12" sm="6">
                            <v-menu ref="menu" v-model="menu" offset-y min-width="auto" :close-on-content-click="false"
                                :return-value.sync="dateModify" transition="scale-transition">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="dateModify" label="Date" prepend-icon="mdi-pencil"
                                        @input="updateDateModify" v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dateModify" locale="fr" @input="menu = false"></v-date-picker>
                            </v-menu>
                        </v-col>



                    </v-row>
                </v-card-text>

                <v-card-actions class="">
                    <v-btn cols="12" v-if="newPoint" color="success" rounded block class="pa-3" @click="addPied">
                        <v-icon>mdi-check-circle</v-icon> Ajouter ce pied
                    </v-btn>
                    <v-btn cols="12" color="primary" v-else rounded small class="pa-3" @click="modifyPied">
                        <v-icon>mdi-check-circle</v-icon> Modifier
                    </v-btn>
                    <v-btn cols="12" v-if="!newPoint" outlined dark rounded small class="pa-3" @click="removePied">
                        <v-icon>mdi-delete-empty</v-icon> Supprimer
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog dark v-model="dialogCreateParcelle" class="pa-2" transition="dialog-top-transition" hide-overlay
            :width="600">
            <v-toolbar color="primary" flat>
                <v-toolbar-title><v-icon>mdi-texture-box</v-icon> Gestion de parcelle</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialogCreateParcelle = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card color="secondary">
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="addParcelle" append-icon="mdi-pencil"
                                label="Nom de la parcelle"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field append-icon="mdi-ticket-confirmation-outline" v-model="legendeParcelle"
                                label="Légende de la parcelle"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea append-icon="mdi-map-legend" v-model="descriptionParcelle"
                                label="Description de la parcelle"></v-textarea>
                        </v-col>
                        <v-alert type="info" dense> La description apparaît quand on zoom suffisement sur
                            la parcelle.</v-alert>
                        <v-col cols="12" sm="4">
                            <v-select filled v-model="addParcelleColor" :items="colorsItem"
                                label="Choisissez une couleur" item-value="color" item-text="color" return-object>
                                <template v-slot:item="{ item }">
                                    <div style="display: flex; align-items: center;">
                                        <div
                                            :style="{ backgroundColor: item.color, width: '20px', height: '20px', marginRight: '10px' }">
                                        </div>
                                        {{ item.color }}
                                    </div>
                                </template>
                                <template v-slot:selection="{ item, index }">
                                    <div v-if="index === 0" style="display: flex; align-items: center;">
                                        <div
                                            :style="{ backgroundColor: item.color, width: '20px', height: '20px', marginRight: '10px' }">
                                        </div>
                                        {{ item.color }}
                                    </div>
                                </template>
                            </v-select>
                        </v-col>


                        <v-col cols="12" sm="8">
                            <v-select outlined append-icon="mdi-information" v-model="selectedType"
                                :items="parcellesTypes" label="Type de parcelles" single-line item-text="name"
                                item-value="value" :hint="selectedType ? selectedType.description : 'Votre parcellaire'"
                                return-object></v-select>
                        </v-col>


                        <v-col cols="12" sm="12">
                            <v-switch v-model="pictoParcelle"
                                label="Je préfère le pictogramme que la couleur de parcelle" inset></v-switch>
                        </v-col>



                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="!dialogEditParcelle" color="primary" @click="createParcelle">
                        <v-icon>mdi-plus</v-icon> Créer la parcelle
                    </v-btn>
                    <v-btn color="primary" v-else @click="editConfParcelle">
                        <v-icon>mdi-pencil</v-icon> Editer la parcelle
                    </v-btn>
                    <v-btn text @click="dialogCreateParcelle = false">
                        Annuler
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- <v-btn absolute dark fab bottom left color="pink" class="pulse" style="bottom: 30px" v-if="inventaires.length"
            @click="$emit('displayFilter')">
            <v-icon>mdi-calendar-clock</v-icon>
        </v-btn> -->





        <v-row ref="chips_legend_diametre" v-if="cluster == true && nameCluster == 'diametre'" dense class="pa-2">
            <v-col cols="auto" v-for="(  item, index  ) in   legendItems  " :key="index">
                <v-chip :style="{ backgroundColor: item.color, color: getContrastColor(item.color) }">
                    {{ item.diameter }}
                </v-chip>
            </v-col>
        </v-row>
        <v-row ref="chips_legend_hauteur" v-if="cluster == true && nameCluster == 'hauteur'" dense class="pa-2">
            <v-col cols="auto" v-for="(  item, index  ) in   legendItems2  " :key="index">
                <v-chip :style="{ backgroundColor: item.color, color: getContrastColor(item.color) }">
                    {{ item.diameter }}
                </v-chip>
            </v-col>
        </v-row>

        <v-row dense class="ma-1 justify-end">
            <v-btn outlined v-if="selectedInventaireObj.profil == 'forestier' && cluster == false && !nameCluster" small
                dab @click="seeCluster" dark color="secondary">🪵Diamètres</v-btn>

            <v-btn outlined v-if="selectedInventaireObj.profil == 'forestier' && cluster == false && !nameCluster" small
                @click="seeCluster2" dark color="secondary">📏Hauteurs</v-btn>

            <v-btn v-if="selectedInventaireObj.profil == 'forestier' && cluster == true" small @click="resetCluster"
                dark color="secondary"><v-icon left>mdi-graph</v-icon>
                Revenir
                sur les
                points</v-btn>
        </v-row>

        <v-row class="d-flex justify-space-between" v-if="cluster == false">
            <v-col :cols="12" v-if="inventaires.length && statsEssences.length" class="mx-auto  mb-3" max-width="100%">
                <v-slide-group style="background: transparent !important" ref="chips_legend" id="chips_legend"
                    show-arrows center-active v-model="selectEssence">
                    <v-slide-item style="background: transparent !important" class="mr-2"
                        v-for="(  item, key  ) in   statsEssences  " :key="key" v-slot="{ active, toggle }">
                        <v-scroll-y-transition>
                            <v-chip style="background: transparent !important" v-if="item.title != 'undefined'" outlined
                                class="primary--text" active-class="success--text" :input-value="isActive(item.title)"
                                @click="toggleChip(item.title)">
                                <span class="essenceStat" :style="`background-color: ${item.color}`"></span><b>{{
                                    item.nb
                                    }}</b> %
                                de {{
                                item.title }}
                            </v-chip>
                            <v-chip v-else outlined class="primary--text" active-class="secondary--text"
                                :input-value="active">
                                <span class="essenceStat" :style="`background-color: ${item.color}`"></span><b>{{
                                    item.nb
                                    }}</b> %
                                non
                                renseigné
                            </v-chip>

                        </v-scroll-y-transition>
                    </v-slide-item>
                </v-slide-group>

            </v-col>





            <!-- <div class="d-flex flex-row"> -->

            <!-- <v-speed-dial direction='top' :open-on-hover="true" v-if="!finishDraw" fab fixed bottom right
                    class="lg-and-up d-flex justify-start custom-position-desktop" v-model="fab">
                    <template v-slot:activator>
                        <v-btn color="pink" v-model="fab" dark fab>
                            <v-icon v-if="fab">
                                mdi-close
                            </v-icon>
                            <v-icon v-else>
                                mdi-plus
                            </v-icon>
                        </v-btn>
                    </template> -->

            <!-- <v-btn  fab dark small color="primary" @click="getTimeline">
                        <v-icon>mdi-timeline-check-outline</v-icon>
                    </v-btn> -->


            <!-- <v-btn v-if="selectedInventaireObj.profil == 'vigneron' && !isFreemium" fab dark small
                        :loading="loadingScreenshot" color="primary" @click="loadJSON">
                        <v-icon>mdi-fruit-grapes</v-icon>
                    </v-btn> -->


            <!-- <v-btn v-if="!isFreemium" fab dark small :loading="loadingEmail" color="teal" @click="sendEmail">
                        <v-icon>mdi-email</v-icon>
                    </v-btn> -->



            <!-- <v-btn
                        fab
                        dark
                        small
                        color="indigo"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn
                        fab
                        dark
                        small
                        color="red"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn> -->
            <!-- </v-speed-dial> -->


            <!-- <v-btn fab
                    fixed
                    bottom
                    right
                    style="bottom: 100px"
                    big v-if="!finishDraw" @click="createPoly" >
                        <v-icon>mdi-draw</v-icon>
                    </v-btn>
                    <v-btn fab
                    fixed
                    bottom
                    right
                    style="bottom: 100px"
                    big v-else  @click="() => { finishDraw = false; confArea() }">
                        <v-icon>mdi-check</v-icon>
                    </v-btn> -->



            <!-- <v-col
          cols="12"
          v-if="selectedInventaire"
        >
          <p><strong>Gérer mes parcelles</strong></p>

          <v-btn-toggle
            variant="outlined"
            primary
          >
            <v-btn color="primary" icon @click="createPoly">
              <v-icon>mdi-plus</v-icon>
            </v-btn>

            <v-btn color="primary" icon @click="seeParcelles = !seeParcelles">
              <v-icon>{{ seeParcelles ? 'mdi-eye-off': 'mdi-eye'}}</v-icon>
            </v-btn>

            <v-btn color="primary" icon>
              <v-icon dark>mdi-delete</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-col> -->


            <!-- <v-btn
                small
                color='primary'
                outlined
                rounded
                class="ma-2"
                ><v-icon>mdi-plus</v-icon>Créer une parcelle</v-btn>
           
            <v-switch 
                hide-details
                inset
               v-model="seeParcelles" 
               label="Voir les parcelles"></v-switch> -->

            <!-- </div> -->


        </v-row>

        <v-btn v-if="selectedInventaire && user.reader != true" style="position: absolute;bottom: 130px;right: 20px;z-index: 1;" fab dark big
            :loading="loadingScreenshot" :disabled="loadingScreenshot" color="info"
            @click="() => { loadingScreenshot = true; takePhoto() }">
            <v-icon>mdi-camera</v-icon>
        </v-btn>


        <v-snackbar v-model="snackbar">
            {{ snack }}
            <template v-slot:action="{ attrs }">
                <v-btn color="pink" icon text v-bind="attrs"
                    @click="snackbar = false"><v-icon>mdi-close</v-icon></v-btn>
            </template>
        </v-snackbar>

        <v-btn v-if="dialogIconsR == true" @click="dialogIcons = true; dialogIconsR = false" small color="primary"
            style="position: fixed; right: 0px; top: 50vh; z-index: 99"><v-icon
                class="mr-2">mdi-cogs</v-icon>Réglage</v-btn>
        <v-dialog v-model="dialogIcons" persistent max-width="300px" hide-overlay content-class="transparent-dialog2">
            <v-toolbar dark color="primary">
                <v-toolbar-title><v-icon>mdi-cogs</v-icon>Réglage</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialogIconsR = true; dialogIcons=false">
                    <v-icon>mdi-minus-circle</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card class="pa-2">
                <v-slider append-icon="mdi-ruler" label="Taille" @input="updateSvgColor" dense v-model="icon.size"
                    :min="4" :max="400" :thumb-label="true" tick-size="4" step="1" range hint="Sélectionner la taille">
                </v-slider>

                <v-text-field @blur="updatePopup" label="Titre" append-icon="mdi-pencil"
                    v-model="icon.title"></v-text-field>

                <v-text-field @blur="updatePopup" label="Description" append-icon="mdi-pencil"
                    v-model="icon.description"></v-text-field>



                <v-color-picker flat dot-size="25" hide-inputs hide-mode-switch show-swatches label="Couleur de l'icône"
                    swatches-max-height="200" v-model="icon.color" @input="updateSvgColor"></v-color-picker>

                <v-card-actions class="mt-4">
                    <v-spacer></v-spacer>
                    <v-btn @click="finishIcon" color="primary"><v-icon>mdi-check</v-icon>Terminer</v-btn>
                    <v-btn @click="cancelIcon" color="secondary" outlined><v-icon>mdi-close</v-icon>Annuler</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

        <v-dialog v-model="dialogViews" persistent max-width="300px" hide-overlay content-class="transparent-dialog">
            <v-select color="primary" ref="selectv" :autofocus="true" prepend-inner-icon="mdi-eye" v-model="viewItem"
                :items="filteredButtonsViews" item-value="view" item-text="title" label="Choisir la vue" solo
                :hide-details="true" success @change="changeViews">
            </v-select>
        </v-dialog>

        <v-tour name="stepsMap" :steps="steps" :options="labels" button-skip-label="Passer" button-next-label="Suivant"
            button-prev-label="Précédent"></v-tour>

        <v-sheet class="mx-auto" max-width="600" v-if="statsEssences.length"
            style="z-index:1; position: absolute; top: 660px;right: 10px; background-color: transparent">
            <div>
                <v-btn id="step_views" @click="dialogViews = true" v-model="fabView" small color="secondary"  dark
                    rounded>
                    <v-icon left v-if="fabView">mdi-cog</v-icon>
                    <v-icon left v-else>mdi-eye-off</v-icon>
                    {{ viewItem }}
                </v-btn>

                <!-- <v-slide-group show-arrows>
                    <v-slide-item v-for="(button, index) in filteredButtonsViews" :key="index">
                        <v-btn small :key="index" :color="button.color" dark rounded @click="changeViews(button.view)">
                            <v-icon left>{{ button.icon }}</v-icon> {{ button.correspondant ? button.correspondant :
        button.title }}
                        </v-btn>
                    </v-slide-item>
                </v-slide-group> -->

            </div>



        </v-sheet>

        <v-overlay :value="loadingPop" absolute>
            <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
        </v-overlay>
        <div ref="map" id="map_custom" :style="{ height: heightMap, width: widthMap }"></div>


        <v-card-actions class="d-none d-lg-flex d-xl-none justify-center">
            <v-btn @click="resizeMap" :color="heightMap == '580px' ? 'info' : 'amber'" dark rounded small>
                <v-icon>mdi-arrow-expand-vertical</v-icon>
                {{ heightMap == '580px' ? "Agrandir la carte" : "Réduire la carte" }}
            </v-btn>
        </v-card-actions>

        <v-btn color="primary" small rounded v-if="editedDraw" @click="editAreaParcelle"
            style="position: absolute;right: 53px;top: 83px;"><v-icon>mdi-draw-pen</v-icon> Terminer la
            parcelle</v-btn>

        <v-btn color="warning" small rounded outlined v-if="editedDraw" @click="diffParcelle"
            style="position: absolute;right: 280px;top: 83px;"><v-icon>mdi-draw-pen</v-icon> Différence entre
            parcelle</v-btn>

        <!-- <v-sheet class="d-flex justify-center">
            <v-radio-group row v-model="typeMap">
                <v-radio label="Satellite" color="primary" value="satellite-streets-v11"></v-radio>

                <v-radio label="Outdoor" color="primary" value="outdoors-v11"></v-radio>

                <v-radio label="Light" color="primary" value="light-v10"></v-radio>

                <v-radio label="Street" color="primary" value="streets-v12"></v-radio>

                <v-radio label="Dark" color="primary" value="dark-v11"></v-radio>

            </v-radio-group>
        </v-sheet> -->

        <MyArrow :zoom="map.getZoom()" :invt="selectedInventaire" :markerlngLat="markerlngLat" ref="arrow"
            v-if="addArrow" @addMarker="addMarker" @cancelMarker="cancelMarker" @refreshParent="addArrows" />

    </v-card>
</template>
<style scoped>
.resizable-content {
    height: 100%;
    width: 100%;
    background-color: aqua;
}
</style>
<script>

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import Mapbox from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import eauFond from '@/assets/img/pictos/2.png'
import cheminFond from '@/assets/img/pictos/15.png'
import entreprotFond from '@/assets/img/pictos/16.png'
import surveillanceFond from '@/assets/img/pictos/18.png'
import etangFond from '@/assets/img/pictos/19.png'
import humideFond from '@/assets/img/pictos/20.png'
import rareFond from '@/assets/img/pictos/21.png'
import habitatFond from '@/assets/img/pictos/22.png'
import observationFond from '@/assets/img/pictos/23.png'
import incendieFond from '@/assets/img/pictos/24.png'
import erosionFond from '@/assets/img/pictos/25.png'
import maladieFond from '@/assets/img/pictos/26.png'
import protegeesFond from '@/assets/img/pictos/27.png'
import randoFond from '@/assets/img/pictos/28.png'
import exploitationFond from '@/assets/img/pictos/29.png'
import archeoFond from '@/assets/img/pictos/30.png'
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import Photos from './Photos.vue'
import { Canvg } from 'canvg';

// import MapboxDraggable from 'mapbox-gl-draggable';

class MapboxGLButtonControl {
    constructor({
        className = "",
        title = "",
        eventHandler = evtHndlr
    }) {
        this._className = className;
        this._title = title;
        this._eventHandler = eventHandler;
    }

    onAdd(map) {
        this._btn = document.createElement("button");
        this._btn.className = "mapboxgl-ctrl-icon" + " " + this._className;
        this._btn.type = "button";
        this._btn.title = this._title;
        this._btn.onclick = this._eventHandler;

        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl- mapboxgl-ctrl";
        this._container.appendChild(this._btn);

        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }
}

class PitchToggle {
    constructor({ bearing = -20, pitch = 70, minpitchzoom = null }) {
        this._bearing = bearing;
        this._pitch = pitch;
        this._minpitchzoom = minpitchzoom;
    }

    onAdd(map) {
        this._map = map;
        let _this = this;

        this._btn = document.createElement("button");
        this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d";
        this._btn.type = "button";
        this._btn["aria-label"] = "Toggle Pitch";
        this._btn.onclick = function () {
            if (map.getPitch() === 0) {
                let options = { pitch: _this._pitch, bearing: _this._bearing };
                if (_this._minpitchzoom && map.getZoom() > _this._minpitchzoom) {
                    options.zoom = _this._minpitchzoom;
                }
                map.easeTo(options);
                _this._btn.className =
                    "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d";
            } else {
                map.easeTo({ pitch: 0, bearing: 0 });
                _this._btn.className =
                    "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d";
            }
        };

        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
        this._container.appendChild(this._btn);

        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }
}

import xhr from '../plugins/axios';

import mapboxgl from 'mapbox-gl';
import wineImage from '@/assets/683981.png';
import colors from './colors.json'
import essencesImp from './essences.json'
import moment from 'moment';
import html2canvas from 'html2canvas';
import Vue from 'vue'

export default {
    name: 'Inventaire',
    directives: {},
    props: ['parcellesTypes', 'dialogParcelleFix', 'parcelles', 'viewItem', 'loading', 'inventaires', 'traits', 'markers','postitsLoaded', 'arrows', 'galleries',
        'selectedInventaire', 'selectedInventaireObj', 'statsEssences', 'rail', 'operateurs', 'grille', 'zones', 'secteursShared', 'actionMarker'],
    components: {
    },
    computed: {
        labels() {
            return {
                labels: {
                    buttonSkip: 'Passer',
                    buttonNext: 'Suivant',
                    buttonPrevious: 'Précédent',
                    buttonStop: "Ok j'ai compris!"
                },
            }
        },
         formattedDate() {
            if (!this.postit.date) return '';
            const date = new Date(this.postit.date);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
            },
        maxDate() {
            return new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)
                .toISOString()
                .substring(0, 10);
        },
        dasharrayTrait(){
            return [parseInt(this.trait.length), parseInt(this.trait.spacing)]
        },
        filteredButtonsViews() {
            return this.buttonsViews.filter(
                button => !button.profil || button.profil == this.selectedInventaireObj.profil
            );
        },
        isFreemium() {
            return !this.user.subscribed
        },
        isVigneron() {
            return this.selectedInventaireObj.profil == 'vigneron'
        },
        typeMap: {
            get() {
                return this.$store.state.inventaire.typeMap;
            },
            set(value) {
                this.$store.dispatch('updateSelection', { key: 'typeMap', value });
            }
        },
    },
    created() {
        let nowaday = new Date()
        this.filteredChips = false
        this.dateMeteo = nowaday.toISOString().split('T')[0];
        if (this.rail) {
            this.heightMap = '1300px'
        }

        let user = localStorage.getItem("user");
        if (user) {
            this.user = JSON.parse(user)
            this.operateurModify = this.user.email
        }
        this.colorsEssences = colors
        this.essences = essencesImp.map((e) => e.essences)
    },
    beforeDestroy() {
        this.map.off('zoom', this.handleZoom);
    },
    async mounted() {
        mapboxgl.accessToken = this.accessToken;  // Remplacez par votre clé d'API Mapbox

        this.map = new mapboxgl.Map({
            container: this.$refs.map,
            style: `mapbox://styles/mapbox/${this.typeMap}`,
            center: [2.4522, 46.7097],  // Vous pouvez changer les coordonnées pour les centrées où vous voulez
            zoom: 4
        });



        


        this.map.on('click', 'secteur-label', (e) => {
            const properties = e.features[0].properties;
            const coordinates = e.lngLat;

            const attentionPanel = properties.TXT_OUT && properties.TXT_OUT != "null" ? '<strong>Attention 🔔:</strong> ' + properties.TXT_OUT + '<br>' : '';

            new mapboxgl.Popup()
                .setLngLat(coordinates)
                .setHTML(`
                 ${attentionPanel}
                <strong>Commune:</strong> ${properties.COMMUNE}<br>
                <strong>INSEE:</strong> ${properties.INSEE}<br>
                <strong>Secteur:</strong> ${properties.SECTEUR}<br>
                <strong>Texte:</strong> ${properties.TXT_OUT}
                <strong>Commune de Prospection:</strong> ${properties.COM_PROSP}<br>
                <strong>INSEE Prospection:</strong> ${properties.INSEE_PR}
            `).addTo(this.map);
        });



        let nav = new mapboxgl.NavigationControl();
        this.map.addControl(nav, 'top-right');

        this.map.on('load', async (e) => {


            // let resVolnay = await xhr.get('/get-volnay')
            // let volnay = resVolnay.data
            // console.log(volnay, "volnay");

            // this.map.addSource('geojson-volnay', {
            //     type: 'geojson',
            //     data: volnay
            // });

            // this.map.addLayer({
            //     id: 'geojson-layer-volnay',
            //     type: 'fill',
            //     source: 'geojson-volnay',
            //     layout: {},
            //     paint: {
            //         'fill-color': '#AB3443',
            //         'fill-opacity': 0.9
            //     }
            // });

            
        
            console.log("this.user.roles", this.user.roles);

            if(this.user.roles == 'fredon'){
                let res = await xhr.get('/get-communes')
                let comms = res.data
                this.map.addSource('geojson-comms', {
                    type: 'geojson',
                    data: comms
                });

                this.map.addLayer({
                    id: 'geojson-layer-comms',
                    type: 'fill',
                    source: 'geojson-comms',
                    layout: {},
                    paint: {
                        'fill-color': '#888888',
                        'fill-opacity': 0.2
                    }
                });


                this.map.addLayer({
                    id: 'outlinecomms',
                    type: 'line',
                    source: 'geojson-comms',
                    layout: {},
                    paint: {
                        'line-color': '#A52A2A', // Couleur orange
                        'line-width': 1,
                        'line-dasharray': [2, 2] // Style pointillé
                    }
                });

                this.map.addLayer({
                    id: 'commune-label',
                    type: 'symbol',
                    source: 'geojson-comms',
                    layout: {
                        'text-field': ['get', 'COMMUNE'],
                        'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
                        'text-size': 12,
                        'text-offset': [0, 1.5],
                        'text-anchor': 'top'
                    },
                    paint: {
                        'text-color': '#EE82EE'
                    }
                });

                this.map.addLayer({
                    id: 'secteur-label',
                    type: 'symbol',
                    source: 'geojson-comms',
                    layout: {
                        'text-field': ['get', 'SECTEUR'],
                        'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
                        'text-size': 12,
                        'text-offset': [0, -1.5],
                        'text-anchor': 'bottom'
                    },
                    paint: {
                        'text-color': '#ffffff'
                    }
                });


            }
          
         
            // if (e.key === 'Enter') {
            //     // Vérifie si l'utilisateur est en train de dessiner une ligne
            //     if (this.draw.getMode() === 'draw_line_string') {
            //         this.draw.changeMode('simple_select');
            //         console.log("laaa");
            //         // this.endTrait()
            //     }
            // }

            //TODO: faire du dynamic sur le zoom
            this.map.on('zoom', () => {
                this.handleZoom()

                if (this.map.getZoom() > 18) {
                    this.map.getStyle().layers.forEach(layer => {
                        if (layer.id.startsWith('lay-description-')) {
                            this.map.setLayoutProperty(layer.id, 'visibility', 'visible');
                        } else if (layer.id.startsWith('lay-legende-')) {
                            this.map.setLayoutProperty(layer.id, 'visibility', 'none');
                        }
                    });

                } else {
                    this.map.getStyle().layers.forEach(layer => {
                        if (layer.id.startsWith('lay-description-')) {
                            this.map.setLayoutProperty(layer.id, 'visibility', 'none');
                        } else if (layer.id.startsWith('lay-legende-')) {
                            this.map.setLayoutProperty(layer.id, 'visibility', 'visible');
                        }
                    });
                }
            });

            this.onMapLoaded(e); 
            if (this.inventaires.length) {
                this.moveTo()
            }
        });


             
    if (this.$route.query.first === "true") {
      this.snack = "Veillez séléctionner une vue"
      this.snackbar = true
      setTimeout(() =>{
          this.$tours['stepsMap'].start()
      }, 2000)
    }



    },
    data(vm) {
        return {
            savePoints: [],
            symptomes: [],
            allSymptomes: [
                "Jaunissement des feuilles",
                "Enroulement des feuilles",
                "Chute des grappes",
                "Décoloration des nervures",
                "Absence d’aoûtement",
                "Port érigé des rameaux",
            ],
            steps: [
                {
                    target: '#step_views',
                    header: {
                        title: 'Vue',
                    },
                    params: {
                        placement: 'right'
                    },
                    content: 'Choisissez votre donnée à afficher',
                },
                // {
                //   target: '#v-step-1',  // We're using document.querySelector() under the hood
                //   header: {
                //     title: 'Vos filtres',
                //   },
                //   params: {
                //     placement: 'left'
                //   },
                //   content: 'Personnalisez vos filtres ici',
            ],
            addArrow: false,
            
            isSwitched: false,
            menuIcon: false,
            selectedIcon: '🏠',
            iconMap: {
                'mdi-home': '🏠',
                'mdi-account': '👤',
                'mdi-alert': '⚠️',
                'mdi-pencil': '✏️',
                'mdi-bell': '🔔',
                'mdi-camera': '📷',
                'mdi-cart': '🛒',
                'mdi-email': '✉️',
                'mdi-heart': '❤️',
                'mdi-map': '🗺️',
                'mdi-phone': '📞',
                'mdi-settings': '⚙️',
                'mdi-information': 'ℹ️',
                'mdi-lock': '🔒',
                'mdi-help-circle': '❓',
                'mdi-power': '🔌',
                'mdi-magnify': '🔍',
                'mdi-menu': '🍔',
                'mdi-key': '🔑',
                'mdi-wifi': '📶',
                'mdi-bluetooth': '📡',
                'mdi-battery': '🔋',
                'mdi-volume-high': '🔊',
                'mdi-brightness-6': '🔆',
                'mdi-calendar': '📅',
                'mdi-clock': '🕒',
                'mdi-close': '❌',
                'mdi-check': '✔️',
                'mdi-download': '⬇️',
                'mdi-upload': '⬆️'
            },
            loadingPop : false,
            menu: false,
            postits: [],
            postit : {},
            activePicker: null,
            draggingPostit: null,
            offsetX: 0,
            offsetY: 0,
            popup: null,
            icon: {
                type: 'danger',
                rotate: 0,
                size: 50,
                color: "#ffaa22",
                title: '',
                description: ''
            },

            selectedType: { value: 'parcelle', name: 'Parcelles et Sous-Parcelle', description: 'Type de parcelle' }, // Type de parcelle par défaut

            dialogViews: false,
            imageCanvas: null,
            canvas: null,
            dialogIcons: false,
            dialogIconsR: false,
            viewsOptions: [],
            cluster: false,
            buttonsViews: [
            ],
            colorsItem: this.generateRandomColors(),
            activeChips: [],
            photo: null,
            pointDrag: null,
            cepages: [
                "Alicante H Bouschet",
                "Aligoté",
                "Alphonse Lavallée",
                "Auxerrois",
                "Cabernet franc",
                "Cabernet sauvignon",
                "Carignan noir",
                "Chardonnay",
                "Chasselas",
                "Chenin blanc",
                "Cinsaut",
                "Colombard",
                "Cot",
                "Fer servadou",
                "Folle blanche",
                "Gamay",
                "Gewurztraminer",
                "Grenache",
                "Grenache blanc",
                "Grenache gris",
                "Grolleau",
                "Gros manseng",
                "Gros Vert",
                "Loin de l’œil",
                "Macabeu",
                "Malbec",
                "Melon de Bourgogne",
                "Merlot",
                "Mourvèdre",
                "Muscadelle",
                "Muscat blanc à petits grains",
                "Muscat d’Alexandrie",
                "Muscat de Hambourg",
                "Négrette",
                "Niellucio",
                "Petit Manseng",
                "Pinot blanc",
                "Pinot gris",
                "Pinot meunier",
                "Pinot noir",
                "Riesling",
                "Sangiovese",
                "Sauvignon blanc",
                "Sciaccarello",
                "Sémillon",
                "Syrah",
                "Sylvaner",
                "Tannat",
                "Tempranillo",
                "Ugni blanc",
                "Vermentino",
                "Viognier",
                "Viura",
            ],
            essences: [],
            qualites: [
                'Bonne', 'Brogneux', 'Chablis', 'Chauffage', 'Excellente',
                'Moyenne',
                'Gélif', 'Mort à conserver', 'Mauvaise', 'Médiocre',
                'Sain', 'Sec', 'Semis', 'Taillis', 'Taré', 'Très bonne',
                'Très mauvaise', 'Très médiocre', 'Toutes', 'Alpha',
                'Bravo', 'Charlie', 'Delta', 'Plot', 'Poteau', 'Sciage',
                'Avivé', 'Merrain', 'Palette', 'Emballage', 'Déroulage',
                'Charpente', 'Traverse', 'Piquet', 'Canter', 'Bûche',
            ],
            cepageModify: "",
            domaineModify: "",
            foretModify: "",
            coupeModify: "",
            essenceModify: "",
            el: null,
            volumeModify: "",
            diametreModify: "",
            hauteurModify: "",
            tarifModify: "",
            etatModify: "",
            stopSaisie: false,
            sens: "mdi-arrow-right",
            commentModify: "",
            perCepsModify: 0,
            perGrappeModify: 0,
            operateurModify: "",
            parcelleModify: "",
            subparcelleModify: "",
            alleeModify: "",
            pointModify: "",
            dateModify: null,
            parcelleSelected: null,
            layerSelected: null,
            menu: false,
            dataMeteo: {},
            dataMeteoReg: "",
            dataHistoryMeteo: {},
            dateMeteo: '',
            overlayImg: false,
            overlayImgSrc: "",
            formattedDateMeteo: '',
            dialogMeteo: false,
            selectedFilter: null,
            alertTxt: "",
            widthMap: '100%',
            changeView: false,
            heightMap: '580px',
            parcellesClean: [],
            fab: false,
            mapInstance: {},
            geoJsonSource: {
                id: "alpha"
            },
            geoJsonLayer: {
                //...some GeoJSON layer configuration object
            },
            allMarkers: [], 
            allGalleries: [], 
            allArrows: [],
            parallelLines: [],
            allPoints: [],
            results: [],
            currentPopup: null,
            handleClickReference: null,
            dialogCreateParcelle: false,
            dialogEditParcelle: false,
            addParcelle: '',
            legendeParcelle: '',
            descriptionParcelle: '',
            pictoParcelle: false,
            addParcelleColor: '#ab3443',
            selectEssence: null,
            arbreSelected: [],
            clearInv: false,
            asyncActions: {},
            map: {},
            loadingScreenshot: false,
            snackbar: false,
            snack: "",
            seeParcelles: true,
            popin: null,
            inventairesSearch: [],
            indexParcellex: [],
            loadGeoloc: true,
            dialogLoad: false,
            geoloc: { lat: 45.764043, lng: 4.835659 },
            nomInventaire: "",
            geoInventaire: null,
            selectedFile: null,
            accessToken: 'pk.eyJ1IjoidGFpd2E2OSIsImEiOiJjbHR5aXlpYWowaHEyMmlvamV2Z2h2M25zIn0.AO8gp7k8K94wLW7sTlGlDA', // your access token. Needed if you using Mapbox maps
            mapStyle: 'mapbox://styles/mapbox/outdoors-v12', // your map style
            coordinates: [4.834277, 45.76342],
            finishDraw: false,
            currentShape: null,
            loadingEmail: false,
            dialogPied: false,
            dialogTrait: false,
            dialogDescAlert: false,
            dialogTraitLight: false,
            stepTraitLight: 1,
            traitOne: {},
            traitTwo: {},
            trait:{
                name: "",
                color: "#0000FF",
                thickness: 2,
                spacing: 2,
                length: 2,
                coordinates: []
            },
            traitLight: {
                name: "Début",
                color: "#7f00ff",
                thickness: 4,
                spacing: 2,
                length: 2,
                coordinates: []
            },
            nameTrait: "",
            dialogCanvas: false,
            dialogGrille: false,
            colorTrait: "#0000FF",
            epaisseurTrait: 2,
            dasharrayTraitE: 3,
            dasharrayTraitL: 2,
            dialogPoint: false,
            operateursSelected: null,
            idEvt: null,
            evtProp: null,
            idEvt: null,
            gpsEvt: null,
            fabView: 'etat',
            longLat: {},
            newPoint: false,
            showPoint: false,
            editedDraw: false,
            draw: null,
            filteredChips: false,
            latModify: "",
            featuresAlert: null,
            imageURL: '/img/icons/arrow.png',
            dragging: false,
            imageCoordinates: [],
            dragOffset: { x: 0, y: 0 },
            longModify: "",
            nameCluster: null,
            legendItems: [
                { diameter: "0-9 cm.", color: "#ffdfe4" },
                { diameter: "10-19 cm.", color: "#ffb6c1" },
                { diameter: "20-29 cm.", color: "#ee9ca5" },
                { diameter: "30-39 cm.", color: "#dd8289" },
                { diameter: "40-49 cm.", color: "#cd686e" },
                { diameter: "50-59 cm.", color: "#bc4e52" },
                { diameter: "60-69 cm.", color: "#ac3437" },
                { diameter: "70-79 cm.", color: "#9b1a1b" },
                { diameter: "80 cm+", color: "#8b0000" }
            ],
            legendItems2: [
                { diameter: "1 à 3 m.", color: "#ffffff" },
                { diameter: "3 à 6 m.", color: "#fff2f4" },
                { diameter: "6 à 8 m.", color: "#ffdfe4" },
                { diameter: "8 à 11 m.", color: "#ffb6c1" },
                { diameter: "11 à 14 m.", color: "#ee9ca5" },
                { diameter: "14 à 17 m.", color: "#dd8289" },
                { diameter: "17 à 20 m.", color: "#cd686e" },
                { diameter: "20 à 23 m.", color: "#bc4e52" },
                { diameter: "23 à 26 m.", color: "#ac3437" },
                { diameter: "Plus de 26 m.", color: "#9b1a1b" },
            ],
            marker: null,
            drawin: null,
            goDrag: false,
            longitudeMarker: null,
            latitudeMarker: null

        }
    },
    methods: {
        resetNew(){
            this.savePoints = []
        },
        addGrille(){
            this.dialogGrille = true
        },
        signalerPied(lineIndex, pointIndex,  longitude, latitude){
            if(this.popup){
                this.popup = null
            }
            this.alleeModify = lineIndex 
            this.pointModify = pointIndex
            
            // const coords = e.lngLat;
            this.dialogPoint = true
            this.newPoint = true
            this.latModify = latitude
            this.longModify = longitude
        },
        updateGrille() {
            if (!this.trait.length) return false

            let line1 = this.traitOne;
            let line2 = this.traitTwo;
            // console.log(this.traits, "this.traits");

            // Function to interpolate points between two lines
            let interpolatePoints = (pt1, pt2, factor) => {
                return [
                    pt1[0] + (pt2[0] - pt1[0]) * factor,
                    pt1[1] + (pt2[1] - pt1[1]) * factor
                ];
            }

            // Generate 10 parallel lines between line1 and line2
            this.parallelLines = [];
            let numLines = parseInt(this.grille.nbR);

            if (!numLines || numLines <= 0) return false 

            for (let i = 1; i <= numLines; i++) {
                let factor = i / (numLines + 1); // Create equally spaced lines between the two base lines
                let start = interpolatePoints(line1.coordinates[0], line2.coordinates[0], factor);
                let end = interpolatePoints(line1.coordinates[1], line2.coordinates[1], factor);
                this.parallelLines.push(turf.lineString([start, end]));
            }

            let generatePointsOnLine = (line, interval, lineIndex) => {
                let points = [];
                let length = turf.length(line, { units: 'meters' });
                for (var i = 0; i <= length; i += interval) {
                    let point = turf.along(line, i, { units: 'meters' });
                    point.properties = { line: lineIndex, point: Math.floor(i / interval) };
                    points.push(point);
                }
                return points;
            }

            this.allPoints = [];
            let nbP = parseFloat(this.grille.nbP)
            if (!nbP || nbP <= 0) return false 

            this.parallelLines.forEach((line, index) => {
                let points = generatePointsOnLine(line, nbP, index + 1);
                this.allPoints = this.allPoints.concat(points);
            });
            const layers = [
                'base-lines-layer',
                'parallel-lines-layer',
                'points-layer',
                'points-hover-layer'
            ]
            const sources = [
                'base-lines-source',
                'parallel-lines-source',
                'points-source'
            ]

            layers.forEach(layer => {
                    if (this.map.getLayer(layer)) {
                        this.map.removeLayer(layer);
                    }
            });

            sources.forEach(source => {
                if (this.map.getSource(source)) {
                    this.map.removeSource(source);
                }
            });

            this.map.addSource('base-lines-source', {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': [line1, line2]
                }
            });

            this.map.addLayer({
                'id': 'base-lines-layer',
                'type': 'line',
                'source': 'base-lines-source',
                'layout': {},
                'paint': {
                    'line-color': '#FF0000',
                    'line-width': 2
                }
            });

             this.map.addSource('parallel-lines-source', {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': this.parallelLines
                }
            });

            this.map.addLayer({
                'id': 'parallel-lines-layer',
                'type': 'line',
                'source': 'parallel-lines-source',
                'layout': {},
                'paint': {
                    'line-color': '#0000FF',
                    'line-width': 1
                }
            });
            
            this.map.addSource('points-source', {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': this.allPoints
                }
            });

            this.map.addLayer({
                'id': 'points-layer',
                'type': 'circle',
                'source': 'points-source',
                'layout': {},
                'paint': {
                    'circle-color': '#EE82EE',
                    'circle-radius': 2
                }
            });


            // Add a popup on hover
            this.popup = new mapboxgl.Popup({
                closeButton: true,
                closeOnClick: true
            });
        
            let vm = this

            this.map.on('click', 'points-layer', (e) => {
                this.map.getCanvas().style.cursor = 'pointer';


                let coordinates = e.features[0].geometry.coordinates.slice();
                let longitude = coordinates[0];
                let latitude = coordinates[1];
                let lineIndex = e.features[0].properties.line;
                let pointIndex = e.features[0].properties.point;
             
                let description = `
                    <h3>Données du pied de vigne</h3>
                    <ul class="ma-2">
                        <li>Rangée (NE): ${lineIndex}</li>
                        <li>Pied (NE): ${pointIndex}</li>
                    </ul>
                    
                    <button  id="signal-button"  class="signal-button pa-2 v-btn v-btn--icon btn-primary primary">
                        <i class="v-icon mdi mdi-alert"></i> Signaler ce pied
                    </button>
                `;

                // Ensure that if the map is zoomed out such that multiple copies of the feature are visible,
                // the popup appears over the copy being pointed to.
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                this.popup.setLngLat(coordinates).setHTML(description).addTo(this.map);

                document.querySelectorAll('.signal-button').forEach(button => {
                    button.addEventListener('click', () => {
                        this.popup.remove();
                        this.$emit('closeSetting')
                        this.signalerPied(lineIndex, pointIndex, longitude, latitude);
                    });
                });

            });

            this.map.on('mouseleave', 'points-layer', () => {
                this.map.getCanvas().style.cursor = '';
                // popup.remove();
            });

        },
        updateCoordinates(lng, lat){
            this.longitudeMarker = lng;
            this.latitudeMarker = lat;
        },
        async addArrows(){
            this.addArrow = false
            this.snackbar = true
            this.snack = "Votre flèche a bien été enregistrée"
            this.refreshParent()
        },
       
        async cancelMarker() {
            if (this.marker) {
                this.marker.remove();
                this.addArrow = false
            }
        },
        async addMark(el, long, lat){
            // let center = this.map.getCenter();

            // if (this.marker) {
            //     center = this.marker.getLngLat()
            //     this.marker.remove();
            // }   
            // console.log(el, "el");
            this.marker = new mapboxgl.Marker({ element: el, draggable: true })
                .setLngLat([long, lat])
                .addTo(this.map);

            return this.marker;

         },
        async addMarker(el){
            let center = this.map.getCenter();

            if (this.marker) {
                center = this.marker.getLngLat()
                this.marker.remove();
            }

            this.marker = new mapboxgl.Marker({ element: el, draggable: true })
                .setLngLat(center)
                .addTo(this.map);

            this.marker.on('dragend', () => {
                const lngLat = this.marker.getLngLat();
                this.markerlngLat = lngLat
            });
            // this.map.flyTo({ center, zoom: this.map.getZoom() });

            // this.updateMarkerSize();


            // this.map.flyTo({ center: [0, 0], zoom: 3 });

            this.snackbar = true
            this.snack = "Déplacer votre fleche pour la positionner sur la carte"
        },
        async deletePostit(postit){
                await xhr.post('/delete-postit', {
                    postitId: postit._id,
                    invt: this.selectedInventaire
                })
                this.snackbar = true
                this.snack = "Votre postit a bien été supprimé"
                this.$emit('refreshParent')
        },
        selectIcon(icon) {
            this.selectedIcon = icon;
            this.menuIcon = false;
        },
        save(date) {
            this.postit.date = date;
            this.menu = false;
        },
        async registerPostit(){
            try {
                this.postit.title = document.getElementById('titlePostit').innerText
                  this.postit.content = document.getElementById('contentPostit').innerText
                let res = await xhr.post('/add-postit', {
                    title:  this.postit.title, content: this.postit.content, img: this.postit.img,
                    coordinates: this.postit.coordinates, invt: this.selectedInventaire
                })
                this.postits =  []
                this.$emit("refreshParent")
            } catch (error) {
                console.log("error", error);
            }
            //
           // this.postits = this.postits.filter(p => p.id != id)
        },  
        clearPostit(id){
            if (this.map.getLayer(`layer-drag-${id}`)) {
                this.map.removeLayer(`layer-drag-${id}`)
            }
            
            if (this.map.getSource(`source-drag-${id}`)){
                this.map.removeSource(`source-drag-${id}`)
            }

            this.postits = this.postits.filter(p => p.id != id)
        },
        startDrag(postit, event) {
            if(!this.goDrag){
                this.goDrag = true
                this.postit = postit
                this.draggingPostit = postit;
                this.offsetX = event.clientX - postit.left;
                this.offsetY = event.clientY - postit.top;
                document.addEventListener('mousemove', this.onDrag);
            } else {
                this.loadingPop = true
                this.stopDrag(event)
            }

        },
        onDrag(event) {
                if (this.draggingPostit) {
                    this.draggingPostit.left = event.clientX - this.offsetX;
                    this.draggingPostit.top = event.clientY - this.offsetY;
                }
        },
        stopDrag(event) {
            this.goDrag = false
            document.removeEventListener('mousemove', this.onDrag);
                if (this.draggingPostit) {
                    
                    let element = document.getElementById(`postit-${this.draggingPostit.id}`);
                    let dragIcon = element.querySelector('.mdi-drag');
                    let colorPicker = element.querySelector('.v-color-picker');
                    let dflex = element.querySelector('.v-btn');

                    if (dragIcon) {
                        dragIcon.style.display = 'none'; 
                    }

                    if (dflex) {
                        dflex.style.display = 'none';
                    }

                    if (colorPicker) {
                        colorPicker.style.display = 'none';
                    }

                    element.querySelectorAll('button').forEach(element => {
                        let primaryIcon = element;
                        if (primaryIcon) {
                            primaryIcon.style.display = 'none';
                        }
                    });
                    element.querySelectorAll('.hidable').forEach(element => {
                        let primaryIcon = element;
                        if (primaryIcon) {
                            primaryIcon.style.display = 'none';
                        }
                    });

                    
                
                        html2canvas(element, { backgroundColor: null, scale: 2, }).then(canvas => {
                        
                        if (dragIcon) {
                            dragIcon.style.display = 'block'; 
                        }

                        element.querySelectorAll('button').forEach(element => {
                            let primaryIcon = element;
                            if (primaryIcon) {
                                primaryIcon.style.display = 'block';
                            }
                        });
                           element.querySelectorAll('.hidable').forEach(element => {
                            let primaryIcon = element;
                            if (primaryIcon) {
                                primaryIcon.style.display = 'block';
                            }
                        });


                        if (colorPicker) {
                            colorPicker.style.display = 'block';
                        }

                        if (dflex) {
                            dflex.style.display = 'block';
                        }

                        let imgSrc = new Image();
                        imgSrc.id = `imgpostit-${this.draggingPostit.id}`
                        imgSrc.src = canvas.toDataURL("image/png");
                        
                        const point = new mapboxgl.Point(event.clientX, event.clientY);
                        const geoCoordinates = this.map.unproject(point);
                        imgSrc.onload = () => {
                            this.addImageToMap(geoCoordinates, imgSrc.src, imgSrc);
                            this.draggingPostit = null;
                        };
                    });

                }
    },
    addImageToMap(geoCoordinates, imgSrc, img) {
        // this.map.setZoom(15)
        const zoomLevel = this.map.getZoom();
        const earthCircumferenceKm = 40075; // Circonférence de la Terre en kilomètres
        const baseResolution = earthCircumferenceKm / 256; // Résolution au niveau de zoom 0
        const resolutionAtZoom = baseResolution / Math.pow(2, zoomLevel); // Résolution à votre niveau de zoom

        const pixelOffset = -40; // décalage en pixels
        const kmPerDegree = 110; // km par degré de latitude
        const kmPerPixel = resolutionAtZoom; // 100 pixels = 1 km
        const degreesOffset = (pixelOffset * kmPerPixel) / kmPerDegree;

        // Assumer que l'image est approximativement centrée autour de geoCoordinates
            // Vous devrez ajuster ces valeurs en fonction de la taille de l'image et de la précision souhaitée
        const pixelsPerDegree = 0.00001; // Cette valeur est à titre d'exemple
        // Facteur d'agrandissement
        const scaleFactor = 1 //1.67;


        // Récupérer la largeur et la hauteur de l'image
        const naturalWidth = img.naturalWidth;
        const naturalHeight = img.naturalHeight;

        // console.log(naturalWidth, naturalHeight );

        const imgWidthInKm = naturalWidth * kmPerPixel / 6; // Convertir les pixels en km
        const imgHeightInKm = naturalHeight * kmPerPixel / 10; // Convertir les pixels en km

        const imgWidthInDegrees = imgWidthInKm / kmPerDegree;
        const imgHeightInDegrees = imgHeightInKm / kmPerDegree;


        const coordinates = [
            [geoCoordinates.lng - imgWidthInDegrees / 2, geoCoordinates.lat + imgHeightInDegrees / 2 - degreesOffset], // coin nord-ouest
            [geoCoordinates.lng + imgWidthInDegrees / 2, geoCoordinates.lat + imgHeightInDegrees / 2 - degreesOffset], // coin nord-est
            [geoCoordinates.lng + imgWidthInDegrees / 2, geoCoordinates.lat - imgHeightInDegrees / 2 - degreesOffset], // coin sud-est
            [geoCoordinates.lng - imgWidthInDegrees / 2, geoCoordinates.lat - imgHeightInDegrees / 2 - degreesOffset]  // coin sud-ouest
        ];

        
        this.postit.img = imgSrc

        if (this.map.getLayer(`layer-drag-${this.draggingPostit.id}`)) {
            this.map.removeLayer(`layer-drag-${this.draggingPostit.id}`)
        }
        
        if (this.map.getSource(`source-drag-${this.draggingPostit.id}`)){
            this.map.removeSource(`source-drag-${this.draggingPostit.id}`)
        }
        this.postit.coordinates = coordinates

        this.map.addSource(`source-drag-${this.draggingPostit.id}` , {
            type: 'image',
            url: imgSrc,
            coordinates: coordinates
        });

        this.map.addLayer({
            'id': `layer-drag-${this.draggingPostit.id}`,
            type: 'raster',
            source: `source-drag-${this.draggingPostit.id}`,
            paint: {
                'raster-fade-duration': 0
            }
        });
        this.loadingPop = false

        // console.log("imgSrc", imgSrc);
        },
        updatePostitPositions() {
            // console.log("updated !", this.draggingPostit);
        // Code pour mettre à jour les positions des post-its si nécessaire
        },

         async deleteArrow(id){
             let res = await xhr.post('/remove-arrows', {
                invt: this.selectedInventaire,
                id
             })

            this.snackbar = true
            this.snack = "Votre flèche a bien été supprimée"
            this.clearMap()
            // await this.$emit("refreshParent")
        },  

        async deleteMarker(id){
         
            let res = await xhr.post('/remove-marker', {
                invt: this.selectedInventaire,
                id
             })
                if(this.popup){
                this.popup.remove(); // Fermer la popup courante
                this.popup = null; 
            }

            
            this.snackbar = true
            this.snack = "Votre marqueur a bien été supprimé"
            this.clearMap()
            // await this.$emit("refreshParent")
        },  
        async cancelIcon(){
            this.icon.title = ""
            this.icon.description = ""
            this.dialogIcons = false
            this.popup = null
            this.drawin = null
            if (this.marker) {
                this.marker.remove(); // Supprime le marqueur de la carte
                this.marker = null; // Optionnel, réinitialise la variable si nécessaire
            }   
        },
        async addPhotos(){
            this.snackbar = true
            this.snack = "Déplacer votre gallerie de photo sur la carte"

            let dataUrl = `https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/Icons8_flat_gallery.svg/768px-Icons8_flat_gallery.svg.png?20160307113428`;
              
                const el = document.createElement('div');
                el.style.backgroundImage = `url(${dataUrl})`;
                el.style.width = `30px`;
                el.style.height = `30px`;
                el.style.backgroundSize = 'cover';
                el.style.transformOrigin = 'center';
                // Création du marqueur s'il n'existe pas déjà
                const lngLat = this.map.getCenter();
                this.longitudeMarker = lngLat.lng
                this.latitudeMarker = lngLat.lat

                let marker = new mapboxgl.Marker({
                    draggable: true,
                    element: el
                }).setLngLat(this.map.getCenter())

                const overlay = (src) =>{
                    this.overlayImg = true
                    this.overlayImgSrc = src
                }

                const save = () => {

                }

                const galleryComponent = new Vue({
                    render: h => h(Photos, {
                    props: {
                        invId: this.selectedInventaire,
                        longitudeMarker: this.longitudeMarker,
                        latitudeMarker: this.latitudeMarker
                    },
                    on: {
                        overlayImg: overlay,
                        save: save
                    }
                }),
                }).$mount()

                // console.log("galleryComponent", galleryComponent);

                 let popup = new mapboxgl.Popup({
                     closeButton: true,
                    closeOnClick: true
                })
                await popup.setDOMContent(galleryComponent.$el)
                
                marker.setPopup(popup).addTo(this.map).on('dragend', () => {
                    const lngLat = marker.getLngLat();
                    this.updateCoordinates(lngLat.lng, lngLat.lat);
                });

                // this.marker.setPopup(this.popup); // Associer le popup au marqueur
                marker.getElement().addEventListener('click',  () =>  {
                    console.log("laa updated");
                   marker.setPopup(popup);
                    // this.popup.addTo(this.map); // Ceci est utile si vous avez besoin de contrôler précisément quand le popup est ajouté à la carte
                });

                // Optionally, open the popup immediately after adding the marker
                this.popup.addTo(this.map)
                


        },
        clearMarkers() {
            if(this.allMarkers){
                this.allMarkers.forEach(marker => marker.remove());
            }
            this.allMarkers = [];
        },
        clearGalleries() {
            if (this.allGalleries) {
                this.allGalleries.forEach(marker => marker.remove());
            }
            this.allGalleries = [];
        },
        clearArrows() {
            if (this.allArrows) {
                this.allArrows.forEach(marker => marker.marker.remove());
            }
            this.allArrows = [];
        },
        async finishIcon(){
           
            const lngLat = this.marker.getLngLat();

            //XHR
            let res = await xhr.post('/add-marker', {
                invt: this.selectedInventaire,
                color: this.icon.color, 
                rotate: this.icon.rotate,
                size: this.icon.size,
                type: this.icon.type,
                title: this.icon.title, 
                description: this.icon.description, 
                latitude: lngLat.lat, 
                longitude: lngLat.lng
            })
            this.snackbar = true
            this.snack = "Votre marqueur a bien été ajouté à l'inventaire"

            this.icon.title = ""
            this.icon.description = ""
            this.dialogIcons = false
            this.popup = null
            this.drawin = null
            this.marker.setDraggable(false);
            if (this.marker) {
                this.marker.remove(); // Fermer la popup courante
                this.marker = null;
            }
            if (this.popup) {
                this.popup.remove(); // Fermer la popup courante
                this.popup = null;
            }

            this.$emit('refreshParent')
            
        },
        updateSvgRotate() {
            if (this.drawin) {

                let svgElement = this.drawin.node;
                let serializedSVG = new XMLSerializer().serializeToString(svgElement);
                let encodedData = encodeURIComponent(serializedSVG);
                let dataUrl = `data:image/svg+xml;utf8,${encodedData}`;
                this.addImageMarkerToMap(dataUrl);
            }
        },
        updateSvgColor() {
            if (this.drawin) {
                this.drawin.find('path').stroke(this.icon.color); // Met à jour la couleur des éléments 'path', ajustez selon vos besoins
                
                let svgElement = this.drawin.node;
                let serializedSVG = new XMLSerializer().serializeToString(svgElement);
                let encodedData = encodeURIComponent(serializedSVG);
                let dataUrl = `data:image/svg+xml;utf8,${encodedData}`;

                this.addImageMarkerToMap(dataUrl);
            }
        },
        addImageMarkerToMap(dataUrl) {
            if (!this.marker) {
                const el = document.createElement('div');
                el.style.backgroundImage = `url(${dataUrl})`;
                el.style.width = `${this.icon.size}px`;
                el.style.height = `${this.icon.size}px`;
                // el.style.transform = `rotate(${this.icon.rotate}deg)`;
                el.style.backgroundSize = 'cover';
                el.style.transformOrigin = 'center';

                // Création du marqueur s'il n'existe pas déjà
                this.marker = new mapboxgl.Marker({
                    draggable: true,
                    element: el
                }).setLngLat(this.map.getCenter()).addTo(this.map);
        } else {
            const el = this.marker.getElement();
            el.style.width = `${this.icon.size}px`;
            el.style.height = `${this.icon.size}px`;
            el.style.backgroundImage = `url(${dataUrl})`;
        }

            this.updatePopup()
        },
      
         async loadSVG(name = 'danger.svg') {
            const response = await import(`@/assets/svg/${name}?raw`);
            let svgContent = response.default;
            const svgText = svgContent;
            //  console.log("svgText", svgText);
            return svgText;
        },

        async loadSVGArrow(name = 'danger.svg') {
            // const response = await fetch(require(`@/assets/arrows/arrow${name}`));
            const response = await import(`@/assets/arrows/arrow${name}?raw`);
            let svgContent = response.default;
            const svgText = svgContent;
            return svgText;
        },
      

        async loadSvgAndAddMarker(type) {
            const svg = await this.loadSVG(type)
            console.log(this.icon.size, this.icon.size, "svg")
            this.drawin = SVG(svg).size(this.icon.size, this.icon.size); // Assurez-vous que la taille est appropriée pour le marqueur
            this.updateSvgColor(); 
           
        },
        
        updatePopup(){
            this.popup = new mapboxgl.Popup({
                closeButton: true,
                closeOnClick: false
            }).setHTML(`<h1>${this.icon.title}</h1><p>${this.icon.description}</p>`);

            this.marker.setPopup(this.popup); // Associer le popup au marqueur
            this.marker.getElement().addEventListener('click',  function()  {
                this.popup.addTo(this.map); // Ceci est utile si vous avez besoin de contrôler précisément quand le popup est ajouté à la carte
            });
        },
         handleZoom() {
            let zoomLevel = this.map.getZoom();
             zoomLevel = Math.ceil(zoomLevel) 

            const threshold = 15; 
            this.allArrows.forEach(marker => {

                if (zoomLevel >= Math.floor(marker.zoom) && zoomLevel <= Math.floor(marker.zoom)) {
                    marker.marker.getElement().style.display = 'block';
                 } else {
                    marker.marker.getElement().style.display = 'none';

                 }
             });

            this.allMarkers.forEach(marker => {
                if (zoomLevel < threshold) {
                marker.getElement().style.display = 'none';
                } else {
                marker.getElement().style.display = 'block';
                }
            });

            if (this.marker) {
                // const baseSize = 100; // Base size factor to adjust the sensitivity of resizing
                // const width = this.width * (zoom / baseSize);
                // const height = this.height * (zoom / baseSize);
                // const markerElement = this.marker.getElement();
                // markerElement.style.width = `${width}px`;
                // markerElement.style.height = `${height}px`;
                const zoom = this.map.getZoom();
                // let size;
                const markerElement = this.marker.getElement();
                // // Récupérer les dimensions actuelles de l'élément
                // const boundingRect = markerElement.getBoundingClientRect();
                // const currentWidth = boundingRect.width;
                // const currentHeight = boundingRect.height;

                // if (zoom < 5) {
                //     size = Math.max(10, (zoom * 20) / 5); // Adjust the size calculation as needed
                // }
                // if (zoom < 8) {
                //     size = Math.max(10, (zoom * 20) / 3); // Adjust the size calculation as needed
                // }
                // else if (zoom < 10) {
                //     size = Math.max(10, (zoom * 20) / 2); // Adjust the size calculation as needed
                // } 
                // else {
                //     size = currentWidth; // Adjust the size calculation as needed
                // }

                if (zoom < 15) {
                    markerElement.style.display = 'none'
                } 
                else {
                      markerElement.style.display = 'block'
                }
        

                // markerElement.style.width = `${size}px`;
                // markerElement.style.height = `${size}px`;
            }
        },
        async addPicto(type){
            this.icon.type = type
            this.dialogIcons = true
            this.loadSvgAndAddMarker(type + '.svg')
        }, 
        getStyles() {
            return [
                // Style par défaut pour les lignes
                {
                    "id": "gl-draw-line",
                    "type": "line",
                    "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-color": this.trait.color, // Couleur de la ligne
                        "line-dasharray": this.dasharrayTrait,
                        "line-width": this.trait.thickness // Épaisseur de la ligne
                    }
                }
            ]
        },
         getStylesLight() {
            return [
                // Style par défaut pour les lignes
                {
                    "id": "gl-draw-line",
                    "type": "line",
                    "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-color": this.traitLight.color, // Couleur de la ligne
                        "line-dasharray": this.dasharrayTrait,
                        "line-width": this.traitLight.thickness // Épaisseur de la ligne
                    }
                }
            ]
        },
        redessinerTrait() {
            const allData = this.draw.getAll();
            const lineWidth = parseInt(this.trait.thickness);
            const lineColor = this.trait.color;  // Assurez-vous que cette valeur est définie quelque part dans vos données
            const lineStyle = this.dasharrayTrait;  // 'solid' ou [2, 2] pour pointillés, par exemple
            const styles = this.map.getStyle();
            // Mettre à jour le style de la couche de ligne
            if (this.map.getLayer('gl-draw-line.cold')) {
                this.map.setPaintProperty('gl-draw-line.cold', 'line-width', lineWidth);
                this.map.setPaintProperty('gl-draw-line.cold', 'line-color', lineColor);
                this.map.setPaintProperty('gl-draw-line.cold', 'line-dasharray',  lineStyle);
            }

               if (this.map.getLayer('gl-draw-line.hot')) {
                this.map.setPaintProperty('gl-draw-line.hot', 'line-width', lineWidth);
                this.map.setPaintProperty('gl-draw-line.hot', 'line-color', lineColor);
                   this.map.setPaintProperty('gl-draw-line.hot', 'line-dasharray',  lineStyle);
            }
        },
         addFirstTrait(){
            this.snackbar = true
            this.snack = "Appuyer sur Entrée pour terminer le trait"

            if (this.draw) {
                this.map.removeControl(this.draw);
            }

            this.draw = new MapboxDraw({
                displayControlsDefault: false,
                controls: {
                    line_string: true,
                    trash: true
                },
                styles: this.getStylesLight()
            })
            
            this.map.addControl(this.draw, 'top-left');
            this.map.on('draw.create', this.endTraitLight);
            this.map.on('draw.update', this.endTraitLight);
            this.draw.changeMode('draw_line_string');
            this.map.getCanvas().style.cursor = 'crosshair';
        },

        addTrait(){
            this.snackbar = true
            this.snack = "Appuyer sur Entrée pour terminer le trait"

            this.draw = new MapboxDraw({
                displayControlsDefault: false,
                controls: {
                    line_string: true,
                    trash: true
                },
                styles: this.getStyles()
            })

            this.map.addControl(this.draw, 'top-left');
            this.map.on('draw.create', this.endTrait);
            this.map.on('draw.update', this.endTrait);
            this.draw.changeMode('draw_line_string');
            this.map.getCanvas().style.cursor = 'crosshair';
            
        },
        async clearFleche(){
             if (this.map.getLayer('flecheLayer')) {
                this.map.removeLayer('flecheLayer');
            }

             // Vérifiez et supprimez l'ancienne source et couche si elles existent
            if (this.map.getSource('flecheImage')) {
                this.map.removeSource('flecheImage');
            }

           
        },
        addFleche() {
            this.snackbar = true;
            this.snack = "Editez et glissez la flèche sur la carte";
            this.loadArrow = true
            this.addArrow = true
        },

        addNote() {
            this.snackbar = true;
            this.snack = "Editez et glissez le postit sur la carte";

            this.clearFleche()
          
            this.postits =  [...this.postits, 
                { title: "Votre titre", content: "Votre contenu", id: this.postits.length,  top: 0, left: 0,  color: '#FF22AA' }
            ]
            this.postit =   { title: "Votre titre", content: "Votre contenu" }

        },
        endTraitLight() {
            // this.map.getCanvas().style.cursor = '';
            this.dialogTraitLight = true;
        },
        endTrait(){
            this.map.getCanvas().style.cursor = '';
            this.dialogTrait = true;
        },
         async clearMap(){
            this.map.getCanvas().style.cursor = '';
            this.dialogTrait = false;
             if (this.draw) {
                this.map.removeControl(this.draw);
                this.draw = null;
            }

            this.currentShape = null
            const layers = [
                'gl-draw-polygon-fill',
                'gl-draw-polygon-stroke-active',
                'gl-draw-polygon-stroke-inactive',
                'gl-draw-line-inactive',
                'gl-draw-line-active',
                'gl-draw-polygon-and-line-vertex-stroke-inactive',
                'gl-draw-polygon-and-line-vertex-inactive',
                'gl-draw-point-point-stroke-inactive',
                'gl-draw-point-inactive',
                'gl-draw-point-stroke-active',
                'gl-draw-point-active',
           'gl-draw-line.cold', 'gl-draw-line.hot', 'gl-draw-polygon.cold', 'gl-draw-polygon.hot', 'original-parcelle'];

             layers.forEach(layer => {
                 if (this.map.getLayer(layer)) {
                     this.map.removeLayer(layer);
                 }
             });

              // Remove the source if it exists
                const drawSources = ['gl-draw-polygon', 'gl-draw-line', 'gl-draw-point'];
                drawSources.forEach(source => {
                    if (this.map.getSource(source)) {
                        this.map.removeSource(source);
                    }
                });
                

            
            await this.$emit("refreshParent")
        },
        async finishTrait() {
             if (this.currentPopup) {
                this.currentPopup.remove()
            }
             let data = this.draw.getAll();
            let coordinates = data.features[0].geometry.coordinates;
            let res = await xhr.post('/add-trait', {
                invt: this.selectedInventaire,
                trait: {
                    ...this.trait, coordinates: coordinates}
            })

            // this.dialogPoint = false

            this.dialogTrait = false;
            setTimeout(() =>{
                this.$emit('refreshParent')
            }, 200)

        },
        async nextTraitLight(){
            if (this.currentPopup) {
                this.currentPopup.remove()
            }
            let data = this.draw.getAll();
            let coordinates = data.features[0].geometry.coordinates;
            this.traitOne = {
                    trait: this.traitLight, coordinates: coordinates
            }
            // let res = await xhr.post('/add-trait', {
            //     invt: this.selectedInventaire,
            //     trait: {
            //         ...this.traitLight, coordinates: coordinates
            //     }
            // })
            this.addFirstTrait()
            setTimeout(() => {
                // this.$emit('refreshParent')
                this.stepTraitLight = 2;
                this.traitLight.name = "Fin"
            }, 200)


            
        },

        async finishTraitLight() {
            if (this.currentPopup) {
                this.currentPopup.remove()
            }
            let data = this.draw.getAll();
            let coordinates = data.features[0].geometry.coordinates;
            // let res = await xhr.post('/add-trait', {
            //     invt: this.selectedInventaire,
            //     trait: {
            //         ...this.traitLight, coordinates: coordinates}
            // })

            this.traitTwo = {
                trait: this.traitLight, coordinates: coordinates
            }

            this.dialogPoint = false
            this.traitLight.name = "Début"
            this.stepTraitLight = 1;

            setTimeout(() =>{
                this.dialogTraitLight = false
                // this.$emit('refreshParent')
                this.$emit('openSetting')

            }, 200)
        },
        itemText(item) {
            return `${item.firstname} ${item.lastname}`;
        },
        deselectCadastre() {

            if (this.map.getLayer('cadastre-layer')) {
                this.map.removeLayer('cadastre-layer')
            }
            if (this.map.getLayer('cadastre-codes')) {
                this.map.removeLayer('cadastre-codes')
            }
            if (this.map.getSource('cadastre')) {
                this.map.removeSource('cadastre')
            }


        },
        deselectFeuille() {

            if (this.map.getLayer('feuille-layer')) {
                this.map.removeLayer('feuille-layer')
            }
            if (this.map.getLayer('feuille-codes')) {
                this.map.removeLayer('feuille-codes')
            }
            if (this.map.getSource('feuille')) {
                this.map.removeSource('feuille')
            }


        },
        addFeuille(data) {
            this.deselectFeuille()
            // this.map.flyTo({
            //     zoom: 14 // Remplacez par le niveau de zoom souhaité
            // });

            // Ajouter la Feature Collection comme source sur la carte
            this.map.addSource('feuille', {
                type: 'geojson',
                data: data
            });

            // Créer une couche utilisant la source GeoJSON pour afficher les parcelles cadastrales
            this.map.addLayer({
                id: 'feuille-layer',
                type: 'line', // Utiliser 'line' ou 'symbol' selon le type de données et l'effet désiré
                source: 'feuille',
                layout: {},
                paint: {
                    'line-color': '#3b0034', // Couleur des lignes
                    'line-opacity': 0.9, // Transparence des lignes
                    'line-width': 2 // Épaisseur des lignes
                }
            });

            this.map.addLayer({
                id: 'feuille-codes',
                type: 'symbol',
                source: 'feuille', // Assurez-vous que c'est le nom de votre source GeoJSON
                layout: {
                    'text-field': ['concat', ['get', 'section'], ' ', ['get', 'feuille']], // Exemple: combine section et feuille
                    'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
                    'text-radial-offset': 0.5,
                    'text-justify': 'auto',
                    'text-size': 14,

                },
                paint: {
                    'text-color': '#000000', // Couleur du texte
                    'text-halo-color': '#FFFFFF', // Couleur de l'halo autour du texte pour améliorer la lisibilité
                    'text-halo-width': 2, // Largeur de l'halo autour du texte
                }
            });

        },
        addCadastre(data) {
            this.deselectCadastre()
            // this.map.flyTo({
            //     zoom: 14 // Remplacez par le niveau de zoom souhaité
            // });

            // Ajouter la Feature Collection comme source sur la carte
            this.map.addSource('cadastre', {
                type: 'geojson',
                data: data
            });

            // Créer une couche utilisant la source GeoJSON pour afficher les parcelles cadastrales
            this.map.addLayer({
                id: 'cadastre-layer',
                type: 'line', // Utiliser 'line' ou 'symbol' selon le type de données et l'effet désiré
                source: 'cadastre',
                layout: {},
                paint: {
                    'line-color': '#088', // Couleur des lignes
                    'line-opacity': 0.9, // Transparence des lignes
                    'line-width': 2 // Épaisseur des lignes
                }
            });

            this.map.addLayer({
                id: 'cadastre-codes',
                type: 'symbol',
                source: 'cadastre', // Assurez-vous que c'est le nom de votre source GeoJSON
                layout: {
                    'text-field': ['concat', ['get', 'numero'], ' '], // Exemple: combine section et feuille
                    'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
                    'text-radial-offset': 0.5,
                    'text-justify': 'auto',
                    'text-size': 12,

                },
                paint: {
                    'text-color': '#FFFFFF', // Couleur du texte
                    'text-halo-color': '#000000', // Couleur de l'halo autour du texte pour améliorer la lisibilité
                    'text-halo-width': 2, // Largeur de l'halo autour du texte
                }
            });

        },
        getContrastColor(color) {
            const rgb = parseInt(color.substring(1), 16); // Convertir hex en rgb
            const r = (rgb >> 16) & 0xff;
            const g = (rgb >> 8) & 0xff;
            const b = (rgb >> 0) & 0xff;

            // Calculer la luminance
            const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
            return luminance > 0.5 ? 'black' : 'white'; // Couleur du texte en fonction de l,a luminance
        },
        restClustLayers() {
            if (this.map && this.map.getLayer('clusters')) {
                this.map.removeLayer('clusters');
            }
            if (this.map && this.map.getLayer('unclustered-point')) {
                this.map.removeLayer('unclustered-point');
            }
        },
        resetCluster() {
            this.restClustLayers()
            this.map.resize();
            this.nameCluster = null

            this.changeViews("essence")
            this.cluster = false
            this.$emit("refreshParent")
        },
        seeCluster() {
            this.restClustLayers()
            this.changeViews("diametre")
            this.nameCluster = 'diametre'
            this.cluster = true

            if (this.map && this.map.getLayer('inventaires-layer')) {
                this.map.removeLayer('inventaires-layer');
            }
            if (this.map && this.map.getSource('inventaires')) {
                this.map.removeSource('inventaires');
            }

            const geojson = {
                'type': 'geojson',
                'data': {
                    "type": "FeatureCollection",
                    'features': this.results
                },
                cluster: true,
                clusterMaxZoom: 10,
                clusterRadius: 80
            }

            this.map.addSource("inventaires", geojson)
            this.map.addLayer({
                id: 'clusters',
                type: 'circle',
                source: 'inventaires',
                filter: ['has', 'diametre'],
                paint: {
                    "circle-opacity": 0.6,
                    // Sélectionner une couleur basée sur le diamètre
                    "circle-color": [
                        "step",
                        ["to-number", ["get", "diametre"]],
                        "#ffdfe4", // Couleur par défaut
                        10, "#ffb6c1", // Pour diamètre >= 30cm
                        20, "#ee9ca5", // Pour diamètre >= 30cm
                        30, "#dd8289", // Pour diamètre >= 30cm
                        40, "#cd686e", // Pour diamètre >= 40cm
                        50, "#bc4e52", // Pour diamètre >= 50cm
                        60, "#ac3437", // Pour diamètre >= 60cm
                        70, "#9b1a1b",  // Pour diamètre >= 70cm
                        80, "#8b0000"  // Pour diamètre >= 70cm
                    ],
                    // Ajuster le rayon du cercle basé sur la propriété 'diametre'
                    "circle-radius": [
                        "interpolate", ["linear"], ["to-number", ["get", "diametre"]],
                        10, 3,
                        20, 8,
                        30, 12,
                        40, 15,
                        50, 20,
                        60, 25,
                        70, 35,
                        80, 50
                    ],
                }
            });
            this.map.addLayer({
                id: 'unclustered-point',
                type: 'circle',
                source: 'inventaires',
                filter: ['!', ['has', 'point_count']],
                paint: {
                    'circle-color': '#11b4da',
                    'circle-radius': 0,
                    'circle-stroke-width': 0,
                }
            });

            // Zoomer sur un cluster lors du clic
            this.map.on('click', 'clusters', (e) => {
                // Obtenir les coordonnées du point sur lequel on a cliqué
                const coordinates = e.features[0].geometry.coordinates;


                const feature = e.features[0];

                // Extrait la propriété 'diametre' (assurez-vous qu'elle est bien présente)
                const diametre = feature.properties.diametre;


                let idEvt = e.features[0].properties.id
                let evtProp = e.features[0].properties

                let ignores = ['clef', 'commentaire', 'date', 'commentaires', 'iddevice', 'idarbre', 'volume', 'longitude', 'latitude', 'symptomes']
                let correspondances = {
                    volume: {
                        title: "Volume",
                        value: "volume"
                    },
                    spar: {
                        title: "Parcelle",
                        value: "spar"
                    },
                    trfchaude: {
                        title: "trfchaude",
                        value: "Tarif Chaudé"
                    },
                    hautmoy: {
                        title: "hautmoy",
                        value: "Hauteur Moy."
                    },
                    par: {
                        title: "par",
                        value: "Parcelle"
                    },
                    frt: {
                        title: "frt",
                        value: "Forêt"
                    },
                };

                let correspondancesVal = {
                    date: {
                        icon: "📅"
                    },
                    heure: {
                        icon: "⌚"
                    },
                    hauteur: {
                        unite: "m.",
                        icon: "📏"
                    },
                    volume: {
                        unite: "m3",
                        icon: "🪵"
                    },
                    diametre: {
                        unite: "cm.",
                        icon: "🟤"
                    },
                    essence: {
                        icon: "🌲"
                    },
                    utilisateur: {
                        icon: "👷‍♂️"
                    },
                    commune: {
                        icon: "🏙️"
                    },
                };

                let properties = e.features[0].properties;
                let ucfirst = (str) => str.charAt(0).toUpperCase() + str.slice(1);

                let listeHtml = Object.keys(properties)
                    .filter(key => !ignores.includes(key) && key.length >= 3)
                    .map(key => {
                        let value = key === 'operateur' && !properties[key] ? 'Non renseigné' : properties[key];
                        value = key === 'date' && properties[key] ? this.formatDate(properties[key]) : value;
                        value = key === 'coupe' && properties[key] && properties[key] == "-" ? "Non renseigné" : properties[key];
                        value = key === 'symptomes' && properties[key] ? properties[key].join(" , ")  : "Non renseigné"  ;

                        let formattedKey = ucfirst(key.replace(/[_-]/g, ' '));
                        let keybas = key.toLowerCase()
                        return `<li>${correspondancesVal[keybas] ? correspondancesVal[keybas].icon : "✅"} ${formattedKey}: <b>${value}</b> ${correspondancesVal[keybas] && correspondancesVal[keybas].unite ? correspondancesVal[keybas].unite : ""}</li>`;
                    })
                    .join('');

                let dateHtml = properties.date ? `<p style="font-size: 13px; margin-left: 20px"><small>📅 Fait le: <b>${this.formaterDateEnFrancais(properties.date)}</b></small></p>` : '';
                let defaultColor = "#ffcc00"; // Mettez la couleur par défaut que vous souhaitez utiliser

                // 
                let col = this.statsEssences.find((e) => e.title == properties.essence)
                let stateColor = col["color"] ? col["color"] : defaultColor;
                let cepageContent = properties.essence ? `${properties.essence}` : ''; // Affiche le cépage suivi d'un tiret si présent.

                let html = `<div class="boxPopup"> 
                                        <h3 style="margin-bottom: 15px"><span class="etat-titre" style="background-color:${stateColor} !important">${cepageContent} ${properties.etat ? properties.etat : ''}</span></h3>
                                            ${dateHtml}
                                            <hr style="border-color: #f46370" />
                                            <ul class="ma-liste">${listeHtml}</ul>
                                            <div cols="12">
                                                <a  class="v-btn v-btn-one v-btn--primary modify-btn v-btn--rounded  v-btn--colorful pa-1" id="modifyLocationLink" data-action="modify" data-id="${idEvt}"> <span>🖋️</span> Modifier</a>
                                                <a class="v-btn v-btn-one v-btn--primary modify-btn v-btn--rounded  v-btn--colorful pa-1" id="modifyLocationLinkTwo" data-action="move" data-id="${idEvt}"> <span>📌</span> Déplacer</a>
                                                <a class="v-btn v-btn-one v-btn--primary alert-btn v-btn--rounded  v-btn--outlined pa-1" id="alertLink" data-action="modify" data-id="${idEvt}"> <span>👷‍♂️</span> Alerter</a>
                                            </div>
                                        </div>`

                this.currentPopup = new mapboxgl.Popup()
                    .setLngLat(e.lngLat)
                    .setHTML(html)
                    .addTo(this.map);

                document.querySelectorAll('.modify-btn').forEach(btn => {
                    btn.addEventListener('click', (event) => {
                        let action = event.target.dataset.action;
                        let id = event.target.dataset.id;

                        if (action === 'move') {
                            this.modifyGps(e.lngLat, id, evtProp);
                        } else if (action === 'modify') {
                            this.modifyPoint(e.lngLat, id, evtProp);
                        }

                        event.preventDefault();
                    });
                });

                document.querySelectorAll('.alert-btn').forEach(btn => {
                    btn.addEventListener('click', (event) => {
                        let id = event.target.dataset.id;
                        this.alertPoint(e.lngLat, id, evtProp);
                        event.preventDefault();
                    });
                });




                // Crée une nouvelle infobulle (Popup) et l'attache à la carte
                // new mapboxgl.Popup()
                //     .setLngLat(e.lngLat) // Définit la position du Popup au point cliqué
                //     .setHTML(`<h4>Diamètre de l'arbre: ${diametre} cm</h4>`) // Définit le contenu HTML du Popup
                //     .addTo(this.map);

                // Centrer la carte sur le cluster et ajuster le zoom
                this.map.easeTo({
                    center: coordinates,
                    zoom: 18
                });
            });
            // Assurer la redimension de la carte
            this.map.resize();


        },
        seeCluster2() {
            this.restClustLayers()
            this.changeViews("hauteur")
            this.nameCluster = 'hauteur'
            this.cluster = true

            if (this.map && this.map.getLayer('inventaires-layer')) {
                this.map.removeLayer('inventaires-layer');
            }
            if (this.map && this.map.getSource('inventaires')) {
                this.map.removeSource('inventaires');
            }

            const geojson = {
                'type': 'geojson',
                'data': {
                    "type": "FeatureCollection",
                    'features': this.results
                },
                cluster: true,
                clusterMaxZoom: 10,
                clusterRadius: 80
            }

            this.map.addSource("inventaires", geojson)
            this.map.addLayer({
                id: 'clusters',
                type: 'circle',
                source: 'inventaires',
                filter: ['has', 'hauteur'],
                paint: {
                    // Sélectionner une couleur basée sur le diamètre
                    "circle-color": [
                        "step",
                        ["to-number", ["get", "hauteur"]],
                        "#ffdfe4", // Couleur par défaut
                        3, "#ffffff", // Pour diamètre >= 30cm
                        6, "#fff2f4", // Pour diamètre >= 30cm
                        8, "#ee9ca5", // Pour diamètre >= 30cm
                        11, "#dd8289", // Pour diamètre >= 30cm
                        14, "#cd686e", // Pour diamètre >= 40cm
                        17, "#bc4e52", // Pour diamètre >= 50cm
                        20, "#ac3437", // Pour diamètre >= 60cm
                        23, "#9b1a1b",  // Pour diamètre >= 70cm
                        26, "#8b0000"  // Pour diamètre >= 70cm
                    ],
                    // Ajuster le rayon du cercle basé sur la propriété 'diametre'
                    "circle-radius": [
                        "interpolate", ["linear"], ["to-number", ["get", "hauteur"]],
                        3, 3,
                        6, 6,
                        8, 10,
                        11, 15,
                        14, 20,
                        17, 35,
                        20, 50,
                        23, 65,
                        26, 70
                    ],
                }
            });
            this.map.addLayer({
                id: 'unclustered-point',
                type: 'circle',
                source: 'inventaires',
                filter: ['!', ['has', 'point_count']],
                paint: {
                    'circle-color': '#11b4da',
                    'circle-radius': 0,
                    'circle-stroke-width': 0,
                }
            });

            // Zoomer sur un cluster lors du clic
            this.map.on('click', 'clusters', (e) => {
                const coordinates = e.features[0].geometry.coordinates;
                const feature = e.features[0];
                const hauteur = feature.properties.hauteur;

                new mapboxgl.Popup()
                    .setLngLat(e.lngLat) // Définit la position du Popup au point cliqué
                    .setHTML(`<h4>Hauteur de l'arbre: ${hauteur} m.</h4>`) // Définit le contenu HTML du Popup
                    .addTo(this.map);

                this.map.easeTo({
                    center: coordinates,
                    zoom: 18
                });
            });
            // Assurer la redimension de la carte
            // this.map.resize();


        },
        resizeImage(file) {
            return new Promise((resolve, reject) => {
                const img = document.createElement('img');
                const canvas = document.createElement('canvas');
                const reader = new FileReader();

                reader.onload = (e) => {
                    img.src = e.target.result;

                    img.onload = () => {
                        const MAX_WIDTH = 800; // Largeur maximale souhaitée
                        const MAX_HEIGHT = 600; // Hauteur maximale souhaitée
                        let width = img.width;
                        let height = img.height;

                        // Ajuster les dimensions si nécessaire
                        if (width > height) {
                            if (width > MAX_WIDTH) {
                                height *= MAX_WIDTH / width;
                                width = MAX_WIDTH;
                            }
                        } else {
                            if (height > MAX_HEIGHT) {
                                width *= MAX_HEIGHT / height;
                                height = MAX_HEIGHT;
                            }
                        }

                        canvas.width = width;
                        canvas.height = height;

                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0, width, height);

                        // Convertir le canvas en Blob
                        canvas.toBlob((blob) => {
                            resolve(blob);
                        }, 'image/jpeg', 0.7); // Ajuster la qualité ici
                    };
                };

                reader.onerror = error => reject(error);

                // Lire le fichier sélectionné
                reader.readAsDataURL(file);
            });
        },
        async handleFileUpload(event) {
            // const files = event.target.files || event; // Gérer les deux cas
            // if (!files.length) return;

            // const file = files[0];
            // const resizedImage = await this.resizeImage(file);

            // // Mettre à jour `photo` avec l'image compressée pour l'envoi au serveur
            // this.photo = resizedImage;
        },
        updateDateModify(value) {
            this.dateModify = this.convertDateToISO(value);
            this.menu = false; // Ferme le menu du date picker après la sélection
        },
        convertDateToISO(stringDate) {
            if (!stringDate) return null; // Retourne null si la chaîne est vide ou indéfinie

            const parts = stringDate.split('-');
            if (parts.length === 3) {
                return `${parts[2]}-${parts[1]}-${parts[0]}`; // Convertit en format YYYY-MM-DD
            }
            return null; // Retourne null si le format est incorrect
        },
        removeMapClickEvent() {
            this.newPoint = false

            this.map.off('click', (e) => {
                this.map.getCanvas().style.cursor = '';
            });
        },
        addPoint() {
            let dt = new Date()
            // this.cepageModify = ""
            // this.domaineModify = ""
            // this.foretModify = ""
            // this.coupeModify = ""
            // this.essenceModify = ""
            // this.volumeModify = ""
            // this.diametreModify = ""
            // this.hauteurModify = ""
            // this.tarifModify = ""
            // this.etatModify = ""
            // this.operateurModify = ""
            // this.parcelleModify = ""
            // this.subparcelleModify = ""
            this.dateModify = moment(dt).format("YYYY-MM-DD")
            this.map.getCanvas().style.cursor = 'crosshair';
            this.showPoint = false
            this.map.on('click', (e) => {
                if (this.showPoint == false) {
                    const coords = e.lngLat;
                    this.dialogPoint = true
                    this.newPoint = true
                    this.latModify = coords.lat
                    this.longModify = coords.lng
                }

            });

        },
        async addPointLight(go = false) {
            this.stopSaisie = false

            if (this.handleClickReference) {
                this.map.off('click', this.handleClickReference);
            }

            let dt = new Date()
            this.cepageModify = ""
            this.domaineModify = ""
            this.etatModify = "Jaunisse"
            this.operateurModify = this.user.email
            this.parcelleModify = ""
            // this.subparcelleModify = ""
            this.dateModify = moment(dt).format("YYYY-MM-DD")
            this.map.getCanvas().style.cursor = 'crosshair';
            this.showPoint = false
            const handleClick = async (e) => {
                    if (this.stopSaisie || this.actionMarker != "point"){
                        return false
                    }
                    this.stopSaisie = true
                    const coords = e.lngLat;
                    this.latModify = coords.lat
                    this.longModify = coords.lng

                    const features = this.map.queryRenderedFeatures(e.point, {
                        layers: ['geojson-layer-comms']
                    });

                    // if (!features.length) {
                    //     return;
                    // }

                    const feature = features[0];

                    
                    // TODO: make xhr

                    let data = {
                        commune: feature.properties.COMMUNE,
                        secteur: feature.properties.SECTEUR,
                        cepageModify: this.cepageModify,
                        alleeModify: this.alleeModify,
                        perCepsModify: this.perCepsModify,
                        perGrappeModify: this.perGrappeModify,
                        domaineModify: this.domaineModify,
                        etatModify: this.etatModify,
                        commentModify: this.commentModify,
                        operateurModify: this.operateurModify,
                        parcelleModify: this.parcelleModify,
                        subparcelleModify: this.subparcelleModify,
                        name: this.user.name,
                        nature: "point",
                        dateModify: moment().format('DD/MM/YYYY'),
                        symptomes: this.symptomes,
                        currentTime:  moment().format('HH:mm'),
                        type: 'vigneron'
                    }

                try {
                    let res = await xhr.post('/add-pied', {
                        invt: this.selectedInventaire,
                        gps: { lng: this.longModify, lat: this.latModify },
                        data
                    })
                    if (res.data == true){
                        this.latModify = ""
                        this.longModify = ""
                        this.snackbar = true
                        this.snack = "Votre point a été ajouté"
                        this.$emit("refreshPoint")
                        this.map.off('click', 'geojson-layer-comms', handleClick);
                        this.map.off('click', 'geojson-layer-comms', this.handleClickReference);

                        this.stopSaisie = true
                    }

                } catch (error) {
                    console.log("offline !!!!");

                     data = {
                            commune: feature.properties.COMMUNE,
                            secteur: feature.properties.SECTEUR,
                            longitude: this.longModify,
                            latitude: this.latModify,
                            cepage: this.cepageModify,
                            allee: this.alleeModify,
                            percent_ceps: this.perCepsModify,
                            percent_grappe: this.perGrappeModify,
                            domaine: this.domaineModify,
                            etat: this.etatModify,
                            comment: this.commentModify,
                            operateur: this.operateurModify,
                            parcelle: this.parcelleModify,
                            sous_parcelle: this.subparcelleModify,
                            name: this.user.name,
                            nature: "point",
                            date: moment().format('DD/MM/YYYY'),
                            symptomes: this.symptomes,
                            heure:  moment().format('HH:mm'),
                            type: 'vigneron'
                        }

                    this.savePoints.push({
                        ...data,
                    })
                    this.latModify = ""
                    this.longModify = ""

                    return true
                }
                  
                  


                return false
            }

            // Stocker la référence à la fonction de gestion de l'événement
            this.handleClickReference = handleClick;

            this.map.on('click', 'geojson-layer-comms', handleClick);
            this.map.off('click', this.handleClickReference);
       

            return true


        },

        async addArrowLight(icon) {

           this.stopSaisie = false
           this.sens = icon

              // Vérifier et supprimer l'écouteur d'événement précédent
            if (this.handleClickReference) {
                this.map.off('click', this.handleClickReference);
            }

            this.map.getCanvas().style.cursor = 'crosshair';

            const handleClickTwo = async(e) =>{

                if (this.stopSaisie || this.actionMarker != "arrow"){
                    return false
                 }
                this.stopSaisie = true


                const coords = e.lngLat;
                this.latModify = coords.lat
                this.longModify = coords.lng
                    const features = this.map.queryRenderedFeatures(e.point, {
                        layers: ['geojson-layer-comms']
                    });

                    if (!features.length) {
                        this.snack = "En dehors de la parcelle définie"
                        this.snackbar = true
                        return;
                    }

                    const feature = features[0];

                    let data = {
                        commune: feature.properties.COMMUNE,
                        secteur: feature.properties.SECTEUR,
                        cepageModify: this.cepageModify,
                        alleeModify: this.alleeModify,
                        perCepsModify: this.perCepsModify,
                        perGrappeModify: this.perGrappeModify,
                        domaineModify: this.domaineModify,
                        etatModify: this.etatModify,
                        commentModify: this.commentModify,
                        operateurModify: this.operateurModify,
                        parcelleModify: this.parcelleModify,
                        subparcelleModify: this.subparcelleModify,
                        name: this.user.name,
                        nature: "fleche",
                        sens: this.sens,
                        dateModify: moment().format('DD/MM/YYYY'),
                        symptomes: this.symptomes,
                        currentTime:  moment().format('HH:mm'),
                        type: 'vigneron'
                    }

                    try {
                        await xhr.post('/add-pied', {
                            invt: this.selectedInventaire,
                            gps: { lng: this.longModify, lat: this.latModify },
                            data
                        })

                        this.latModify = ""
                        this.longModify = ""
                        this.snackbar = true
                        this.snack = "Votre flèche a été ajoutée"
                        this.map.off('click', this.handleClickReference);
                        this.alertTxt = ""
                        this.map.off('click', 'geojson-layer-comms', handleClickTwo);
                        this.map.getCanvas().style.cursor = 'pointer';
                        this.$emit("refreshPoint")


                    } catch (error) {
                        console.log("offline !!! ^^^^^^");
                        data = {
                            commune: feature.properties.COMMUNE,
                            secteur: feature.properties.SECTEUR,
                            longitude: this.longModify, 
                            latitude: this.latModify,
                            cepage: this.cepageModify,
                            allee: this.alleeModify,
                            percent_ceps: this.perCepsModify,
                            percent_grappe: this.perGrappeModify,
                            domaine: this.domaineModify,
                            etat: this.etatModify,
                            comment: this.commentModify,
                            operateur: this.operateurModify,
                            parcelle: this.parcelleModify,
                            sous_parcelle: this.subparcelleModify,
                            name: this.user.name,
                            nature: "fleche",
                            sens: this.sens,
                            date: moment().format('DD/MM/YYYY'),
                            symptomes: this.symptomes,
                            heure:  moment().format('HH:mm'),
                            type: 'vigneron'
                        }

                        this.savePoints.push({
                            ...data,
                        })
                        this.latModify = ""
                        this.longModify = ""
                         this.stopSaisie = true
                         
                         return true
                    
                    }

                    

                    return true
            }

            // Stocker la référence à la fonction de gestion de l'événement
            this.handleClickReference = handleClickTwo;

            if(!this.stopSaisie){
                 this.map.on('click', 'geojson-layer-comms', handleClickTwo);
            }else{
                console.log("offf !! ");
                this.map.off('click','geojson-layer-comms', () => handleClickTwo);
            }

            return true
        },

        async addAlertLight() {


            this.stopSaisie = false
            this.map.getCanvas().style.cursor = 'crosshair';
              // Vérifier et supprimer l'écouteur d'événement précédent
            if (this.handleClickReference) {
                this.map.off('click', this.handleClickReference);
            }

            const handleClickThree = async (e) => {
                 if(this.stopSaisie || this.actionMarker != "alert"){
                    return false
                 }
                this.stopSaisie = true
                const coords = e.lngLat;
                this.latModify = coords.lat
                this.longModify = coords.lng

                 this.featuresAlert = this.map.queryRenderedFeatures(e.point, {
                        layers: ['geojson-layer-comms']
                    });

                    if (!this.featuresAlert.length) {
                        return;
                    }

                    this.featuresAlert  = this.featuresAlert[0];
                    // TODO: make xhr

                    this.map.off('click', 'geojson-layer-comms',handleClickThree);
                    this.map.off('click', this.handleClickReference);

                    this.stopSaisie = true
                
                this.dialogDescAlert = true
                return false

            };

            // Stocker la référence à la fonction de gestion de l'événement
            this.handleClickReference = handleClickThree;

            this.map.on('click','geojson-layer-comms', handleClickThree);
            return true
        },
      

        async confDescArrow(){
            this.dialogDescAlert = false

            
                let data = {
                    commune: this.featuresAlert.properties.COMMUNE,
                    secteur: this.featuresAlert.properties.SECTEUR,
                    cepageModify: this.cepageModify,
                    alleeModify: this.alleeModify,
                    perCepsModify: this.perCepsModify,
                    perGrappeModify: this.perGrappeModify,
                    domaineModify: this.domaineModify,
                    etatModify: this.etatModify,
                    commentModify: this.commentModify,
                    operateurModify: this.operateurModify,
                    parcelleModify: this.parcelleModify,
                    subparcelleModify: this.subparcelleModify,
                    name: this.user.name,
                    nature: "alert",
                    alertTxt : this.alertTxt,
                    dateModify: moment().format('DD/MM/YYYY'),
                    symptomes: this.symptomes,
                    currentTime:  moment().format('HH:mm'),
                    type: 'vigneron'
                }

            try {
                

                await xhr.post('/add-pied', {
                    invt: this.selectedInventaire,
                    gps: { lng: this.longModify, lat: this.latModify },
                    data
                })


                this.finishDraw = false
                this.stopSaisie = false
                this.draw = null
                this.map.getCanvas().style.cursor = 'pointer';
                this.longModify = ""
                this.latModify = ""
                // this.$emit("refreshLight")

                this.snackbar = true
                this.snack = "Votre alerte a été ajoutée"

                this.alertTxt = ''
                this.$emit("refreshPoint")

            } catch (error) {
                console.log("offline");

                data = {
                    commune: this.featuresAlert.properties.COMMUNE,
                    secteur: this.featuresAlert.properties.SECTEUR,
                    cepage: this.cepageModify,
                    allee: this.alleeModify,
                    percent_ceps: this.perCepsModify,
                    percent_grappe: this.perGrappeModify,
                    longitude: this.longModify,
                    latitude: this.latModify,
                    domaine: this.domaineModify,
                    etat: this.etatModify,
                    comment: this.commentModify,
                    operateur: this.operateurModify,
                    parcelle: this.parcelleModify,
                    sous_parcelle: this.subparcelleModify,
                    name: this.user.name,
                    nature: "alert",
                    alertTxt: this.alertTxt,
                    date: moment().format('DD/MM/YYYY'),
                    symptomes: this.symptomes,
                    heure: moment().format('HH:mm'),
                    type: 'vigneron'
                }
                this.savePoints.push({
                    ...data,
                    longitude: this.longModify, latitude: this.latModify
                })
                this.alertTxt = ''
                this.latModify = ""
                this.longModify = ""
                this.stopSaisie = true
                    
                return true

            }

                
                    
   


            // this.$emit("refreshParent")
        },
        geoloca() {
            this.map.flyTo({
                zoom: 14 // Remplacez par le niveau de zoom souhaité
            });
            this.map.getCanvas().style.cursor = 'pointer';
            this.map.on('click', (e) => {
                const coords = e.lngLat;
                this.$emit("nextphito", coords)
            });

        },
        getTimeline() {
            this.$emit("getTimeline")
        },
        async removeParcelle(parcelle) {
            if (this.currentPopup) {
                this.currentPopup.remove()
            }
            let res = await xhr.post('/remove-parcelle', {
                invt: this.selectedInventaire,
                parcelleId: parcelle.features[0].id
            })
            this.dialogPoint = false
            this.$emit('refreshParent')

        },
        async editConfParcelle() {
            if (this.currentPopup) {
                this.currentPopup.remove()
            }
            let res = await xhr.post('/edit-parcelle', {
                invt: this.selectedInventaire,
                parcelleId: this.editedParcelle.features[0].id,
                datas: {
                    name: this.addParcelle,
                    legend: this.legendeParcelle,
                    type: this.selectedType.value,
                    description: this.descriptionParcelle,
                    picto: this.pictoParcelle,
                    color: this.addParcelleColor.color
                }
            })

            this.dialogEditParcelle = false
            this.dialogCreateParcelle = false
            this.$emit('refreshParent')
        },
        async editParcelle(parcelle) {
            let parc = parcelle.features[0].properties
            this.addParcelle = parc.name
            this.legendeParcelle = parc.legend
            this.descriptionParcelle = parc.description
            this.pictoParcelle = parc.picto
            this.addParcelleColor = parc.color
            this.selectedType = parc.type

            this.editedParcelle = parcelle
            this.dialogEditParcelle = true
            this.dialogCreateParcelle = true
        },

        async diffParcelle() {
            this.snackbar = true
            this.snack = "Veillez séléctionner une autre parcelle pour différence"

            const layers = this.map.getStyle().layers; // Obtenir tous les layers de la carte
            layers.forEach(  layer => {
                if (layer.id.startsWith("lay-")) {
                    this.map.on('click', layer.id, async (e) => {
                        //const feature = features[0];
                        const features = this.map.queryRenderedFeatures(e.point, {
                            layers: [layer.id]
                        });
                        // console.log("e.point", );
                         if (features.length) {
                            const feature = features[0];
                             // Assurez-vous que this.parcelleSelected est une feature GeoJSON
                             if (this.parcelleSelected && this.layerSelected) {
                                 try {
                                     const difference = turf.difference(this.parcelleSelected, feature);

                                     // Afficher le résultat ou effectuer une action spécifique
                                     if (difference) {

                                         let layerSelect = this.map.getLayer(this.layerSelected)
                                        
                                           // Supprimer l'ancien layer
                                         if (this.map.getLayer(this.layerSelected)) {
                                             this.map.removeLayer(this.layerSelected);
                                         }
                                 
                                         // Vous pouvez ajouter la différence à la carte comme un nouveau layer
                                         if (this.map.getSource('difference')) {
                                             this.map.getSource('difference').setData(difference);
                                        } else {

                                           
                                             this.map.addSource('difference', {
                                                 'type': 'geojson',
                                                 'data': difference
                                             });

                                             this.map.addLayer({
                                                 'id': 'difference-layer',
                                                 'type': 'fill',
                                                 'source': 'difference',
                                                 'layout': {},
                                                 'paint': {
                                                     'fill-color': '#ff22aa',
                                                     'fill-opacity': 0.5
                                                 }
                                             });

                                             let payload = {
                                                 invt: this.selectedInventaire,
                                                 parcelleId: this.editedParcelle.features[0].id, // Assurez-vous que l'ID est présent
                                                 area: difference.geometry
                                             };

                                             try {
                                                 let res = await xhr.post('/edit-parcelle-area', {
                                                     invt: this.selectedInventaire,
                                                     parcelleId: this.editedParcelle.features[0].id,
                                                     payload: payload
                                                 })
                                                 this.$emit('refreshParent')
                                                 this.draw.changeMode('simple_select');
                                                 this.finishDraw = true
                                                 this.editedDraw = false
                                            } catch(e){
                                            }


                                         }
                                     } else {
                                         console.log("No difference found between selected parcels.");
                                     }
                                 } catch (error) {
                                     console.error("Error calculating difference:", error);
                                 }
                             } else {
                                 console.log("No initial parcel selected to compare with.");
                             }
                         
                         
                        }
                    });
                }
            });

            
        },

        async makeDiffParcelle() {
           
        },

        async editAreaParcelle() {
            this.dialogEditParcelle = false
            this.dialogCreateParcelle = false

            const allFeatures = this.draw.getAll();
            if (allFeatures.features.length > 0) {

                const updatedParcelle = allFeatures.features[0];
                let payload = {
                    invt: this.selectedInventaire,
                    parcelleId: updatedParcelle.id, // Assurez-vous que l'ID est présent
                    area: updatedParcelle.geometry
                };

                try {
                    let res = await xhr.post('/edit-parcelle-area', {
                        invt: this.selectedInventaire,
                        parcelleId: this.editedParcelle.features[0].id,
                        payload: payload
                    })
                    this.editedDraw = false
                    this.$emit('refreshParent')
                    this.draw.changeMode('simple_select');
                    this.finishDraw = true

                } catch (error) {
                    console.error("Erreur lors de l'envoi des données à l'API :", error);
                     this.clearMap()
                }
                 this.clearMap()

            }
             this.clearMap()
        },
        async drawParcelle(parcelle) {
            this.clearMap()
            this.editedParcelle = parcelle
            this.editedDraw = true


            if (this.currentPopup) {
                this.currentPopup.remove()
            }


            let layers = this.map.getStyle().layers

            if (this.draw) {
                this.map.removeControl(this.draw);
                this.draw = null;
            }

            // redraw parcelle
            this.draw = new MapboxDraw({
                displayControlsDefault: false,
                controls: {
                    polygon: true,
                    trash: true
                },
                defaultMode: 'simple_select'
            });
            this.map.addControl(this.draw);

            const feature = parcelle.features[0]; 
            this.draw.add(feature);

            const featureId = parcelle.features[0].id;
            // Vérifiez si un id est défini pour la feature, sinon utilisez un identifiant temporaire ou le premier disponible
            if (!featureId) {
                console.error('La parcelle doit avoir un "id" pour être sélectionnée dans Mapbox Draw.');
                return;
            }

            this.draw.changeMode('direct_select', { featureId: featureId });

            // this.map.on('draw.create', this.createArea);
            // this.map.on('draw.create', this.createArea);
            // this.map.on('draw.delete', this.createArea);
            this.finishDraw = true
            this.snackbar = true
            this.snack = "Redessiner votre parcelle sur la carte"

        },
        generateRandomColor() {
            const couleurs = [
                "#000000",  // Noir
                "#808080",  // Gris
                "#C0C0C0",  // Argent
                "#FFFFFF",  // Blanc
                "#800000",  // Marron
                "#FF0000",  // Rouge
                "#808000",  // Olive
                "#FFFF00",  // Jaune
                "#008000",  // Vert
                "#00FF00",  // Vert citron
                "#008080",  // Sarcelle
                "#00FFFF",  // Aqua
                "#000080",  // Bleu marine
                "#0000FF",  // Bleu
                "#800080",  // Violet
                "#FF00FF",  // Fuchsia
                "#FFA500",  // Orange
                "#FF4500",  // Rouge orangé
                "#FF6347",  // Tomate
                "#FF8C00",  // Orange foncé
                "#FFD700",  // Or
                "#FFDAB9",  // Pêche
                "#FF69B4",  // Rose vif
                "#FF1493",  // Rose profond
                "#FFB6C1",  // Rose clair
                "#FFC0CB",  // Rose
                "#FFA07A",  // Saumon clair
                "#FF7F50",  // Corail
                "#FF4500",  // Rouge orangé
                "#FF0000",  // Rouge
                "#DC143C",  // Cramoisi
                "#8B0000",  // Rouge foncé
                "#B22222",  // Brique
            ];
            return couleurs;
        },
        generateRandomColors() {
            let tab = this.generateRandomColor()
            return tab.map((e) => { return { color: e } })
        },
        isActive(title) {
            return this.activeChips.includes(title);
        },
        toggleChip(title) {
            this.filteredChips = true
            const index = this.activeChips.indexOf(title);
            if (index === -1) {
                this.activeChips.push(title);
            } else {
                this.activeChips.splice(index, 1);
            }

            this.$emit("listenFilter", this.activeChips)
        },
        async loadJSON() {
            // this.map.flyTo({
            //     zoom: 11 // Remplacez par le niveau de zoom souhaité
            // });
            this.loadingScreenshot = true
            fetch(`${process.env.VUE_APP_BASE_URL}shape/fusion.geojson`)
                .then(response => response.json())
                .then(data => {
                    // Ajouter le GeoJSON comme source à la carte
                    this.map.addSource('myGeojson', {
                        type: 'geojson',
                        data: data
                    });

                    this.map.loadImage(wineImage, (error, image) => {
                        if (error) throw error;

                        // Ajouter l'image à la carte
                        this.map.addImage('my-icon', image);

                        // Maintenant, vous pouvez ajouter votre calque ici...
                        this.map.addLayer({
                            id: 'myLayer',
                            type: 'symbol', // Utiliser 'symbol' pour les icônes
                            source: 'myGeojson',
                            layout: {
                                'icon-image': 'my-icon', // Utiliser l'icône chargée
                                'icon-size': 0.1 // Ajuster la taille de l'icône si nécessaire
                            }
                        });
                        this.loadingScreenshot = false
                    });
                    // Créer une couche pour afficher les données GeoJSON


                    this.map.on('click', 'myLayer', (e) => {
                        var coordinates = e.features[0].geometry.coordinates.slice();
                        var description = `
                        <p>⛳ Commune: <b>${e.features[0].properties.nomcom}</b></p>
                        <p>📒 Catégorie (cépage) : <b>${e.features[0].properties.categorie}</b></p>
                        <p>✨ Dénomination : <b><i>${e.features[0].properties.denom}</i></b></p>`;

                        // Assurer que l'infobulle suit la carte en cas de panoramique ou de zoom
                        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                        }

                        this.currentPopup = new mapboxgl.Popup()
                            .setLngLat(coordinates)
                            .setHTML(description)
                            .addTo(this.map);
                    });

                })
                .catch(error => {
                    console.error('Erreur lors du chargement du fichier GeoJSON:', error);
                });
        },

        async selectPointAOC() {
            this.snackbar = true
            this.snack = "Veillez séléctionner un point sur la carte pour récupérer l'appellation AOC"
            this.map.getCanvas().style.cursor = 'crosshair';


            if (this.map.getLayer('appellationLayer')) {
                this.map.removeLayer('appellationLayer')
            }

            if (this.map.getLayer('appellationLabels')) {
                this.map.removeLayer('appellationLabels')
            }

            if (this.map.getSource('appellationSource')) {
                this.map.removeSource('appellationSource')
            }

            this.map.once('click', async (e) => {
                // Récupérer les coordonnées du point cliqué
                const longitude = e.lngLat.lng;
                const latitude = e.lngLat.lat;

                let res = await xhr.post('https://apicarto.ign.fr/api/aoc/appellation-viticole', {
                    "geom": { "type": "Point", "coordinates": [longitude, latitude] }
                }, {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8'
                    }
                })


                let features = res.data.features.map(feature => {
                    if (feature.properties.appellation) {
                        feature.properties.appellation = feature.properties.appellation.replace(/Ã/g, 'É');
                    }
                    return feature;
                });

                // 

                if (!this.map.getSource('appellationSource')) {
                    this.map.addSource('appellationSource', {
                        "type": "geojson",
                        "data": {
                            "type": "FeatureCollection",
                            "features": features
                        }
                    });
                }


                this.map.addLayer({
                    "id": "appellationLayer",
                    "type": "fill",
                    "source": "appellationSource",
                    "layout": {},
                    "paint": {
                        "fill-color": "#FCF3CF", // Couleur de remplissage des polygones
                        "fill-opacity": 0.2 // Transparence
                    }
                });

                this.map.addLayer({
                    id: 'appellationLabels',
                    type: 'symbol',
                    source: 'appellationSource',
                    layout: {
                        'text-field': ['get', 'appellation'], // Utilise l'attribut `appellation` de vos features
                        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
                        'text-justify': 'auto',
                        'text-size': 12
                    },
                    paint: {
                        'text-color': '#000000', // Couleur du texte
                        'text-halo-color': '#FFFFFF', // Couleur de l'halo autour du texte pour améliorer la lisibilité
                        'text-halo-width': 2, // Largeur de l'halo autour du texte
                    }
                });


            })
        },
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split('-');
            return `${day}/${month}/${year}`;
        },
        async fetchWeather() {
            if (!this.dateMeteo) return;

            const weatherApiUrl = `http://api.weatherapi.com/v1/history.json?key=abb164c38e9b4c1989b134223232212&q=${this.longLat.lat},${this.longLat.long}&dt=${this.dateMeteo}&lang=fr`;

            try {
                // Faire un appel GET à l'API météo
                const response = await xhr.get(weatherApiUrl);
                const data = response.data;
                this.dataHistoryMeteo = data.forecast.forecastday[0].hour
            } catch (error) {
                console.error("Erreur lors de la récupération des données météo:", error);
            }
        },


        async getMeteo() {
            this.map.flyTo({
                //center: [longitude, latitude], // Remplacez par les coordonnées souhaitées
                zoom: 17 // Remplacez par le niveau de zoom souhaité
            });
            this.snackbar = true
            this.snack = "Veillez séléctionner un point sur la carte"

            this.map.once('click', async (e) => {
                // Récupérer les coordonnées du point cliqué
                const longitude = e.lngLat.lng;
                const latitude = e.lngLat.lat;
                this.longLat = { long: longitude, lat: latitude }
                // Construire l'URL pour l'API météo
                const weatherApiUrl = `http://api.weatherapi.com/v1/current.json?key=abb164c38e9b4c1989b134223232212&q=${latitude},${longitude}&dt=${this.dateMeteo}&lang=fr`;

                try {
                    // Faire un appel GET à l'API météo avec axios
                    const response = await xhr.get(weatherApiUrl);
                    const data = response.data;

                    // Traiter les données météo ici
                    this.dialogMeteo = true
                    this.dataMeteo = data
                    this.dataMeteoReg = `${this.dataMeteo.location.name}, ${this.dataMeteo.location.region}`


                    // Mise à jour du state de Vue.js avec les données météo
                } catch (error) {
                    console.error("Erreur lors de la récupération des données météo:", error);
                }
            });

        },

        // take statusEssences
        changeEssences(val) {
            this.dialogLoad = true

            if (this.popin) {
                this.popin.remove();
            }
            if (!val || !val.length) {
                return []
            }

            if (val && val.length) {

                if (this.map && this.map.getLayer('inventaires-layer')) {
                    this.map.removeLayer('inventaires-layer');
                }

                if (this.map && this.map.getLayer('inventaires-layer-arrows')) {
                    this.map.removeLayer('inventaires-layer-arrows');
                }

                if (this.map && this.map.getLayer('inventaires-layer-alert')) {
                    this.map.removeLayer('inventaires-layer-alert');
                }



                let cols = []

                val.forEach((essence) => {
                    if (essence) {
                        //todo: essence.color et essence.title pour compléter le cols
                        if (essence.color) {
                            cols = [...cols, essence.title, essence.color]
                        }
                    }
                })
               

                cols = cols.map(item => item === undefined ? null : item);
                cols = cols.map(item => item === null ? "#aa22cc" : item);
                // cols = cols.map(item => isNaN(item) ? item : Number(item));
                // cols = cols.map(item => item + "");

                if (!cols.length) {
                    return []
                }

                if (this.map) {
                    // 
                    if (this.map.getSource('inventaires')) {
                        if (this.viewItem != "") {


                               this.map.addLayer({
                                    id: 'inventaires-layer',
                                    type: 'circle',
                                    source: 'inventaires',
                                    paint: {
                                        'circle-radius': [
                                            'interpolate',
                                            ['exponential', 1],
                                            ['zoom'],
                                            0, 0,
                                            2, 5,
                                        ],
                                        'circle-color': [
                                            'match',
                                            ['to-string', ['get', this.viewItem]], 
                                            ...cols,
                                            '#eee'
                                        ],
                                    },
                                     filter: ['==', ['get', 'nature'], 'point'],
                                });


                               

                                // Ajouter l'icône SVG pour les flèches

                                this.map.addLayer({
                                    id: 'inventaires-layer-arrows',
                                    type: 'symbol',
                                    source: 'inventaires',
                                    layout: {
                                        'icon-image':  ['get', 'sens'],  // Utilisez un nom d'icône que vous allez définir ci-dessous
                                        'icon-size': 0.4,
                                    },
                                    filter: ['==', ['get', 'nature'], 'fleche']
                                });

                                 this.map.addLayer({
                                    id: 'inventaires-layer-alert',
                                    type: 'symbol',
                                    source: 'inventaires',
                                    layout: {
                                        'icon-image':  'alert',  // Utilisez un nom d'icône que vous allez définir ci-dessous
                                        'icon-size': 0.1,
                                    },
                                    filter: ['==', ['get', 'nature'], 'alert']
                                });
                                
                                

                                  
                                this.map.on('click', 'inventaires-layer-alert', (e) => {
                                    if (this.currentPopup) {
                                        this.currentPopup.remove(); // Fermez l'infobulle précédente
                                    }

                                    if (this.selectedInventaireObj.profil == "vigneron") {
                                        let idEvt = e.features[0].properties.id
                                        let evtProp = e.features[0].properties

                                        // 
                                        // 
                                        let properties = e.features[0].properties;
                                        let ucfirst = (str) => str.charAt(0).toUpperCase() + str.slice(1);

                                        let listeHtml = Object.keys(properties)
                                            .filter(key => !['id', 'latitude', 'longitude', 'clef', 'sens', 'nature'].includes(key) && key.length >= 3)
                                            .filter(key => properties[key].length && properties[key] != "")
                                            .filter(key => key != 'symptomes')
                                            .map(key => {
                                                let value = key === 'operateur' && !properties[key] ? 'Non renseigné' : properties[key];
                                                value = key === 'symptomes' ? properties[key].replace(/["[\]]/g, '') : properties[key];

                                                let formattedKey = ucfirst(key.replace(/[_-]/g, ' '));
                                                return `<li>✅ ${formattedKey}: <b>${value}</b></li>`;
                                            })
                                            .join('');
                                        let dateHtml = properties.date ? `<p style="font-size: 13px; margin-left: 20px"><small>📅 Fait le: <b>${properties.date}</b> à <b>${properties.heure}</b></small></p>` : '';
                                        let defaultColor = "#ffcc00"; // Mettez la couleur par défaut que vous souhaitez utiliser
                                        let stateColor = this.colorsEssences[properties.etat] ? this.colorsEssences[properties.etat] : defaultColor;
                                        let cepageContent = properties.etat ? `${properties.etat}` : ''; // Affiche le cépage suivi d'un tiret si présent.

                                        let html = `
                                        <div class="boxPopup"> 
                                        <h3 style="margin-bottom: 15px"><span class="etat-titre" style="background-color:${stateColor} !important">${cepageContent}</span></h3>
                                            ${dateHtml}
                                            <hr style="border-color: #f46370" />
                                            <ul class="ma-liste">${listeHtml}</ul>`

                                        html += properties.operateur == this.user.email || this.user.admin == true ? `<div cols="12">
                                                <a class="v-btn v-btn-one v-btn--primary modify-btn v-btn--rounded  v-btn--colorful pa-1" id="modifyLocationLinkTwo" data-action="move" data-id="${idEvt}"> <span>📌</span> Déplacer</a>
                                                <a class="v-btn v-btn-one v-btn--primary modify-btn v-btn--rounded  v-btn--outlined pa-1" id="alertLink" data-action="supprimer" data-obj="${properties.latitude};${properties.longitude}"> <span>🚮</span> Supprimer</a>
                                            </div>
                                        </div>
                                    `: ``

                                        this.currentPopup = new mapboxgl.Popup({ closeOnClick: true })
                                            .setLngLat(e.lngLat)
                                            .setHTML(html)
                                            .addTo(this.map);


                                        // Attache les événements après que l'info-bulle est ajoutée à la carte
                                        // this.map.once('open', () => {
                                        document.querySelectorAll('.modify-btn').forEach(btn => {
                                            btn.addEventListener('click', (event) => {
                                                let action = event.target.dataset.action;
                                                let id = event.target.dataset.id;
                                                let obj = event.target.dataset.obj;

                                                if (action === 'move') {
                                                    this.modifyGps(e.lngLat, id, evtProp);
                                                } else if (action === 'supprimer') {
                                                    // this.idEvt = e.lngLat
                                                    this.removePiedLight(obj);
                                                    if(this.currentPopup){
                                                        this.currentPopup.remove()
                                                    }
                                                }

                                                event.preventDefault();
                                            });
                                        });

                                        document.querySelectorAll('.alert-btn').forEach(btn => {
                                            btn.addEventListener('click', (event) => {
                                                let id = event.target.dataset.id;
                                                this.alertPoint(e.lngLat, id, evtProp);
                                                event.preventDefault();
                                            });
                                        });


                                    }


                                });
                                
                                this.map.on('click', 'inventaires-layer-arrows', (e) => {
                                    if (this.currentPopup) {
                                        this.currentPopup.remove(); // Fermez l'infobulle précédente
                                    }

                                    if (this.selectedInventaireObj.profil == "vigneron") {
                                        let idEvt = e.features[0].properties.id
                                        let evtProp = e.features[0].properties

                                        let properties = e.features[0].properties;
                                        let ucfirst = (str) => str.charAt(0).toUpperCase() + str.slice(1);

                                        let listeHtml = Object.keys(properties)
                                            .filter(key => !['id', 'latitude', 'longitude', 'clef', 'sens', 'comment'].includes(key) && key.length >= 3)
                                            .filter(key => properties[key].length && properties[key] != "")
                                            .filter(key => key != 'symptomes')
                                            .map(key => {
                                                let value = key === 'operateur' && !properties[key] ? 'Non renseigné' : properties[key];
                                                value = key === 'symptomes' ? properties[key].replace(/["[\]]/g, '') : properties[key];

                                                let formattedKey = ucfirst(key.replace(/[_-]/g, ' '));
                                                return `<li>✅ ${formattedKey}: <b>${value}</b></li>`;
                                            })
                                            .join('');
                                        let dateHtml = properties.date ? `<p style="font-size: 13px; margin-left: 20px"><small>📅 Fait le: <b>${properties.date}</b> à <b>${properties.heure}</b></small></p>` : '';
                                        let defaultColor = "#ffcc00"; // Mettez la couleur par défaut que vous souhaitez utiliser
                                        let stateColor = this.colorsEssences[properties.etat] ? this.colorsEssences[properties.etat] : defaultColor;
                                        let cepageContent = properties.etat ? `${properties.etat}` : ''; // Affiche le cépage suivi d'un tiret si présent.

                                        let html = `
                                        <div class="boxPopup"> 
                                        <h3 style="margin-bottom: 15px"><span class="etat-titre" style="background-color:${stateColor} !important">${cepageContent}</span></h3>
                                            ${dateHtml}
                                            <hr style="border-color: #f46370" />
                                            <ul class="ma-liste">${listeHtml}</ul>`

                                        html += properties.operateur == this.user.email || this.user.admin == true ? `<div cols="12">
                                                <a class="v-btn v-btn-one v-btn--primary modify-btn v-btn--rounded  v-btn--colorful pa-1" id="modifyLocationLinkTwo" data-action="move" data-id="${idEvt}"> <span>📌</span> Déplacer</a>
                                                <a class="v-btn v-btn-one v-btn--primary modify-btn v-btn--rounded  v-btn--outlined pa-1" id="alertLink" data-action="supprimer" data-obj="${properties.latitude};${properties.longitude}"> <span>🚮</span> Supprimer</a>
                                            </div >
                                        </div>
                                            `: ``
                                          

                                        this.currentPopup = new mapboxgl.Popup({ closeOnClick: true })
                                            .setLngLat(e.lngLat)
                                            .setHTML(html)
                                            .addTo(this.map);


                                        // Attache les événements après que l'info-bulle est ajoutée à la carte
                                        // this.map.once('open', () => {
                                        document.querySelectorAll('.modify-btn').forEach(btn => {
                                            btn.addEventListener('click', (event) => {
                                                let action = event.target.dataset.action;
                                                let id = event.target.dataset.id;
                                                let obj = event.target.dataset.obj;

                                                if (action === 'move') {
                                                    this.modifyGps(e.lngLat, id, evtProp);
                                                } else if (action === 'supprimer') {
                                                    // this.idEvt = e.lngLat
                                                    this.removePiedLight(obj);
                                                    if(this.currentPopup){
                                                        this.currentPopup.remove()
                                                    }
                                                }

                                                event.preventDefault();
                                            });
                                        });

                                        document.querySelectorAll('.alert-btn').forEach(btn => {
                                            btn.addEventListener('click', (event) => {
                                                let id = event.target.dataset.id;
                                                this.alertPoint(e.lngLat, id, evtProp);
                                                event.preventDefault();
                                            });
                                        });


                                    }


                                });


                                this.map.on('click', 'inventaires-layer', (e) => {
                                    if (this.currentPopup) {
                                        this.currentPopup.remove(); // Fermez l'infobulle précédente
                                    }   

                                    if (this.selectedInventaireObj.profil == "vigneron") {
                                        let idEvt = e.features[0].properties.id
                                        let evtProp = e.features[0].properties

                                        // 
                                        // 
                                        let properties = e.features[0].properties;
                                        let ucfirst = (str) => str.charAt(0).toUpperCase() + str.slice(1);
                                        let listeHtml = Object.keys(properties)
                                            .filter(key => !['id', 'latitude', 'longitude', 'clef', 'nature', 'sens', 'comment'].includes(key) && key.length >= 3)
                                            .filter(key => properties[key].length && properties[key] != "")
                                            .filter(key => key != 'symptomes')
                                            .map(key => {
                                                let value = key === 'operateur' && !properties[key] ? 'Non renseigné' : properties[key];
                                                value = key === 'symptomes' ? properties[key].replace(/["[\]]/g, '') : properties[key];

                                                let formattedKey = ucfirst(key.replace(/[_-]/g, ' '));
                                                return `<li>✅ ${formattedKey}: <b>${value}</b></li>`;
                                            })
                                            .join('');
                                        let dateHtml = properties.date ? `<p style="font-size: 13px; margin-left: 20px"><small>📅 Fait le: <b>${properties.date}</b> à <b>${properties.heure}</b></small></p>` : '';
                                        let defaultColor = "#ffcc00"; // Mettez la couleur par défaut que vous souhaitez utiliser
                                        let stateColor = this.colorsEssences[properties.etat] ? this.colorsEssences[properties.etat] : defaultColor;
                                        let cepageContent = properties.etat ? `${properties.etat}` : ''; // Affiche le cépage suivi d'un tiret si présent.

                                        let html = `
                                        <div class="boxPopup"> 
                                        <h3 style="margin-bottom: 15px"><span class="etat-titre" style="background-color:${stateColor} !important">${cepageContent}</span></h3>
                                            ${dateHtml}
                                            <hr style="border-color: #f46370" />
                                            <ul class="ma-liste">${listeHtml}</ul>`
                                        html += properties.operateur == this.user.email || this.user.admin == true ? `<div cols="12">
                                                <a class="v-btn v-btn-one v-btn--primary modify-btn v-btn--rounded  v-btn--colorful pa-1" id="modifyLocationLinkTwo" data-action="move" data-id="${idEvt}"> <span>📌</span> Déplacer</a>
                                                <a class="v-btn v-btn-one v-btn--primary modify-btn v-btn--rounded  v-btn--outlined pa-1" id="alertLink" data-action="supprimer" data-obj="${properties.latitude};${properties.longitude}"> <span>🚮</span> Supprimer</a>
                                            </div>
                                        </div>
                                            `: ``

                                        this.currentPopup = new mapboxgl.Popup({ closeOnClick: true })
                                            .setLngLat(e.lngLat)
                                            .setHTML(html)
                                            .addTo(this.map);


                                        // Attache les événements après que l'info-bulle est ajoutée à la carte
                                        // this.map.once('open', () => {
                                        document.querySelectorAll('.modify-btn').forEach(btn => {
                                            btn.addEventListener('click', (event) => {
                                                let action = event.target.dataset.action;
                                                let id = event.target.dataset.id;
                                                let obj = event.target.dataset.obj;

                                                if (action === 'move') {
                                                    this.modifyGps(e.lngLat, id, evtProp);
                                                } else if (action === 'supprimer') {
                                                    // this.idEvt = e.lngLat
                                                    this.removePiedLight(obj);
                                                    if(this.currentPopup){
                                                        this.currentPopup.remove()
                                                    }
                                                }

                                                event.preventDefault();
                                            });
                                        });

                                        document.querySelectorAll('.alert-btn').forEach(btn => {
                                            btn.addEventListener('click', (event) => {
                                                let id = event.target.dataset.id;
                                                this.alertPoint(e.lngLat, id, evtProp);
                                                event.preventDefault();
                                            });
                                        });


                                    } else { // forestier

                                        let idEvt = e.features[0].properties.id
                                        let evtProp = e.features[0].properties

                                        let ignores = ['id', 'clef', 'commentaire', 'date', 'commentaires', 'iddevice',  'volume', 'longitude', 'latitude', 'symptomes']
                                        let correspondances = {
                                            volume: {
                                                title: "Volume",
                                                value: "volume"
                                            },
                                            spar: {
                                                title: "Parcelle",
                                                value: "spar"
                                            },
                                            trfchaude: {
                                                title: "trfchaude",
                                                value: "Tarif Chaudé"
                                            },
                                            hautmoy: {
                                                title: "hautmoy",
                                                value: "Hauteur Moy."
                                            },
                                            par: {
                                                title: "par",
                                                value: "Parcelle"
                                            },
                                            frt: {
                                                title: "frt",
                                                value: "Forêt"
                                            },
                                        };

                                        let correspondancesVal = {
                                            idarbre: {
                                                icon: "✨"
                                            },
                                            date: {
                                                icon: "📅"
                                            },
                                            heure: {
                                                icon: "⌚"
                                            },
                                            hauteur: {
                                                unite: "m.",
                                                icon: "📏"
                                            },
                                            volume: {
                                                unite: "m3",
                                                icon: "🪵"
                                            },
                                            diametre: {
                                                unite: "cm.",
                                                icon: "🟤"
                                            },
                                            essence: {
                                                icon: "🌲"
                                            },
                                            utilisateur: {
                                                icon: "👷‍♂️"
                                            },
                                            commune: {
                                                icon: "🏙️"
                                            },

                                        };

                                        let properties = e.features[0].properties;
                                        let ucfirst = (str) => str.charAt(0).toUpperCase() + str.slice(1);
                                        let listeHtml = Object.keys(properties)
                                            .filter(key => !ignores.includes(key) && key.length >= 2)
                                            .map(key => {
                                                let value = key === 'operateur' && !properties[key] ? 'Non renseigné' : properties[key];
                                                value = key === 'date' && properties[key] ? this.formatDate(properties[key]) : value;
                                                value = key === 'coupe' && properties[key] && properties[key] == "-" ? "Non renseigné" : properties[key];
                                                value = key === 'symptomes' && properties[key] ? properties[key].join(" , ") :  "Non renseigné" ;

                                                let formattedKey = ucfirst(key.replace(/[_-]/g, ' '));
                                                let keybas = key.toLowerCase()
                                                return `<li>${correspondancesVal[keybas] ? correspondancesVal[keybas].icon : "✅"} ${formattedKey}: <b>${value}</b> ${correspondancesVal[keybas] && correspondancesVal[keybas].unite ? correspondancesVal[keybas].unite : ""}</li>`;
                                            })
                                            .join('');


                                        let dateHtml = properties.date ? `<p style="font-size: 13px; margin-left: 20px"><small>📅 Fait le: <b>${this.formaterDateEnFrancais(properties.date)}</b></small></p>` : '';
                                        let defaultColor = "#ffcc00"; // Mettez la couleur par défaut que vous souhaitez utiliser

                                        let col = this.statsEssences.find((e) => e.title == properties.essence) || {}
                                        let stateColor = col["color"] || defaultColor;
                                        let cepageContent = properties.essence ? `${properties.essence}` : properties[this.viewItem];

                                        let html = `<div class="boxPopup"> 
                                        <h3 style="margin-bottom: 15px"><span class="etat-titre" style="background-color:${stateColor} !important">${cepageContent} ${properties.etat ? properties.etat : ''}</span></h3>
                                            ${dateHtml}
                                            <hr style="border-color: #f46370" />
                                            <ul class="ma-liste">${listeHtml}</ul>
                                            <div cols="12">
                                                <a  class="v-btn v-btn-one v-btn--primary modify-btn v-btn--rounded  v-btn--colorful pa-1" id="modifyLocationLink" data-action="modify" data-id="${idEvt}"> <span>🖋️</span> Modifier</a>
                                                <a class="v-btn v-btn-one v-btn--primary modify-btn v-btn--rounded  v-btn--colorful pa-1" id="modifyLocationLinkTwo" data-action="move" data-id="${idEvt}"> <span>📌</span> Déplacer</a>
                                                <a class="v-btn v-btn-one v-btn--primary alert-btn v-btn--rounded  v-btn--outlined pa-1" id="alertLink" data-action="modify" data-id="${idEvt}"> <span>👷‍♂️</span> Alerter</a>
                                            </div>
                                        </div>`

                                        this.currentPopup = new mapboxgl.Popup()
                                            .setLngLat(e.lngLat)
                                            .setHTML(html)
                                            .addTo(this.map);

                                        document.querySelectorAll('.modify-btn').forEach(btn => {
                                            btn.addEventListener('click', (event) => {
                                                let action = event.target.dataset.action;
                                                let id = event.target.dataset.id;

                                                if (action === 'move') {
                                                    this.modifyGps(e.lngLat, id, evtProp);
                                                } else if (action === 'modify') {
                                                    this.modifyPoint(e.lngLat, id, evtProp);
                                                }

                                                event.preventDefault();
                                            });
                                        });

                                        document.querySelectorAll('.alert-btn').forEach(btn => {
                                            btn.addEventListener('click', (event) => {
                                                let id = event.target.dataset.id;
                                                this.alertPoint(e.lngLat, id, evtProp);
                                                event.preventDefault();
                                            });
                                        });



                                    }


                                });

                        } else {
                            this.map.addLayer({
                                'id': 'inventaires-layer',
                                'type': 'circle',
                                'source': 'inventaires',
                                'paint': {
                                    'circle-radius': [
                                        "interpolate",
                                        ["exponential", 1],
                                        ["zoom"],
                                        0, 0,
                                        2, 5,
                                    ],
                                    // Utiliser une couleur par défaut si aucune correspondance n'est trouvée
                                    "circle-color": '#eee',  // Couleur par défaut pour toutes les pastilles
                                },
                                'filter': ['==', '$type', 'Point']
                            });
                        }

                    }
                }

                this.dialogLoad = false
            }
        },
        changeViews(type) {
            this.dialogViews = false
            this.$emit('changeView', type)
        },
        async alertPied() {
            let formData = new FormData();

            formData.append('evtProp', this.evtProp);
            formData.append('idEvt', this.idEvt);
            formData.append('alertTxt', this.alertTxt);
            formData.append('operateurs', JSON.stringify(this.operateursSelected)); // Les tableaux/objets doivent être convertis en chaîne
            formData.append('gps', this.gpsEvt);
            formData.append('user', JSON.stringify({ email: this.user.email, uid: this.user.uid })); // Convertir l'objet en chaîne
            formData.append('created', new Date());

            if (this.photo) {
                formData.append('photo', this.photo, this.photo.name);
            }

            await xhr.post('/alert-pied', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            this.dialogPied = false
            this.dialogPoint = false
            this.operateursSelected = null
            this.alertTxt = ""
            this.snackbar = true
            this.snack = "Votre demande e-mail a bien été envoyé"
        },
          async removePiedLight(longlat) {
            let val  = longlat.split(";")
            let gps = {
                latitude: val[0],
                longitude: val[1]
            }
            this.dialogPoint = false

            let conf = window.confirm("Voulez-vous supprimer ce pied ?")
            if (conf == true) {
                await xhr.post('/remove-pied-light', {
                    gps: gps,
                    email: this.user.email,
                    invt: this.selectedInventaire
                })
                this.$emit("refreshPoint")
                this.dialogPied = false
                this.dialogPoint = false
                this.snackbar = true
                this.snack = "Votre point a bien été supprimé"
            }

        },
        async removePied() {
            this.dialogPoint = false

            let conf = window.confirm("Voulez-vous supprimer ce pied ?")
            if (conf == true) {
                await xhr.post('/remove-pied', {
                    gps: this.idEvt,
                    invt: this.selectedInventaire
                })
                this.$emit("refreshParent")
                this.dialogPied = false
                this.dialogPoint = false
                this.snackbar = true
                this.snack = "Votre point a bien été supprimé"
            }

        },
        async modifyPied() {
            let data = {}
            if (this.isVigneron) {
                data = {
                    cepageModify: this.cepageModify,
                    alleeModify: this.alleeModify,
                    perCepsModify: this.perCepsModify,
                    perGrappeModify: this.perGrappeModify,
                    domaineModify: this.domaineModify,
                    etatModify: this.etatModify,
                    commentModify: this.commentModify,
                    operateurModify: this.operateurModify,
                    parcelleModify: this.parcelleModify,
                    subparcelleModify: this.subparcelleModify,
                    dateModify: this.dateModify,
                    type: 'vigneron'
                }
            } else {
                data = {
                    coupeModify: this.coupeModify,
                    foretModify: this.foretModify,
                    essenceModify: this.essenceModify,
                    etatModify: this.etatModify,
                    commentModify: this.commentModify,
                    volumeModify: this.volumeModify,
                    operateurModify: this.operateurModify,
                    parcelleModify: this.parcelleModify,
                    subparcelleModify: this.subparcelleModify,
                    dateModify: this.dateModify,
                    diametreModify: this.diametreModify,
                    hauteurModify: this.hauteurModify,
                    tarifModify: this.tarifModify,
                    type: 'forestier'
                }
            }
            await xhr.post('/modify-pied', {
                invt: this.selectedInventaire,
                gps: { lng: this.idEvt.longitude, lat: this.idEvt.latitude },
                data
            })
            this.dialogPied = false
            this.dialogPoint = false
            this.snackbar = true
            this.snack = "Votre point a été modifié"
            // this.$emit("resetInv")
            this.$emit("refreshParent")
        },
        async addPied() {
            let data = {}
            const currentTime = moment().format('HH:mm');
            if (this.isVigneron) {
                data = {
                    cepageModify: this.cepageModify,
                    alleeModify: this.alleeModify,
                    perCepsModify: this.perCepsModify,
                    perGrappeModify: this.perGrappeModify,
                    domaineModify: this.domaineModify,
                    etatModify: this.etatModify,
                    commentModify: this.commentModify,
                    operateurModify: this.operateurModify,
                    parcelleModify: this.parcelleModify,
                    subparcelleModify: this.subparcelleModify,
                    dateModify: this.dateModify,
                    symptomes: this.symptomes,
                    currentTime,
                    type: 'vigneron'
                }
            } else {
                data = {
                    foretModify: this.foretModify,
                    essenceModify: this.essenceModify,
                    etatModify: this.etatModify,
                    commentModify: this.commentModify,
                  
                    volumeModify: this.volumeModify,
                    operateurModify: this.operateurModify,
                    parcelleModify: this.parcelleModify,
                    subparcelleModify: this.subparcelleModify,
                    dateModify: this.dateModify,
                    diametreModify: this.diametreModify,
                    hauteurModify: this.hauteurModify,
                    tarifModify: this.tarifModify,
                    coupeModify: this.coupeModify,
                    currentTime,
                    type: 'forestier'
                }
            }
            await xhr.post('/add-pied', {
                invt: this.selectedInventaire,
                gps: { lng: this.latModify, lat: this.longModify },
                data
            })
            this.dialogPied = false
            this.dialogPoint = false
            this.latModify = ""
            this.longModify = ""
            this.snackbar = true
            this.snack = "Votre point a été ajouté"
            this.$emit("refreshParent")

        },
        alertPoint(gps, idEvt, evtProp) {
            this.dialogPied = true
            this.evtProp = evtProp
            this.idEvt = idEvt
            this.gpsEvt = gps
        },

        modifyGps(gps, id, point) {
            document.querySelectorAll('.mapboxgl-popup').forEach((popupEl) => {
                popupEl.remove();
            });

            this.snackbar = true
            this.snack = "Déplacer le point bleu pour la nouvelle localisation du point"

            // Créer un point déplaçable
            // Vérifiez d'abord si la source existe déjà, sinon créez-en une nouvelle
            if (!this.map.getSource('draggable-point')) {
                this.map.addSource('draggable-point', {
                    type: 'geojson',
                    data: {
                        type: 'Feature',
                        properties: { ...point },
                        geometry: {
                            type: 'Point',
                            coordinates: [gps.lng, gps.lat]
                        }
                    }
                });

                this.map.addLayer({
                    id: 'draggable-point',
                    source: 'draggable-point',
                    type: 'circle',
                    paint: {
                        'circle-radius': 10,
                        'circle-color': '#007cbf'
                    }
                });
            } else {
                // Mettre à jour les coordonnées du point déplaçable existant
                this.map.getSource('draggable-point').setData({
                    type: 'Feature',
                    properties: { ...point },
                    geometry: {
                        type: 'Point',
                        coordinates: [gps.lng, gps.lat]
                    }
                });
            }

            // Rendre le point déplaçable
            this.setupDraggablePoint(gps, id, point);

        },

        modifyPoint(gps, id, point) {
            // Fermer tous les popups existants
            document.querySelectorAll('.mapboxgl-popup').forEach((popupEl) => {
                popupEl.remove();
            });

            this.snackbar = true
            this.snack = "Modifier votre point"

            this.dialogPoint = true
            this.idEvt = point
            this.gpsEvt = gps
            if (this.isVigneron) {
                this.cepageModify = point.cepage
                this.alleeModify = point.allee,
                this.perCepsModify = point.percent_ceps
                this.perGrappeModify =  point.percent_grappe
                this.domaineModify = point.domaine
                this.etatModify = point.etat
                this.commentModify = point.comment
                this.operateurModify = point.operateur
                this.parcelleModify = point.parcelle
                this.subparcelleModify = point['sous_parcelle']

            } else {

                this.coupeModify = point.coupe
                this.foretModify = point.frt
                this.essenceModify = point.essence
                this.volumeModify = point.volume
                this.parcelleModify = point.par
                this.diametreModify = point.diametre
                this.hauteurModify = point.hauteur
                this.tarifModify = point.trfchaude
                this.etatModify = point.qualite
                this.commentModify = point.comment
                this.operateurModify = point.utilisateur
                this.subparcelleModify = point['spar']

            }

            this.dateModify = point.date // new Date() //this.convertDateToISO(point.date)
        },

        traceRoute(start, end) {
            // Construit l'URL de requête à l'API Directions de Mapbox
            var directionsRequest = 'https://api.mapbox.com/directions/v5/mapbox/walking/' + start[0] + ',' + start[1] + ';' + end[0] + ',' + end[1] + '?steps=true&geometries=geojson&access_token=' + mapboxgl.accessToken;

            // Requête à l'API Directions de Mapbox
            fetch(directionsRequest)
                .then(response => response.json())
                .then(data => {
                    let route = data.routes[0].geometry;
                    let distance = data.routes[0].distance; // Récupère la distance de l'itinéraire en mètres
                    let distanceInKm = (distance / 1000).toFixed(2);

                    // Calculer le temps estimé à pied (5 km/h de moyenne)
                    const walkingSpeedKmH = 5;
                    const timeHours = distanceInKm / walkingSpeedKmH;
                    const timeMinutes = timeHours * 60;

                    // Vérifie si une couche de route existe déjà, si oui, la supprime
                    if (this.map.getSource('route')) {
                        this.map.removeLayer('route');
                        this.map.removeSource('route');
                    }

                    // Ajoute la route comme une source de données et une couche sur la carte
                    this.map.addSource('route', {
                        type: 'geojson',
                        data: {
                            type: 'Feature',
                            properties: {},
                            geometry: route
                        }
                    });

                    this.map.addLayer({
                        id: 'route',
                        type: 'line',
                        source: 'route',
                        layout: {
                            'line-join': 'round',
                            'line-cap': 'round'
                        },
                        paint: {
                            'line-color': '#ab3443',
                            'line-width': 8
                        }
                    });
                    if (window.destinationMarker) window.destinationMarker.remove();

                    window.destinationMarker = new mapboxgl.Marker()
                        .setLngLat(end)
                        .addTo(this.map);


                    new mapboxgl.Popup()
                        .setLngLat(end) // Positionne l'infobulle à l'endroit cliqué (destination)
                        .setHTML(`<strong>Distance:</strong> ${distance.toFixed(2)} m (environ ${distanceInKm} km.). <br>
                        Temps à pied estimé: ${Math.round(timeMinutes)} minutes
                        `) // Définit le contenu de l'infobulle
                        .addTo(this.map); // Ajoute l'infobulle à la carte
                });
        },
        goGeoloc(lng, lat) {
              this.map.flyTo({
                    center: [lng, lat],
                    zoom: 15
            });
        },
        traceIti() {
            if ('geolocation' in navigator) {

                navigator.geolocation.getCurrentPosition((position) => {
                    // Obtient les coordonnées de l'utilisateur
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude;

                    this.map.flyTo({
                        center: [lng, lat],
                        zoom: 12
                    });

                    let start = [position.coords.longitude, position.coords.latitude];

                    this.currentPopup = new mapboxgl.Marker()
                        .setLngLat([lng, lat])
                        .addTo(this.map);

                    this.map.on('click', (e) => {
                        let end = [e.lngLat.lng, e.lngLat.lat];
                        this.traceRoute(start, end);
                    });

                    this.map.getCanvas().style.cursor = 'crosshair';


                    this.snack = "Marquer le point d'itnéraire sur votre parcelle"
                    this.snackbar = true

                }, (error) => {
                    console.error('Erreur de géolocalisation', error);
                    // Gérez les erreurs ici, par exemple en informant l'utilisateur que la géolocalisation a échoué
                }, {
                    enableHighAccuracy: true, // Active la haute précision si disponible
                    timeout: 5000, // Temps maximum en millisecondes avant d'appeler le rappel d'erreur
                    maximumAge: 0 // Indique de ne pas utiliser de position mise en cache
                });
            } else {
                // Géolocalisation non supportée
                alert('La géolocalisation n\'est pas prise en charge par votre navigateur.');
            }

            //this.map.flyTo({ zoom: 13 });
        },

        setupDraggablePoint(gps, id, point) {
            let isDragging = false;
            let startPoint = null;
            this.pointDrag = point
            // 

            this.map.on('touchstart', 'draggable-point', (e) => {
                e.preventDefault();
                isDragging = true;

                this.map.getCanvas().style.cursor = 'grabbing';

                this.map.on('touchmove', onMove);
                this.map.once('touchend', onUp);
            });

            this.map.on('mousedown', 'draggable-point', (e) => {
                e.preventDefault();
                isDragging = true;

                this.map.getCanvas().style.cursor = 'grabbing';

                this.map.on('mousemove', onMove);
                this.map.once('mouseup', onUp);

            });

            const onMove = (e) => {
                if (!isDragging) return;
                let coords = e.lngLat ? [e.lngLat.lng, e.lngLat.lat] : [e.touches[0].lngLat.lng, e.touches[0].lngLat.lat];
                this.map.getSource('draggable-point').setData({
                    type: 'Feature',
                    properties: { ...point },
                    geometry: {
                        type: 'Point',
                        coordinates: coords
                    }
                });
            };

            const onUp = async (e) => {
                if (!isDragging) return;
                isDragging = false;
                let source = this.map.getSource('inventaires');
                let data = source._data;
                let newCoords = e.lngLat ? [e.lngLat.lng, e.lngLat.lat] : [e.changedTouches[0].lngLat.lng, e.changedTouches[0].lngLat.lat];

                let pointToModify = data.features.find(feature => feature.properties.longitude == this.pointDrag.longitude && feature.properties.latitude == this.pointDrag.latitude);
                if (pointToModify) {
                    pointToModify.geometry.coordinates = newCoords;

                    this.map.getSource('inventaires').setData(data);

                    //go xhr
                    await xhr.post('/modify-point', {
                        invt: this.$route.params.id,
                        newCoords,
                        id,
                        gps: this.pointDrag
                    })
                    this.snackbar = true
                    this.snack = "Votre point a été mise à jour sur ses coordonnées"
                    this.pointDrag = null

                    if (this.map.getLayer('draggable-point')) {
                        this.map.removeLayer('draggable-point');
                    }

                }

                // Mettre à jour également le point déplaçable pour refléter le changement
                if (this.map.getSource('draggable-point')) {
                    this.map.getSource('draggable-point').setData({
                        type: 'Feature',
                        properties: { ...point },
                        geometry: {
                            type: 'Point',
                            coordinates: newCoords
                        }
                    });
                    this.map.removeSource('draggable-point');
                }



                this.map.getCanvas().style.cursor = '';
                this.map.off('mousemove', onMove);
            };
        },

        resizeMap() {
            if (this.heightMap == '580px') {
                this.heightMap = '1300px'
                this.$nextTick(() => {
                    if (this.map && this.map.resize) {
                        this.map.resize();
                    }
                });
            } else {
                this.heightMap = '580px'
                this.$nextTick(() => {
                    if (this.map && this.map.resize) {
                        this.map.resize();
                    }
                });
            }

        },
        async sendEmail() {
            let prom = new Promise((resolve, reject) => {
                this.map.once("render", () => {
                    resolve(this.map.getCanvas().toDataURL('image/jpeg', 0.2));
                });
                this.map.setBearing(this.map.getBearing());
            })

            let res = await prom;
            await xhr.post('/send-email', {
                user: { email: "j.boyer69003@gmail.com", uid: this.user.uid },
                image: res
            })
            this.snackbar = true
            this.snack = "Envoyer un e-mail"

        },
        async resetInv() {
            this.clearInv = false
            this.activeChips = []
            this.$emit('resetInv')
            // this.map.flyTo({
            //     essential: true,
            //     duration: 2000,
            //     zoom: 11,
            // })
        },
        async createParcelle() {

            this.$emit('confSaveArea', {
                currentShape: this.currentShape,
                name: this.addParcelle,
                legend: this.legendeParcelle,
                type: this.selectedType.value,
                description: this.descriptionParcelle,
                picto: this.pictoParcelle,
                color: this.addParcelleColor
            })

            this.addParcelle = ''
            this.dialogCreateParcelle = false
            this.draw.changeMode('simple_select');
            this.finishDraw = true

            this.$emit('refreshParent')
        },

        async getPhotoMap() {
            let prom = new Promise((resolve, reject) => {
                this.map.once("render", async () => {
                    const canvas = this.map.getCanvas();

                    canvas.toBlob((blob) => {
                        if (blob) {
                          
                            resolve(blob);
                        } else {
                            reject(new Error("Failed to convert canvas to blob."));
                        }
                    }, 'image/png');

                    // const dataUrl = await canvas.toDataURL('image/png');
                    // let res = await fetch(dataUrl)
                    // res = await res.blob()
                    // 
                    // resolve(res)
                });
                this.map.setBearing(this.map.getBearing());
            })

            return prom;

        },

        async getLegendMap() {
            try {
                let element = null

                if (this.$refs.chips_legend_diametre) {
                    element = this.$refs.chips_legend_diametre; // Accéder à l'élément DOM
                }
                else if (this.$refs.chips_legend_hauteur) {
                    element = this.$refs.chips_legend_hauteur; // Accéder à l'élément DOM
                }
                else if (this.$refs.chips_legend) {
                    element = this.$refs.chips_legend.$el; // Accéder à l'élément DOM
                }

                if (element) {
                    // 
                    // Capturer l'élément avec html2canvas
                    const canvas = await html2canvas(element, {

                        // backgroundColor: null // Assurer la transparence
                    });

                    // Convertir le canvas en blob
                    const blob = new Promise((resolve, reject) => {
                        canvas.toBlob(blob => {
                            if (blob) {
                                resolve(blob);
                            } else {
                                reject(new Error("Failed to convert canvas to blob."));
                            }
                        }, 'image/png');
                    });

                    // 

                    return blob
                }

                return null;
                // Ici, vous pouvez décider de télécharger le blob, l'afficher ou le transmettre ailleurs
            } catch (error) {
                console.error("Error capturing element:", error);
            }
        },
        async takePhoto() {

            let prom = new Promise((resolve, reject) => {
                this.map.once("render", () => {
                    resolve(this.map.getCanvas().toDataURL());
                });
                this.map.setBearing(this.map.getBearing());
            })

            let res = await prom;
            const link = document.createElement("a");
            link.href = res;
            let now = new Date();
            now = now.toLocaleDateString("fr")
            link.download = `export-carte-${now.toString()}.png`;
            link.click();

            this.loadingScreenshot = false

            return true;

        },

        async moveTo(zoomRvt = null) {
            if (this.inventaires.length) {

                // Convertir les données de l'inventaire en un tableau de points [longitude, latitude]
                let points = this.inventaires.map(invt => [invt.longitude, invt.latitude]);

                // Calculer les limites englobant tous les points
                const regexNombreFlottant = /^-?\d+(\.\d+)?$/;
                let coordonnees = points[0]

                if (coordonnees && Array.isArray(coordonnees)) {
                    let bounds = new mapboxgl.LngLatBounds();
                    for (const coordonnees of points) {
                        // Vérifier chaque coordonnée dans le tableau
                        for (const coordonnee of coordonnees) {
                            // Vérifier si chaque élément du point est un nombre flottant
                            if (coordonnee && typeof coordonnee !== 'number' && !regexNombreFlottant.test(coordonnee.toString())) {
                                return false;
                            }
                        }
                        if (coordonnees && coordonnees.length == 2 && coordonnees[0] && coordonnees[1]) {
                            bounds.extend(coordonnees);
                            // Adapter la vue de la carte pour inclure toutes les limites
                            this.map.fitBounds(bounds, {
                                padding: 20, // Ajustez ce padding si nécessaire
                                duration: 2000, // Durée de l'animation
                                essential: true // Cette animation est considérée comme essentielle
                            });
                        }
                    }

                }

            }
        },
        async createPoly() {
            this.addParcelle = ''
            this.legendeParcelle = ''
            this.descriptionParcelle = ''
            // this.pictoParcelle = false
            this.selectedType = { value: 'parcelle', name: 'Parcelles et Sous-Parcelle', description: 'Type de parcelle' }
            this.addParcelleColor = '#ab3443'
            this.dialogEditParcelle = false

            //create a Poly in Map
            this.draw = new MapboxDraw({
                displayControlsDefault: false,
                controls: {
                    polygon: true,
                    trash: true
                },
                defaultMode: 'draw_polygon'
            });
            this.map.addControl(this.draw);
            this.map.on('draw.create', this.createArea);
            this.map.on('draw.update', this.createArea);
            this.finishDraw = true
            this.snackbar = true
            this.snack = "Dessiner votre parcelle sur la carte"
        },
        async createArea(e) {
       
            setTimeout(() =>{
                this.dialogCreateParcelle = true;
                this.finishDraw = false
                this.currentShape = this.draw.getAll();
                 this.map.getCanvas().style.cursor = '';
            }, 400)
       
            // this.finishDraw = false
            // this.currentShape = this.draw.getAll();
            // this.currentShape = data
            // // this.draw = null
            // this.map.getCanvas().style.cursor = 'pointer';
        },
       
       
        async closePop() {
            if (this.currentPopup) {
                this.currentPopup.remove(); // Fermez l'infobulle précédente
            }
        },

        async selectOneParcelle(e, parcelle, datas) {

            if (this.currentPopup) {
                this.currentPopup.remove(); // Fermez l'infobulle précédente
            }
            this.arbreSelected = []

            // Supposons que 'e.features[0].geometry.coordinates' contient les coordonnées de votre parcelle.
            let poly = turf.polygon(e.features[0].geometry.coordinates);

            // Calcul de la superficie de la parcelle en mètres carrés.
            let area = turf.area(poly);

            if (e.features[0].properties.name) {
                this.parcelleSelected = e.features[0]
                this.layerSelected = parcelle
                let allSource = this.map.getStyle().layers.filter((e) => /^(lay-fill-source-)/.test(e.id))

                if (allSource.length) {
                    allSource.forEach((el) => {
                        this.map.setPaintProperty(el.id, 'fill-color', '#ddd');
                    })
                }

                if (this.map.getLayer(parcelle)) {
                    this.map.setPaintProperty(parcelle, 'fill-color', '#FFFF00');
                }
                this.inventaires.forEach((inventaire, index) => {
                    if (inventaire.longitude && inventaire.latitude) {
                        let pt = turf.point([inventaire.longitude, inventaire.latitude]);
                        let poly = turf.polygon(e.features[0].geometry.coordinates);
                        if (turf.booleanPointInPolygon(pt, poly)) {
                            this.arbreSelected.push({
                                ...inventaire
                            })
                        }
                    }
                })

                let centers = e.features[0].geometry.coordinates[0][0]
                this.map.flyTo({
                    essential: true,
                    duration: 2000,
                    center: centers
                });


                if (this.arbreSelected.length) {
                    this.$emit('changeInventaires', { selected: this.arbreSelected, name: e.features[0].properties.name })
                    this.clearInv = true
                }

            }
        },

        async deselectParcelle() {
            if (this.draw) {

                const allFeatures = this.draw.getAll();
                if (allFeatures.features.length > 0) {
                    const updatedParcelle = allFeatures.features[0];
                    let payload = {
                        invt: this.selectedInventaire,
                        parcelleId: updatedParcelle.id, // Assurez-vous que l'ID est présent
                        area: updatedParcelle.geometry
                    };

                    try {
                        let res = await xhr.post('/edit-parcelle-area', {
                            invt: this.selectedInventaire,
                            parcelleId: this.editedParcelle.features[0].id,
                            payload: payload
                        })

                    } catch (e) {

                    }
                }

            }

            this.editedDraw = false
            this.clearMap()
            // this.$emit("refreshParent")
        },


        async onMapLoaded(event) {


             this.map.addControl(new MapboxLanguage({
                defaultLanguage: 'fr'
            }));
          
            let labels = ['country-label', 'state-label',
                 'settlement-subdivision-label',
                'airport-label', 'poi-label', 'water-point-label',
                'water-line-label', 'natural-point-label',
                'natural-line-label', 'waterway-label', ];

            labels.forEach(label => {
                this.map.setLayoutProperty(label, 'text-field', ['get', 'name_fr']);
            });
            
            
                                 this.map.loadImage('/pictos/mdi-arrow-top-left.png', (error, image) => {
                                    if (error) throw error;
                                    this.map.addImage('mdi-arrow-top-left', image);
                                      });
                                    this.map.loadImage('/pictos/mdi-arrow-top-right.png', (error, image) => {
                                    if (error) throw error;
                                    this.map.addImage('mdi-arrow-top-right', image);
                                        });

                                     this.map.loadImage('/pictos/mdi-arrow-left.png', (error, image) => {
                                    if (error) throw error;
                                    this.map.addImage('mdi-arrow-left', image);
                                        });

                                     this.map.loadImage('/pictos/mdi-arrow-right.png', (error, image) => {
                                    if (error) throw error;
                                    this.map.addImage('mdi-arrow-right', image);
                                        });
                                    this.map.loadImage('/pictos/mdi-arrow-bottom-right.png', (error, image) => {
                                    if (error) throw error;
                                    this.map.addImage('mdi-arrow-bottom-right', image);
                                        });
                                    this.map.loadImage('/pictos/mdi-arrow-bottom-left.png', (error, image) => {
                                    if (error) throw error;
                                    this.map.addImage('mdi-arrow-bottom-left', image);

                                    });

                                    this.map.loadImage('/pictos/alert.png', (error, image) => {
                                    if (error) throw error;
                                    this.map.addImage('alert', image);

                                    });
                                     this.map.loadImage('/pictos/mdi-arrow-top.png', (error, image) => {
                                    if (error) throw error;
                                    this.map.addImage('mdi-arrow-top', image);
                                        });
                                     this.map.loadImage('/pictos/mdi-arrow-down.png', (error, image) => {
                                    if (error) throw error;
                                    this.map.addImage('mdi-arrow-down', image);
                                     });

            this.map.loadImage(
                eauFond, // '@/' est un alias pour le répertoire src/ dans Vue.js
                (error, image) => {
                    if (error) throw error;
                    // Ajouter l'image chargée en tant que source d'image pour Mapbox
                    this.map.addImage('fond-eau', image);
                }
            );


            this.map.loadImage(
                cheminFond, // '@/' est un alias pour le répertoire src/ dans Vue.js
                (error, image) => {
                    if (error) throw error;
                    // Ajouter l'image chargée en tant que source d'image pour Mapbox
                    this.map.addImage('fond-chemin', image);
                }
            );


            this.map.loadImage(
                entreprotFond, // '@/' est un alias pour le répertoire src/ dans Vue.js
                (error, image) => {
                    if (error) throw error;
                    // Ajouter l'image chargée en tant que source d'image pour Mapbox
                    this.map.addImage('fond-entrepot', image);
                }
            );
            this.map.loadImage(
                surveillanceFond, // '@/' t un alias pour le répertoire src/ dans Vue.js
                (error, image) => {
                    if (error) throw error;
                    // Ajouter l'image chargée en tant que source d'image pour Mapbox
                    this.map.addImage('fond-surveillance', image);
                }
            );
            this.map.loadImage(
                etangFond, // '@/' t un alias pour le répertoire src/ dans Vue.js
                (error, image) => {
                    if (error) throw error;
                    // Ajouter l'image chargée en tant que source d'image pour Mapbox
                    this.map.addImage('fond-etang', image);
                }
            );

            this.map.loadImage(
                humideFond, // '@/' t un alias pour le répertoire src/ dans Vue.js
                (error, image) => {
                    if (error) throw error;
                    // Ajouter l'image chargée en tant que source d'image pour Mapbox
                    this.map.addImage('fond-humide', image);
                }
            );


            this.map.loadImage(
                rareFond, // '@/' t un alias pour le répertoire src/ dans Vue.js
                (error, image) => {
                    if (error) throw error;
                    // Ajouter l'image chargée en tant que source d'image pour Mapbox
                    this.map.addImage('fond-rare', image);
                }
            );

            this.map.loadImage(
                habitatFond, // '@/' t un alias pour le répertoire src/ dans Vue.js
                (error, image) => {
                    if (error) throw error;
                    // Ajouter l'image chargée en tant que source d'image pour Mapbox
                    this.map.addImage('fond-habitat', image);
                }
            );
            this.map.loadImage(
                observationFond, // '@/' t un alias pour le répertoire src/ dans Vue.js
                (error, image) => {
                    if (error) throw error;
                    // Ajouter l'image chargée en tant que source d'image pour Mapbox
                    this.map.addImage('fond-observation', image);
                }
            );

            this.map.loadImage(
                incendieFond, // '@/' t un alias pour le répertoire src/ dans Vue.js
                (error, image) => {
                    if (error) throw error;
                    // Ajouter l'image chargée en tant que source d'image pour Mapbox
                    this.map.addImage('fond-incendie', image);
                }
            );
            this.map.loadImage(
                erosionFond, // '@/' t un alias pour le répertoire src/ dans Vue.js
                (error, image) => {
                    if (error) throw error;
                    // Ajouter l'image chargée en tant que source d'image pour Mapbox
                    this.map.addImage('fond-erosion', image);
                }
            );

            this.map.loadImage(
                maladieFond, // '@/' t un alias pour le répertoire src/ dans Vue.js
                (error, image) => {
                    if (error) throw error;
                    // Ajouter l'image chargée en tant que source d'image pour Mapbox
                    this.map.addImage('fond-maladie', image);
                }
            );


            this.map.loadImage(
                randoFond, // '@/' t un alias pour le répertoire src/ dans Vue.js
                (error, image) => {
                    if (error) throw error;
                    // Ajouter l'image chargée en tant que source d'image pour Mapbox
                    this.map.addImage('fond-rando', image);
                }
            );

            this.map.loadImage(
                exploitationFond, // '@/' t un alias pour le répertoire src/ dans Vue.js
                (error, image) => {
                    if (error) throw error;
                    // Ajouter l'image chargée en tant que source d'image pour Mapbox
                    this.map.addImage('fond-exploitation', image);
                }
            );

            this.map.loadImage(
                archeoFond, // '@/' t un alias pour le répertoire src/ dans Vue.js
                (error, image) => {
                    if (error) throw error;
                    // Ajouter l'image chargée en tant que source d'image pour Mapbox
                    this.map.addImage('fond-historiques', image);
                }
            );




            this.map.loadImage(
                protegeesFond, // '@/' t un alias pour le répertoire src/ dans Vue.js
                (error, image) => {
                    if (error) throw error;
                    // Ajouter l'image chargée en tant que source d'image pour Mapbox
                    this.map.addImage('fond-protegees', image);
                }
            );


            if (this.selectedInventaireObj.profil == 'forestier') {

                this.map.addSource('forests', {
                    'type': 'geojson',
                    'data': 'http://localhost:4000/api/geojson-foret' // URL ou objet GeoJSON direct
                });

                // Ajoutez un nouveau calque utilisant les données GeoJSON
                this.map.addLayer({
                    'id': 'forests-layer',
                    'type': 'fill',
                    "minZoom": 22,
                    'source': 'forests',
                    'layout': {},
                    'paint': {
                        'fill-color': '#228B22', // Couleur des forêts
                        'fill-opacity': 0.4
                    }
                });

                this.map.addLayer({
                    'id': 'forets-names',
                    'type': 'symbol',
                    'source': 'forests',
                    "minZoom": 22,
                    'layout': {
                        // Ici, vous définissez comment et où le texte sera affiché
                        'text-field': ['get', 'llib_frt'], // Utilisez la propriété 'lib_frt' de votre GeoJSON comme texte
                        'text-size': 12, // Taille du texte
                        'text-anchor': 'top', // Anchor du texte (où il est ancré par rapport à son point de coordonnées)
                        'text-offset': [0, 1.2] // Décale le texte un peu au-dessus du point pour ne pas superposer le symbole s'il y en a un
                    },
                    'paint': {
                        'text-color': '#000000', // Couleur du texte
                        'text-halo-color': '#ffffff', // Halo autour du texte pour le rendre lisible sur fond varié
                        'text-halo-width': 2
                    }
                });
            }


            if(this.user.reader != true){
                    this.map.addControl(new Mapbox.FullscreenControl());
            }


            function calculDistance(event) {
                alert("Event handler when clicking on \r\n" + event.target.className);

            }

            // Add the geolocate control to the map.
            const geolocateControl = new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                trackUserLocation: true,
           
                showAccuracyCircle: true
            });

            this.map.addControl(geolocateControl, 'top-right');

            if(this.user.reader != true){
                this.map.addControl(new PitchToggle({ minpitchzoom: 18 }), "top-left");
            }




            // this.map.addControl(ctrlLine, "bottom-right");

            if (this.selectedInventaireObj) {
                // this.map.on('load', async () => {
                //     await this.map.flyTo({
                //             center: [
                //                 this.selectedInventaireObj.inventaires[0].Longitude, 
                //                 this.selectedInventaireObj.inventaires[0].Latitude,
                //             ],
                //             zoom: 8,
                //             speed: 2,
                //             duration: 2000,
                //             essential: true
                //         })
                //         this.map.resize()
                // })
                // this.map.setCenter([
                //     this.selectedInventaireObj.inventaires[0].Longitude, 
                //     this.selectedInventaireObj.inventaires[0].Latitude,
                // ])
                // this.map.setZoom(10);
                // this.map.resize();

            }

            // setTimeout(async () =>{

            //     if(this.selectedInventaireObj){
            //         
            //             
            //             await this.map.flyTo({
            //             center: [
            //                 this.selectedInventaireObj.inventaires[0].Longitude, 
            //                 this.selectedInventaireObj.inventaires[0].Latitude,
            //             ],
            //             zoom: 8,
            //         })


            //     }
            // }, 3000)

            //  this.map.on('draw.create', updateArea);
            //  this.map.on('draw.delete', updateArea);
            //  this.map.on('draw.update', updateArea);


            // this.map.on('load', () => { =W 
            // this.licensesTwo.forEach((e) =>{


            // // Change the cursor to a pointer when the it enters a feature in the 'circle' layer.
            // this.map.on('mouseenter', 'circle', () => {
            //     this.map.getCanvas().style.cursor = 'pointer';
            // });

            // // // Change it back to a pointer when it leaves.
            // this.map.on('mouseleave', 'circle', () => {
            //     this.map.getCanvas().style.cursor = '';
            // });

            //         this.map.addLayer(
            //           {
            //           'id': 'terrain-data',
            //           'type': 'circle',
            //           'source': 'ethnicity',
            //           'source-layer': 'sf2010',
            //           'paint': {
            //           // Make circles larger as the user zooms from z12 to z22.
            //           'circle-radius': {
            //             'base': 1.75,
            //             'stops': [
            //               [12, 2],
            //               [22, 180]
            //             ]
            //           },
            //           // Color circles by ethnicity, using a `match` expression.
            //           'circle-color': [
            //             'match',
            //             ['get', 'ethnicity'],
            //             'White',
            //             '#fbb03b',
            //             'Black',
            //             '#223b53',
            //             'Hispanic',
            //             '#e55e5e',
            //             'Asian',
            //             '#3bb2d0',
            // /* other */ '#ccc'
            //           ]
            //           },
            //         },
            //           // Place polygons under labels, roads and buildings.
            //           'aeroway-polygon'
            //         );

            // })

            // Add the vector tileset as a source.
            // })
            // const newParams = await asyncActions.flyTo({
            //   center: [-76.53063297271729,
            //     39.18174077994108],
            //   zoom: 8,
            //   speed: 2
            // })
            // 
            /* => {
                    center: [30, 30],
                    zoom: 9,
                    bearing: 9,
                    pitch: 7
                  }
            */
        },

        formaterDateEnFrancais(dateString) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            let ok = new Date(dateString).toLocaleDateString('fr-FR', options);
            return ok
        },

        removeAllFillLayers(ch) {
            const layers = this.map.getStyle().layers; // Obtenir tous les layers de la carte
            layers.forEach(layer => {
                if (layer.id.startsWith(ch)) {
                    this.map.removeLayer(layer.id);
                }
            });
        },

        removeAllFillSources(ch) {
            const style = this.map.getStyle();

            // Collecter les identifiants des sources à supprimer
            const sourcesToRemove = Object.keys(style.sources).filter(sourceId => sourceId.startsWith(ch));

            sourcesToRemove.forEach(sourceId => {
                // Vérifier si la source est utilisée par un layer
                const layersUsingSource = style.layers.filter(layer => layer.source === sourceId);

                // Supprimer tous les layers utilisant cette source d'abord
                layersUsingSource.forEach(layer => {
                    this.map.removeLayer(layer.id);
                });

                // Enfin, supprimer la source
                this.map.removeSource(sourceId);
            });
        },

        removeAllLineLayers(prefix) {
            const layers = this.map.getStyle().layers;
            if (layers) {
                layers.forEach((layer) => {
                    if (layer.id.startsWith(prefix)) {
                        this.map.removeLayer(layer.id);
                    }
                });
            }
        },
        
        removeAllLineSources(prefix) {
            const sources = this.map.getStyle().sources;
            if (sources) {
                Object.keys(sources).forEach((source) => {
                    if (source.startsWith(prefix)) {
                        this.map.removeSource(source);
                    }
                });
            }
        }
    },
    watch: {

        savePoints(newVal) {
            this.$emit('updateOffline', newVal);
        },
      
        secteursShared(val){
             if (val && val.length) {
             
                let commune = val[0]
                 if (commune && commune.geometry && commune.geometry.coordinates){
                    let center = commune.geometry.coordinates[0][0][0]
                     this.map.flyTo({
                            center: center,
                            zoom: 14
                    });
                }
             }
        },
        selectedType(val) {

            if (val && val.value) {

                let valModifier = val.value

                switch (valModifier) {
                    case "chemin_foret":
                        this.addParcelleColor = { color: "#FFB6C1" }
                        break;
                    case "installation_gestion":
                        this.addParcelleColor = { color: "#808080" }
                        break;
                    case "surveillance":
                        this.addParcelleColor = { color: "#FF8C00" }
                        break;
                    case "ruisseau_riviere":
                        this.addParcelleColor = { color: "#00FFFF" }
                        break;
                    case "etang_lac":
                        this.addParcelleColor = { color: "#0000FF" }
                        break;
                    case "zone_humide":
                        this.addParcelleColor = { color: "#00FF00" }
                        break;
                    case "plante_rare":
                        this.addParcelleColor = { color: "#FFD700" }
                        break;
                    case "habitats_faunique":
                        this.addParcelleColor = { color: "#808000" }
                        break;
                    case "observation_espece":
                        this.addParcelleColor = { color: "#FFDAB9" }
                        break;
                    case "zone_risque_incendie":
                        this.addParcelleColor = { color: "#FF0000" }
                        break;
                    case "zone_risque_erosion":
                        this.addParcelleColor = { color: "#00FFFF" }
                        break;
                    case "zone_affectees_maladie":
                        this.addParcelleColor = { color: "#FFA07A" }
                        break;
                    case "air_protegees":
                        this.addParcelleColor = { color: "#FF7F50" }
                        break;
                    case "sentier":
                        this.addParcelleColor = { color: "#00FF00" }
                        break;
                    case "exploitation":
                        this.addParcelleColor = { color: "#FF1493" }
                        break;
                    case "historiques":
                        this.addParcelleColor = { color: "#DC143C" }
                        break;
                }

            }



        },

        dialogPoint(val) {
            if (val == false) {
                this.showPoint = true
                this.removeMapClickEvent()
            }
        },
        dateMeteo(newDate) {
            if (newDate) {
                this.formattedDateMeteo = this.formatDate(newDate);
            }
        },

        rail(val) {
            this.map.resize()
            this.$emit('refreshParent');
        },
        typeMap(val) {
            if (val == "perso") {
                this.map.setStyle('mapbox://styles/taiwa69/clutrehs6003301qxbjf478th');
            } else {
                this.map.setStyle('mapbox://styles/mapbox/' + val);
            }
            this.$emit('refreshParent');

        },
        seeParcelles(val) {
            setTimeout(() => {
                // if(val != true){
                //     this.indexParcellex.forEach((e) => {
                //             this.map.setLayoutProperty('fill-' + e, 'visibility', 'none');
                //     })
                // } else {
                //     this.indexParcellex.forEach((e) => {
                //             this.map.setLayoutProperty('fill-' + e, 'visibility', 'visible');
                //     })
                // }
            }, 1000)

        },

        async markers(val) {

            this.clearMarkers();

            if (val.length){
                val.forEach(async(marker) => {
                         // Création de l'élément div pour l'icône du marqueur
                        const el = document.createElement('div');
                        el.className = 'custom-marker';

                        const svg = await this.loadSVG(marker.type + ".svg")
                        if (!marker.type) return ;
                        let drawin = SVG(svg).size(marker.size, marker.size);
                        drawin.find('path').stroke(marker.color); // Met à jour la couleur des éléments 'path', ajustez selon vos besoins

                        const svgElement = drawin.node;
                        const serializedSVG = new XMLSerializer().serializeToString(svgElement);
                        const encodedData = encodeURIComponent(serializedSVG);
                        const dataUrl = `data:image/svg+xml;utf8,${encodedData}`;
                        el.style.backgroundImage = `url(${dataUrl})`;
                        el.style.width = `${marker.size}px` // Taille de l'icône
                        el.style.height = `${marker.size}px`
                        el.style.backgroundSize = 'contain';
                        el.style.display = 'none'
                        

                        this.popup = new mapboxgl.Popup({ offset: 25 })
                            .setHTML(`<h3>${marker.title}</h3><p>${marker.description}</p>
                                <button style='text-transform: capitalize' class="v-btn primary pa-2 v-btn--contained v-btn--color-white v-btn--rounded v-btn--primary"  id="delete-${marker._id}">✂️ Supprimer ce marqueur</button>
                            `);

                        const mapMarker = new mapboxgl.Marker({
                            element: el
                        }).setLngLat([marker.longitude, marker.latitude])
                        .setPopup(this.popup)
                        .addTo(this.map);

                        this.allMarkers.push(mapMarker); 

                    this.popup.on('open', () => {
                        document.getElementById(`delete-${marker._id}`).addEventListener('click', () => {
                            let conf = confirm('Voulez-vous supprimer ce marqueur ?')
                            if(conf == true){
                                return this.deleteMarker(marker._id);
                            }
                        });
                    });
                });
            }
        },


        async galleries(val) {
            this.clearGalleries()
            
            if (val.length) {
                val.forEach(async (gallerie) => {

                    let dataUrl = `https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/Icons8_flat_gallery.svg/768px-Icons8_flat_gallery.svg.png?20160307113428`;

                    const el = document.createElement('div');
                    el.style.backgroundImage = `url(${dataUrl})`;
                    el.style.width = `30px` // Taille de l'icône
                    el.style.height = `30px`
                    el.style.backgroundSize = 'contain';
                    // el.style.display = 'none'

                     const mapMarker = new mapboxgl.Marker({
                        element: el
                    }).setLngLat([gallerie.longitude, gallerie.latitude])
                    .setPopup(this.popup)
                    .addTo(this.map);

                    // console.log("gallerie", gallerie);
                    this.popup = new mapboxgl.Popup({ offset: 15 })

                    const overlay = (src) => {
                        this.overlayImg = true
                        this.overlayImgSrc = src
                    }
                        
                    const galleryComponent = new Vue({
                        render: h => h(Photos, {
                        props: {
                            gallerie,
                                invId: this.selectedInventaire,
                        },
                        on: {
                            overlayImg: overlay,
                        }
                    }),
                    }).$mount()

                // console.log("galleryComponent", galleryComponent);

                let popup = new mapboxgl.Popup({
                    closeButton: true,
                    closeOnClick: true
                })
                popup.setDOMContent(galleryComponent.$el)
                mapMarker.setPopup(popup);

                mapMarker.getElement().addEventListener('click', () => {
                    this.popup.addTo(this.map); // Ceci est utile si vous avez besoin de contrôler précisément quand le popup est ajouté à la carte
                });

                this.allGalleries.push(mapMarker);

                    // this.popup.on('open', () => {
                    //     document.getElementById(`delete-${gallerie._id}`).addEventListener('click', () => {
                    //         let conf = confirm('Voulez-vous supprimer cette gallerie  ?')
                    //         if (conf == true) {
                    //             // return this.deleteMarker(marker._id);
                    //         }
                    //     });
                    // });
                });
            }
        },


        async arrows(val) {

            this.clearArrows();
            if (val.length) {
                val.forEach(async (marker) => {
                    // Création de l'élément div pour l'icône du marqueur
                    // console.log("val", val);
                    const svgContent = await this.loadSVGArrow(marker.type + ".svg")
                    
                    let draw = SVG(svgContent).size(marker.size, marker.size);
                    draw.find('path').fill(marker.color); // Met à jour la couleur des éléments 'path', ajustez selon vos besoins
                          
                    const svgElement = draw.node;
                    const clonedSvgElement = svgElement.cloneNode(true);
                    const rectElement = clonedSvgElement.querySelector('rect');
                    if (rectElement) {
                        rectElement.remove();
                    }
                    // Convert the cloned SVG to a string
                    const svgString = new XMLSerializer().serializeToString(clonedSvgElement);
                    const canvas = document.createElement('canvas');
                    canvas.width = marker.size;
                    canvas.height = marker.size;
                    const ctx = canvas.getContext('2d');
                    const v = Canvg.fromString(ctx, svgString);
                    await v.render();
                    let dataUrl = canvas.toDataURL('image/png');
                    
                    const el = document.createElement('div');
                    el.style.backgroundImage = `url(${dataUrl})`;
                    el.style.width = `${marker.size}px` // Taille de l'icône
                    el.style.height = `${marker.size}px`
                    el.style.backgroundSize = '100%';
                    el.className = 'marker';
                    el.style.display = 'none'

                    const textElement = document.createElement('div');
                    textElement.className = 'marker-text white--text';
                    textElement.textContent = marker.legende;
                    textElement.style.fontSize = `${marker.fontSize}px`;
                    el.appendChild(textElement);
           
                    el.style.transform = `rotate(${this.icon.rotate}deg)`;

                    let mapMarker = new mapboxgl.Marker({
                        element: el
                    }).setLngLat([marker.longitude, marker.latitude])
                    .addTo(this.map);


                    // this.popup = new mapboxgl.Popup({ offset: 25 })
                    //     .setHTML(`<h3>${marker.legend}</h3>
                    //             <button style='text-transform: capitalize' class="v-btn primary pa-2 v-btn--contained v-btn--color-white v-btn--rounded v-btn--primary"  id="delete-${marker._id}">✂️ Supprimer ce marqueur</button>
                    //         `);
//mapMarker
                    this.allArrows.push({ marker: mapMarker, zoom: marker.zoom })

                });


                // this.map.addSource('markersAll', {
                //     type: 'geojson',
                //     data: {
                //         type: 'FeatureCollection',
                //         features: this.allArrows,
                //     },
                // });

                // this.map.addLayer({
                //     id: 'markersEvery',
                //     type: 'symbol',
                //     source: 'markersAll',
                //     layout: {
                //         'icon-image': ['get', 'icon'],
                //         'icon-size': ['get', 'size'],
                //         'icon-allow-overlap': true,
                //     },
                // });

            }
        },
        
        async postitsLoaded(val) {

            //reset layers and source
            this.removeAllFillLayers('layer-loaded-')
            this.removeAllFillSources('source-loaded-')

            if(val && val.length){
                let base64ToBlob = (base64, type = 'image/png') => {
                    const binary = atob(base64.split(',')[1]);
                    const array = [];
                    for (let i = 0; i < binary.length; i++) {
                        array.push(binary.charCodeAt(i));
                    }
                    return new Blob([new Uint8Array(array)], { type: type });
                }

                let loadImage = async (url) =>  {
                    return new Promise((resolve, reject) => {
                        const img = new Image();
                        img.onload = () => resolve(img);
                        img.onerror = () => reject(new Error('Failed to load image'));
                        img.src = url;
                    });
                }


                val.forEach(async (elt, index) => {

   
                    try {
                        console.log("elt", elt);
                           let base64Image = elt.img;
                            // Vérifier si base64Image inclut le préfixe
                            if (!base64Image.startsWith('data:image')) {
                                base64Image = `data:image/png;base64,${base64Image}`;
                            }
                            const blob = base64ToBlob(base64Image);
                            const url = URL.createObjectURL(blob);

                            // Charger l'image
                            const image = await loadImage(url);

                            this.map.addSource(`source-loaded-${elt._id}`, {
                                type: 'image',
                                url,
                                coordinates: elt.coordinates
                            });

                            this.map.addLayer({
                                'id': `layer-loaded-${elt._id}`,
                                type: 'raster',
                                source: `source-loaded-${elt._id}`,
                                paint: {
                                    'raster-fade-duration': 0
                                }
                            });

                    

                    } catch (error) {
                        
                    }
                    



                })

            }


        },

        async parcelles(val) {
            
            //    if(!this.map) this.map = Mapbox

            //reset layers and source
            this.removeAllFillLayers('lay-')
            this.removeAllFillSources('source-')

            val.forEach((elt, index) => {
                let parcelle = "source-" + index
                this.indexParcellex = [...this.indexParcellex, parcelle]

                let features = elt.features.map((e) => {

                    let feature = elt.features[0]
                    const area = turf.area(feature); // La surface en mètres carrés
                    const hectares = area / 10000; // Convertit en hectares (1 hectare = 10 000 mètres carrés)

                    return {
                        ...e,
                        "properties": {
                            "name": elt.features[0].properties ? elt.features[0].properties.name : 'Non renseigné',
                            "legend": elt.features[0].properties ? elt.features[0].properties.legend : null,
                            "picto": elt.features[0].properties ? elt.features[0].properties.picto : false,
                            "type": elt.features[0].properties ? elt.features[0].properties.type : null,
                            "description": elt.features[0].properties ? elt.features[0].properties.description : null,
                            "hectares": hectares ? hectares.toFixed(2) : 0,
                            "color": elt.features[0].properties ? elt.features[0].properties.color : '#ab3443',
                        },
                    }
                })

                this.map.addSource(parcelle, {
                    'type': 'geojson',
                    'data': {
                        "type": elt.type,
                        "features": features,
                    }
                })
                let colorParcelle = elt.features[0].properties.color || '#ab3443'



                this.map.addLayer({
                    'id': 'lay-label-' + parcelle, // Un identifiant unique pour le layer de texte
                    'type': 'symbol', // Type symbol pour afficher du texte
                    'source': parcelle, // Utilisez la même source que le layer de type 'fill'
                    'layout': {
                        'visibility': 'visible',
                        'text-field': ['get', 'name'], // Utilise 'name' depuis la propriété de l'objet GeoJSON
                        'text-size': 14, // Taille du texte
                        'text-transform': 'uppercase', // Transforme le texte en majuscules
                        'text-letter-spacing': 0.05, // Espacement entre les lettres
                        'text-offset': [0, -2],// Ancrage du texte, peut être ajusté selon le besoin
                    },
                    'paint': {
                        'text-color': '#FFFFFF', // Couleur du texte
                        'text-halo-color': '#000000', // Couleur de l'halo autour du texte pour améliorer la lisibilité
                        'text-halo-width': 2, // Largeur de l'halo autour du texte
                    },
                    'filter': ['==', '$type', 'Polygon'] // Assurez-vous que ce layer s'applique uniquement aux Polygones si nécessaire
                });

                this.map.addLayer({
                    'id': 'lay-description-' + parcelle, // Un identifiant unique pour le layer de légende
                    'type': 'symbol', // Type symbol pour afficher du texte
                    'source': parcelle, // Utilisez la même source que le layer de type 'fill'
                    'layout': {
                        'visibility': 'none', // Affiche ce layer seulement quand zoom < 22
                        'text-field': ['get', 'description'], // Utilise 'legend' depuis la propriété de l'objet GeoJSON
                        'text-size': 10, // Taille du texte plus petite que celle du nom
                        'text-offset': [0, 0],
                    },
                    'paint': {
                        'text-color': '#FFFFFF',
                        'text-halo-color': '#000000', // Couleur de l'halo autour du texte pour améliorer la lisibilité
                        'text-halo-width': 1,
                    },
                    'filter': ['==', '$type', 'Polygon'] // S'assure que ce layer s'applique uniquement aux Polygones
                })
                let typeSelected = features[0].properties.type

                if (typeSelected == "ruisseau_riviere" && features[0].properties.picto == true) {
                    this.map.addLayer({
                        'id': 'contour-' + parcelle,
                        'type': 'line',
                        'source': parcelle,
                        'layout': {},
                        'paint': {
                            'line-color': '#094DA7', // Hex code pour bleu
                            'line-width': 4, // Largeur du contour en pixels
                            'line-dasharray': [2, 4]
                        },
                        'filter': ['==', ['get', 'type'], 'ruisseau_riviere'] // Appliquez le même filtre que le fill-pattern si nécessaire
                    });
                    this.map.addLayer({
                        'id': 'picto-layer-' + parcelle,
                        'type': 'symbol',
                        'source': parcelle, // Assurez-vous que cette source contient vos données de polygone
                        'layout': {
                            'icon-image': 'fond-eau', // Le nom de l'image chargée
                            'icon-size': 1, // Ajustez la taille de l'icône selon le besoin
                            'icon-allow-overlap': true,
                            'icon-offset': [0, 50], //
                        }
                    });
                }

                else if (typeSelected == "chemin_foret" && features[0].properties.picto == true) {
                    this.map.addLayer({
                        'id': 'contour-' + parcelle,
                        'type': 'line',
                        'source': parcelle,
                        'layout': {},
                        'paint': {
                            'line-color': '#A95700', // Hex code pour bleu
                            'line-width': 4, // Largeur du contour en pixels
                            'line-dasharray': [2, 4]
                        },
                        'filter': ['==', ['get', 'type'], 'chemin_foret'] // Appliquez le même filtre que le fill-pattern si nécessaire
                    });
                    this.map.addLayer({
                        'id': 'picto-layer-' + parcelle,
                        'type': 'fill',
                        'source': parcelle, // Assurez-vous que cette source contient vos données de polygone
                        'paint': {
                            'fill-pattern': 'fond-chemin', // Utilisez le nom donné lors du chargement de l'image
                            'fill-opacity': 1 // Vous pouvez ajuster l'opacité si nécessaire
                        },
                    });
                }

                else if (typeSelected == "installation_gestion" && features[0].properties.picto == true) {
                    this.map.addLayer({
                        'id': 'contour-installation_gestion-' + parcelle,
                        'type': 'line',
                        'source': parcelle,
                        'layout': {},
                        'paint': {
                            'line-color': '#808080', // Hex code pour bleu
                            'line-width': 4, // Largeur du contour en pixels
                            'line-dasharray': [2, 4]
                        },
                        'filter': ['==', ['get', 'type'], 'installation_gestion'] // Appliquez le même filtre que le fill-pattern si nécessaire
                    });
                    this.map.addLayer({
                        'id': 'picto-layer-installation_gestion-' + parcelle,
                        'type': 'symbol',
                        'source': parcelle, // Assurez-vous que cette source contient vos données de polygone
                        'layout': {
                            'icon-image': 'fond-entrepot', // Le nom de l'image chargée
                            'icon-size': 1, // Ajustez la taille de l'icône selon le besoin
                            'icon-allow-overlap': true,
                            'icon-offset': [0, 50], //



                        },
                    });
                }

                else if (typeSelected == "surveillance" && features[0].properties.picto == true) {
                    this.map.addLayer({
                        'id': 'contour-surveillance-' + parcelle,
                        'type': 'line',
                        'source': parcelle,
                        'layout': {},
                        'paint': {
                            'line-color': '#f88e55', // Hex code pour bleu
                            'line-width': 4, // Largeur du contour en pixels
                            'line-dasharray': [2, 2]
                        },
                        'filter': ['==', ['get', 'type'], 'surveillance'] // Appliquez le même filtre que le fill-pattern si nécessaire
                    });
                    this.map.addLayer({
                        'id': 'picto-layer-surveillance-' + parcelle,
                        'type': 'symbol',
                        'source': parcelle, // Assurez-vous que cette source contient vos données de polygone
                        'layout': {
                            'icon-image': 'fond-surveillance', // Le nom de l'image chargée
                            'icon-size': 0.6, // Ajustez la taille de l'icône selon le besoin
                            'icon-allow-overlap': true,
                            'icon-offset': [0, 70], //
                        },
                    });
                }

                else if (typeSelected == "etang_lac" && features[0].properties.picto == true) {
                    this.map.addLayer({
                        'id': 'contour-etang_lac-' + parcelle,
                        'type': 'line',
                        'source': parcelle,
                        'layout': {},
                        'paint': {
                            'line-color': '#30cecf', // Hex code pour bleu
                            'line-width': 4, // Largeur du contour en pixels
                            'line-dasharray': [2, 2]
                        },
                        'filter': ['==', ['get', 'type'], 'etang_lac'] // Appliquez le même filtre que le fill-pattern si nécessaire
                    });
                    this.map.addLayer({
                        'id': 'picto-layer-etang_lac-' + parcelle,
                        'type': 'symbol',
                        'source': parcelle, // Assurez-vous que cette source contient vos données de polygone
                        'layout': {
                            'icon-image': 'fond-etang', // Le nom de l'image chargée
                            'icon-size': 0.6, // Ajustez la taille de l'icône selon le besoin
                            'icon-allow-overlap': true,
                            'icon-offset': [0, 70], //
                        },
                    });
                }

                else if (typeSelected == "zone_humide" && features[0].properties.picto == true) {
                    this.map.addLayer({
                        'id': 'contour-zone_humide-' + parcelle,
                        'type': 'line',
                        'source': parcelle,
                        'layout': {},
                        'paint': {
                            'line-color': '#5F9EA0', // Hex code pour bleu
                            'line-width': 4, // Largeur du contour en pixels
                            'line-dasharray': [2, 2]
                        },
                        'filter': ['==', ['get', 'type'], 'zone_humide'] // Appliquez le même filtre que le fill-pattern si nécessaire
                    });
                    this.map.addLayer({
                        'id': 'picto-layer-humide-' + parcelle,
                        'type': 'symbol',
                        'source': parcelle, // Assurez-vous que cette source contient vos données de polygone
                        'layout': {
                            'icon-image': 'fond-humide', // Le nom de l'image chargée
                            'icon-size': 0.6, // Ajustez la taille de l'icône selon le besoin
                            'icon-allow-overlap': true,
                            'icon-offset': [0, 70], //
                        },
                    });
                }

                else if (typeSelected == "plante_rare" && features[0].properties.picto == true) {
                    this.map.addLayer({
                        'id': 'contour-plante_rare-' + parcelle,
                        'type': 'line',
                        'source': parcelle,
                        'layout': {},
                        'paint': {
                            'line-color': '#5F9EA0', // Hex code pour bleu
                            'line-width': 4, // Largeur du contour en pixels
                            'line-dasharray': [2, 2]
                        },
                        'filter': ['==', ['get', 'type'], 'plante_rare'] // Appliquez le même filtre que le fill-pattern si nécessaire
                    });
                    this.map.addLayer({
                        'id': 'picto-layer-humide-' + parcelle,
                        'type': 'symbol',
                        'source': parcelle, // Assurez-vous que cette source contient vos données de polygone
                        'layout': {
                            'icon-image': 'fond-rare', // Le nom de l'image chargée
                            'icon-size': 0.6, // Ajustez la taille de l'icône selon le besoin
                            'icon-allow-overlap': true,
                            'icon-offset': [0, 70], //
                        },
                    });
                }

                else if (typeSelected == "habitats_faunique" && features[0].properties.picto == true) {
                    this.map.addLayer({
                        'id': 'contour-habitats_faunique-' + parcelle,
                        'type': 'line',
                        'source': parcelle,
                        'layout': {},
                        'paint': {
                            'line-color': '#5F9EA0', // Hex code pour bleu
                            'line-width': 4, // Largeur du contour en pixels
                            'line-dasharray': [2, 2]
                        },
                        'filter': ['==', ['get', 'type'], 'habitats_faunique'] // Appliquez le même filtre que le fill-pattern si nécessaire
                    });
                    this.map.addLayer({
                        'id': 'picto-layer-humide-' + parcelle,
                        'type': 'symbol',
                        'source': parcelle, // Assurez-vous que cette source contient vos données de polygone
                        'layout': {
                            'icon-image': 'fond-habitat', // Le nom de l'image chargée
                            'icon-size': 0.6, // Ajustez la taille de l'icône selon le besoin
                            'icon-allow-overlap': true,
                            'icon-offset': [0, 70], //
                        },
                    });
                }

                else if (typeSelected == "observation_espece" && features[0].properties.picto == true) {
                    this.map.addLayer({
                        'id': 'contour-observation_espece-' + parcelle,
                        'type': 'line',
                        'source': parcelle,
                        'layout': {},
                        'paint': {
                            'line-color': '#9ACD32', // Hex code pour bleu
                            'line-width': 4, // Largeur du contour en pixels
                            'line-dasharray': [2, 2]
                        },
                        'filter': ['==', ['get', 'type'], 'observation_espece'] // Appliquez le même filtre que le fill-pattern si nécessaire
                    });
                    this.map.addLayer({
                        'id': 'picto-layer-observation_espece-' + parcelle,
                        'type': 'symbol',
                        'source': parcelle, // Assurez-vous que cette source contient vos données de polygone
                        'layout': {
                            'icon-image': 'fond-observation', // Le nom de l'image chargée
                            'icon-size': 0.6, // Ajustez la taille de l'icône selon le besoin
                            'icon-allow-overlap': true,
                            'icon-offset': [0, 70], //
                        },
                    });
                }

                else if (typeSelected == "zone_risque_incendie" && features[0].properties.picto == true) {
                    this.map.addLayer({
                        'id': 'contour-zone_risque_incendie-' + parcelle,
                        'type': 'line',
                        'source': parcelle,
                        'layout': {},
                        'paint': {
                            'line-color': '#e22819', // Hex code pour bleu
                            'line-width': 4, // Largeur du contour en pixels
                            'line-dasharray': [2, 2]
                        },
                        'filter': ['==', ['get', 'type'], 'zone_risque_incendie'] // Appliquez le même filtre que le fill-pattern si nécessaire
                    });
                    this.map.addLayer({
                        'id': 'picto-layer-zone_risque_incendie-' + parcelle,
                        'type': 'symbol',
                        'source': parcelle, // Assurez-vous que cette source contient vos données de polygone
                        'layout': {
                            'icon-image': 'fond-incendie', // Le nom de l'image chargée
                            'icon-size': 0.6, // Ajustez la taille de l'icône selon le besoin
                            'icon-allow-overlap': true,
                            'icon-offset': [0, 70], //
                        },
                    });
                }

                else if (typeSelected == "zone_risque_erosion" && features[0].properties.picto == true) {
                    this.map.addLayer({
                        'id': 'contour-zone_risque_erosion-' + parcelle,
                        'type': 'line',
                        'source': parcelle,
                        'layout': {},
                        'paint': {
                            'line-color': '#0000FF', // Hex code pour bleu
                            'line-width': 4, // Largeur du contour en pixels
                            'line-dasharray': [2, 2]
                        },
                        'filter': ['==', ['get', 'type'], 'zone_risque_erosion'] // Appliquez le même filtre que le fill-pattern si nécessaire
                    });
                    this.map.addLayer({
                        'id': 'picto-layer-zone_risque_erosion-' + parcelle,
                        'type': 'symbol',
                        'source': parcelle, // Assurez-vous que cette source contient vos données de polygone
                        'layout': {
                            'icon-image': 'fond-erosion', // Le nom de l'image chargée
                            'icon-size': 0.6, // Ajustez la taille de l'icône selon le besoin
                            'icon-allow-overlap': true,
                            'icon-offset': [0, 70], //
                        },
                    });
                }


                else if (typeSelected == "zone_affectees_maladie" && features[0].properties.picto == true) {
                    this.map.addLayer({
                        'id': 'contour-zone_affectees_maladie-' + parcelle,
                        'type': 'line',
                        'source': parcelle,
                        'layout': {},
                        'paint': {
                            'line-color': '#F4661B', // Hex code pour bleu
                            'line-width': 4, // Largeur du contour en pixels
                            'line-dasharray': [2, 1]
                        },
                        'filter': ['==', ['get', 'type'], 'zone_affectees_maladie'] // Appliquez le même filtre que le fill-pattern si nécessaire
                    });
                    this.map.addLayer({
                        'id': 'picto-layer-zone_affectees_maladie-' + parcelle,
                        'type': 'symbol',
                        'source': parcelle, // Assurez-vous que cette source contient vos données de polygone
                        'layout': {
                            'icon-image': 'fond-maladie', // Le nom de l'image chargée
                            'icon-size': 0.6, // Ajustez la taille de l'icône selon le besoin
                            'icon-allow-overlap': true,
                            'icon-offset': [0, 70], //
                        },
                    });
                }


                else if (typeSelected == "air_protegees" && features[0].properties.picto == true) {
                    this.map.addLayer({
                        'id': 'contour-air_protegees-' + parcelle,
                        'type': 'line',
                        'source': parcelle,
                        'layout': {},
                        'paint': {
                            'line-color': '#40E0D0', // Hex code pour bleu
                            'line-width': 4, // Largeur du contour en pixels
                            'line-dasharray': [2, 1]
                        },
                        'filter': ['==', ['get', 'type'], 'air_protegees'] // Appliquez le même filtre que le fill-pattern si nécessaire
                    });
                    this.map.addLayer({
                        'id': 'picto-layer-air_protegees-' + parcelle,
                        'type': 'symbol',
                        'source': parcelle, // Assurez-vous que cette source contient vos données de polygone
                        'layout': {
                            'icon-image': 'fond-protegees', // Le nom de l'image chargée
                            'icon-size': 0.6, // Ajustez la taille de l'icône selon le besoin
                            'icon-allow-overlap': true,
                            'icon-offset': [0, 70], //
                        },
                    });
                }


                else if (typeSelected == "sentier" && features[0].properties.picto == true) {
                    this.map.addLayer({
                        'id': 'contour-sentier-' + parcelle,
                        'type': 'line',
                        'source': parcelle,
                        'layout': {},
                        'paint': {
                            'line-color': '#F4661B', // Hex code pour bleu
                            'line-width': 4, // Largeur du contour en pixels
                            'line-dasharray': [2, 1]
                        },
                        'filter': ['==', ['get', 'type'], 'sentier'] // Appliquez le même filtre que le fill-pattern si nécessaire
                    });
                    this.map.addLayer({
                        'id': 'picto-layer-sentier-' + parcelle,
                        'type': 'symbol',
                        'source': parcelle, // Assurez-vous que cette source contient vos données de polygone
                        'layout': {
                            'icon-image': 'fond-rando', // Le nom de l'image chargée
                            'icon-size': 0.6, // Ajustez la taille de l'icône selon le besoin
                            'icon-allow-overlap': true,
                            'icon-offset': [0, 70], //
                        },
                    });
                }



                else if (typeSelected == "exploitation" && features[0].properties.picto == true) {
                    this.map.addLayer({
                        'id': 'contour-exploitation-' + parcelle,
                        'type': 'line',
                        'source': parcelle,
                        'layout': {},
                        'paint': {
                            'line-color': '#e5e724', // Hex code pour bleu
                            'line-width': 4, // Largeur du contour en pixels
                            'line-dasharray': [2, 1]
                        },
                        'filter': ['==', ['get', 'type'], 'exploitation'] // Appliquez le même filtre que le fill-pattern si nécessaire
                    });
                    this.map.addLayer({
                        'id': 'picto-layer-exploitation-' + parcelle,
                        'type': 'symbol',
                        'source': parcelle, // Assurez-vous que cette source contient vos données de polygone
                        'layout': {
                            'icon-image': 'fond-exploitation', // Le nom de l'image chargée
                            'icon-size': 0.6, // Ajustez la taille de l'icône selon le besoin
                            'icon-allow-overlap': true,
                            'icon-offset': [0, 70], //
                        },
                    });
                }


                else if (typeSelected == "historiques" && features[0].properties.picto == true) {
                    this.map.addLayer({
                        'id': 'contour-historiques-' + parcelle,
                        'type': 'line',
                        'source': parcelle,
                        'layout': {},
                        'paint': {
                            'line-color': '#008080', // Hex code pour bleu
                            'line-width': 4, // Largeur du contour en pixels
                            'line-dasharray': [2, 1]
                        },
                        'filter': ['==', ['get', 'type'], 'historiques'] // Appliquez le même filtre que le fill-pattern si nécessaire
                    });
                    this.map.addLayer({
                        'id': 'picto-layer-historiques-' + parcelle,
                        'type': 'symbol',
                        'source': parcelle, // Assurez-vous que cette source contient vos données de polygone
                        'layout': {
                            'icon-image': 'fond-historiques', // Le nom de l'image chargée
                            'icon-size': 0.6, // Ajustez la taille de l'icône selon le besoin
                            'icon-allow-overlap': true,
                            'icon-offset': [0, 70], //
                        },
                    });
                }

                else {

                    this.map.addLayer({
                        'id': 'lay-fill-' + parcelle,
                        'type': 'fill',
                        'source': parcelle,
                        'layout': {
                            'visibility': 'visible'
                        },
                        'paint': {
                            'fill-color': colorParcelle,
                            //'fill-pattern': 'fond-pattern',
                            'fill-opacity': 0.3,
                            'fill-outline-color': colorParcelle
                        },
                        'filter': ['==', '$type', 'Polygon']
                    });
                }




                this.map.addLayer({
                    'id': 'lay-legende-' + parcelle, // Un identifiant unique pour le layer de légende
                    'type': 'symbol', // Type symbol pour afficher du texte
                    'source': parcelle, // Utilisez la même source que le layer de type 'fill'
                    'layout': {
                        'text-field': ['get', 'legend'], // Utilise 'legend' depuis la propriété de l'objet GeoJSON
                        'text-size': 12, // Taille du texte plus petite que celle du nom
                        'text-offset': [0, 0],
                    },
                    'paint': {
                        'text-color': '#FFFFFF', // Couleur du texte
                        'text-halo-color': '#000000', // Couleur de l'halo autour du texte pour améliorer la lisibilité
                        'text-halo-width': 1, // Largeur de l'halo autour du texte
                    },
                    'filter': ['==', '$type', 'Polygon'] // S'assure que ce layer s'applique uniquement aux Polygones
                })

                this.map.addLayer({
                    'id': 'lay-parcelle-surface-' + parcelle,
                    'type': 'symbol',
                    'source': parcelle,
                    'layout': {
                        'text-field': '{hectares} ha', // Utilisez la propriété 'hectares' pour le texte du symbole
                        'text-size': 11,
                        'text-offset': [0, 2], // Ajustez selon le besoin pour positionner le texte
                    },
                    'paint': {
                        'text-color': '#FFFFFF', // Choisissez une couleur de texte visible sur votre fond de carte
                    }
                });


                this.map.on('mouseleave', 'lay-fill-' + parcelle, () => {
                    this.map.getCanvas().style.cursor = '';
                });
                this.map.on('mouseenter', 'lay-fill-' + parcelle, () => {
                    this.map.getCanvas().style.cursor = 'pointer';
                });



                // this.map.on('click', 'fill-' + parcelle, (e) => {
                //     this.loadOneParcelle(e, parcelle)
                // });

            })
        },

        grille: {
            handler(newValue, oldValue) {
                // debounce( () =>{
                    this.updateGrille()
                // }, 1000);

            },
            deep: true

        },
        
        async traits(val) {
            if(!val.length) return  false
            console.log("Load traits...");

            // Reset layers and sources
            this.removeAllLineLayers('line-lay-');
            this.removeAllLineLayers('line-label-');
            this.removeAllLineSources('line-source-');

            val.forEach((trait, index) => {
                let traitSource = "line-source-" + String(trait._id);
                this.indexTraits = [...this.traits, traitSource];

                const feature = {
                    "type": "Feature",
                    "geometry": {
                        "type": "LineString",
                        "coordinates": trait.coordinates
                    },
                    "properties": {
                        "name": trait.name,
                        "thickness": trait.thickness,
                        "color": trait.color,
                        "length": trait.length,
                        "spacing": trait.spacing
                    }
                };
                this.map.addSource(traitSource, {
                    'type': 'geojson',
                    'data': {
                        "type": "FeatureCollection",
                        "features": [feature],
                    }
                });

                this.map.addLayer({
                    'id': 'line-lay-' + traitSource,
                    'type': 'line',
                    'source': traitSource,
                    'layout': {
                        'line-join': 'round',
                        'line-cap': 'round'
                    },
                    'paint': {
                        'line-color': trait.color || '#000000',
                        'line-width': trait.thickness || 2,
                        'line-dasharray': trait.spacing ? [trait.length || 2, trait.spacing] : [1, 0]
                    }
                });

                // Calculate the distance using Turf.js

                this.map.addLayer({
                    'id': 'line-label-' + traitSource,
                    'type': 'symbol',
                    'source': traitSource,
                    'layout': {
                        'text-field': ['concat', ['get', 'name']],
                        'text-size': 10,
                        'text-transform': 'uppercase',
                        'text-offset': [0, -1.5],
                        'text-anchor': 'top'
                    },
                    'paint': {
                        'text-color': '#FFFFFF',
                        'text-halo-color': '#000000',
                        'text-halo-width': 2
                    }
                });



            });

            // this.updateGrille()
            
        },



        async inventaires(val) {
            this.dialogLoad = true
            if (this.popin) {
                this.popin.remove();
            }
            let ucfirst = (str) => str.charAt(0).toUpperCase() + str.slice(1);
            const colors = [
                'red lighten-2',
                'pink darken-1',
                'purple accent-2',
                'deep-purple accent-3',
                'indigo lighten-1',
                'blue darken-2',
                'light-blue accent-1',
                'cyan darken-1',
                'teal lighten-2',
                'green accent-3',
                'light-green darken-1',
                'lime accent-4',
                'yellow darken-3',
                'amber accent-2',
                'orange darken-2',
                'deep-orange accent-1',
                'brown lighten-1',
                'grey darken-3',
                'blue-grey lighten-2'
            ];
            const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)];

            if (val && val.length) {


                // Récupération des noms des clés en excluant 'date', 'longitude' et 'latitude'
                const clefs = val.reduce((acc, obj) => {
                    Object.keys(obj).forEach(key => {
                        if (!acc.includes(key) && key !== 'date' && key !== 'longitude' && key !== 'latitude') {
                            acc.push(key);
                        }
                    });
                    return acc;
                }, []);
                if (clefs.length) {
                    let prof = this.selectedInventaireObj.profil
                    clefs.forEach((clef) => {
                        const exists = this.buttonsViews.some(buttonView => buttonView.view === clef);
                        let ignores = ['id', 'clef', 'commentaire', 'commentaires', 'heure', 'iddevice', 'idarbre', 'volume', 'symptomes']
                        let correspondances = {
                            volume: {
                                title: "Volume",
                                value: "volume"
                            },
                            trfchaude: {
                                title: "trfchaude",
                                value: "Tarif Chaudé"
                            },
                            hautmoy: {
                                title: "hautmoy",
                                value: "Hauteur Moy."
                            },
                            par: {
                                title: "par",
                                value: "Parcelle"
                            },
                            frt: {
                                title: "frt",
                                value: "Forêt"
                            },
                        };

                        this.buttonsViews = this.buttonsViews.map((e) => {
                            let data = { ...e };
                            if (correspondances[e.view]) {
                                data["correspondant"] = correspondances[e.view].value
                            }
                            return data
                        })
                        if (!exists && !ignores.includes(clef)) {
                            this.buttonsViews.push({
                                profil: prof,
                                view: clef,
                                color: getRandomColor(),
                                icon: 'mdi-eye',
                                title: ucfirst(clef)
                            })
                        }

                    })

                    this.buttonsViews = this.buttonsViews.filter(button => clefs.includes(button.view));
                    let cond = this.buttonsViews.some(button => button.view === "essence") || this.buttonsViews.some(button => button.view === "etat")
                    if (!cond && !this.filteredChips && this.user.reader != true) {
                        this.changeViews(this.buttonsViews[0].view)
                    }
                }

                //    this.buttonsViews =  [
                //     {
                //         profil: 'vigneron',
                //         view: 'domaine',
                //         color: 'orange',
                //         icon: 'mdi-eye',
                //         title: 'Domaine'
                //     }
                // ];

                let nb = 1
                let results = []
                val.forEach((elt) => {
                    nb++
                    let obj = {
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": []
                        },
                        "id": elt.longitude + elt.latitude + "",
                        "properties": {
                        }
                    }
                    // id 
                    obj.properties["id"] = elt.longitude + elt.latitude + ""
                   

                    // Ajout des propriétés dynamiquement
                    for (let key in elt) {
                        if (elt.hasOwnProperty(key)) {
                            obj.properties[key] = elt[key];
                        }
                    }
                    obj.geometry.coordinates = [elt.longitude, elt.latitude]
                    obj.properties.nature = obj.properties.nature ? obj.properties.nature : "point"

                    results = [...results, obj]
                })


                let cols = []
                this.statsEssences.forEach((essence) => {
                    if (essence) {
                        //todo: essence.color et essence.title pour compléter le cols
                        if (essence.color) {
                            cols = [...cols, essence.title, essence.color]
                        }
                    }
                })

                cols = cols.map(item => item === undefined ? null : item);
                cols = cols.map(item => item === null ? "#aa22cc" : item);


                // this.map.on('load', () => {


                this.results = results

                    console.log("results", results);

                // this.map.resize()
                // 

                // if (this.map && this.map.getLayer('inventaires-layer')) {
                //     this.map.removeLayer('inventaires-layer');
                // }

                // if (this.map && this.map.getSource('inventaires')) {
                //     this.map.removeSource('inventaires');
                // }

                if (!this.map.getSource('inventaires')) {

                    // console.log("results", results);
                    const geojson = {
                        'type': 'geojson',
                        'data': {
                            "type": "FeatureCollection",
                            'features': results
                        },

                    }

                    this.map.addSource("inventaires", geojson)
                    this.map.resize()
                } else {
                    const source = this.map.getSource('inventaires');
                    source.setData({
                        "type": "FeatureCollection",
                        'features': results // Vos nouvelles données ici
                    });
                }
                // 
                this.changeEssences(this.statsEssences)

                // });

                this.dialogLoad = false
            } else {
                // remove
                if (this.map && this.map.getLayer('inventaires-layer')) {
                    this.map.removeLayer('inventaires-layer');
                }

                this.snackbar = true
                this.snack = "Aucun point correspond à votre recherche"

                // if (this.map && this.map.getSource('inventaires')) {
                //     this.map.removeSource('inventaires');
                // }
            }


        },

        async statsEssences(val) {
            this.changeEssences(val)

        },
    }

}
</script>

<style lang="scss">
.ma-liste {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.etat-titre {
    padding: 7px;
    color: white;
    border-radius: 5px;
}

.etat-titre::before {
    // content: '';
    // display: inline-block;
    // height: 10px;
    // width: 10px;
    // // background-color: red;
    // border-radius: 50%;
    // margin-right: 5px;
    // vertical-align: middle;
}

/* Responsive design */
@media (max-width: 600px) {
    .etat-titre {
        font-size: 14px;
    }

    .boxPopup {
        text-align: left;
        max-width: 580px !important;
    }

    .mapboxgl-popup {
        max-width: 600px !important;
        min-width: 300px !important;
    }

    .ma-liste {
        margin-bottom: 20px;
        flex-direction: column;
        align-items: flex-start;
    }

    .mapboxgl-popup .v-btn-one {
        float: none !important;
        margin-left: 50px;
    }



    .ma-liste li {
        font-size: 12px;
    }

    .v-btn {
        font-size: 12px;
        padding: 5px 10px;
    }
}

.ma-liste {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
}

.ma-liste li {
    flex: 1 1 50%;
    /* Chaque élément prend au moins 50% de la largeur du parent */
    margin-bottom: 10px;
    /* Espacement entre les éléments */
}

@media (max-width: 600px) {
    .ma-liste li {
        flex-basis: 100%;
        /* Chaque élément prend 100% de la largeur du parent sur les petits écrans */
    }
}
</style>

<style>
#menu {
    position: absolute;
    background: #efefef;
    padding: 10px;
    font-family: 'Open Sans', sans-serif;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.mapboxgl-popup {
    animation: fadeIn 0.3s ease-out;
    /* Appliquer l'animation */
    max-width: 400px;
    font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
    border: 3px solid #eee;
}

.mapboxgl-popup-content {
    /* border-radius: 10px;
    padding: 15px; */
}

.mapboxgl-popup-content .mapboxgl-popup-close-button {
    padding: 5px;
    font-size: 20px;
    color: blueviolet;
}

.mapbox-gl-draw_point,
.mapbox-gl-draw_line,
.mapbox-gl-draw_polygon {
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: auto;
}

.mapboxgl-ctrl-pitchtoggle-3d {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+ICAgIDx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBkeT0iLjM1ZW0iIHN0eWxlPSJmb250LXNpemU6IDE0cHg7IGZvbnQtZmFtaWx5OiAnSGVsdmV0aWNhIE5ldWUnLEFyaWFsLEhlbHZldGljYSxzYW5zLXNlcmlmOyBmb250LXdlaWdodDogYm9sZDsgdGV4dC1hbmNob3I6IG1pZGRsZTsiPjNEPC90ZXh0Pjwvc3ZnPg==);
}

.mapboxgl-ctrl-pitchtoggle-2d {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+ICAgIDx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBkeT0iLjM1ZW0iIHN0eWxlPSJmb250LXNpemU6IDE0cHg7IGZvbnQtZmFtaWx5OiAnSGVsdmV0aWNhIE5ldWUnLEFyaWFsLEhlbHZldGljYSxzYW5zLXNlcmlmOyBmb250LXdlaWdodDogYm9sZDsgdGV4dC1hbmNob3I6IG1pZGRsZTsiPjJEPC90ZXh0Pjwvc3ZnPg==);
}

@media (min-width: 1264px) {
    .v-application .d-flex {
        .custom-position-desktop {
            right: 50px;
            /* right: 1px;
            bottom: 50px;
            position: fixed; */
        }
    }
}
</style>

<style lang="scss">
.transparent-dialog {
    background-color: transparent !important;
    box-shadow: none !important;
}
.transparent-dialog2 {
    background-color: white !important;
    position: fixed;
    top: 0px;
    right: 0px;
}
/* Style pour rendre le v-select transparent */
.transparent-select .v-input__control,
.transparent-select .v-input__slot {
    background-color: transparent !important;
    /* Rend l'arrière-plan du v-select transparent */
    box-shadow: none !important;
    /* Supprime l'ombre autour du v-select */
}

.transparent-select .v-select__slot,
.transparent-select .v-input__append-inner,
.transparent-select .v-input__icon {
    background-color: transparent !important;
    /* Rend les éléments intérieurs du v-select transparents */
}

.transparent-select .v-input__append-inner {
    color: rgba(0, 0, 0, 0.87);
    /* Ajuste la couleur du texte et des icônes pour qu'ils soient visibles */
}

@media (min-width: 1024px) {
    .dialogTrait {
        position: absolute !important;
        top: 0px !important;
        right: 0px !important;
    }
}
.v-overlay__content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.resized-img {
    max-width: 100vw;
    max-height: 100vh;
}
.custom-geolocate-button {
        background-color: #AB3443; /* Change the color */
        border: none;
        border-radius: 50%;
        padding: 10px;
        cursor: pointer;
}
</style>