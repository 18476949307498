import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.closin)?_c(VCard,{staticClass:"pa-3"},[_c(VCardTitle,{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v("Répartition des "+_vm._s(_vm.prop + 's'))]),_c(VSpacer),_c(VBtn,{attrs:{"fab":"","icon":""},on:{"click":function($event){_vm.closin = false}}},[_c(VIcon,[_vm._v(" mdi-close ")])],1)],1),_c('Doughnut',{attrs:{"chartData":_vm.datas,"chartOptions":{ responsive: true, maintainAspectRatio: false }}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }