<template>
    <div>
        <v-card v-if="closin" class="pa-3">
            <v-card-title class="d-flex justify-content-between">
                <span>Répartition des {{ prop
                    + 's' }}</span>
                    <v-spacer></v-spacer>
                <v-btn fab icon @click="closin = false">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>

            </v-card-title>
            <Doughnut :chartData="datas" :chartOptions="{ responsive: true, maintainAspectRatio: false }" />
        </v-card>
    </div>

</template>

<script>
import { Doughnut, Radar, Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, RadialLinearScale, PointElement, LineElement, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, RadialLinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement)


export default {
    name: 'Stat',
    props: ['presentProperties', 'datas', 'prop'],
    components: {
        Doughnut,
        Radar,
        Line
    },
    data(vm) {
        return {
            closin: true
        }
    },
    methods: {
   
    }
}
</script>
