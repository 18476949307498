<template>
    <v-sheet width="100%" class="pa-2 mx-auto">
        <v-container class="fill-height" fluid>
            <v-row justify="center" align="center">
                <v-col cols="12" sm="8" md="6">
                    <v-card class="elevation-12" outlined>
                        <v-card-title class="headline">Annulation d'Abonnement</v-card-title>
                        <v-card-text>
                            <p>Nous sommes désolés de vous voir partir. Si vous avez un moment, dites-nous pourquoi vous
                                annulez votre abonnement, afin que nous puissions continuer à nous améliorer.</p>
                            <v-textarea outlined label="Votre Feedback" hint="Raison de l'annulation" rows="5"></v-textarea>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="submitFeedback">Soumettre la
                                raison</v-btn>
                            <v-btn color="grey darken-1" text @click="goBack">Annuler</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-sheet>
</template>

<script>
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import { StripeCheckout } from '@vue-stripe/vue-stripe';

export default {
    name: 'PremiumSuccess',
    components: {
        StripeElementCard,
        StripeCheckout
    },
    data() {
        return {
            publishableKey: "",
            token: null,
            loading: false,
            customerId: "cus_P7oOPrMpmmJ8Eh",
            customerEmail: "julien@taiwa.fr",
            loading: false,

            successURL: 'your-success-url',
            cancelURL: 'your-cancel-url',
            lineItems:
                [
                    [
                        {
                            price: 'price_1OJvBZIo5pmAhUysgj3BS7vJ', // The id of the recurring price you created in your Stripe dashboard
                            quantity: 1,
                        }],

                    [{
                        price: 'price_1OJvXoIo5pmAhUysKgAO6l2s', // The id of the recurring price you created in your Stripe dashboard
                        quantity: 1,
                    }],
                    [{
                        price: 'price_1OJvaQIo5pmAhUysFu7O6cbj', // The id of the recurring price you created in your Stripe dashboard
                        quantity: 1,
                    }],
                    [{
                        price: 'price_1OJvayIo5pmAhUysHJ7U9wGW', // The id of the recurring price you created in your Stripe dashboard
                        quantity: 1,
                    }],

                ],
            successURL: 'https://cartovia.fr/api/success-payment',
            cancelURL: 'https://cartovia.fr/api/cancel-payment',
        }
    },
    created() {
        console.log(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY, "process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;");
        this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    },
    methods: {
        submitFeedback() {
            // Logique pour traiter le feedback
        },
        goBack() {
            // Logique pour revenir à la page précédente ou à la page d'accueil
        },
        submit() {
            // this will trigger the process
            // this.$refs.elementRef.submit();

            this.$refs.checkoutRef.redirectToCheckout();

        },
        tokenCreated(token) {
            console.log(token);
            // handle the token
            // send it to your server
        },
    }

}
</script>