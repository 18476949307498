<template>
  <v-container fluid style="padding: 0; padding-left: 2px; padding-right: 0px;">


    <v-tour name="myTour" :steps="steps" :options="labels" button-skip-label="Passer" button-next-label="Suivant"
      button-prev-label="Précédent"></v-tour>


    <v-dialog v-model="diagShared" fullscreen :width="500">
      <v-card class="pb-2">
        <v-toolbar dark color="secondary white--text">
          <v-toolbar-title><v-icon>mdi-land-plots</v-icon>{{secteursShared.length }} Secteurs affectés</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="diagShared = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-alert v-if="secteursShared.length && secteursShared[0].comment" dense text dismissible border="bottom"
          type="success">
          Message de l'administrateur: {{ secteursShared[0].comment }}
        </v-alert>

        <v-sheet class="pa-3">


          <v-list dense>
            <template v-for="shared in secteursShared">



              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="d-flex d-flex justify-space-between">
                    <span><v-icon>mdi-marker-check</v-icon>
                      Commune: {{ shared.COMMUNE }}</span>
                  </v-list-item-title>

                  <v-list-item-subtitle>Secteur: {{ shared.SECTEUR }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>


            </template>
          </v-list>
        </v-sheet>
      </v-card>
    </v-dialog>


    <v-dialog height="400" max-width="350" transition="fade-transition" hide-overlay v-model="isEditableDate"
      class="d-flex justify-center align-center">
      <!-- <v-toolbar dark color="secondary white--text">
          <v-toolbar-title><v-icon>mdi-clock-time-eight-outline</v-icon> Sélectionner une date</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="isEditableDate = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar> -->
      <!-- <v-row>
        <v-col cols="12">
          <v-date-picker block year-icon="mdi-calendar-blank" prev-icon="mdi-skip-previous" next-icon="mdi-skip-next"
            elevation="1" color="primary" v-model="dateInventaireFr" @input="saveContentDate"></v-date-picker>
        </v-col>
      </v-row> -->

    </v-dialog>



    <v-dialog max-width="600" transition="fade-transition" v-model="dialogSendEmail"
      class="d-flex justify-center align-center">
      <v-form v-model="validSend">
        <v-card>
          <v-toolbar dark color="secondary white--text">
            <v-toolbar-title><v-icon>mdi-magic-staff</v-icon> Générer le rapport</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogSendEmail = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-row class="pa-2">
            <v-col cols="12">
              <v-alert type="info">Veuillez renseigner une photo d'avatar sur votre profil pour la page d'avant-garde du
                rapport
                <v-btn text small color="white" @click="dialogProfil = true"><v-icon>mdi-account-search</v-icon> Je
                  complète mon profil</v-btn>
              </v-alert>

            </v-col>
          </v-row>


          <v-row class="pa-2">
            <v-col cols="12" sm="8">
              <v-text-field v-model="emailsend" solo filled append-icon="mdi-email"
                placeholder="Ex: mon.email@gmail.com" label="E-mail du contact" name="Email" :rules="emailRules"
                required class="required" type="email"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-switch v-model="copysend" inset label="M'envoyer une copie par e-mail"></v-switch>
            </v-col>
          </v-row>
          <v-row class="pa-4">
            <v-col cols="12" sm="6" md="4" v-for="item in itemsOptionsPdf" :key="item.text">
              <v-checkbox v-model="item.checked" :label="item.text" :value="item.value"></v-checkbox>
            </v-col>
          </v-row>

          <v-divider></v-divider>




          <v-row class="pa-2">
            <v-col cols="12">
              <v-textarea solo append-icon="mdi-pencil" label="Description du rapport" v-model="descsend"></v-textarea>
            </v-col>
          </v-row>


          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="generatePdf" :disabled="!validSend" color="primary"><v-icon>mdi-email</v-icon> Envoyer le
              rapport</v-btn>
          </v-card-actions>

        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog fullscreen ref="dialog" width="100%" v-model="dialogTimeline">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title><v-icon>mdi-calendar</v-icon>Vos inventaires</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogTimeline = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field solo v-model="searchQuery" append-icon="mdi-magnify"
                label="Rechercher par mots-clefs, par nom d'inventaire, par nom de domaine ou forêt, un nom de membre"
                single-line hide-details></v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-col cols="12">
              <v-timeline :dense="$vuetify.breakpoint.smAndDown">
                <v-timeline-item fill-dot :class="{ active: isActiveRoute(inventaire.id) }" active-color="primary"
                  v-for="inventaire in filteredInventaires" :key="inventaire.id" small class="pa-2" color="secondary">
                  <Dot @closeDialog="dialogTimeline = false" :inventaire="inventaire"></Dot>
                  <template v-slot:opposite>
                    <v-chip color="secondary" outlined class="pa-2"><v-icon small
                        dark>mdi-clipboard-text-clock-outline</v-icon>{{ formatDate(inventaire.dateInventaire)
                      }}</v-chip>
                  </template>
                </v-timeline-item>
              </v-timeline>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" elevation="2" @click="dialogTimeline = false">
            <v-icon>mdi-close</v-icon>
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>




    <v-dialog persistent content-class="dialogTrait" dark v-model="dialogGrille" class="pa-2"
      transition="dialog-top-transition" hide-overlay :width="500">
      <v-card color="secondary" class="pa-2">
        <v-card-text>
          <v-row>

            <v-col cols="6">
              <v-text-field v-model="grille.nbR" label="Combien de rangée ?" prepend-icon="mdi-pencil"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="grille.nbP" label="Ecart par pied en m. ?"
                prepend-icon="mdi-pencil"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-col>
            <v-btn color="primary" big dark @click="finishG"> <v-icon>mdi-check</v-icon> Terminer</v-btn>
          </v-col>

          <v-col>
            <v-btn text @click="dialogGrille = false">
              Annuler
            </v-btn>
          </v-col>
        </v-card-actions>

      </v-card>
    </v-dialog>


    <v-dialog fullscreen ref="dialog" width="100%" v-model="dialogCalendar" hide-overlay>
      <v-card>
        <v-toolbar transparent>
          <v-toolbar-title><v-icon left>mdi-calendar-check</v-icon>Calendrier des tâches</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogCalendar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container class="timeline-vertical-container">
          <Calendar :readOnly="readOnly" ref="cal" :profil="user.type" @loadTasks="loadTasks" :id="invId"
            :tasks="tasks">
          </Calendar>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog ref="dialog" v-model="dialogProfil" hide-overlay :width="800">
      <v-card>
        <v-toolbar dark color="primary white--text">
          <v-toolbar-title><v-icon>mdi-calendar-account-outline</v-icon> Modifier son profil</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogProfil = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field v-model="nomModify" label="Nom et Prénom" solo></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field placeholder="Ex: SARL FORESTIER" label="Votre Entreprise" name="Entreprise"
                  :rules="nomCompagnyRules" v-model="nomEntrepriseModify" solo></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field append-icon="mdi-phone-dial" placeholder="Ex: 06 01 02 03 05" @input="formatPhoneNumber"
                  :rules="telRules" v-model="telEntrepriseModify" label="Téléphone de l'entreprise" solo></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-file-input v-model="logoFile" label="Logo de l'entreprise" prepend-icon="mdi-image"
                  accept=".jpg, .jpeg, .png"></v-file-input>
                <v-img :width="250" v-if="logoFile" :src="getLogoFile" />
              </v-col>

              <v-col cols="12" md="12">
                <v-textarea placeholder="Entreprise spécialisée dans le reboisement près de Lyon"
                  v-model="descEntrepriseModify" label="Description de l'entreprise" solo></v-textarea>
              </v-col>

              <v-col cols="12" md="6">
                <v-switch v-model="notifEntrepriseModify" inset
                  label="J'active les notifications par e-mail"></v-switch>
              </v-col>
              <v-col cols="12" md="6">
                <v-switch v-model="subEntrepriseModify" inset
                  label="Je souscris à la newsletter de Cartovia"></v-switch>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" elevation="2" @click="modifyProfil">
            <v-icon>mdi-check-circle</v-icon>
            Modifier mon profil
          </v-btn>

          <v-btn color="secondary" outlined @click="dialogProfil = false">
            <v-icon>mdi-close</v-icon>
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="dialogPeople">
      <v-card class="pb-4">
        <v-toolbar dark color="secondary white--text">
          <v-toolbar-title><v-icon>mdi-table-check</v-icon> Inviter jusqu'à 5 personnes</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogPeople = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <People @sendedUsers="sendedUsers" :uid="user.uid" :inv="invId"></People>
      </v-card>
    </v-dialog>

    <v-dialog fullscreen ref="dialog" v-model="dialogTodos">
      <v-card>
        <v-toolbar class="mb-2" color="primary" dark>
          <v-toolbar-title><v-icon>mdi-table-check</v-icon> Toutes les tâches</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogTodos = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <Todos ref="todos" @refreshTasks="refreshTasks" :parcelles="parcellesAll" @loadTasks="loadTasks" :tasks="tasks"
          :uid="user.uid" :operateurs="operateurs" @finished="finishedTask" :profil="user.type" :readOnly="readOnly"
          :selectedInventaire="selectedInventaireObj" />
      </v-card>
    </v-dialog>


    <v-dialog v-if="dialogMinized == false" hide-overlay :fullscreen="bigAssistant" ref="dialog"
      v-model="dialogAssistant" :width="800" id="cartoGPT">
      <v-card>
        <v-toolbar dark color="primary white--text">
          <v-toolbar-title><v-icon>mdi-wechat</v-icon> Votre assistant AI</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="!bigAssistant" @click="bigAssistant = true" icon>
            <v-icon>mdi-fullscreen</v-icon>
          </v-btn>
          <v-btn v-else @click="bigAssistant = false" icon>
            <v-icon>mdi-fullscreen-exit</v-icon>
          </v-btn>
          <v-btn icon @click="dialogMinized = true">
            <v-icon>mdi-minus</v-icon>
          </v-btn>

          <v-btn icon @click="dialogAssistant = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <Chat :bigAssistant="bigAssistant" :selectedInventaire="selectedInventaireObj"
          :profil="selectedInventaireObj.profil" />
      </v-card>
    </v-dialog>

    <v-btn @click="dialogMinized = false" v-else fab dark bottom left color="secondary" class="fab-bottom-left">
      <v-icon>mdi-chat-processing-outline</v-icon>
    </v-btn>




    <v-row class="pb-2">

      <v-dialog v-if="dialogUpload" fullscreen v-model="dialogUpload">
        <v-app-bar-nav-icon dark slot="activator"></v-app-bar-nav-icon>
        <v-card>
          <Upload @closeMediasDialog="dialogUpload = false" :user="user" :selectedInventaire="selectedInventaireObj" />
        </v-card>
      </v-dialog>

      <v-dialog v-if="dialogMedias" height="700" v-model="dialogMedias">
        <v-app-bar-nav-icon dark slot="activator"></v-app-bar-nav-icon>
        <v-card>
          <v-toolbar dark color="pink darken-4 white--text">
            <v-toolbar-title><v-icon>mdi-text-box-multiple</v-icon> Votre Mediathèque</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogMedias = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <Medias @closeMediasDialog="closeMediasDialog" :user="user" :selectedInventaire="selectedInventaireObj" />
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogStats" persistent fullscreen>
        <v-card>
          <v-toolbar dark color="primary white--text">
            <v-toolbar-title><v-icon>mdi-chart-line</v-icon> Statistiques</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="!readOnly" icon @click="openDraw">
              <v-icon>mdi-filter-plus</v-icon>
            </v-btn>

            <v-btn icon @click="takeSnapStats">
              <v-icon>mdi-camera</v-icon>
            </v-btn>

            <v-btn icon @click="dialogStats = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-sheet class="pa-2">
            <Stats :user="user" ref="stats" :profil="selectedInventaireObj.profil" :inventaires="filterEssences">
            </Stats>
          </v-sheet>
        </v-card>
      </v-dialog>


      <v-dialog v-model="dialogPhitos" fullscreen>
        <v-card>
          <v-toolbar dark color="secondary white--text">
            <v-toolbar-title><v-icon>mdi-bottle-tonic-plus</v-icon> Gestion des phytosanitaires</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn icon @click="dialogPhitos = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-sheet class="pa-2">
            <Phito :profil="user.type" :parcelles="parcelles" ref="phito" :inventaire="selectedInventaireObj"
              :uid="user.uid" @geolocation="geolocation">
            </Phito>
          </v-sheet>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogCalcul" width="500" hide-overlay persistent>
        <v-card class="pa-2">
          <v-toolbar dark color="secondary white--text">
            <v-toolbar-title><v-icon>mdi-account-group-outline</v-icon> Reformuler le calcul du volume</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn icon @click="dialogCalcul = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-alert type="warning" dense>
            Attention : La reformulation va impacter chaque calcul de cubage fait initialement lors de l'inventaire.
          </v-alert>

          <v-text-field cols="12" sm="8" prepend-icon="mdi-lead-pencil"
            placeholder="π * (D/2)² x H avec D le Diamètre et H la Hauteur" label="Formule du calcul volumique en m3"
            v-model="formuleCalc"></v-text-field>

          <v-row class="pa-2 d-flex flex-column align-center ma-2">
            <v-btn @click="updateFormule" color="orange" dark><v-icon>mdi-calculator-variant</v-icon> Recalculer le
              cubage de chaque pied</v-btn>
          </v-row>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogTeam" fullscreen>
        <v-card>
          <v-toolbar dark color="secondary white--text">
            <v-toolbar-title><v-icon>mdi-account-group-outline</v-icon> Gestion de l'équipe</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn icon @click="dialogTeam = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-sheet class="pa-2">
            <Team ref="team" :inventaire="selectedInventaireObj" :uid="user.uid">
            </Team>
          </v-sheet>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogCalculator" persistent fullscreen>
        <v-card>
          <v-toolbar dark color="primary white--text">
            <v-toolbar-title><v-icon>mdi-calculator</v-icon> Simulateur</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="openDraw">
              <v-icon>mdi-filter-plus</v-icon>
            </v-btn>

            <v-btn icon @click="dialogCalculator = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-sheet class="pa-2">
            <Simulator :uid="user.uid" :invId="selectedInventaire" :profil="selectedInventaireObj.profil"
              :inventaires="filterEssences">
            </Simulator>
          </v-sheet>

        </v-card>
      </v-dialog>

      <v-dialog v-if="dialogFilter" v-model="dialogFilter" class="bottomDialog" :fullscreen="true" max-height="400px">
        <v-card>
          <v-card-title>
            <v-icon>mdi-filter</v-icon>
            Filtrer mes données
            <v-spacer></v-spacer>
            <v-chip color="pink" outlined>{{ filterEssences.length }} pieds concernés</v-chip>
            <v-btn icon color="primary" @click="dialogFilter = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-autocomplete label="État du pied" placeholder="Etat du pied" v-model="filterEssence" chips
                      variant="underlined" :items="allessences" deletable-chips multiple>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-autocomplete label="Cépage du pied" placeholder="Cépage du pied" v-model="filterQualite"
                      :items="allqualites" color="secondary" chips variant="underlined" deletable-chips
                      multiple></v-autocomplete>

                  </v-col>
                </v-row>

                <!-- <v-row>
                            <v-col cols="12" md="6">
                              <v-range-slider
                                @end="(e) => filterHauteur = e"
                                :value="filterHauteur"
                                color="orange"
                                label="Hauteur (m.)"
                                :max="100"
                                :min="1"
                                thumb-label="always"
                                thumb-color="secondary"
                              >
                                <template v-slot:thumb-label="{ value }">
                                  <span class="pa-2"><small>{{ value }}m.</small></span>
                                </template>

</v-range-slider>
</v-col>

<v-col cols="12" md="6">
  <v-range-slider @end="(e) => filterDiametre = e" :value="filterDiametre" color="orange" label="Diamètre (cm.)"
    hint="En cm." :max="100" :min="1" thumb-label="always" thumb-color="secondary">
    <template v-slot:thumb-label="{ value   }">
                                <span class="pa-2"><small>{{ value  }}cm.</small></span>
                              </template>

  </v-range-slider>
</v-col>
</v-row> -->
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" elevation="2" @click="dialogFilter = false">
                <v-icon>mdi-auto-fix</v-icon>
                Filtrer les pieds
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-if="dialogDelete" v-model="dialogDelete" hide-overlay max-width="500px" color="red">
        <v-card>
          <v-card-title>
            <v-icon>mdi-delete</v-icon>
            Supprimer un inventaire
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-alert border="left" dense type="warning" color="red darken-2" colored-border elevation="2">
              Attention! Vous allez supprimer l'inventaires <strong>{{ selectedInventaireObj.name }}</strong> <br> ainsi
              que toutes ses parcelles
            </v-alert>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field label="Recopier le nom de l'inventaire pour confirmer"
                    placeholder="Ex: Inventaire de Cèdres" v-model="confEssence">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="confEssence != selectedInventaireObj.name" color="red" elevation="2" outlined
                @click="(e) => { confDeleteInventaire(); dialogDelete = false }">
                <v-icon>mdi-check</v-icon>
                Confirmer la suppression
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-if="dialogEdit" v-model="dialogEdit" hide-overlay max-width="600px" color="red">
        <v-card>
          <v-card-title>
            <v-icon>mdi-book-edit</v-icon>
            Editer l'inventaire {{ selectedInventaireObj.name }}
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field label="Nom de l'inventaire" placeholder="Parcelle de Cèdres" v-model="nomInventaire">
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea clearable counter append-inner-icon="mdi-comment" clear-icon="mdi-close-circle"
                    label="Commentaire sur l'inventaire" placeholder="Topographie, Essences rares, Observation ..."
                    v-model="descriptionInventaire">
                  </v-textarea>
                </v-col>
                <v-col>
                  <p for="">Date de l'inventaire</p>
                  <v-date-picker v-model="dateInventaire"></v-date-picker>
                </v-col>
                <v-col>
                  <v-text-field @blur="changeAdress" :prepend-inner-icon="!nameLoc ? 'mdi-map-marker' : 'mdi-check'"
                    :loading="loadGeoloc" v-model="geoInventaire" placeholder="Ex: Lyon"
                    label="Localisation"></v-text-field>
                </v-col>
              </v-row>
            </v-container>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" elevation="2" outlined @click="() => { dialogEdit = false; editInventaire() }">
                <v-icon>mdi-pencil</v-icon>
                Modifier
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>



      <v-dialog fullscreen persistant v-model="dialogDomaines">
        <v-toolbar dark color="secondary white--text">
          <v-toolbar-title><v-icon>mdi-land-fields</v-icon> Gestion des domaines </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn icon @click="dialogDomaines = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card>
          <v-container>
            <Domaine @goInv="goInv" :user="user" />
          </v-container>

        </v-card>
      </v-dialog>

      <v-dialog max-width="400" persistant content-class="dialogParcelle" v-model="dialogParcelleSelected" hide-overlay
        transition="dialog-right-transition"
        style="position: absolute; top: 0px !important;margin-top: 0px !important;overflow-x: hidden;">
        <v-card style="overflow: hidden;">

          <v-toolbar bar class="mb-2" color="primary" dark>
            <v-badge :value="parcelles.length > 0" :content="parcelles.length">
              <v-card-title v-if="parcelleSelectedData.parcelle"><v-icon left>mdi-fence</v-icon>{{
                parcelleSelectedData.parcelle.parcelleName }}</v-card-title>
            </v-badge>
            <v-spacer></v-spacer>
            <v-btn dark icon @click="dialogParcelleSelected = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <div class="pa-2" v-if="parcelleSelectedData.parcelle">

            <v-row cols="12">

              <v-col cols="12" sm="6">
                <p>Type: <b>{{ getParcelleType(parcelleSelectedData.parcelle.parcelleType).name }}</b></p>
                <p>Légende: <b>{{ parcelleSelectedData.parcelle.parcelleLegende }}</b></p>
                <p>Description <b>{{ parcelleSelectedData.parcelle.parcelleDescription }}</b></p>
              </v-col>

              <v-col cols="12" sm="6">
                <p>Inventaire: <b>{{ parcelleSelectedData.inventaire.name }}</b></p>
                <p class="highlight">Superficie: <b>{{ parcelleSelectedData.inventaire.hectares }} ha.</b></p>
                <p class="highlight">Nb de pieds: <b>{{ parcelleSelectedData.inventaire.nbArbres }} pieds </b></p>
                <p class="highlight">Total parcellaire: <b>{{ parcelleSelectedData.inventaire.nbArbresTotal }}
                    pieds</b>
                </p>
              </v-col>
            </v-row>
            <v-row cols="12">

              <v-col cols="12" v-if="parcelleSelectedData.tasks.length">
                <v-list flat dense three-line>
                  <template v-for="task in parcelleSelectedData.tasks">

                    <v-list-item dense :disabled="task.enabled">
                      <v-list-item-content>
                        <v-list-item-title class="d-flex d-flex justify-space-between">
                          <span><v-icon x-small right slot="opposite" v-if="task.important == true" size="20"
                              color="red">mdi-alert-decagram</v-icon>
                            {{ task.title }}</span>
                          <v-chip style="font-size: 10;height: 14" x-small
                            :color="task.enabled == true ? 'success' : 'primary'" dark label>{{ task.enabled ?
                            'Fait' : 'En cours' }}</v-chip>
                        </v-list-item-title>

                        <v-list-item-subtitle class="text-caption">
                          <v-icon>
                          </v-icon><span v-if="task.dateRange[0]">Du {{
                            formatDate(task.dateRange[0]) }}</span> <span v-if="task.dateRange[1]">au {{
                            formatDate(task.dateRange[1]) }}</span>
                          <v-col>
                            <v-btn x-small v-for="user in task.users" :key="user._id" class="ma-1" v-on="on" fab
                              color="primary">
                              {{ user.firstname[0] + user.lastname[0] }}
                            </v-btn>
                          </v-col>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider :key="task.title" :inset="true"></v-divider>
                  </template>
                </v-list>

                <v-row class="d-flex justify-center align-center mt-3">
                  <v-btn small color="primary" @click="getCalendar"><v-icon>mdi-calendar</v-icon> Voir le
                    calendrier</v-btn>
                </v-row>
              </v-col>

              <v-col cols="12" v-if="parcelleSelectedData.phitos.length">

                <v-alert border="right" dense colored-border type="warning" elevation="2"
                  v-for="phito in parcelleSelectedData.phitos" :key="phito.product" class="pa-2 ma-2">
                  <p> Maladie: <b>{{ phito.diseaseName }}</b> <br /> Date de 1er symptome:
                    <b>{{ formatDate(phito.firstSighting) }}</b>
                  </p>
                  <p>Dose: <b>{{ phito.dose }} L./ha</b> </p>
                  <p>Produit: <b>{{ phito.product }} (Type {{ phito.type }})</b></p>
                </v-alert>

              </v-col>


            </v-row>



          </div>

          <v-card-actions v-if="!readOnly">
            <v-spacer></v-spacer>
            <v-btn color="primary" dark rounded small @click="editParcelle(parcelleSelectedData.parcelleFind)">
              <span>🖋️</span> Editer</v-btn>
            <v-btn color="primary" dark rounded small @click="redrawParcelle(parcelleSelectedData.parcelleFind)">
              <span>📏</span> Redessiner</v-btn>
            <v-btn color="primary" dark rounded small @click="removeParcelle(parcelleSelectedData.parcelleFind)">
              <span>❌</span> Supprimer</v-btn>
          </v-card-actions>

        </v-card>

      </v-dialog>

      <v-dialog v-if="dialog" v-model="dialog">
        <v-card>
          <v-card-title class="text-h5">Gérer un arbre</v-card-title>
          <v-form v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field v-model="arbre.id" :rules="nameRules" label="Id Arbre" required></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field v-model="arbre.operateur" :rules="nameRules" label="Opérateur" required></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="arbre.foret" :rules="nameRules" label="Fôret" required></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field v-model="arbre.qualite" :rules="nameRules" label="Qualité" required></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field v-model="arbre.essence" :rules="nameRules" label="Essence" required></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field v-model="arbre.diametre" :rules="nameRules" label="Diamètre" required></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field v-model="arbre.volume" :rules="nameRules" label="Volume" required></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field v-model="arbre.tarif" :rules="nameRules" label="Tarifs chaudé" required></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field v-model="arbre.parcelle" :rules="nameRules" label="Parcelle" required></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field v-model="arbre.sousparcelle" :rules="nameRules" label="Sous-Parcelle"
                    required></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field v-model="arbre.hauteurmoyenne" :rules="nameRules" label="Hauteur moyenne"
                    required></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dialog = false">Annuler</v-btn>
            <v-btn color="primary" @click="save">Enregistrer</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar absolute top color="primary" shaped v-model="snackbarLauch">
        {{ snack }}
      </v-snackbar>

      <v-snackbar class="custom-snackbar" :timeout="timeout" shaped absolute bottom :top="poSnack" tile
        :color="colorSnack" v-model="snackbar">
        <v-icon dark class=" pa-1">{{ snackIcon }}</v-icon>
        <span class="white--text"> {{ snack }}</span>
      </v-snackbar>

      <v-dialog :width="800" v-model="dialogInventaire">
        <v-card>
          <v-card-title><v-icon>mdi-layers-plus</v-icon>Créer un inventaire</v-card-title>
          <hr>
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field v-model="nomInventaire" placeholder="Parcelle de Douglas"
                  label="Nom de l'inventaire"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field @blur="changeAdress" :prepend-inner-icon="!nameLoc ? 'mdi-map-marker' : 'mdi-check'"
                  :loading="loadGeoloc" v-model="geoInventaire" placeholder="Ex: Lyon"
                  label="Localisation"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <p for="">Date de l'inventaire</p>
                <v-date-picker v-model="dateInventaire"></v-date-picker>
              </v-col>

              <v-col>
                <vue-dropzone @vdropzone-file-added="fileAdded" :maxFiles="Number(10000000000)" :multipleUpload="false"
                  :parallelUpload="1" ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" :useCustomSlot=true>
                  <div class="dropzone-custom-content">
                    <p><v-icon>mdi-file</v-icon>
                      Je dépose ici mon fichier</p>
                    <v-btn block large color="primary" dark @click="handleFileImport">
                      <v-icon>mdi-upload</v-icon> Télécharger votre fichier CSV
                    </v-btn>
                    <input accept="application/csv" ref="uploader" class="d-none" type="file" @change="onFileChanged">
                  </div>
                </vue-dropzone>
                <v-textarea clearable counter append-inner-icon="mdi-comment" clear-icon="mdi-close-circle"
                  label="Commentaire sur l'inventaire" placeholder="Topographie, Essences rares, Observation ..."
                  v-model="descriptionInventaire">
                </v-textarea>
              </v-col>

            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn :disabled="!selectedFile || nomInventaire.length < 2" color="primary" block
              @click="createInventaire"><v-icon>mdi-content-save</v-icon> Enregistrer l'inventaire</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog :width="600" v-model="dialogInventaireFly">
        <v-card>
          <v-card-title><v-icon>mdi-auto-fix</v-icon>Créer un inventaire</v-card-title>
          <hr>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field prepend-inner-icon="mdi-format-text" v-model="nomInventaire"
                  placeholder="Parcelle de Douglas" label="Nom de l'inventaire"></v-text-field>
              </v-col>
              <v-col cols="12">
                <p for="">Date de l'inventaire</p>
                <v-date-picker v-model="dateInventaire"></v-date-picker>
              </v-col>

              <v-col cols="12">
                <v-file-input
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  @change="checkFileSize" :rules="rulesUpload" ref="fileInput" label="Télécharger votre document CSV"
                  outlined></v-file-input>
                <v-alert v-if="fileSizeError" type="error" dismissible>
                  The file size exceeds 1MB.
                </v-alert>

              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn :disabled="nomInventaire.length < 2" color="primary" block
              @click="createInventaireFly"><v-icon>mdi-content-save</v-icon> Enregistrer l'inventaire</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog width="100%" v-model="dialogInventaireAdd">
        <v-card>
          <v-toolbar class="mb-2" color="primary" dark>
            <v-card-title><v-icon>mdi-upload</v-icon>Ajouter des points</v-card-title>
            <v-spacer></v-spacer>
            <v-btn dark icon @click="dialogInventaireAdd = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <vue-dropzone :disablePreviews="true" @vdropzone-drag-enter="dragging = true"
                  @vdropzone-drag-leave="dragging = false" @vdropzone-file-added="fileAddedAsync"
                  :maxFiles="Number(10000000000)" :multipleUpload="true" :parallelUpload="1" ref="myVueDropzone"
                  id="dropzone" :options="dropzoneOptions" :use-custom-slot="true">
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      <v-icon color="primary" size="100" class="pa-2">mdi-cloud-upload-outline</v-icon>
                      <span>Exels, CSV, JSON, GeoJSON, KML</span>
                    </h3>
                    <div class="subtitle">
                      <v-list dense>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title><v-icon class="pa-2">mdi-auto-fix</v-icon>Vos données sont
                              automatiquement
                              reconnues</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title><v-icon class="pa-2">mdi-file-chart-check-outline</v-icon>Plusieurs
                              fichiers sont
                              accéptés</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title><v-icon class="pa-2">mdi-map-check</v-icon>Requis: Coordonnées GPS
                              (Longitude,
                              Latitude)</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>

                    </div>
                  </div>
                </vue-dropzone>
                <!-- <vue-dropzone @vdropzone-file-added="fileAdded" :maxFiles="Number(10000000000)" :multipleUpload="false"
                  :parallelUpload="1" id="dropzone" ref="myVueDropzone" :options="dropzoneOptions" :useCustomSlot=true>
                  <div class="dropzone-custom-content">
                    <p><v-icon>mdi-file</v-icon>
                      Je dépose ici mon fichier ici</p>
                    <v-btn block large color="primary" outlined dark @click="handleFileImport">
                      <v-icon>mdi-upload</v-icon> Télécharger votre fichier CSV
                    </v-btn>
                    <input accept="application/csv" ref="uploader" class="d-none" type="file" @change="onFileChanged">
                  </div>
                </vue-dropzone> -->
                <!-- <v-file-input
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  @change="checkFileSize" :rules="rulesUpload" ref="fileInput" label="Télécharger votre document CSV"
                  outlined></v-file-input> -->
                <v-alert v-if="fileSizeError" type="error" dismissible>
                  The file size exceeds 1MB.
                </v-alert>

              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-overlay :value="loaderPdf" style="z-index: 99999999999">
        <img src="https://i.gifer.com/IuFL.gif" alt="Loading..." />
      </v-overlay>
      <v-col :style="rail == true ? 'max-width: 100%;' : ''" md="12" sm="12">
        <v-alert :icon="false"
          style="border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;margin-bottom: 0px"
          v-if="selectedInventaireObj.name" dark type="success" :color="!readOnly ? 'secondary' : 'info'"
          id="bannerSelected">
          <v-row class="d-flex">

            <v-col cols="10" md="10" sm="10" v-if="!readOnly"
              style="text-decoration: underline; text-decoration-color: #AB3443;text-decoration-thickness: 7px;">
              <span v-if="inventairesCombined.length == 1"><strong>
                  <a class="titleHover" @blur="saveContent" style="color: white;" :contenteditable="isEditable"
                    @click="toggleEditable" ref="editableLink">{{
                    invTitle }}</a>
                  <v-btn icon color="white" class="ml-1" small rounded dark v-if="isEditable" @click="saveContent">
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                </strong></span>


              <v-chip color="primary" class="pa-2" label v-else-if="inventairesCombined.length > 1"> <span><strong>{{
                    inventairesCombined.length }}
                    inventaires combinés
                  </strong></span></v-chip>


              <v-badge class="ml-2" :content="filterEssences.length" :value="filterEssences.length" color="green"
                overlap>
                <v-icon large>{{ selectedInventaireObj.profil == 'vigneron' ?
                  "mdi-fruit-grapes" : "mdi-forest" }}</v-icon>
              </v-badge>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="1" md="1" sm="1" v-if="user.reader == true && secteursShared && secteursShared.length">
              <v-btn style="position: relative;bottom: 21px;right: 9px;" @click="diagShared = true"
                class="ringed animate-bell" color="amber" fab small icon>
                <v-badge :content="secteursShared.length" top right overlap
                  color="red"><v-icon>mdi-bell-ring</v-icon></v-badge>
              </v-btn>
            </v-col>

            <!-- <v-col cols="4" lg="2" md="2" sm="2" v-if="inventairesCombined.length == 1 && !readOnly">
              <v-chip v-if="selectedInventaireObj.dateInventaire" outlined rounded @click="toggleEditableDate">
                <strong>
                  <a class="titleHover" style="color: white;" ref="editableLinkDate">
                    <b>{{ formatDate(selectedInventaireObj.dateInventaire) }}</b></a>
                </strong>
              </v-chip>

              <span v-else>
                <v-chip outlined rounded @click="toggleEditableDate">
                  <i> Non renseigné</i></v-chip>
              </span>


              <v-btn icon color="white" class="ml-1" small rounded dark v-if="isEditableDate"
                    @click="saveContentDate">
                    <v-icon>mdi-check</v-icon>
                  </v-btn> 
            </v-col> -->

            <v-spacer></v-spacer>
            <v-col cols="12">
              <v-row id="menu_app" no-gutters class="d-flex justify-center pb-1 my-2">

                <v-col v-if="isMore1000" cols="1" class="mr-2">
                  <v-btn icon fab small outlined color="outlined" @click="morePoint">
                    <v-icon>mdi-map-marker-multiple-outline</v-icon>
                  </v-btn>
                </v-col>

                <v-col cols="1" class="mr-2" v-if="user.reader != true">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" :class="viewInventaire == 'inventaire' ? 'active' : ''" icon fab small outlined
                        color="outlined" @click="() => { viewInventaire = 'inventaire'; }">
                        <v-icon>mdi-map</v-icon>
                      </v-btn>
                    </template>
                    Voir la carte
                  </v-tooltip>

                </v-col>

                <v-col cols="1" class="mr-2" v-if="user.reader !=true">

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" :class="viewInventaire == 'liste' ? 'active' : ''" icon fab outlined small
                        @click="viewInventaire = 'liste'">
                        <v-icon>mdi-format-list-bulleted-square</v-icon>
                      </v-btn>
                    </template>
                    Voir la liste
                  </v-tooltip>



                </v-col>




                <!-- <v-col cols="1" class="ma-1">
                  <v-btn icon fab outlined small @click="dialogInventaireAdd = true">
                    <v-icon>mdi-map-marker-plus</v-icon>
                  </v-btn>
                </v-col> -->

                <!-- <v-col v-if="!readOnly" cols="1" class="mr-2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon fab outlined small @click="getTimeline">
                        <v-icon>mdi-timeline-text-outline</v-icon>
                      </v-btn>
                    </template>
                    Voir la timeline
                  </v-tooltip>

                </v-col> -->

                <!-- <v-col v-if="!readOnly" cols="1" class="mr-2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon fab outlined small @click="addGrille">
                        <v-icon>mdi-land-fields</v-icon>
                      </v-btn>
                    </template>
                    Créer une zone de prospection
                  </v-tooltip>

                </v-col> -->

                <v-col v-if="user.reader !=true" cols="1" class="mr-2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon fab outlined small @click="dialogTodos = true">
                        <v-icon>mdi-list-status</v-icon>
                      </v-btn>
                    </template>
                    Voir les tâches
                  </v-tooltip>
                </v-col>


                <!-- <v-col cols="1" class="mr-2">

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" fab outlined small @click="getCalendar">
                        <v-icon>mdi-calendar-clock</v-icon>
                      </v-btn>
                    </template>
                    Voir le calendrier
                  </v-tooltip>


                </v-col> -->

                <!-- <v-col v-if="!isFreemium" cols="1" class="mr-2">
                  <v-btn icon fab small outlined color="outlined" @click="getMeteo">
                    <v-icon>mdi-weather-partly-cloudy</v-icon>
                  </v-btn>
                </v-col> -->

                <v-col v-if="user.reader != true" cols="1" class="mr-2">

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon fab outlined small @click="dialogStats = true">
                        <v-icon>mdi-finance</v-icon>
                      </v-btn>
                    </template>
                    Voir les statistiques
                  </v-tooltip>
                </v-col>

                <!-- <v-col  cols="1" class="mr-1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon fab outlined small @click="dialogPhitos = true">
                        <v-icon>mdi-bottle-tonic-plus</v-icon>
                      </v-btn>
                    </template>
                    Voir les phytosanitaires
                  </v-tooltip>
                </v-col> -->


                <v-col v-if="user.reader != true" cols="1">

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon fab outlined small @click="defineParcelle">
                        <v-icon>mdi-map-marker-path</v-icon>
                      </v-btn>
                    </template>
                    Créer une parcelle
                  </v-tooltip>
                </v-col>

                <v-row v-if="user.reader == true" justify="center" align="center" class="sticky-row pa-4">
                  <v-col cols="auto" class="d-flex justify-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn x-large v-on="on" dark fab color="success" @click="addPointLight">
                          <v-icon x-large>mdi-map-marker-plus</v-icon>
                        </v-btn>
                      </template>
                      Ajouter un point
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn x-large v-on="on" fab color="info" @click="addArrowLight" class="ml-3">
                          <v-icon x-large>{{ arrowSens }}</v-icon>
                        </v-btn>
                      </template>
                      Ajouter une flèche
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn x-large v-on="on" color="orange" dark fab @click="addAlertLight" class="ml-3">
                          <v-icon x-large>mdi-alert</v-icon>
                        </v-btn>
                      </template>
                      Ajouter une alerte
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn x-large v-on="on" color="white" dark fab @click="refreshPoint" class="ml-3 teal--text">
                          <v-icon x-large>mdi-refresh</v-icon>
                        </v-btn>
                      </template>
                      Rafraîchir la carte
                    </v-tooltip>
                  </v-col>

                </v-row>



                <!-- <v-col v-if="selectedInventaireObj.profil == 'vigneron'" cols="1" class="mr-1 hidden-sm-and-down">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon fab outlined small @click="viewSymptomes">
                        <v-icon>mdi-virus-outline</v-icon>
                      </v-btn>
                    </template>
                    Voir les symptomes
                  </v-tooltip>

                </v-col> -->

                <v-col v-if="selectedInventaireObj.profil == 'forestier'" cols="1" class="mr-1 hidden-sm-and-down">

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon fab outlined small @click="dialogCalculator = true">
                        <v-icon>mdi-calculator</v-icon>
                      </v-btn>
                    </template>
                    Voir le simulateur de coût
                  </v-tooltip>

                </v-col>

                <!-- <v-btn
                                    icon
                                    fab
                                    outlined
                                    small
                                    class="ma-2"
                                    @click="dialogStats = true">
                                        <v-icon>mdi-finance</v-icon>
                                </v-btn> -->


                <!-- <v-btn
                                    icon
                                    fab
                                    outlined
                                    small
                                    class="ma-2"
                                    @click="dialogInventaireLight = true">
                                        <v-icon>mdi-map-marker-plus</v-icon>
                                </v-btn>

                                <v-btn
                                    icon
                                    fab
                                    outlined
                                    small
                                    class="ma-2"
                                    @click="dialogStats = true">
                                        <v-icon>mdi-finance</v-icon>
                                </v-btn>
                                
                                <v-btn
                                      icon
                                      fab
                                      outlined
                                      small
                                      class="ma-2"
                                      @click="dialogTodos = true">
                                          <v-icon>mdi-list-status</v-icon>
                                </v-btn>

                                <v-btn
                                        icon
                                        fab
                                        outlined
                                        small
                                        class="ma-2"
                                        @click="dialogMedias = true">
                                            <v-icon>mdi-folder-multiple</v-icon>
                                </v-btn> -->


                <v-col v-if="false" cols="1">
                  <v-menu offset-y>
                    <!-- <template v-slot:activator="{ on }">
                      <v-btn icon fab outlined small color="#fff" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template> -->
                    <v-list lines="one">
                      <!-- <v-list-item  @click="dialogEdit = true">
                                      <v-list-item-title><v-icon>mdi-pencil</v-icon>Editer</v-list-item-title>
                                    </v-list-item> -->
                      <v-list-item @click="closeInv">
                        <v-list-item-title><v-icon>mdi-close</v-icon>Fermer</v-list-item-title>
                      </v-list-item>

                      <v-list-item @click="dialogDelete = true">
                        <v-list-item-title><v-icon>mdi-delete</v-icon>Supprimer</v-list-item-title>
                      </v-list-item>

                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-alert>





        <v-row class="" v-if="inventairesCombined.length > 1">
          <v-col cols="12" class="text-right">
            <v-chip small :disabled="inventaire._id == selectedInventaire"
              v-for="(inventaire, index) in inventairesCombined" :key="inventaire._id"
              :close="inventaire._id != selectedInventaire" @click:close="removeInventaire(index)" color="primary" dark
              class="ma-2">
              {{ inventaire.name }}
            </v-chip>
          </v-col>
        </v-row>

        <v-btn style="position: absolute; top: 780px; left: 10px; z-index: 99999" light outined small rounded
          @click="dialogParcelleFix = true"
          v-if=" parcelles.length && !dialogParcelleFix"><v-icon>mdi-land-fields</v-icon>
          Mes
          parcelles</v-btn>

        <v-btn style="position: fixed; bottom: 10px; right: 10px; z-index: 99999" color="secondary" small rounded
          @click="dialogTraitsFix = true"
          v-if=" user.reader !=true && !dialogTraitsFix && (traits.length || markers.length)"><v-icon>mdi-heat-wave</v-icon>
          Ma légende</v-btn>

        <v-btn style="position: fixed; bottom: 50px; right: 10px; z-index: 99999" color="secondary" small rounded
          @click="dialogPostitsFix = true" v-if="postits.length && !dialogPostitsFix"><v-icon>mdi-note-text</v-icon>
          Mes notes</v-btn>


        <v-btn style="position: fixed; bottom: 50px; right: 10px; z-index: 99999" color="secondary" small rounded
          @click="dialogGalleriesFix = true"
          v-if="galleries.length && !dialogGalleriesFix"><v-icon>mdi-image-multiple</v-icon>
          Mes galleries</v-btn>

        <v-card ref="parcelles_box" :elevation="4" v-if="parcelles.length && dialogParcelleFix"
          class="parcelles-box pb-2">
          <v-toolbar dense flat class="secondary--text mb-4">
            <v-badge :value="parcelles.length > 0" :content="parcelles.length">
              <v-toolbar-title>
                📌 Vos parcelles
              </v-toolbar-title></v-badge>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogParcelleFix = false">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-row class="d-flex flex-column">
            <v-col class="ml-6" v-for="(parcelle, index) in parcelles" :key="index" v-if="parcelle">
              <v-chip :color="selectedParcelleIndex == index ? 'success' : 'primary'" label outlined dark>
                <a v-if="user.reader != true"
                  @click="selectedParcelleIndex != index ? selectParcelle(parcelle, index) : deselectParcelle()">
                  <v-badge class="pa-1" overlap :color="parcelle.features[0].properties.color"></v-badge>
                  <span class="pl-1 ml-3"><b>{{
                      parcelle.features[0].properties.name }}</b></span>
                  <span v-if="parcelle.features[0].properties.legend" class="pl-1"><i>{{
                      parcelle.features[0].properties.legend }}</i></span>
                </a>
                <a v-else @click="visibleParcelle(parcelle)">
                  <v-badge class="pa-1" overlap :color="parcelle.features[0].properties.color"></v-badge>
                  <span class="pl-1 ml-3"><b>{{
                      parcelle.features[0].properties.name }}</b></span>
                  <span v-if="parcelle.features[0].properties.legend" class="pl-1"><i>{{
                      parcelle.features[0].properties.legend }}</i></span>
                </a>

                <v-icon v-if="!isFilterPercelle(parcelle)" class="ml-4" color="secondary"
                  @click="visibleParcelle(parcelle)">mdi-eye</v-icon>
                <v-icon v-else class="ml-4" color="secondary" @click="invisibleParcelle(parcelle)">mdi-eye-off</v-icon>
                <!-- <v-icon class=" pa-2">{{ selectedParcelleIndex == index ? "mdi-check" : "mdi-selection-search"
                  }}</v-icon> -->
              </v-chip>
            </v-col>
          </v-row>

          <v-row class="ml-1" v-if="selectedParcelleIndex">
            <v-col>
              <v-btn small filled dark color="secondary" @click="deselectParcelle">
                <v-icon>mdi-restore</v-icon>Revenir à la carte
              </v-btn>
            </v-col>
          </v-row>

        </v-card>

        <v-card ref="traits_box" :elevation="4" v-if="(traits.length || markers.length) && dialogTraitsFix"
          class="traits_box pb-2">
          <v-toolbar dense flat class="secondary--text mb-4">
            <v-badge :value="traits.length > 0" :content="traits.length + markers.length">
              <v-toolbar-title>
                ⛳ Ma légende
              </v-toolbar-title></v-badge>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogTraitsFix = false">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-row class="d-flex flex-column">
            <v-col class="ml-6" v-for="(trait, index) in traits" :key="index" v-if="trait">
              <v-chip label outlined>
                <v-icon :color="trait.color">mdi-chart-timeline-variant</v-icon>
                <span class="pl-2">{{ trait.name }}</span>
                <v-btn x-small @click="removeTrait(trait)" icon><v-icon>mdi-close</v-icon></v-btn>
              </v-chip>
            </v-col>
          </v-row>

          <v-row class="d-flex flex-column">
            <v-col class="ml-6" v-for="(marker, index) in markers" :key="index" v-if="marker">
              <v-chip label outlined>
                <div v-html="marker.svgContent" :style="`stroke: ${marker.color} !important`" class="v-icon__svg"></div>
                <span class="pl-2">{{ marker.title }}</span>
                <v-btn x-small @click="removeMarker(marker)" icon><v-icon>mdi-close</v-icon></v-btn>
              </v-chip>
            </v-col>
          </v-row>


          <v-row class="d-flex flex-column">
            <v-col class="ml-6" v-for="(arrow, index) in arrows" :key="index" v-if="arrow">
              <v-chip label outlined>
                <div v-html="arrow.svgContent" :style="`stroke: ${arrow.color} !important`" class="v-icon__svg"></div>
                <span class="pl-2">{{ arrow.legende }}</span>
                <v-btn x-small @click="removeArrow(arrow)" icon><v-icon>mdi-close</v-icon></v-btn>
              </v-chip>
            </v-col>
          </v-row>



          <v-row class="ml-1" v-if="selectedParcelleIndex">
            <v-col>
              <v-btn small filled dark color="secondary" @click="deselectParcelle">
                <v-icon>mdi-restore</v-icon>Revenir à la carte
              </v-btn>
            </v-col>
          </v-row>

        </v-card>

        <v-card ref="galleries_box" :elevation="4" v-if="(galleries.length || galleries.length) && dialogGalleriesFix"
          class="traits_box pb-2">
          <v-toolbar dense flat class="secondary--text mb-4">
            <v-badge :value="galleries.length > 0" :content="galleries.length">
              <v-toolbar-title>
                🖼️ Mes Galleries
              </v-toolbar-title></v-badge>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogGalleriesFix = false">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-row class="d-flex flex-column">
            <v-col class="ml-6" v-for="(gallerie, index) in galleries" :key="index" v-if="gallerie">
              <v-chip label outlined>
                <v-icon color="primary">mdi-camera</v-icon>
                <span @click="goGallerie(gallerie)" class="pl-2">{{ gallerie.legende }} ({{ gallerie.photos.length }}
                  photos )</span>
                <v-btn x-small @click="removeGallerie(gallerie)" icon><v-icon>mdi-close</v-icon></v-btn>
              </v-chip>
            </v-col>
          </v-row>

          <v-row class="d-flex flex-column">
            <v-col class="ml-6" v-for="(marker, index) in markers" :key="index" v-if="marker">
              <v-chip label outlined>
                <div v-html="marker.svgContent" :style="`stroke: ${marker.color} !important`" class="v-icon__svg"></div>
                <span class="pl-2">{{ marker.title }}</span>
                <v-btn x-small @click="removeMarker(marker)" icon><v-icon>mdi-close</v-icon></v-btn>
              </v-chip>
            </v-col>
          </v-row>


          <v-row class="d-flex flex-column">
            <v-col class="ml-6" v-for="(arrow, index) in arrows" :key="index" v-if="arrow">
              <v-chip label outlined>
                <div v-html="arrow.svgContent" :style="`stroke: ${arrow.color} !important`" class="v-icon__svg"></div>
                <span class="pl-2">{{ arrow.legende }}</span>
                <v-btn x-small @click="removeArrow(arrow)" icon><v-icon>mdi-close</v-icon></v-btn>
              </v-chip>
            </v-col>
          </v-row>



          <v-row class="ml-1" v-if="selectedParcelleIndex">
            <v-col>
              <v-btn small filled dark color="secondary" @click="deselectParcelle">
                <v-icon>mdi-restore</v-icon>Revenir à la carte
              </v-btn>
            </v-col>
          </v-row>

        </v-card>

        <v-card ref="postits_box" :elevation="4" v-if="postits.length && dialogPostitsFix" class="postits_box pb-2">
          <v-toolbar dense flat class="secondary--text mb-4">
            <v-badge :value="postits.length > 0" :content="postits.length">
              <v-toolbar-title>
                🗒️ Mes notes
              </v-toolbar-title></v-badge>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogPostitsFix = false">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-row class="d-flex flex-column">
            <v-col class="ml-6" v-for="(postit, index) in postits" :key="index" v-if="postit">
              <v-chip label outlined>
                <v-icon>mdi-note-text</v-icon>
                <span class="pl-2">{{ postit.title }}</span>
                <v-btn x-small @click="deletePostit(postit)" icon><v-icon>mdi-close</v-icon></v-btn>
              </v-chip>
            </v-col>
          </v-row>

          <v-row class="d-flex flex-column">
            <v-col class="ml-6" v-for="(marker, index) in markers" :key="index" v-if="marker">
              <v-chip label outlined>
                <img :src='"@/assets/svg/" + marker.type + ".svg"' alt="icon" class="v-icon__svg" />
                <span class="pl-2">{{ marker.title }}</span>
                <v-btn x-small @click="removeMarker(marker)" icon><v-icon>mdi-close</v-icon></v-btn>
              </v-chip>
            </v-col>
          </v-row>

          <v-row class="ml-1" v-if="selectedParcelleIndex">
            <v-col>
              <v-btn small filled dark color="secondary" @click="deselectParcelle">
                <v-icon>mdi-restore</v-icon>Revenir à la carte
              </v-btn>
            </v-col>
          </v-row>

        </v-card>

        <Inventaire @updateOffline="updateOffline" :dialogParcelleFix="dialogParcelleFix"
          :parcellesTypes="parcellesTypes" @nextphito="nextphito" @getCalendar="getCalendar" :grille="grille"
          @getTimeline="getTimeline" @changeView="changeView" :viewItem="viewItem" :operateurs="operateurs"
          @displayFullMap="displayFullMap" :rail="rail" @addPoints="dialogInventaireAdd = true"
          @refreshParent="methodeDeRafraichissement" @refreshLight="refreshLight" :loading="loading"
          @resetInv="resetInv" @changeInventaires="changeInventaires" ref="inventaire" @listenFilter="listenFilter"
          :statsEssences="statsEssences" @confSaveArea="confSaveArea" v-if="viewInventaire == 'inventaire'"
          @displayFilter="dialogFilterDrawer" :selectedInventaireObj="selectedInventaireObj"
          :selectedInventaire="selectedInventaire" @refreshPoint="refreshPoint" :inventaires="filterEssences"
          :parcelles="filterParcelles" @closeSetting="closeSetting" @openSetting="openSetting" :galleries="galleries"
          :arrows="arrows" :actionMarker="actionMarker" :markers="markers" :traits="traits" :postitsLoaded="postits"
          :zones="zones" :secteursShared="secteursShared">
        </Inventaire>

        <Liste :user="user" @refreshPoint="refreshPoint" @refreshParent="methodeDeRafraichissement"
          @modify="modifyPoint" :selectedInventaireObj="selectedInventaireObj" :inventaires="filterEssences" v-else>
        </Liste>

      </v-col>






      <!-- </v-main> -->
      <v-navigation-drawer temporary hide-overlay @input="changeToolbar" v-model="viewModal" temporary fixed top right
        dark>
        <v-list-item>

          <!-- <v-list-item-avatar>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-avatar> -->

          <v-list-item-title @click="drawerRightProfil = true">
            <v-badge dot class="ma-2" left color="success"></v-badge> Bonjour {{ user.displayName
            }}
            <v-icon color="yellow" v-if="user.subscribed" small>
              mdi-crown
            </v-icon>
          </v-list-item-title>

          <v-btn v-if="rail == false" icon @click="closeModal">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>

        </v-list-item>
        <!-- <v-list-item class="d-flex justify-center">
            <v-chip v-if="rail == false" small>Profil {{ user.type }}</v-chip>
          </v-list-item> -->
        <div @click="$router.push('/premium')" v-if="user.subscribed">
          <v-chip label dark class="pa-1"><v-icon color="yellow" class="pa-2">mdi-crown</v-icon> {{ getOffre
            }}</v-chip>
        </div>

        <v-divider v-if="inventaires.length"></v-divider>

        <v-list shaped>
          <!-- <v-list-item class="px-2" link @click="drawerRightProfil = true">
            <v-list-item-icon>
              <v-icon>mdi-account-box</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Mon Profil</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider> -->




          <v-list-item class="px-2" link @click="openDomaines" v-if="user.reader != true">
            <v-list-item-icon>
              <v-icon>mdi-land-fields</v-icon><v-badge v-if="nbDomaines > 0" top :content="nbDomaines" right
                overlap></v-badge>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Mes domaines</v-list-item-title>
            </v-list-item-content>
          </v-list-item>



          <v-list-item v-if="user.reader != true" class="px-2" link @click="drawerRightInventaires = true">
            <v-list-item-icon>
              <v-icon>mdi-clipboard-list</v-icon><v-badge v-if="inventairesSearch.length" top
                :content="inventairesSearch.length" right overlap></v-badge>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Mes inventaires</v-list-item-title>
            </v-list-item-content>
          </v-list-item>



          <v-list-item class="px-2" link @click="drawerRightInventairesShared = true">
            <v-list-item-icon>
              <v-icon>mdi-share-variant</v-icon><v-badge v-if="inventairesSearchShared.length" top
                :content="inventairesSearchShared.length" right overlap></v-badge>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Inventaires partagés</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


          <v-divider></v-divider>





          <v-list-item v-if="user.reader != true" class="px-2" link @click="drawerRightParcelles = true">
            <v-list-item-icon color="orange">
              <v-icon color="orange">mdi-texture-box</v-icon><v-badge v-if="parcellesAll.length" top
                :content="parcellesAll.length" right overlap></v-badge>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Mes parcelles</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


          <!-- premium search for Pro -->

          <!-- <v-list-item v-if="!selectedInventaire" color="success" class="px-2" link
            @click="drawerRightInventairesAddAnother = true">
            <v-list-item-icon>
              <v-icon>mdi-map-search</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Rechercher un inventaire</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <!--  <v-list-item v-if="user.reader == false" id="v-step-1" class="px-2" link @click="openCal">
            <v-list-item-icon>
              <v-icon color="info">mdi-calendar</v-icon> <v-badge v-if="tasks.length" top :content="tasks.length" right
                overlap></v-badge>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Mes tâches</v-list-item-title>
            </v-list-item-content>
          </v-list-item>-->


          <v-list-item v-if="user.reader == false" id="v-step-1" class="px-2" link @click="openPhito">
            <v-list-item-icon>
              <v-icon color="success">mdi-spray</v-icon> <v-badge v-if="nbPhytos" top :content="nbPhytos" right
                overlap></v-badge>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Mes phytos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>



          <v-list-item v-if="user.reader == false" id="v-step-1" class="px-2" link @click="openTeam">
            <v-list-item-icon>
              <v-icon color="pink">mdi-account-group</v-icon> <v-badge v-if="nbUsers" top :content="nbUsers" right
                overlap></v-badge>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Mon équipe</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item id="v-step-1" class="px-2" link v-if="selectedInventaire"
            @click=" alreadyLoad = true; toggleDrawer()">
            <v-list-item-icon>
              <v-icon color="golden">mdi-filter</v-icon> <v-badge v-if="nbFiltres" top :content="nbFiltres" right
                overlap></v-badge>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Filtrer la carte</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


          <v-divider></v-divider>

          <v-list-item id="v-step-1" class="px-2" link v-if="selectedInventaire" @click="syncInvs">
            <v-list-item-icon>
              <v-icon color="yellow">mdi-web-sync</v-icon> <v-badge v-if="nbEmptyPoints" top :content="nbEmptyPoints"
                right overlap></v-badge>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Synchronisation</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="user.reader != true" id="v-step-1" class="px-2" link @click="dialogFilterDrawer">
            <v-list-item-icon>
              <v-icon color="yellow">mdi-clock-time-eight-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Filtrer le temps</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


          <v-list-item v-if="user.reader != true && selectedInventaireObj" id="v-step-1" class="px-2" link
            @click="drawerCadastre = true">
            <v-list-item-icon>
              <v-icon color="brown">mdi-fence</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Filtrer le cadastre</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item class="px-2" link @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Se déconnecter</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-navigation-drawer v-model="drawerRight" absolute temporary top right>
            <v-sheet class="pa-2 pb-5">
              <h3>
                Filtrer les données 🍇<v-badge v-if="nbFiltres > 0" :content="nbFiltres" top left overlap></v-badge>
              </h3>


              <v-row class="pt-5 pb-5">

                <v-col cols="12" v-for="item in itemsFilters" :key="item">
                  <v-autocomplete multiple class="specialselect" small-chips :items="viewItemsFilters[item]"
                    item-value="value" item-text="title" v-model="viewItemFilters[item]" filled color="secondary" dark
                    :label="`${capitalize(item)}`"></v-autocomplete>
                </v-col>

                <transition transition="scroll-y-transition">
                  <v-col v-show="moreFilters">
                    <v-autocomplete label="Lieux-Dits" small-chips dense :items="lieuxDits" v-model="lieuxDitsSelected"
                      item-text="name" item-value="id" return-object chips multiple outlined></v-autocomplete>
                    <v-slider small color="primary" :step="0.1" v-model="hauteurVigne" :max="180" :min="1"
                      label="Hauteur (cm)" thumb-label="always"></v-slider>
                  </v-col>
                </transition>
              </v-row>

              <!-- <v-row justify="center" class="pt-5">
                <v-btn rounded color="primary" light @click="moreFilter">
                  <v-icon>{{ !moreFilters ? "mdi-menu-down" : "mdi-menu-up" }}</v-icon>
                  {{ !moreFilters ? "Plus de filtres" : "Moins de filtres" }}</v-btn>
              </v-row>-->
              <v-row justify="center" class="pt-5">
                <v-btn rounded color="pink" outlined dark @click="resetFilter(); drawerRight = false">
                  <v-icon>{{ "mdi-close" }}</v-icon> Réinitialiser</v-btn>
              </v-row>
            </v-sheet>
          </v-navigation-drawer>



          <v-navigation-drawer v-model="drawerRightTempo" absolute temporary top right>
            <v-sheet class="pa-2 pb-5">
              <h3>
                ⌛Filtrer par périodes
              </h3>

              <v-divider></v-divider>

              <v-container v-if="!switchYear" class="d-flex justify-center">
                <v-range-slider color="secondary" vertical v-model="monthRange" :min="1" :max="12" :thumb-label="always"
                  :tick-labels="monthLabels" tick-size="4" step="1" range :height="500">
                </v-range-slider>
              </v-container>


              <v-container v-else class="d-flex justify-center">
                <v-range-slider color="secondary" vertical v-model="yearRange" :min="minYear" :max="currentYear"
                  :thumb-label="always" :tick-labels="yearLabels" tick-size="4" step="1" range>
                </v-range-slider>
              </v-container>

              <v-row class="ma-2" justify="center">
                <v-switch color="primary" dark v-model="switchYear" inset :label="`En année`"></v-switch>
                <v-select :items="yearOptions" v-model="yearFilter" item-text="text" item-value="value"
                  label="Quelle année ?" outlined></v-select>
              </v-row>
            </v-sheet>
          </v-navigation-drawer>


          <v-navigation-drawer v-model="drawerRightProfil" absolute temporary top right>
            <v-sheet class="pt-2 pb-5">
              <h3>
                Mon profil
              </h3>


              <v-divider></v-divider>

              <v-list>



                <v-list-item v-if="user.name" active-color="primary">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-chip label><v-icon class="pa-2">mdi-account</v-icon> {{ user.name }}</v-chip>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>


                <v-list-item active-color="primary" v-if="user.type">
                  <v-list-item-content>
                    <v-list-item-title>
                      <span><v-icon class="pa-2">mdi-cogs</v-icon> Profil <b> {{ user.type }}</b></span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon class="pa-2">mdi-email</v-icon> {{ user.email }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="user.nameCompagny">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon class="pa-2">mdi-home</v-icon> {{ user.nameCompagny }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="user.telCompagny">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon class="pa-2">mdi-phone</v-icon> {{ user.telCompagny }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item @click="$router.push('/premium')" v-if="user.subscribed && rail == false">
                  <v-list-item-content>
                    <v-list-item-title>
                      <span label dark><v-icon color="yellow" class="pa-2">mdi-crown</v-icon> {{ getOffre
                        }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>

              <v-row justify="center" class="pt-5">
                <v-btn rounded small outlined color="info" dark @click="dialogProfil = true">
                  <v-icon class="pa-2">mdi-card-account-details-outline</v-icon> Compléter mon profil</v-btn>
              </v-row>
              <v-row justify="center" class="pt-5">
                <v-btn rounded color="secondary" dark @click="logout">
                  <v-icon>{{ "mdi-logout" }}</v-icon> Se déconnecter</v-btn>
              </v-row>
              <v-row justify="center" class="pt-5">
                <v-btn rounded color="pink" outlined dark @click=" drawerRightProfil = false">
                  <v-icon>{{ "mdi-close" }}</v-icon> Fermer</v-btn>
              </v-row>
            </v-sheet>
          </v-navigation-drawer>

          <v-navigation-drawer v-model="drawerRightParcelles" absolute temporary top right>
            <v-sheet class="pt-2 pb-5">
              <h3>
                Toutes mes parcelles 📌<v-badge v-if="filteredParcelles.length" :content="filteredParcelles.length" top
                  left overlap></v-badge>
              </h3>

              <v-text-field v-model="searchQueryParcelle" append-icon="mdi-magnify" label="Rechercher une parcelle"
                single-line></v-text-field>

              <v-list dense v-if="filteredParcelles.length">

                <v-list-item :to="{ name: 'Detail', params: { id: parcelle.invId } }"
                  v-for="  parcelle   in   filteredParcelles  " :key="parcelle.id"
                  :class="{ active: isActiveRoute(parcelle.invId) }" active-color="primary">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon color="secondary" class="pr-2">mdi-star-circle</v-icon>{{
                      parcelle.features[0].properties.name
                      }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-alert class="pa-2 ma-1 d-flex justify-center align-center" outlined dark
                v-if="!filteredParcelles.length">
                Vous n'avez pas de parcelles
              </v-alert>

              <v-divider></v-divider>

              <v-row justify="center" class="pt-5">
                <v-btn rounded color="pink" outlined dark @click=" drawerRightParcelles = false">
                  <v-icon>{{ "mdi-close" }}</v-icon> Fermer</v-btn>
              </v-row>
            </v-sheet>
          </v-navigation-drawer>

          <v-navigation-drawer v-model="drawerRightInventaires" absolute temporary top right>
            <v-sheet class="pt-2 pb-5">
              <h3>
                Tous mes inventaires 📒<v-badge v-if="filteredInventairesQuick.length"
                  :content="filteredInventairesQuick.length" top left overlap></v-badge>
              </h3>
              <v-text-field v-model="searchQueryInventaires" append-icon="mdi-magnify" label="Rechercher un inventaire"
                single-line clearable></v-text-field>
              <v-list dense v-if="filteredInventairesQuick.length">

                <v-list-item :to="{ name: 'Detail', params: { id: inventaire.id } }"
                  v-for="inventaire in filteredInventairesQuick" :key="inventaire.id"
                  :class="{ active: isActiveRoute(inventaire.id) }" active-color="primary">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon color="secondary" class="pr-2">mdi-star-circle</v-icon>{{ inventaire.name }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-alert class="pa-2 ma-1 d-flex justify-center align-center" outlined dark
                v-if="!filteredInventairesQuick.length">
                Vous n'avez pas d'inventaire
              </v-alert>

              <v-btn small class="pa-1 ma-2" to="/import?dashboard=true" rounded color="success">
                <v-icon>mdi-map-plus</v-icon>
                Ajouter un
                inventaire</v-btn>

              <v-btn small class="pa-1 ma-2" to="/import?dashboard=true&nopoints=true" rounded color="orange">
                <v-icon>mdi-plus-circle</v-icon>
                Créer son parcellaire</v-btn>

              <!-- <v-btn v-else class="pa-2" to="/premium?first=true" block big dark color="amber">
                <v-icon>mdi-crown</v-icon>
                Passer en Premium</v-btn> -->

              <v-divider></v-divider>

              <v-row justify="center" class="pt-5">
                <v-btn rounded color="pink" outlined dark @click=" drawerRightInventaires = false">
                  <v-icon>{{ "mdi-close" }}</v-icon> Fermer</v-btn>
              </v-row>
            </v-sheet>
          </v-navigation-drawer>

          <v-navigation-drawer v-model="drawerRightInventairesShared" absolute temporary top right>
            <v-sheet class="pt-2 pb-5">
              <h3>
                Inventaires partagés📒<v-badge v-if="inventairesSearchShared.length"
                  :content="inventairesSearchShared.length" top left overlap></v-badge>
              </h3>

              <v-list dense v-if="inventairesSearchShared.length">

                <v-list-item :to="{ name: 'Detail', params: { id: inventaire._id } }"
                  v-for="inventaire in inventairesSearchShared" :key="inventaire._id"
                  :class="{ active: isActiveRoute(inventaire._id) }" active-color="primary">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon color="secondary" class="pr-2">mdi-star-circle</v-icon>{{ inventaire.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-alert class="pa-2 ma-1 d-flex justify-center align-center" outlined dark
                v-if="!inventairesSearchShared.length">
                Vous n'avez pas d'inventaire partagé
              </v-alert>


              <v-divider></v-divider>

              <v-row justify="center" class="pt-5">
                <v-btn rounded color="pink" outlined dark @click=" drawerRightInventairesShared = false">
                  <v-icon>{{ "mdi-close" }}</v-icon> Fermer</v-btn>
              </v-row>
            </v-sheet>
          </v-navigation-drawer>

          <v-navigation-drawer v-model="drawerCadastre" absolute temporary top right>
            <v-sheet class="pt-2 pb-5">
              <h3>
                Filtrer selon le cadastre
              </h3>

              <v-autocomplete v-model="filterCadastre" :items="villesAutocomplete" item-text="nom" item-value="code"
                prepend-icon="mdi-map-search-outline" chips color="blue" label="Sélectionnez une ville" return-object
                @change="onSelectCity" @update:search-input="searchVilles"></v-autocomplete>


              <v-divider></v-divider>
              <v-row justify="center" class="pt-5">
                <v-btn class="mb-4" rounded color="red" outlined dark @click="deselectCadastre">
                  <v-icon>{{ "mdi-close" }}</v-icon> Réinitialiser</v-btn>
                <v-btn rounded color="pink" outlined dark @click="drawerCadastre = false">
                  <v-icon>{{ "mdi-close" }}</v-icon> Fermer</v-btn>
              </v-row>
            </v-sheet>
          </v-navigation-drawer>

          <v-navigation-drawer v-model="drawerRightInventairesAdd" absolute temporary top right>
            <v-sheet class="pt-2 pb-5">
              <h3>
                <v-icon>mdi-format-list-checks</v-icon> Combiner un inventaire
              </h3>
              <v-list>
                <v-list-item
                  :disabled="!isActiveRoute(inventaire.id) && !labelCombined.includes(inventaire.name) && inventaire.profil == selectedInventaireObj.profil"
                  @click="addInventaire(inventaire)" v-for="inventaire   in   inventairesSearch" :key="inventaire.id"
                  :class="{ active: isActiveRoute(inventaire.id) }" active-color="primary">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon
                        :color="isActiveRoute(inventaire.id) || labelCombined.includes(inventaire.name) ? 'success' : 'primary'"
                        class="pr-2">{{
                        isActiveRoute(inventaire.id) || labelCombined.includes(inventaire.name) ?
                        "mdi-check-circle-outline" : "mdi-clipboard-list" }}</v-icon>{{ inventaire.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ inventaire.dateInventaire }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-btn @click="drawerRightInventairesAddAnother = true" dark color="teal" block class="pa-3">
                <v-icon>mdi-map-plus</v-icon>
                Autre inventaire</v-btn>

              <v-divider></v-divider>

              <v-row justify="center" class="pt-5">
                <v-btn rounded color="pink" outlined dark @click=" drawerRightInventairesAdd = false">
                  <v-icon>{{ "mdi-close" }}</v-icon> Fermer</v-btn>
              </v-row>
            </v-sheet>
          </v-navigation-drawer>

          <v-navigation-drawer v-model="drawerRightInventairesAddAnother" absolute temporary top right>
            <v-sheet class="pt-2 pb-5">
              <h3>
                <v-icon>mdi-import</v-icon> Importer un inventaire
              </h3>


              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon>mdi-email</v-icon> Son e-mail:
                    </v-list-item-title>
                    <v-text-field v-model="emailImport" label="Son e-mail"></v-text-field>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon>mdi-format-list-checks</v-icon> Son nom d'inventaire:
                    </v-list-item-title>
                    <v-text-field v-model="invtImport" label="Son nom d'inventaire"></v-text-field>
                  </v-list-item-content>
                </v-list-item>


                <v-list-item>
                  <v-list-item-content>
                    <v-btn color="blue" @click="importInvt" dark><v-icon>mdi-search-web</v-icon> Rechercher</v-btn>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-row justify="center" class="pt-5">
                <v-btn rounded color="pink" outlined dark @click=" drawerRightInventairesAddAnother = false">
                  <v-icon>{{ "mdi-close" }}</v-icon> Fermer</v-btn>
              </v-row>
            </v-sheet>
          </v-navigation-drawer>

          <v-navigation-drawer v-model="drawerRightExport" absolute temporary top right>
            <v-sheet class="pt-2 pb-5">
              <h3>
                Type d'export 📁
              </h3>

              <v-list dense>
                <v-list-item active-color="primary">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-btn small>
                        <v-icon color="white" class="pr-3">mdi-image</v-icon>Exporter en image
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-btn small>
                        <v-icon color="white" class="pr-3">mdi-microsoft-excel</v-icon>Exporter les données
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-btn small>
                        <v-icon color="white" class="pr-3">mdi-file-pdf-box</v-icon>Exporter en PDF
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-row justify="center" class="pt-5">
                <v-btn rounded color="pink" outlined dark @click=" drawerRightExport = false">
                  <v-icon>{{ "mdi-close" }}</v-icon> Fermer</v-btn>
              </v-row>
            </v-sheet>
          </v-navigation-drawer>


          <v-navigation-drawer v-model="drawerRightDessinExport" absolute temporary top right>
            <v-sheet class="pt-2 pb-5">
              <h3>
                Type de dessin
              </h3>

              <v-list dense>
                <v-list-item active-color="primary">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row>
                        <v-col>
                          <v-btn small @click="addTrait">
                            <v-icon color="white" class="pr-3">mdi-chart-timeline-variant</v-icon>Un trait
                          </v-btn>
                        </v-col>

                        <v-col>
                          <v-btn small @click="addNote">
                            <v-icon color="white" class="pr-3">mdi-note-edit</v-icon>Un postit
                          </v-btn>
                        </v-col>

                        <v-col>
                          <v-btn small @click="addPhotos">
                            <v-icon color="white" class="pr-3">mdi-image-multiple</v-icon>Une gallerie
                          </v-btn>
                        </v-col>


                        <v-col>
                          <v-btn small @click="addFleche">
                            <v-icon color="white" class="pr-3">mdi-arrow-right-top-bold</v-icon>Une flèche
                          </v-btn>
                        </v-col>

                      </v-row>



                    </v-list-item-title>
                    <v-text-field v-model="searchQueryIcons" label="Rechercher un icon"
                      prepend-icon="mdi-magnify"></v-text-field>
                    <v-divider />
                    <v-row>
                      <v-col v-for="icon, index in filteredIcons" :key="index">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-on="on" fab icon @click="addPicto(icon.title)">
                              <Pictos :img="icon.title" />
                            </v-btn>
                          </template>
                          <span>{{ icon.title }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>


                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-row justify="center" class="pt-5">
                <v-btn rounded color="pink" outlined dark @click=" drawerRightDessinExport = false">
                  <v-icon>{{ "mdi-close" }}</v-icon> Fermer</v-btn>
              </v-row>
            </v-sheet>
          </v-navigation-drawer>


          <v-list-item class="px-2" v-if="user.reader != true">


            <v-autocomplete placeholder="Mes inventaires" variant="solo" @change="selectInvt" item-title="name"
              item-value="id" return-object filled v-model="selectedInventaireObj" :items="inventairesSearch">
              <template v-slot:selection="data">
                <v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select"
                  @click:close="remove(data.item)">
                  {{ data.item.name }}
                </v-chip>
              </template>

              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.dateInventaire"></v-list-item-subtitle>
                </v-list-item-content>
              </template>

            </v-autocomplete>
          </v-list-item>

          <!-- <v-btn v-if="!user.subscribed" class="pa-2" to="/premium?first=true" block big dark color="amber">
            <v-icon>mdi-crown</v-icon>
            Passer en Premium</v-btn> -->



          <v-list class="fade-in" v-if="user.reader != true">



            <v-list-item class="px-2">
              <v-list-item-content>
                <v-btn class="pa-3" dark rounded small color="teal" @click="addPoint">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on"><v-icon>mdi-map-marker-plus</v-icon>Ajouter un point</span>
                    </template>
                    Ajouter un point sur cet inventaire
                  </v-tooltip>
                </v-btn>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="px-2">
              <v-list-item-content>
                <v-btn class="pa-3" dark rounded small color="purple" @click="addDessin">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on"><v-icon>mdi-draw-pen</v-icon>Dessiner sur la carte</span>
                    </template>
                    Dessiner sur cet inventaire
                  </v-tooltip>
                </v-btn>
              </v-list-item-content>
            </v-list-item>


            <!-- <v-list-item class="px-2 d-none d-sm-flex" v-if="!isFreemium">
              <v-list-item-content>
                <v-btn class="pa-3" dark rounded small color="success" @click="openPointsAdd">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on"><v-icon>mdi-map-marker-multiple-outline</v-icon>Importer des points</span>
                    </template>
                    Importer plus de points sur cet inventaire
                  </v-tooltip>
                </v-btn>
              </v-list-item-content>
            </v-list-item> -->



            <v-list-item class="px-2">
              <v-list-item-content>
                <v-btn class="pa-3" dark rounded small color="info" @click="defineParcelle">

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on"><v-icon>mdi-draw</v-icon>Définir une parcelle</span>
                    </template>
                    Définissez le contour de vos parcelles
                  </v-tooltip>
                </v-btn>
              </v-list-item-content>
            </v-list-item>

            <!-- <v-list-item class="px-2" v-if="!isFreemium">
              <v-list-item-content>
                <v-btn @click="dialogPeople = true" class="pa-3" dark rounded small color="pink">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on"> <v-icon>mdi-account-group</v-icon>Ajouter
                        des personnes
                      </span>
                    </template>
                    <span>Ajouter
                      des personnes à collaborer sur inventaire en cours</span>
                  </v-tooltip>
                </v-btn>
              </v-list-item-content>
            </v-list-item> -->


            <v-list-item v-if="inventairesCombined.length" class="px-2">
              <v-list-item-content>
                <v-btn @click="drawerRightInventairesAdd = true" class="pa-3" dark rounded small color="orange"
                  :class="{ 'btn-single-inventaire': inventairesCombined.length == 1, 'btn-multiple-inventaires': inventairesCombined.length > 1 }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-if="inventairesCombined.length == 1" v-on="on">
                        <v-icon left>mdi-link-variant-plus</v-icon>
                        Combiner un inventaire
                      </span>
                      <span v-else-if="inventairesCombined.length > 1" v-on="on">
                        <v-icon left>mdi-plus-circle</v-icon> <v-badge class="pr-1"
                          :content="inventairesCombined.length" :value="inventairesCombined.length" right top> Autre
                          inventaire
                        </v-badge>
                      </span>
                    </template>
                    <span v-if="inventairesCombined.length == 1">Cliquez ici pour ajouter un inventaire</span>
                    <span v-else-if="inventairesCombined.length > 1">Cliquez ici pour gérer les inventaires
                      existants</span>
                  </v-tooltip>
                </v-btn>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="px-2">
              <v-list-item-content>
                <v-btn class="pa-3" dark rounded small color="green" @click="dialogSendEmail = true">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on"><v-icon>mdi-file-pdf-box</v-icon>Génerer un rapport</span>
                    </template>
                    Génerer un rapport en PDF
                  </v-tooltip>
                </v-btn>
              </v-list-item-content>
            </v-list-item>


            <v-list-item class="px-2">
              <v-list-item-content>
                <v-btn class="pa-3" dark rounded small color="Salmon" @click="dialogFilterAssistant">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on"><v-icon class="mr-1">mdi-chat-question</v-icon>Parler à l'Assistant AI</span>
                    </template>
                    Discuter avec un Assistant AI
                  </v-tooltip>
                </v-btn>
              </v-list-item-content>
            </v-list-item>


            <v-list-item class="px-2" v-if="selectedInventaireObj.profil == 'vigneron'">
              <v-list-item-content>
                <v-btn class="pa-3" dark rounded small color="green" @click="getAOC">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on"><v-icon class="mr-1">mdi-glass-wine</v-icon>Séléctionner un A.O.C.</span>
                    </template>
                    Voir tous les vins AOC
                  </v-tooltip>
                </v-btn>
              </v-list-item-content>
            </v-list-item>



            <v-list-item class="px-2">
              <v-list-item-content>
                <v-btn @click="traceIti" class="pa-3" dark rounded small color="pink">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on"> <v-icon>mdi-map-marker-path</v-icon> Tracer un itinéraire
                      </span>
                    </template>
                    <span>Tracer un parcours jusqu'à un point de la carte</span>
                  </v-tooltip>
                </v-btn>
              </v-list-item-content>
            </v-list-item>


            <v-list-item class="px-2" v-if="selectedInventaireObj.profil == 'forestier'">
              <v-list-item-content>
                <v-btn class="pa-3" dark rounded small color="brown" @click="dialogCalcul = true">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on"><v-icon>mdi-calculator</v-icon>Recalculer le volume</span>
                    </template>
                    Enregistrer la formule de recalcul de volume
                  </v-tooltip>
                </v-btn>
              </v-list-item-content>
            </v-list-item>


            <v-list-item class="px-2 d-none d-sm-flex">
              <v-list-item-content>
                <v-btn @click="removeInvt" class="pa-3" dark rounded small color="red">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <v-icon left>mdi-delete-empty</v-icon>
                        Supprimer l'inventaire
                      </span>
                    </template>
                    <span>Cliquez ici pour supprimer l'inventaire</span>
                  </v-tooltip>
                </v-btn>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="px-2 mt-6" v-if="invId">
              <v-list-item-content>
                <v-btn @click="takePhoto" class="pa-3" dark color="teal">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <span v-on="on"><v-icon>mdi-download-circle</v-icon>Exporter</span>

                    </template>
                    <span> Exporter la cartographie à différents formats
                    </span>
                  </v-tooltip>
                </v-btn>

              </v-list-item-content>
            </v-list-item>

            <v-list-item class="px-2 mt-6" v-if="invId">
              <v-list-item-content>
                <v-btn @click="exportExcel" class="pa-3" dark color="success">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <span v-on="on"><v-icon>mdi-microsoft-excel</v-icon>Exporter en CSV</span>

                    </template>
                    <span> Exporter au format Excel
                    </span>
                  </v-tooltip>
                </v-btn>

              </v-list-item-content>
            </v-list-item>


            <v-list-item class="px-2" v-if="rail == true">
              <v-list-item-content>
                <v-btn icon class="pa-3" dark rounded small color="success"
                  @click="addPoint"><v-icon>mdi-map-marker-plus</v-icon></v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-list>

      </v-navigation-drawer>

    </v-row>
  </v-container>
</template>

<script>

import xhr from '../plugins/axios';
import axios from 'axios';
import moment from 'moment';

moment.locale('fr');

import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

import Stats from './Stats'
import Simulator from './Simulator'

import Inventaire from './Inventaire'
import Liste from './Liste'
import Todos from './Todos';
import Medias from './Medias';
import Upload from './Upload';
import People from './People';
import Chat from './Chat';
import Dot from './Dot';
import Team from './Team';
import Domaine from './Domaine';

import Calendar from './Calendar';
import Phito from './Phito'
import './global.scss'
import { mapGetters, mapActions } from 'vuex';
import colors from './colors.json'
import altitudes from './altitudes.json'
import hauteurs from './hauteurs.json'
import pentes from './pentes.json'
import html2canvas from 'html2canvas';
import Pictos from './Pictos'
import icons from './icons.json';

export default {
  name: 'Carte',
  components: {
    Pictos,
    Domaine: Domaine,
    Team: Team,
    Phito: Phito,
    Calendar: Calendar,
    Upload: Upload,
    People: People,
    vueDropzone: vue2Dropzone,
    Stats,
    Simulator,
    Inventaire,
    Liste,
    Chat,
    Todos,
    Medias,
    Dot
  },
  data(vm) {
    return {
      nbEmptyPoints: 0,
      arrowSens: 'mdi-arrow-right',
      arrowSensC:1,
      dialogGrille: false,
      grille: {
        horizontal: false,
        nbR: 25,
        nbP: 1
      },
      filtParcelles: [],
      searchQueryIcons: '',
      icons: icons,
      itemsOptionsPdf: [
        { text: 'La carte grand format', value: 'large_map', checked: 'large_map' },
        { text: 'Les statistiques', value: 'statistics', checked: false },
        { text: 'Les tâches du mois', value: 'monthly_task', checked: false }
      ],
      dialogDomaines: false,
      searchQuery: '',
      searchQueryParcelle: '',
      searchQueryInventaires: '',
      parcellesTypes: [
        { value: "parcelle", name: 'Parcelles et Sous-Parcelle', description: 'Type de parcelle' },
        { value: "chemin_foret", name: 'Chemins et Routes forestières', description: 'Accès pour la gestion et l\'exploitation' },
        { value: "installation_gestion", name: 'Installations de Gestion', description: 'Emplacements des bureaux, entrepôts, et autres infrastructures clés' },
        { value: "surveillance", name: 'Équipements de surveillance', description: 'Position des caméras de surveillance, stations météorologiques, etc' },
        { value: "ruisseau_riviere", name: 'Rivières et ruisseaux', description: 'Protection des écosystèmes aquatiques.' },
        { value: "etang_lac", name: 'Étangs et lacs', description: "Plans d'eau utilisés pour la biodiversité, la pêche ou comme réserve d'eau en cas d'incendie" },
        { value: "zone_humide", name: 'Zones humides', description: "Zones de marécages et tourbières" },
        { value: "plante_rare", name: 'Plantes rares ou protégées', description: "Emplacements des espèces végétales d'intérêt." },
        { value: "habitats_faunique", name: 'Habitats fauniques', description: "Zones pour la faune, comme les zones de nidification ou les coridors migratoires" },
        {
          value: "observation_espece", name: "Observations d'espèces", description: "Endroits où des espèces spécifiques ont été observées, particulièrement celles qui sont protégées ou d'intérêt cynégétique"
        },
        { value: "zone_risque_incendie", name: "Zones à risque d'incendie", description: "Identifier les zones à haut risque et les emplacements des équipements de lutte contre les incendies" },
        { value: "zone_risque_erosion", name: "Zones sensibles à l'érosion", description: "Identifier les pentes ou les zones où la végétation doit être renforcée pour prévenir l'érosion" },
        { value: "zone_affectees_maladie", name: "Zones affectées par des maladies", description: "Marquer les zones touchées par des pathogènes ou des insectes nuisibles" },
        { value: "air_protegees", name: "Aires protégées", description: "Zones soumises à des pratiques de gestion spécifiques pour préserver la biodiversité" },
        { value: "sentier", name: "Sentiers de randonnée et d'interprétation", description: "Sentiers disponibles pour le public" },
        { value: "exploitation", name: 'Zones d\'exploitation', description: 'Zones actuellement en exploitation ou celles programmées pour la coupe' },
        { value: "historiques", name: 'Structures archéologiques ou historiques', description: 'Eléments patrimoniaux dans la forêt' },
      ],
      formuleCalc: '',
      dialogCalcul: false,
      isOnline: navigator.onLine,
      dialogTeam: false,
      filterCadastre: null,
      villesAutocomplete: [],
      itemsFilters: [],
      viewItemsFilters: [],
      viewItemFilters: {
        domaine: null,
        parcelle: null,
        etat: null,
        cepages: null,
        operateur: null,
        dates: null,
        devices: null,
      },
      emailImport: "",
      invtImport: "",
      editingDesc: false,
      dialogTimeline: false,
      dialogCalendar: false,
      selectedParcelleIndex: null,
      dialogParcelleFix: true,
      dialogTraitsFix: false,
      dialogGalleriesFix: false,
      dialogPostitsFix: false,
      successfullPeople: false,
      isEditable: false,
      isEditableDate: false,
      telRules: [
        (v) =>
          (v.length == 0 || /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/.test(v)) ||
          "Le téléphone doit être valide",
      ],
      nomCompagnyRules: [
        (v) =>
          v.length == 0 || (v && v.length > 2) ||
          "Le nom d'entreprise doit faire au moins 2 caractères",
        v => v.length == 0 || (v && v.length <= 100) || "Le nom d'entreprise ne doit pas dépasser 50 caractères",
      ],
      nbPhytos: 0,
      nbDomaines: 0,
      domaines: [],
      nbUsers: 0,
      nomModify: "",
      prenomModify: "",
      nomEntrepriseModify: "",
      telEntrepriseModify: "",
      descEntrepriseModify: "",
      subEntrepriseModify: false,
      notifEntrepriseModify: false,
      dialogProfil: false,
      limitPoint: 1000,
      dialogMinized: false,
      bigAssistant: false,
      colorsEssences: [],
      colorsAltitude: [],
      colorsHauteur: [],
      colorsPentes: [],
      viewItems: [
        { title: 'Le cépage', value: 'cepage' },
        { title: "L'état", value: 'etats' },
        { title: "Domaine", value: 'domaine' },
        { title: "Parcelle", value: 'parcelle' },
        { title: "Hauteur", value: 'hauteur' },
        { title: "Altitude", value: 'altitude' },
        { title: "Pente (%)", value: 'pente' },
      ],

      yearRange: [new Date().getFullYear() - 10, new Date().getFullYear()],
      currentYear: new Date().getFullYear(),
      minYear: new Date().getFullYear() - 10,
      yearLabels: [],
      markers: [],
      postits: [],
      switchYear: false,
      yearFilter: 6,
      yearOptions: [
        { text: 'Cette année', value: 0 },
        { text: 'Au dernier trimestre', value: -1 },
        { text: 'L\'an passé', value: 1 },
        { text: 'Les 3 dernières années', value: 2 },
        { text: 'Moins de 5 ans', value: 3 },
        { text: 'Moins de 8 ans', value: 4 },
        { text: 'Moins de 15 ans', value: 5 },
        { text: 'Toutes', value: 6 }
      ],
      monthRange: [1, 12],
      monthLabels: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      dialogFilterModal: false,
      draggingDialog: false,
      dragging: false,
      dialogPosition: { x: 0, y: 0 },
      inventairesCombined: [],
      drawerRightInventaires: false,
      drawerRightInventairesShared: false,
      drawerRightParcelles: false,
      drawerCadastre: false,
      drawerRightInventairesAdd: false,
      drawerRightInventairesAddAnother: false,
      drawerRightProfil: false,
      drawerRightExport: false,
      drawerRightDessinExport: false,
      operateurs: [],
      dates: [],
      devices: [],
      cepages: [],
      domaines: [],
      etats: [],
      lieuxDits: [],
      tickeTemp: 0,
      ticksLabel: ["1J", "7J", "1M", "M"
        , "1A", "-1A"],
      selectEssence: null,
      drawerRight: false,
      drawerRightTempo: false,
      dialogAssistant: false,
      steps: [
        {
          target: '#v-step-0',
          header: {
            title: 'Retrouvez vos inventaires',
          },
          params: {
            placement: 'left'
          },
          content: 'Gérer dans le menu vos inventaires',
        },
        // {
        //   target: '#v-step-1',  // We're using document.querySelector() under the hood
        //   header: {
        //     title: 'Vos filtres',
        //   },
        //   params: {
        //     placement: 'left'
        //   },
        //   content: 'Personnalisez vos filtres ici',
        // }
      ],
      dialogPeople: false,
      btnParcelle: false,
      rulesUpload: [
        value => {
          return !value || !value.length || value[0].size < 2000000 || 'Avatar size should be less than 2 MB!'
        },
      ],
      poSnack: 'top',
      snackIcon: 'mdi-alert',
      actionMarker : 'point',
      timeout: 4000,
      colorSnack: 'secondary',
      dialogInventaireAdd: false,
      fileSizeError: false,
      dialogUpload: false,
      dialogMedias: false,
      dialogTodos: false,
      dialogInventaireLight: false,
      dialogAddPoints: false,
      dialogStats: false,
      dialogPhitos: false,
      dialogCalculator: false,
      dialogAllInventaires: false,
      confEssence: '',
      dialogDelete: false,
      dialogEdit: false,
      viewInventaire: 'inventaire',
      alreadyLoad: false,
      loading: false,
      selectedInventaire: "",
      filterEssence: [],
      initialDatas: [],
      emailRules: [
        (v) => !!v || "L'e-mail obligatoire",
        (v) => /.+@.+\..+/.test(v) || "L'e-mail doit être valide",
      ],
      emailsend: '',
      descsend: '',
      copysend: false,
      dialogSendEmail: false,
      urlDownload: '',
      validSend: false,
      tasks: [],
      filterQualite: [],
      filterHauteur: [1, 60],
      filterDiametre: [1, 99],
      dialogFilter: false,
      allessences: [
        "Absent",
        "Apo Part",
        "Apoplexie",
        "ESCA/BDA Part",
        "ESCA/BDA Totale",
        "Fil Cassé",
        "Maladie Bois",
        "Manquant",
        "Mort",
        "Indemne",
        "Jaunisse",
        "Jeune",
        "Piquet",
        "Virose",

      ],
      allqualites: [
        'Chardonnay',
        'Pinot Noir',
        'Gamay',
        'Aligoté',
        'Sauvignon',
      ],
      inventairesSearch: [],
      inventairesSearchShared: [],
      secteursShared: [],
      diagShared: false,
      loadGeoloc: true,
      dialogLoad: false,
      connected: localStorage.getItem("user"),
      valid: false,
      dialog: false,
      nameLoc: null,
      geoloc: { lat: 45.764043, lng: 4.835659 },
      essences: [],
      dialogFilter: false,
      nomInventaire: "",
      geoInventaire: null,
      selectedFile: null,
      dateInventaireFr: null,
      dateInventaire: new Date().toISOString().substr(0, 10),
      rules: [
        value => {
          if (value) return true
          return 'You must enter a inventaire.'
        },
      ],
      dialogInventaire: false,
      logoFile: null,
      loaderPdf: false,
      nameRules: [(v) => !!v || 'Champs est requis', (v) => v.length <= 2 || 'Champsd moins de 2 caractères'],
      arbre: {
        id: null,
        qualite: '',
        essence: '',
        diametre: '',
        hauteur: '',
        volume: '',
        hauteurmoyenne: '',
        tarif: '',
      },
      dropzoneOptions: {
        url: 'https://cartovia.fr/api/upload',
        thumbnailWidth: 80,
        autoProcessQueue: false,
        maxFilesize: 4,
        acceptedFiles: 'text/csv',
        dictDefaultMessage: "<i class='mdi mdi-upload'></i>Télécharger votre inventaire au format CSV"
      },
      selectedInventaireObj: {},
      parcelles: [],
      traits: [],
      galleries: [],
      arrows: [],
      zones: [], 
      licenses: [],
      licensesTwo: [],
      dialogParcelleSelected: false,
      parcelleSelectedData: {},
      inventaire: null,
      license: '',
      verifyRes: '',
      queryTerm: '',
      valid: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      dateFormatted: vm.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      ),
      headers: [
        {
          text: 'UID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        { text: 'Domaine', value: 'domaine' },
        { text: 'Parcelle', value: 'parcelle' },
        { text: 'Sous-parcelle', value: 'sous_parcelle' },
        { text: 'Cépage', value: 'cepage' },
        { text: 'Date', value: 'date' },
        { text: 'Operateur', value: 'operateur' },
        { text: 'Device', value: 'device' },
        { text: 'Placette', value: 'placette' },
        { text: 'Essence', value: 'essence' },
        { text: 'État', value: 'etat' },
        {
          text: 'Action',
          value: 'action',
          align: 'end',
          sortable: false,
        },
      ],
      essencesFilters: [],
      user: {
        nom: '',
        prenom: '',
        email: '',
        password: '',
        idDevice: '',
        entreprise: '',
        statut: true,
        token: '',
      },
      rules: {
        required: (value) => !!value || 'Obligatoire.',
        min: (value) => (value && value.length > 3) || 'Pas assez long',
        password: (value) => (value && value.length > 7) || 'Pas assez long',
        email: (value) => email.test(value) || 'E-mail non valide',
        long: (value) => long.test(value) || 'Non valide',
        repass: (value) => (value && value.length > 7 && this.manager.password == value) || 'Confirmation non valide',
      },
      action: 'new',
      nomInventaire: '',
      descriptionInventaire: '',
      dialogManager: false,
      snackbar: false,
      dialog: false,
      snack: 'Votre compte a bien été activé',
      confirm: false,
      users: [],
      styles: {
        type: Object,
        default: () => { },
      },
      plugins: {
        type: Object,
        default: () => { },
      },
      areaData: {
        datasets: [
          { fill: 'origin' }, // 0: fill to 'origin'
          { fill: '+2' }, // 1: fill to dataset 3
          { fill: 1 }, // 2: fill to dataset 1
          { fill: false }, // 3: no fill
          { fill: '-2' }, // 4: fill to dataset 2
          { fill: { value: 25 } }, // 5: fill to axis value 25
        ],
      },
      parcellesAll: [],
      toggleAddBtn: false,
      galleries: [],
      draw: null,
      invId: null,
      dialogInventaireFly: false,
      snackbarLauch: false,
      currentShape: null,
      nbInvs: 1,
      crossHairsLeft: 0,
      crossHairsTop: 0,
      moreFilters: false,
      invTitle: "",
      inventairesAll: [],
      readoOnly: false,
    };
  },

  async created() {

    // window.addEventListener('online', this.handleNetworkChange);
    // window.addEventListener('offline', this.handleNetworkChange);

    // https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/polygon((4.8357,45.7640),(4.8358,45.7641),(4.8359,45.7642),(4.8360,45.7643),(4.8357,45.7640))/auto/600x600?access_token=pk.eyJ1IjoidGFpd2E2OSIsImEiOiJjbHR5aXlpYWowaHEyMmlvamV2Z2h2M25zIn0.AO8gp7k8K94wLW7sTlGlDA
    // this.$store.dispatch('toggleToolbar', false);
    // if (!this.isMobileDevice) {
    //   this.$store.dispatch('toggleToolbar', true);
    // }
    this.createYearLabels();
    this.colorsEssences = colors
    this.colorsAltitude = altitudes
    this.colorsHauteur = hauteurs
    this.colorsPentes = pentes

    let user = localStorage.getItem("user");
    if (user) {
      this.user = JSON.parse(user)
      console.log("this.user reader...", this.user.reader);


      if (this.user.displayName) {
        this.user.displayName = this.user.displayName
        this.nomModify = this.user.displayName
      } else {
        this.user.displayName = this.user.name
        this.nomModify = this.user.name
      }
      this.nomEntrepriseModify = this.user.nameCompagny
      this.telEntrepriseModify = this.user.telCompagny
      this.logoFile = this.user.logoCompagny

      this.descEntrepriseModify = this.user.description
      this.subEntrepriseModify = this.user.newsletter
      this.notifEntrepriseModify = this.user.notification

      let inventairesAll = await xhr.post('/inventaires-all', { user: this.user.uid });
      this.inventairesAll = inventairesAll.data


      let parcellesAll = await xhr.post('/parcelles-all', { user: this.user.uid });
      this.parcellesAll = parcellesAll.data

      this.inventairesSearch = inventairesAll.data.map((e) => {
        let mom = moment(e.dateInventaire)
        return {
          id: e._id,
          parcelles: e.parcelles,
          members: e.members,
          tasks: e.tasks,
          domaine: e.domaine,
          name: e.name,
          dateInventaire: mom.format('DD/MM/YYYY'),
          geo: e.geo,
          description: e.description
        }
      })
    }

    
      if(this.user.reader == true && !this.$route.params.id){
        this.$store.dispatch('toggleToolbar', true);
      }

    if (this.$route.params.id) {
      if (this.$route.params.readonly && this.$route.params.readonly == 'readonly' && this.$route.params.uid && this.$route.params.id) {

        this.readOnly = true
        let user = await xhr.post('/load-user', {
          uid: this.$route.params.uid
        })
        this.user = user.data

        if (this.user.displayName) {
          this.user.displayName = this.user.displayName
          this.nomModify = this.user.displayName
        } else {
          this.user.displayName = this.user.name
          this.nomModify = this.user.name
        }
        this.nomEntrepriseModify = this.user.nameCompagny
        this.telEntrepriseModify = this.user.telCompagny

        this.descEntrepriseModify = this.user.description
        this.subEntrepriseModify = this.user.newsletter
        this.notifEntrepriseModify = this.user.notification

        let inventairesAll = await xhr.post('/inventaires-all', { user: this.user.uid });
        this.inventairesAll = inventairesAll.data


        let parcellesAll = await xhr.post('/parcelles-all', { user: this.user.uid });
        this.parcellesAll = parcellesAll.data

        this.inventairesSearch = inventairesAll.data.map((e) => {
          let mom = moment(e.dateInventaire)
          return {
            id: e._id,
            name: e.name,
            dateInventaire: mom.format('DD/MM/YYYY'),
            geo: e.geo,
            description: e.description
          }
        })

      }

        if ( this.user.reader == true) {
          this.snack = "Vous êtes invité sur cet inventaire à contribuer 😊"
          this.snackbar = true
        }


      this.invId = this.$route.params.id
      let inventaire = {}
      try {
        inventaire = await xhr.post('/load', {
          inventaire: this.$route.params.id,
          uid: this.user.uid,
          email: this.user.email
        });
        await this.load(inventaire.data)

        // this.viewItem = inventaire.data.profil == "forestier" ? "essence" : "etat"
      } catch (error) {
        this.snack = "Vous n'êtes pas autoriser sur cet inventaire"
        this.snackbar = true
        // this.$router.push('/')
      }

    }
    this.loadEssences();
    await this.loadShared()

    await this.loadTasks()
    await this.loadPhitos()
    await this.loadUsers()
    await this.loadDomaines()

  },
  async mounted() {

     
    if (this.$route.query.invited === "true") {
      this.snack = "Vous êtes invité sur cet inventaire à contribuer 😊"
      this.snackbar = true
    }

    

    if (this.$route.query.confirmed === "true") {
      this.snack = "Bienvenue sur Cartovia 😊"
      this.snackbar = true
      this.snackIcon = "mdi-human-greeting"
      this.$store.dispatch('toggleToolbar', true);

      // setTimeout(() => {
      //   this.$tours['myTour'].start()
      // }, 2000)
    }
  },
  methods: {

    async syncInvs(){
      let conf = confirm('Voulez-vous synchroniser votre inventaire ?')
      if(conf){
        console.log("this.$refs.inventaire.savePoints", this.$refs.inventaire.savePoints);
        // this.$refs.inventaire.savePoints

         let inventairesAll = await xhr.post('/sync-inv-user', { 
          user: this.user.email, 
          invId: this.selectedInventaire,
          allPoints: this.$refs.inventaire.savePoints
        });

        if(inventairesAll.data == true){
          await this.refreshPoint()
          this.snack = "Votre inventaire est synchronisé 🪄"
          this.snackbar = true
          this.snackIcon = "mdi-web-sync"
          this.nbEmptyPoints = 0
          this.$refs.inventaire.resetNew()
          this.$store.dispatch('toggleToolbar', false);

        }


      } 
    },

    async handleNetworkChange(){
      if( this.toggleAddBtn == false){
          let inventairesAll = await xhr.post('/inventaires-all', { user: this.user.uid });
          this.toggleAddBtn = true
          console.log("Push differed", inventairesAll);
      }
    },

    async finishG(){
      
      let response = await xhr.post('/add-grille', {
        invId: this.selectedInventaire,
        parallelLines: this.$refs.inventaire.parallelLines,
        allPoints: this.$refs.inventaire.allPoints,
        debut:  this.$refs.inventaire.traitOne,
        fin:  this.$refs.inventaire.traitTwo,
        grille: this.$refs.inventaire.grille,
      });

      
    },

    async viewSymptomes(){
      //this.statsEssences = []
      this.essencesFilters = []
      this.viewItem = "symptomes"
    },
    async closeSetting(){
      this.dialogGrille = false
    },
    async openSetting() {
      this.dialogGrille = true
    },
    async addGrille(){
      this.$refs.inventaire.addFirstTrait()
      this.$store.dispatch('toggleToolbar', false);
       this.snack = "Tracer les 2 lignes de contours"
      this.snackbar = true
      this.snackIcon = "mdi-land-fields"
    }, 
    async addPoint(){
      this.$refs.inventaire.addPoint()
      this.$store.dispatch('toggleToolbar', false);
       this.snack = "Pointer du doigt sur la parcelle"
      this.snackbar = true
      this.snackIcon = "mdi-cursor-pointer"
    },
    async addPointLight(){
      this.actionMarker = "point"
      this.$refs.inventaire.addPointLight(false)
      this.$store.dispatch('toggleToolbar', false);
       this.snack = "Pointer du doigt sur la parcelle"
      this.snackbar = true
      this.snackIcon = "mdi-cursor-pointer"
    },
    async addAlertLight(){
      this.actionMarker = "alert"
      this.$refs.inventaire.addAlertLight()
      this.$store.dispatch('toggleToolbar', false);
       this.snack = "Pointer du doigt sur la parcelle"
      this.snackbar = true
    },
    async addArrowLight(){
      //this.$refs.inventaire.addArrowLight()
      this.actionMarker = "arrow"
      this.arrowSensC++
      if(this.arrowSensC == 1){
        this.arrowSens = "mdi-arrow-right"
      } else if(this.arrowSensC == 2){
         this.arrowSens = "mdi-arrow-bottom-right"
      } else if(this.arrowSensC == 3){
         this.arrowSens = "mdi-arrow-down"
      }
      else if(this.arrowSensC == 4){
         this.arrowSens = "mdi-arrow-bottom-left"
      }
      else if(this.arrowSensC == 5){
         this.arrowSens = "mdi-arrow-left"
      }
      else if(this.arrowSensC == 6){
         this.arrowSens = "mdi-arrow-top-left"
      }
      else if(this.arrowSensC == 7){
         this.arrowSens = "mdi-arrow-up"
      }
      else if(this.arrowSensC == 8){
         this.arrowSens = "mdi-arrow-top-right"
      }
      else{
          this.arrowSensC = 1
          this.arrowSens = "mdi-arrow-right"
      }


      this.$refs.inventaire.addArrowLight(this.arrowSens)



      this.$store.dispatch('toggleToolbar', false);
      this.snack = "Pointer du doigt sur la parcelle"
      this.snackbar = true
    },
     async getSvgContent(type, color) {
      const response = await import(`@/assets/svg/${type}.svg?raw`);
      let res = await response.default

         // Modifier la couleur du stroke
      let svgContent = res
      return svgContent
    },
      async getArrowSvgContent(type, color) {
        const response = await import(`@/assets/arrows/arrow${type}.svg?raw`);
        let res = await response.default
          // Modifier la couleur du stroke
        let svgContent = res
      return svgContent
    },

    async getImageSrc(type) {
      // let icon = await  import(`@/assets/svg/${type}.svg`).default;
      // console.log(icon, "icon");

      const response = await import(`@/assets/svg/${type}.svg?raw`);
      let svgContent = response.default;

         // Convertir le contenu SVG en un Blob et créer une URL d'objet
      const blob = new Blob([svgContent], { type: 'image/svg+xml' });
      return URL.createObjectURL(blob);

      // return svgContent
    },
    deletePostit(postit){
      let conf = confirm("Voulez-vous supprimer cette note ?")
      if(conf == true){
        this.$refs.inventaire.deletePostit(postit)
      }
    },
    isFilterPercelle(parcelle){
      const parcelleName = parcelle.features[0].properties.name;
      const index = this.filtParcelles.indexOf(parcelleName);

      if (index === -1) {
        return false
      } else {
      return true
      }
    },
    visibleParcelle(parcelle){
      const parcelleName = parcelle.features[0].properties.name;
      const index = this.filtParcelles.indexOf(parcelleName);

      if (index === -1) {
        this.filtParcelles.push(parcelleName);
      } 
    },
    invisibleParcelle(parcelle){
      const parcelleName = parcelle.features[0].properties.name;
      const index = this.filtParcelles.indexOf(parcelleName);

      if (index !== -1) {
         this.filtParcelles.splice(index, 1);
      }
    },
    async getIconPath(type) {
       try {
        // Utilisation de require pour charger dynamiquement le fichier SVG
         return (`<%= BASE_URL %>/svg/${type}.svg`);
      } 
      catch (e) {
        console.error(`Icon not found: ${type}`);
      }
    },
    async addPicto(type){
       this.$refs.inventaire.addPicto(type)
    },  
    async removeTrait(trait){
      let conf = confirm('Ete-vous sûre de supprimer ce trait ?')
      
      if(conf == true){
          let res = await xhr.post('/delete-trait', {
            invt: this.selectedInventaire,
            trait,
          });
        this.snack = "Votre trait a bien été supprimé"
        this.snackbar = true
        this.snackIcon = "mdi-human-greeting"
        this.traits = res.data

        this.$refs.inventaire.clearMap()
      
      }
    
    },

    async removeGallerie(gallerie){
      let conf = confirm('Ete-vous sûre de supprimer cette gallerie ?')
      
      if(conf == true){
          let res = await xhr.post('/delete-gallerie', {
            invt: this.selectedInventaire,
            gallerie,
          });
        this.snack = "Votre gallerie a bien été supprimé"
        this.snackbar = true
        this.snackIcon = "mdi-human-greeting"
        this.traits = res.data

        this.$refs.inventaire.clearMap()
      }
    },

    async goGallerie(gallerie){
     
        this.$refs.inventaire.goGeoloc(gallerie.longitude, gallerie.latitude)
    },

    async removeMarker(marker){
       let conf = confirm('Voulez-vous supprimer ce marqueur ?')
      if(conf == true){
          this.$refs.inventaire.deleteMarker(marker)
        }
    },

    async removeArrow(arrow){
       let conf = confirm('Voulez-vous supprimer cette flèche ?')
      if(conf == true){
        this.$refs.inventaire.deleteArrow(arrow._id)
        }
    },

    async sendRapport(){
       let response = await xhr.post('/send-rapport', {
          invId: this.selectedInventaire,
          email: this.emailsend,
          copysend: this.copysend,
          emailsender: this.user.email,
        });

        if (response.data && response.data == true) {
            this.dialogSendEmail = false
            this.snack = "Votre rapport a été envoyé par e-mail"
            this.snackbar = true
            this.snackIcon = "mdi-human-greeting"
        }
    },
    goInv(inv) {
      this.dialogDomaines = false
      this.$router.push('/inventaire/' + inv._id)
    },
    ...mapActions(['updateName', 'updateNameCompagny', 'updateTel']),
    getParcelleType(parcelle) {
      return this.parcellesTypes.find((e) => e.value == parcelle) || {}
    },
    removeParcelle(parcelle) {
      let conf = confirm('Voulez-vous supprimer cette parcelle ?')
      if (conf) {
        this.$refs.inventaire.removeParcelle(parcelle)
        this.dialogParcelleSelected = false
        this.$refs.inventaire.clearMap()
      }
    },
    editParcelle(parcelle) {
      this.$refs.inventaire.editParcelle(parcelle)
      this.dialogParcelleSelected = false
      this.$refs.inventaire.clearMap()
    },
    redrawParcelle(parcelle) {
      this.dialogParcelleSelected = false
      setTimeout(()=>{
        this.$refs.inventaire.drawParcelle(parcelle)
      }, 200)
    },
    async exportExcel() {
      try {
        let response = await xhr.post('/export-csv', {
          inventaires: this.filterEssences
        });

        if (response.data && response.data.url) {

          window.open(response.data.url, '_blank');

        } else {
          console.error('No URL provided for downloading CSV');

        }

      } catch (error) {
        console.error('Failed to export CSV:', error);

      }
    },

    async takeSnapStats() {
      let prom = await this.$refs.stats.getStatsMap()
      let res = await prom;

      const url = URL.createObjectURL(res);
      const link = document.createElement("a");
      link.href = url;
      let now = new Date();
      now = now.toLocaleDateString("fr")
      link.download = `export-carte-${now.toString()}.png`;
      link.click();
    },
    async getLegendParcellesMap() {
      try {
        if (this.$refs.parcelles_box) {

          const element = this.$refs.parcelles_box.$el; // Accéder à l'élément DOM

          // Capturer l'élément avec html2canvas
          const canvas = await html2canvas(element, {
            // backgroundColor: null // Assurer la transparence
          });

          // Convertir le canvas en blob
          const blob = new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
              if (blob) {
                resolve(blob);
              } else {
                reject(new Error("Failed to convert canvas to blob."));
              }
            }, 'image/png');
          });

          // 

          return blob
        }
        return null
        // Ici, vous pouvez décider de télécharger le blob, l'afficher ou le transmettre ailleurs
      } catch (error) {
        console.error("Error capturing element:", error);
      }

    },
    async getStatsMap() {
      try {
        if (this.$refs.stats) {
          const element = this.$refs.stats.$el; // Accéder à l'élément DOM

          // Capturer l'élément avec html2canvas
          const canvas = await html2canvas(element, {
            // backgroundColor: null // Assurer la transparence
          });
          // Convertir le canvas en blob
          const blob = new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
              if (blob) {
                resolve(blob);
              } else {
                reject(new Error("Failed to convert canvas to blob."));
              }
            }, 'image/png');
          });

          return blob
        }

        return null;
        // Ici, vous pouvez décider de télécharger le blob, l'afficher ou le transmettre ailleurs
      } catch (error) {
        console.error("Error capturing element:", error);
      }
    },
    async getTimelineMap() {
      try {
        if (this.$refs.todos.$refs.timeline) {
          const element = this.$refs.todos.$refs.timeline.$el; // Accéder à l'élément DOM

          const canvas = await html2canvas(element, {
            // backgroundColor: null // Assurer la transparence
          });


          // Convertir le canvas en blob
          const blob = new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
              if (blob) {
                resolve(blob);
              } else {
                reject(new Error("Failed to convert canvas to blob."));
              }
            }, 'image/png');
          });

          return blob
        }

        return null;
        // Ici, vous pouvez décider de télécharger le blob, l'afficher ou le transmettre ailleurs
      } catch (error) {
        console.error("Error capturing element:", error);
      }
    },
    async getCalendarMap() {
      try {
        if (this.$refs.cal.$refs.calendar) {
          const element = this.$refs.cal.$refs.calendar.$el; // Accéder à l'élément DOM

          const canvas = await html2canvas(element, {
            // backgroundColor: null // Assurer la transparence
          });


          // Convertir le canvas en blob
          const blob = new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
              if (blob) {
                resolve(blob);
              } else {
                reject(new Error("Failed to convert canvas to blob."));
              }
            }, 'image/png');
          });

          return blob
        }

        return null;
        // Ici, vous pouvez décider de télécharger le blob, l'afficher ou le transmettre ailleurs
      } catch (error) {
        console.error("Error capturing element:", error);
      }
    },

    async generatePdf() {
      this.loaderPdf = true
      this.dialogStats = true
      this.dialogTodos = true
      this.dialogCalendar = true

      setTimeout(async () => {
        try { 
          let large_map = this.itemsOptionsPdf.find((e) => e.value == "large_map").checked
          let statistics = this.itemsOptionsPdf.find((e) => e.value == "statistics").checked
          let monthly_task = this.itemsOptionsPdf.find((e) => e.value == "monthly_task").checked

          let blob = null
          let blobLegend = null // await this.$refs.inventaire.getLegendMap()
          let blobParcelles = null //  await this.getLegendParcellesMap()
          let blobStats =  null // await this.getStatsMap()
          let blobCalendar = null //  await this.getCalendarMap()

          if (large_map != false){
            blob = await this.$refs.inventaire.getPhotoMap()
            blobParcelles =  await this.getLegendParcellesMap()
            blobLegend = await this.$refs.inventaire.getLegendMap()
          }

          if (statistics != false){
            blobStats = await this.getStatsMap()
          }

          if (monthly_task != false){
            blobCalendar =   await this.getCalendarMap()
          }
          
          // let blobTimeline = await this.getTimelineMap()  // //  
          
          let formData = new FormData();
          formData.append("uid", this.user.uid);
          formData.append("descsend", this.descsend);
          formData.append("invId", this.selectedInventaire);
          formData.append("file", blob, "map.png");
          if (blobLegend)
            formData.append("stat", blobLegend, "stat.png");

          if (blobParcelles)
            formData.append("parcelles", blobParcelles, "parcelles.png");

          if (blobCalendar)
            formData.append("calendar", blobCalendar, "calendar.png");

          if (blobStats)
            formData.append("stats", blobStats, "stats.png");

          // if (blobTimeline)
          //   formData.append("timeline", blobTimeline, "blobTimeline.png");

          let response = await xhr.post('/generate-pdf',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });

          if (response.data && response.data.path) {
            this.loaderPdf = false
            this.dialogStats = false
            this.dialogTodos = false
            this.dialogCalendar = false
            this.dialogStats = false
            this.dialogTodos = false
            this.urlDownload = response.data.path
            // this.dialogSendEmail = true

            this.sendRapport()
          }
        } catch (error) {
          console.log("error", error);
        }

      }, 150)
    },
    getAOC() {
      this.$store.dispatch('toggleToolbar', false);
      this.$refs.inventaire.loadJSON()
      this.$refs.inventaire.selectPointAOC()
    },
    async updateFormule() {
      let ok = await xhr.post('/update-volume', {
        inventaireId: this.selectedInventaire,
        formula: this.formuleCalc
      });

      this.snack = "Votre volume a bien été modifié ⚡"
      this.snackIcon = "mdi-check-circle"
      this.snackbar = true

      this.methodeDeRafraichissement()
    },
    searchVilles(val) {
      // let val = this.filterCadastre
      if (val && val.length > 2) { // Commence la recherche après 2 caractères
        const url = `https://geo.api.gouv.fr/communes?nom=${encodeURIComponent(val)}&fields=nom,code,departement&boost=population&limit=5`;
        fetch(url)
          .then(response => response.json())
          .then(data => {
            this.villesAutocomplete = data;
          });
      }
    },
    onSelectCity(item) {

      let url = `https://apicarto.ign.fr/api/cadastre/feuille?code_insee=${encodeURIComponent(item.code)}`;
      fetch(url)
        .then(response => response.json())
        .then(data => {
          // 
          this.$refs.inventaire.addFeuille(data)
        });

      url = `https://apicarto.ign.fr/api/cadastre/parcelle?code_insee=${encodeURIComponent(item.code)}`;
      fetch(url)
        .then(response => response.json())
        .then(data => {
          // 
          this.$refs.inventaire.addCadastre(data)
        });

    },
    deselectCadastre() {
      this.filterCadastre = null
      this.$refs.inventaire.deselectCadastre()
    },
    geolocation() {
      this.dialogPhitos = false
      this.$refs.inventaire.geoloca()
    },
    nextphito(coords) {
      this.dialogPhitos = true
      this.$refs.phito.nextstep(coords)
    },

    modifyPoint(item) {
      this.$refs.inventaire.modifyPoint({
        long: item.longitude,
        lat: item.latitude
      }, null, item)
    },
    openDraw() {
      this.$store.dispatch('toggleToolbar', true);
    },
    takePhoto() {
      this.$store.dispatch('toggleToolbar', false);
      this.$refs.inventaire.takePhoto()
    },
    openPointsAdd() {
      this.dialogInventaireAdd = true
      this.$store.dispatch('toggleToolbar', false);
    },
    removeInvt() {
      this.dialogDelete = true
      this.$store.dispatch('toggleToolbar', false);
    },
    async loadDomaines() {
      let domaines = await xhr.post('/load-domaines', {
        uid: this.user.uid
      });
      this.nbDomaines = domaines.data.length;
    },

    async loadShared(){
      let shared = await xhr.post('/load-shared', {
        email: this.user.email
      });
      if(shared.data){
        this.inventairesSearchShared = shared.data.final;
      }
       
    },
    async loadPhitos() {
      let phytos = await xhr.post('/load-phito', {
        uid: this.user.uid
      });
      this.nbPhytos = phytos.data.length;
    },

    async loadCommunesShared(){
      let communes = await xhr.post('/load-communes-shared', {
        email: this.user.email,
      });
      this.secteursShared = communes.data;
      console.log("this.secteursShared", this.secteursShared);
    },
    async loadUsers() {
      let users = await xhr.post('/load-members', {
        uid: this.user.uid,
      });
      this.operateurs = users.data
      this.nbUsers = users.data.length;
    },
    async refreshTasks() {
      await this.loadTasks()
    },
    async loadTasks() {
      let tasksdata = await xhr.post('/get-tasks', {
        uid: this.user.uid,
      });
      if (tasksdata.data) {
        this.tasks = tasksdata.data
      }
    },
    openCal() {
      this.dialogTodos = true
      this.$store.dispatch('toggleToolbar', false);
    },
    openPhito() {
      this.$store.dispatch('toggleToolbar', false);
      this.dialogPhitos = true
    },
    openDomaines() {
      this.$store.dispatch('toggleToolbar', false);
      this.dialogDomaines = true
    },
    openTeam() {
      this.$store.dispatch('toggleToolbar', false);
      this.dialogTeam = true
    },
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    finishedTask(mess = "Votre tâche a bien été ajoutée ⚡") {
      // this.dialogTodos = false
      this.snack = mess
      this.snackIcon = "mdi-check-circle"
      this.snackbar = true
    },
    async closeModal() {
      this.$store.dispatch('toggleToolbar', false);
    },
    async traceIti() {

      this.$refs.inventaire.traceIti()
      this.$store.dispatch('toggleToolbar', false);
    },
    async importInvt() {
      try {
        let lic = await xhr.post("/import-invt", {
          uid: this.user.uid,
          email: this.emailImport,
          invt: this.invtImport
        })

        if (this.$route.params.id) {
          this.inventairesCombined = [...this.inventairesCombined, lic.data]
        } else {
          this.$router.push({ name: 'Detail', params: { id: lic.data._id } })
        }
        this.$refs.inventaire.moveTo()
        this.$store.dispatch('toggleToolbar', false);

      } catch (error) {
        this.snack = "Vérifier le nom de l'inventaire ainsi que son e-mail"
        this.snackbar = true
      }

    },
    changeToolbar(value) {
      if (value == false) {
        this.$store.dispatch('toggleToolbar', false);
      }
    },

    addDessin() {
      this.drawerRightDessinExport = true
    },
    addTrait(){
      this.$store.dispatch('toggleToolbar', false);
      this.$refs.inventaire.addTrait()
    },
    addNote(){
      this.$store.dispatch('toggleToolbar', false);
      this.$refs.inventaire.addNote()
    },
    addPhotos(){
      this.$store.dispatch('toggleToolbar', false);
      this.$refs.inventaire.addPhotos()
    },
    addFleche(){
      this.$store.dispatch('toggleToolbar', false);
      this.dialogTraitsFix = false
      this.dialogParcelleFix = false
      this.$refs.inventaire.addFleche()
    },
    modifyFleche(){
      this.$store.dispatch('toggleToolbar', false);
      this.$refs.inventaire.modifyFleche()
    },

    getTimeline() {
      this.dialogTimeline = true
    },
    getCalendar() {
      this.dialogCalendar = true
      if (this.$refs.calendar) {
        this.$refs.calendar.loadEvent()
      }
    },
    deselectParcelle() {
      // this.resetInv()
      this.dialogParcelleSelected = false;

      this.selectedParcelleIndex = null;
      this.$refs.inventaire.deselectParcelle()

    },
    async selectParcelle(parcelle, index) {
      
      // this.resetFilter()

      this.selectedParcelleIndex = index;
      this.inventairesCombined = this.initialDatas

      //  let area = turf.area(poly);
      let poly = turf.polygon(parcelle.features[0].geometry.coordinates);
      let area = turf.area(poly);
      const hectares = (area / 10000).toFixed(2);


      this.$refs.inventaire.selectOneParcelle(parcelle, "lay-fill-source-" + index, this.initialDatas)


      setTimeout(async () => {
        try {
          let res = await xhr.post('/get-parcelle-data', {
            invId: this.selectedInventaire,
            parcelle: parcelle.features[0].properties,
            hectares,
            nbArbres: this.inventairesCombined[0].inventaires.length,
            nbArbresTotal: this.initialDatas[0].inventaires.length
          })

          if (res.data) {
            this.parcelleSelectedData = res.data
            this.dialogParcelleSelected = true;

          }
        } catch (error) {

        }
      }, 100)


    },
    async sendedUsers(users) {
      let res = await xhr.post("/invite-inventaire", {
        id: this.invId,
        uid: this.user.uid,
        users
      })

      if (res.data == true) {
        this.dialogPeople = false
        this.snack = "Vos demandes d'invitations à contributions sont parties !"
        this.snackbar = true
      }
    },
    toggleEditable() {
      this.isEditable = true;
      this.$nextTick(() => {
        this.$refs.editableLink.focus();
      });
    },
    toggleEditableDate() {
      this.isEditableDate = true;
      this.$nextTick(() => {
        this.$refs.editableLinkDate.focus();
      });
    },
    async saveContent() {
      const editedContent = this.$refs.editableLink.textContent;
      this.isEditable = false; // Désactivez le mode édition
      await xhr.post("/rename-inventaire", {
        id: this.$route.params.id,
        name: editedContent
      })
      this.snack = "Le nom de l'inventaire a bien été mise à jour"
      this.snackbar = true
    },
    async saveContentDate() {
      this.isEditableDate = false; // Désactivez le mode édition
      await xhr.post("/date-inventaire", {
        id: this.$route.params.id,
        date: this.dateInventaireFr
      })
      this.snack = "La date de l'inventaire a bien été mise à jour"
      this.snackbar = true
      this.selectedInventaireObj.dateInventaire = this.dateInventaireFr
    },
    formatPhoneNumber() {
      // Retirez tout ce qui n'est pas un chiffre
      let numbers = this.telEntrepriseModify.replace(/\D/g, '');
      // Réinitialisez la valeur formatée
      let formatted = '';
      // Découpez et ajoutez des espaces selon le format français standard
      // Exemple: 06 01 02 03 04
      numbers = numbers.substring(0, 10); // Assurez-vous de ne pas dépasser 10 chiffres

      for (let i = 0; i < numbers.length; i++) {
        if (i !== 0 && i % 2 === 0) formatted += ' '; // Ajoute un espace tous les 2 chiffres
        formatted += numbers[i];
      }

      // Mettez à jour le modèle avec la valeur formatée
      this.telEntrepriseModify = formatted;

      this.updateTel(this.telEntrepriseModify);
    },
    async modifyProfil() {

      let formData = new FormData(); // Créer un objet FormData pour contenir les données du formulaire
      // Ajouter les champs de texte
      
      formData.append('uid', this.user.uid);
      formData.append('name', this.nomModify + " " + this.prenomModify);
      formData.append('nameCompagny', this.nomEntrepriseModify);
      formData.append('telCompagny', this.telEntrepriseModify);
      formData.append('descEntrepriseModify', this.descEntrepriseModify);
      formData.append('notifEntrepriseModify', this.notifEntrepriseModify);
      formData.append('subEntrepriseModify', this.subEntrepriseModify);
      formData.append('email', this.user.email);

      // Ajouter le fichier image pour le logo de l'entreprise
    if (this.logoFile) {
      formData.append('file', this.logoFile); // 'logoCompagny' est le nom du champ attendu par le serveur pour l'image
    }

      let user = await xhr.post("/profil", formData,  
      { headers: {
        'Content-Type': 'multipart/form-data'
      } })
      if (user.data) {
        this.snack = "Votre profil a été mise à jour"
        this.snackbar = true
        this.updateName(user.data.name)
        this.updateNameCompagny(user.data.nameCompagny)
        this.updateTel(user.data.telCompagny)
        this.$store.dispatch('updateSelection', { key: 'newsletter', value: this.notifEntrepriseModify });
        this.$store.dispatch('updateSelection', { key: 'notification', value: this.subEntrepriseModify });
        localStorage.setItem("user", JSON.stringify(user.data));
        this.dialogProfil = false
      }

    },
    morePoint() {
      this.limitPoint = this.limitPoint + 1000
    },
    getMeteo() {
      this.$refs.inventaire.getMeteo()
    },
    changeView(view) {
        this.$store.dispatch('updateSelection', { key: 'viewItem', value: view });
    },
    generateRandomHexColor() {
      // Génère un nombre aléatoire entre 0 et 255 pour chaque composant de couleur
      const red = Math.floor(Math.random() * 256);
      const green = Math.floor(Math.random() * 256);
      const blue = Math.floor(Math.random() * 256);

      // Convertit chaque composant en une chaîne hexadécimale et les concatène
      return '#' + red.toString(16).padStart(2, '0') +
        green.toString(16).padStart(2, '0') +
        blue.toString(16).padStart(2, '0');
    },
    createYearLabels() {
      for (let year = this.minYear; year <= this.currentYear; year++) {
        this.yearLabels.push(year.toString());
      }
    },
    dialogFilterDrawer() {
      this.drawerRightTempo = true
    },
    dialogFilterAssistant() {
      this.dialogAssistant = true
      this.$store.dispatch('toggleToolbar', false);

    },
    removeInventaire(index) {

      this.inventairesCombined.splice(index, 1);
      if (this.inventairesCombined.length) {
        this.parcelles = []
        this.inventairesCombined.forEach((e) => {
          this.parcelles = this.parcelles.concat(e.parcelles)
        })
      }

    },

    async addInventaire(inventaire) {

      if (!this.labelCombined.includes(inventaire.name)) {

        let lic = await xhr.post("/load", { inventaire: inventaire.id, uid: this.user.uid, email: this.user.email })
        this.inventairesCombined = [...this.inventairesCombined, lic.data]
        let newInv = lic.data
        this.dateInventaireFr = null
        if (newInv.parcelles && newInv.parcelles.length) {
          this.parcelles = this.parcelles.concat(newInv.parcelles);
        }

        // this.inventaires = this.inventairesCombined.map((e) => e.inventaires).flat()
        this.nbInvs += lic.data.numberOfTableaux
        this.drawerRightInventairesAdd = false
        this.$store.dispatch('toggleToolbar', false);
      }

    },

    isActiveRoute(inventaireId) {
      // Remplacez 'routeId' par le nom de paramètre de route approprié
      return this.$route.params.id === inventaireId;
    },
    logout() {
      localStorage.clear();
      this.$router.push("/login");
    },
    moreFilter() {
      this.moreFilters = !this.moreFilters
    },
    toggleDrawer() {
      this.drawerRight = !this.drawerRight;
    },
    defineParcelle() {
      this.$store.dispatch('toggleToolbar', false);
      this.btnParcelle = true
      this.$refs.inventaire.createPoly()
    },
    confArea() {
      this.btnParcelle = false
      this.$refs.inventaire.confArea()
    },
    cancelArea() {
      this.btnParcelle = false
      this.$refs.inventaire.cancelArea()
    },
    displayFullMap() {
      this.rail = true
    },
    async methodeDeRafraichissement() {
      if (this.$route.params.id) {
        let inventaire = await xhr.post('/load', {
          inventaire: this.$route.params.id,
          uid: this.user.uid,
          email: this.user.email
        });
        await this.load(inventaire.data, 14, false)
      }
    },
    checkFileSize(event) {

      this.fileSizeError = false;
      this.selectedFile = event
    },
    async changingRoute(id) {
      this.loading = true
      let inventaire = await xhr.post('/load', {
        uid: this.user.uid,
        inventaire: id,
        email: this.user.email
      });
      await this.load(inventaire.data)

      this.loading = false
    },
    async closeMediasDialog() {
      this.snack = "Votre document a bien été uploadé"
      this.snackbar = true
      // this.dialogMedias = false
    },
    async addArbresInventaire() {

      let formData = new FormData();
      formData.append("file", this.selectedFile);
      formData.append("user", this.user.uid);
      formData.append("inv", this.selectedInventaireObj._id);

      let invt = await xhr.post('/add-inventaires', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      await this.load(invt.data)
      this.dialogInventaireLight = false

      this.snack = "Votre arbres d'inventaire ont été mise à jour"
      this.snackbar = true
    },
    async resetInv() {
      await this.load()
    },
    async changeInventaires(elts) {
      //   this.snack = `Votre parcelle ${elts.name} a été séléctionnée`
      //   this.snackbar = true
      // this.resetFilter()
     
      this.inventairesCombined = [{ inventaires: elts.selected }]
    },

    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },

    async listenFilter(essences) {
      this.essencesFilters = essences
    },

    async confSaveArea(e) {
      this.currentShape = e.currentShape

      // if(this.selectedInventaire){
      let invt = await xhr.post('/create-poly', {
        inv: this.selectedInventaire ? this.selectedInventaire : null,
        data: this.currentShape,
        user: this.user.uid,
        name: e.name,
        type: e.type,
        picto: e.picto,
        legend: e.legend,
        description: e.description,
        color: e.color.color
      })
      this.$refs.inventaire.clearMap()

      // this.resetInv()
      //await this.load(invt.data)
      // } else {
      // this.dialogInventaireFly = true
      // await xhr.post('/create-poly-anonymous', {
      //   data: this.currentShape,
      //   user: this.user.id
      // })
      // }

    },

    async editInventaire() {
      let result = await xhr.post('/editInventaire', {
        inventaire: this.selectedInventaireObj._id,
        nomInventaire: this.nomInventaire,
        descriptionInventaire: this.descriptionInventaire,
        geo: this.geoInventaire,
        dateInventaire: this.dateInventaire,
      })

      this.snack = "Votre inventaire a bien été edité"
      this.snackbar = true
      this.currentShape = null

    },

    async confDeleteInventaire() {
      let result = await xhr.post('/deleteInventaire', {
        inventaire: this.selectedInventaireObj._id
      })
      this.snack = "Votre inventaire a bien été supprimé"
      this.snackbar = true
      this.confEssence = ""
      window.location = "/"
    },

    async loadEssences() {
      // let datas =  await xhr.get('/essences')
      // this.allessences = datas.data.map((e) => e.essences)
    },

    async filterArbres() {

    },
    async changeAdress() {

      let res = await axios.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + this.geoInventaire + '&key=AIzaSyAFCIaImVWVYAWbciVU87hfC_p_Iw1J_3g&region=fr')
      if (res.data.status === "OK") {
        this.nameLoc = res.data.results[0].formatted_address
        this.geoloc = res.data.results[0].geometry.location
        this.loadGeoloc = false
        this.geoInventaire = this.nameLoc
      }
    },

    handleFileImport() {
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0];

    },
    async createInventaireFly() {
      let formData = new FormData();

      formData.append('uid', this.user.uid);
      formData.append('name', this.nomInventaire);
      formData.append('file', this.selectedFile);  // 'file' est le nom du champ pour le fichier



      let lic = await xhr.post('/upload-file-single', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      this.snackbar = true;
      this.snack = 'Votre inventaire a bien été crée';
      this.dialogInventaireFly = false
      this.selectInvt({ ...lic.data, id: lic.data._id })
    },
    async createInventaire() {
      let formData = new FormData();
      formData.append("file", this.selectedFile);
      formData.append("user", this.user.uid);
      formData.append("nomLocat", this.nomInventaire);
      formData.append("description", this.descriptionInventaire);
      formData.append("geometry", this.geoloc);
      formData.append("geoLocat", this.geoInventaire);
      formData.append("dateInventaire", this.dateInventaire);

      let lic = await xhr.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      this.snackbar = true;
      this.snack = 'Votre inventaire a bien été crée';
      this.dialogInventaire = false


      this.load(lic.data)
    },

    async closeInv() {
      window.location.href = "/"
    },

    async refreshPoint(){
      this.inventairesCombined = []
      let inventaire = await xhr.post('/load-datas', {
        inventaire: this.$route.params.id,
      });
      this.inventairesCombined = [inventaire.data]
      // this.initialDatas = [...this.inventairesCombined]
    },

    async load(inventaire, zoom = null, noZoom = true) {

      this.inventairesCombined = []
      this.parcelles = []
      this.tasks = []
      this.filterEssence = []
      this.essencesFilters = []
      this.resetFilter()
      this.viewItemsFilters = []
      this.itemsFilters = []
      this.traits = []
      this.markers = []
      this.galleries = []
      this.arrows = []
      this.zones = []
      // this.postits = []
      this.selectedInventaireObj = inventaire
      this.selectedInventaire = inventaire._id
      this.invId = inventaire._id
      this.selectedParcelleIndex = null

      this.nomInventaire = inventaire.name
      this.invTitle = inventaire.name
      this.descriptionInventaire = inventaire.description
      // if (inventaire.dateInventaire.length) {
      //   this.dateInventaire = moment(inventaire.dateInventaire).format('DD/MM/YYYY')
      // }
      this.geoInventaire = inventaire.geo
      // this.viewItem = inventaire.profil == "forestier" ? "essence" : "etat"

      if(this.user.reader == true){
        this.viewItem = 'operateur'
      }
      this.inventairesCombined = [inventaire]
      this.initialDatas = [...this.inventairesCombined]

      // this.inventaires = lic.data.inventaires
      this.nbInvs = inventaire.numberOfTableaux

      this.domaines = inventaire.domaines
      this.essences = inventaire.essences
      this.hauteurs = inventaire.hauteurs
      this.diametres = inventaire.diametres
      this.zones = inventaire.zones
      this.parcellesSelected = inventaire.parcellesUniq
      this.etats = inventaire.etats
      this.cepages = inventaire.cepages
      this.dates = inventaire.dates
      this.devices = inventaire.devices
      this.lieuxDits = inventaire.lieux
      this.tarifs = inventaire.tarifs
      if (inventaire.traits && inventaire.traits.length) {
        this.traits = inventaire.traits;
      }
      // if (inventaire.dateInventaire) {
      //   this.dateInventaireFr = moment(inventaire.dateInventaire).format("YYYY-MM-DD")
      //     (" this.dateInventaireFr ", this.dateInventaireFr);
      // }
      await this.loadCommunesShared()

       await this.loadTasks()
       await this.loadPhitos()
       await this.loadUsers()

      if (inventaire.parcelles && inventaire.parcelles.length) {
        this.parcelles = inventaire.parcelles;
        
        this.parcelles = inventaire.parcelles.sort((a, b) => {
          const nameA = a.features[0].properties.name.toUpperCase(); // convert to uppercase to ensure case-insensitive comparison
          const nameB = b.features[0].properties.name.toUpperCase(); // convert to uppercase to ensure case-insensitive comparison
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
      }

      if (inventaire.postits && inventaire.postits.length) {
        this.postits = inventaire.postits;
      }

      if (inventaire.markers && inventaire.markers.length) {
        this.markers = inventaire.markers;
        for (let marker of this.markers) {
              marker.svgContent = await this.getSvgContent(marker.type, marker.color);
        }
      }

    


      if (inventaire.arrows && inventaire.arrows.length) {
        this.arrows = inventaire.arrows;
         for (let arrow of this.arrows) {
           arrow.svgContent = await this.getArrowSvgContent(arrow.type, arrow.color);
        }
      }

      if (inventaire.galleries && inventaire.galleries.length) {
        this.galleries = inventaire.galleries;
      }


      if(noZoom){
        setTimeout(() => {
          this.$refs.inventaire.closePop()
          this.$refs.inventaire.moveTo(zoom)
        }, 10)
      }

    },

    async refreshLight(){
      this.markers = []
      let inventaire = await xhr.post('/load-light', {
        inventaire: this.$route.params.id,
      });
      let markers = inventaire.data.markers
      console.log("inventaire.markers", markers);
      if (markers && markers.length) {
        this.markers = markers;
        for (let marker of this.markers) {
          marker.svgContent = await this.getSvgContent(marker.type, marker.color);
        }
      }
    },
    async selectInvt(inv) {
      this.$router.push({ name: 'Detail', params: { id: inv.id } })
    },
    async save() {
      // if (this.arbre.id) {

      let formData = new FormData();
      formData.append("file", this.selectedFile);
      await xhr.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      // let save = await xhr.post('/save', {
      //   inventaire: this.inventaire,
      //   datas: this.arbre,
      //   user: 456,
      //   arbre: this.arbre.clef,
      // });
      // } else {
      //   let save = await xhr.post('/add', {
      //     inventaire: this.inventaire,
      //     datas: this.arbre,
      //     user: 456,
      //   });
      // }

      this.snackbar = true;
      this.snack = 'Votre arbre a bien été sauvegarder';
      let lic = await xhr.post('/load', {
        user: 456,
        inventaire: this.inventaire,
      });
      this.inventaires = lic.data
      this.dialog = false;
      this.arbre = {
        id: null,
        qualite: '',
        essence: '',
        diametre: '',
        hauteur: '',
        volume: '',
        hauteurmoyenne: '',
        tarif: '',
      };
    },
    async conf(elt) {
      const enabled = window.confirm('Voulez vous supprimer cet arbre ?');
      if (enabled) {
        await xhr.post('/remove', {
          user: 456,
          inventaire: this.inventaire,
          clef: elt.clef,
        });
        this.snackbar = true;
        this.snack = 'Votre arbre a bien été supprimé';
        let lic = await xhr.post('/load', {
          user: 456,
          inventaire: this.inventaire,
        });
        this.inventaires = lic.data;
      }
    },
    open(elt) {
      this.arbre.qualite = elt.qualite;
      this.arbre.essence = elt.essence;
      this.arbre.hauteur = elt.hauteur;
      this.arbre.volume = elt.volume;
      this.arbre.diametre = elt.diametre;
      this.arbre.foret = elt.foret;
      this.arbre.id = elt.id;
      this.arbre.tarif = elt['tarif'];
      this.arbre.hauteurmoyenne = elt['hauteurmoyenne'];

      this.dialog = true;
    },

    async fileAdded(file) {
      this.selectedFile = file
    },

    async fileAddedAsync(file) {
      this.selectedFile = file


      try {

        let formData = new FormData();
        formData.append("file", this.selectedFile);
        let idInv = this.$route.params.id
        formData.append("inv", idInv);


        let invt = await xhr.post(`${process.env.VUE_APP_BASE_ML}add-points`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })


        let lic = await xhr.post("/load", {
          inventaire: invt.data.id, uid: this.user.uid,
          email: this.user.email
        })
        if (lic.status == 200 && lic.data.dataframe) {
          let invt = lic.data
          this.dialogInventaireAdd = false
          if (this.$refs.myVueDropzone) {
            this.$refs.myVueDropzone.removeAllFiles();
          }
          // this.inventaires = lic.data
          this.snack = "👏 Votre inventaire a été mise à jour avec les nouveaux points"
          this.snackbar = true
          this.snackIcon = 'mdi-success'
          this.colorSnack = 'success'
          this.load(invt, 9)
        }


      } catch (e) {
        if (this.$refs.myVueDropzone) {
          this.$refs.myVueDropzone.removeAllFiles();
        }
        this.snack = `WOOOW 😲 Une erreur est apparue lord de l'import de vos données. Vous pouvez vérifier le format et vos données puis tenter de réimporter.`
        this.colorSnack = 'red'
        this.snackIcon = 'mdi-alert-box'
        this.snackbar = true
        this.poSnack = false
        this.timeout = 3000
      }

    },


    formatDate(datIn) {
      // Expression régulière pour vérifier le format DD/MM/YYYY
      const regex = /^\d{2}\/\d{2}\/\d{4}$/;

      // Vérifie si la date d'entrée est déjà au format DD/MM/YYYY
      if (regex.test(datIn)) {
        // Si c'est le cas, retourne la date telle quelle
        return datIn;
      } else {
        // Sinon, utilise Moment.js pour formater la date
        return moment(datIn).format('DD/MM/YYYY');
      }
    },
    resetFilter() {
      Object.keys(this.viewItemFilters).forEach(key => {
        this.$set(this.viewItemFilters, key, null);
      });
      this.hauteurVigne = 1
    },
    async updateOffline(val) {
      this.nbEmptyPoints = val.length

      this.snack = "Veillez mettre à jour votre inventaire 😊"
      this.snackbar = true
      this.snackIcon = "mdi-web-sync"
    },

  },
  watch: {
    inventairesCombined(val) {
      if (val.length) {
        this.viewItemsFilters = []
        this.itemsFilters = []
        let result = val.map((e) => e.inventaires).flat()
        result.forEach(item => {
          Object.keys(item).forEach(key => {
            let exclude = ['longitude', 'latitude', 'cepage', 'domaine', 'allee', 'percent_ceps', 'percent_grappe', 'symptomes', 'sous_parcelle', 'sens']
            if (!exclude.includes(key)) {
              if (!this.viewItemsFilters[key]) {
                this.viewItemsFilters[key] = new Set();
              }
              if(item[key]  && item[key].length){
                this.viewItemsFilters[key].add(item[key]);

              }
            }
          });
        });

        // Convertir les Sets en tableaux pour chaque clé
        Object.keys(this.viewItemsFilters).forEach(key => {
          this.viewItemsFilters[key] = Array.from(this.viewItemsFilters[key]);
        });

        this.itemsFilters = Object.keys(this.viewItemsFilters);
      }

    },
    '$route'(to, from) {
      if (this.$route.params.id) {
        this.changingRoute(this.$route.params.id)
      }
    },
    async viewInventaire(val) {
      if (val === 'inventaire') {
        setTimeout(() => {
          this.load(this.selectedInventaireObj)
        }, 500)
      }
    },
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },

  },
  computed: {
    filterParcelles(){

      if(this.filtParcelles.length){

        return this.parcelles.filter((e) => !this.filtParcelles.includes(e.features[0].properties.name))
      }else{
        return this.parcelles
      }
    },
    filteredIcons() {
      const query = this.searchQueryIcons.toLowerCase();
      return this.icons.filter(icon => icon.title.toLowerCase().includes(query));
    },
    ...mapGetters({
      viewModal: 'viewModal', // 'getColonnes' est le nom du getter défini dans votre store
      viewItem: 'viewItem'
    }),
    getLogoFile(){
      if(!this.logoFile) return ''
      return `${process.env.VUE_APP_BASE_IMAGE_URL}get-image/${this.user.uid}/${this.logoFile}`
    },
    filteredInventairesQuick() {
      if (!this.searchQueryInventaires.trim() || this.searchQueryInventaires.length < 2) {
        return this.inventairesSearch;  // Return all inventories if search is empty
      }
      const lowerSearchQuery = this.searchQueryInventaires.toLowerCase();
      return this.inventairesSearch.filter(inventaire =>
        inventaire.name.toLowerCase().includes(lowerSearchQuery)
      );
    },

    filteredParcelles() {
      if (!this.searchQueryParcelle.trim() || this.searchQueryParcelle.length < 2) {
        return this.parcellesAll;  // Retourne toutes les parcelles si la recherche est vide
      }
      const lowerSearchQuery = this.searchQueryParcelle.toLowerCase();
      return this.parcellesAll.filter(parcelle =>
        parcelle.features[0].properties.name.toLowerCase().includes(lowerSearchQuery)
      );
    },

    filteredInventaires() {
      if (!this.searchQuery.trim() || this.searchQuery.length < 2) {
        return this.inventairesSearch;
      }
      const searchLower = this.searchQuery.toLowerCase();
      return this.inventairesSearch.filter(inventaire => {
        // Vérifie si le nom, la description ou le domaine de l'inventaire contient la requête
        const matchesInventaire = inventaire.name.toLowerCase().includes(searchLower) ||
          inventaire.description.toLowerCase().includes(searchLower) ||
          (inventaire.domaine && inventaire.domaine.toLowerCase().includes(searchLower));

        // Vérifie si un membre associé contient la requête dans son prénom ou nom
        const matchesMembers = inventaire.members && inventaire.members.some(member =>
          member.firstname.toLowerCase().includes(searchLower) ||
          member.lastname.toLowerCase().includes(searchLower)
        );

        return matchesInventaire || matchesMembers;
      });
    },

    isMobileDevice() {
      return window.innerWidth < 768;
    },
    isFreemium() {
      return !this.user.subscribed
    },
    isMore1000() {
      return this.inventaires.length >= 1000
    },
    selectedMonths() {
      return this.monthLabels.slice(this.monthRange[0] - 1, this.monthRange[1]);
    },
    inventaires: {
      get() {
        let result = this.inventairesCombined.map((e) => e.inventaires).flat()
        if (this.essencesFilters.length) {
          result = result.filter(item => this.essencesFilters.includes(item[this.viewItem]))
        }
        // Déterminer si le filtrage doit être basé sur les années ou les mois
        if (this.switchYear) {
          // Filtrage basé sur les années
          let startYear = this.yearRange[0];
          let endYear = this.yearRange[1];

          let filteredResults = result.filter(item => {
            const date = moment(item.date, ['DD/MM/YYYY', 'DD-MM-YYYY', 'YYYY-MM-DD']);
            if (!date.isValid()) {
              console.error('Date invalide:', item.date);
              return false;
            }
            const year = date.year();

            return year >= startYear && year <= endYear;
          });

          return filteredResults;
        } else {
          if (this.monthRange[0] > 1 || this.monthRange[1] < 12 || this.yearFilter != 6) {

            let startMonth = this.monthRange[0];
            let endMonth = this.monthRange[1];
            let currentYear = new Date().getFullYear();
            let startYear, endYear;

            switch (this.yearFilter) {
              case -1: // Dernier trimestre de l'année en cours
                startYear = endYear = currentYear;
                startMonth = 10; // Octobre
                endMonth = 12; // Décembre
                break;
              case 0: // Cette année
                startYear = endYear = currentYear;
                break;
              case 1: // L'an passé
                startYear = endYear = currentYear - 1;
                break;
              case 2: // 3 dernières années
                startYear = currentYear - 2;
                endYear = currentYear;
                break;
              case 3: // -5 ans
                startYear = currentYear - 5;
                endYear = currentYear;
                break;
              case 4: // -8 ans
                startYear = currentYear - 8;
                endYear = currentYear;
                break;
              case 5: // -15 ans
                startYear = currentYear - 15;
                endYear = currentYear;
                break;
              case 6: // Aucune restriction d'année
                startYear = -Infinity;
                endYear = Infinity;
                break;
              default:
                startYear = -Infinity;
                endYear = Infinity;
                break;
            }

            let filteredResults = result.filter(item => {
              const date = moment(item.date, ['DD/MM/YYYY', 'DD-MM-YYYY', 'YYYY-MM-DD']);
              if (!date.isValid()) {
                console.error('Date invalide:', item.date);
                return false;
              }
              const year = date.year();
              const month = date.month() + 1; // Moment.js commence les mois à 0


              // Vérifier si l'année et le mois de l'objet courant se trouvent dans les plages spécifiées
              return (month >= startMonth && month <= endMonth) && (year >= startYear && year <= endYear);
            });

            return filteredResults;

            // TODO: Filtrer par mois.. et par année: yearFilter
            // yearFilter
            /*
              { text: 'Cette année', value: 0 },
              { text: 'L\'an passé', value: 1 },
              { text: '3 dernières années', value: 2 },
              { text: '-5 ans', value: 3 },
              { text: '-8 ans', value: 4 },
              { text: '-15 ans', value: 5 },
              { text: 'Aucune', value: 6 }
            */
          }
        }

        // filter by selected datas

        return result.slice(0, this.limitPoint);
      },
      set(value) {
        this.inventaires = value;
      },
    },
    nameCombined() {
      if (this.inventairesCombined.length > 1) {
        let names = this.inventairesCombined.map((e) => e.name).join(" , ")
        return names
      }
    },
    labelCombined() {
      if (this.inventairesCombined.length > 1) {
        let names = this.inventairesCombined.map((e) => e.name)
        return names
      } else {

        return []
      }
    },
    statsEssences() {
      let total = this.filterEssences.length;
      let key = this.viewItem;
      let counts;
      // console.log("laaa", key );

      if (key === 'symptomes') {
        console.log("Go Symptômes...")
      }

      else if (!this.essencesFilters.length) {
        counts = this.filterEssences.reduce((p, c) => {
          let category = c[key];
          if (!p.hasOwnProperty(category)) {
            p[category] = 0;
          }
          if (p.hasOwnProperty(category)) {
            p[category]++;
          }
          return p;
        }, {});
      
      
      } else {

        if (key === 'pente') {
          // Gérer les paliers spécifiques pour les pentes
          counts = this.filterEssences.reduce((p, c) => {
            let pente = c[key];
            let category;

            if (pente < 2) {
              category = '<2%';
            } else if (pente >= 2 && pente < 10) {
              category = '2% - 10%';
            } else if (pente >= 10 && pente < 15) {
              category = '10% - 15%';
            } else if (pente >= 15 && pente < 20) {
              category = '15% - 20%';
            } else if (pente >= 20 && pente < 30) {
              category = '20% - 30%';
            } else if (pente >= 30 && pente < 50) {
              category = '30% - 50%';
            } else if (pente >= 50) {
              category = '>50%';
            } else {
              category = 'Non classifié';
            }

            if (!p.hasOwnProperty(category)) {
              p[category] = 0;
            }
            p[category]++;
            return p;
          }, {});
        }

        else if (key === 'hauteur') {
          // Gérer les paliers spécifiques pour les hauteurs de vigne
          counts = this.filterEssences.reduce((p, c) => {
            let hauteur = c[key];
            let category;

            if (hauteur >= 0 && hauteur < 120) {
              category = 'Hauteures Basses';
            } else if (hauteur >= 120 && hauteur < 150) {
              category = 'Hauteures Moyennes';
            } else if (hauteur >= 150 && hauteur < 200) {
              category = 'Hauteures Hautes';
            } else {
              category = 'Hauteures en Espalier';
            }

            if (!p.hasOwnProperty(category)) {
              p[category] = 0;
            }
            p[category]++;
            return p;
          }, {});

        }

        else if (key === 'altitude') {
          // Gérer les paliers spécifiques pour les altitudes de vigne
          counts = this.filterEssences.reduce((p, c) => {
            let altitude = c[key];
            let category;

            if (altitude < 200) {
              category = '0 - 200m (Plaine et Bas Coteaux)';
            } else if (altitude >= 200 && altitude < 500) {
              category = '200m - 500m (Moyens Coteaux)';
            } else if (altitude >= 500 && altitude < 1000) {
              category = '500m - 1000m (Hauts Coteaux)';
            } else if (altitude >= 1000) {
              category = '> 1000m (Montagneux)';
            } else {
              category = 'Non classifié';
            }

            if (!p.hasOwnProperty(category)) {
              p[category] = 0;
            }
            p[category]++;
            return p;
          }, {});
        }

        else {

          // Calcul des comptes pour chaque valeur unique de la clé sélectionnée
          counts = this.inventaires.reduce((p, c) => {
            let name = c[key];
            if (!p.hasOwnProperty(name)) {
              p[name] = 0;
            }
            p[name]++;
            return p;
          }, {});


        }

      }

      // Construction du tableau de statistiques
      let statsEssences = Object.entries(counts).map(([title, count]) => {
        let color
        if (this.colorsEssences[title]) {
          color = this.colorsEssences[title]
        }
        else if (this.colorsAltitude[title]) {
          color = this.colorsAltitude[title]
        }
        else if (this.colorsHauteur[title]) {
          color = this.colorsHauteur[title]
        }
        else if (this.colorsPentes[title]) {
          color = this.colorsPentes[title]
        }
        else {
          color = this.generateRandomHexColor()
        }
        return {
          nb: Math.ceil(count * 100 / total),
          title,
          color
        }
      });

      return statsEssences;

    },

    essencesSelected: {
      get() {
        return this.$store.state.inventaire.essencesSelected;
      },
      set(value) {
        this.$store.dispatch('updateSelection', { key: 'essencesSelected', value });
      }
    },
    hauteursSelected: {
      get() {
        return this.$store.state.inventaire.hauteursSelected;
      },
      set(value) {
        this.$store.dispatch('updateSelection', { key: 'hauteursSelected', value });
      }
    },
    diametresSelected: {
      get() {
        return this.$store.state.inventaire.diametresSelected;
      },
      set(value) {
        this.$store.dispatch('updateSelection', { key: 'diametresSelected', value });
      }
    },
    volumesSelected: {
      get() {
        return this.$store.state.inventaire.volumesSelected;
      },
      set(value) {
        this.$store.dispatch('updateSelection', { key: 'volumesSelected', value });
      }
    },
    tarifsSelected: {
      get() {
        return this.$store.state.inventaire.tarifsSelected;
      },
      set(value) {
        this.$store.dispatch('updateSelection', { key: 'tarifsSelected', value });
      }
    },
    domainesSelected: {
      get() {
        return this.$store.state.inventaire.domaineSelected;
      },
      set(value) {
        this.$store.dispatch('updateDomaineSelected', value);
      }
    },
    rail: {
      get() {
        return this.$store.state.inventaire.rail;
      },
      set(value) {
        this.$store.dispatch('updateSelection', { key: 'rail', value });
      }
    },
    hauteurVigne: {
      get() {
        return this.$store.state.inventaire.hauteurVigne;
      },
      set(value) {
        this.$store.dispatch('updateSelection', { key: 'hauteurVigne', value });
      }
    },
    lieuxDitsSelected: {
      get() {
        return this.$store.state.inventaire.lieuxDitsSelected;
      },
      set(value) {
        this.$store.dispatch('updateSelection', { key: 'lieuxDitsSelected', value });
      }
    },
    parcellesSelecteded: {
      get() {
        return this.$store.state.inventaire.parcellesSelected;
      },
      set(value) {
        this.$store.dispatch('updateSelection', { key: 'parcellesSelected', value });
      }
    },
    etatSelected: {
      get() {
        return this.$store.state.inventaire.etatSelected;
      },
      set(value) {
        // 
        this.$store.dispatch('updateSelection', { key: 'etatSelected', value });
      }
    },
    cepagesSelected: {
      get() {
        return this.$store.state.inventaire.cepagesSelected;
      },
      set(value) {
        this.$store.dispatch('updateSelection', { key: 'cepagesSelected', value });
      }
    },

    cepagesSelected: {
      get() {
        return this.$store.state.inventaire.cepagesSelected;
      },
      set(value) {
        this.$store.dispatch('updateSelection', { key: 'cepagesSelected', value });
      }
    },

    operateursSelected: {
      get() {
        return this.$store.state.inventaire.operateursSelected;
      },
      set(value) {
        this.$store.dispatch('updateSelection', { key: 'operateursSelected', value });
      }
    },
    datesSelected: {
      get() {
        return this.$store.state.inventaire.datesSelected;
      },
      set(value) {
        this.$store.dispatch('updateSelection', { key: 'datesSelected', value });
      }
    },
    devicesSelected: {
      get() {
        return this.$store.state.inventaire.devicesSelected;
      },
      set(value) {
        this.$store.dispatch('updateSelection', { key: 'devicesSelected', value });
      }
    },
    nbFiltres() {
      let totalCount = 0;
      Object.values(this.viewItemFilters).forEach(value => {
        if (Array.isArray(value)) {
          // Ajouter le nombre d'éléments non nuls du tableau
          totalCount += value.filter(item => item !== null).length;
        }
      });
      return totalCount
      // return this.parcellesSelecteded.length + this.lieuxDitsSelected.length + this.etatSelected.length + this.cepagesSelected.length + this.domainesSelected.length + this.tarifsSelected.length + this.diametresSelected.length + this.volumesSelected.length + this.essencesSelected.length + this.hauteursSelected.length + this.operateursSelected.length + this.datesSelected.length + this.devicesSelected.length
    },

    labels() {
      return {
        labels: {
          buttonSkip: 'Passer',
          buttonNext: 'Suivant',
          buttonPrevious: 'Précédent',
          buttonStop: "Ok j'ai compris!"
        },
      }
    },
    getOffre() {
      return this.user.subscription && this.user.subscription.productDetails.name
    },
    filterEssences() {
      let datas = this.inventaires
     
      let resultFilters = {};
      Object.keys(this.viewItemFilters).forEach(key => {
        const value = this.viewItemFilters[key];
        if (Array.isArray(value) && value.length > 0) {
          resultFilters[key] = value;
        }
      });

      if (Object.keys(resultFilters).length > 0) {
        datas = datas.filter(data => {
          // Vérifier chaque critère de filtrage.
          return Object.keys(resultFilters).every(key => {
            // Si la clé n'existe pas dans l'objet data, ou bien si la valeur de cette clé dans data
            // n'est pas dans le tableau de resultFilters pour cette clé, le filtre échoue.
            return data.hasOwnProperty(key) && resultFilters[key].includes(data[key]);
          });
        });
      }
      if (this.filterEssence.length) {

        let ph = this.filterEssence.join(' ').toLowerCase()


        if (ph.length) {
          // pente 
          if (this.viewItem == "pente") {
            const penteRanges = {
              '<2%': { min: 0, max: 2 },
              '2% - 10%': { min: 2, max: 10 },
              '10% - 15%': { min: 10, max: 15 },
              '15% - 20%': { min: 15, max: 20 },
              '20% - 30%': { min: 20, max: 30 },
              '30% - 50%': { min: 30, max: 50 },
              '>50%': { min: 50, max: Infinity }
            };

            datas = datas.filter((e) => {
              if (e[this.viewItem] && typeof e[this.viewItem] === 'number') {
                // Trouver la catégorie correspondante à la pente
                let penteCategory = Object.keys(penteRanges).find(category => {
                  let range = penteRanges[category];
                  return e[this.viewItem] >= range.min && e[this.viewItem] < range.max;
                });

                // Vérifier si la catégorie de pente est incluse dans le filtre sélectionné
                return penteCategory && ph.includes(penteCategory.toLowerCase());
              }
              return false;
            });
          }
          // hauteur  
          else if (this.viewItem == "hauteur") {
            const altitudeRanges = {
              'Vignes Basses': { min: 0, max: 120 },
              'Vignes Moyennes': { min: 120, max: 150 },
              'Vignes Hautes': { min: 150, max: 200 },
              'Vignes en Espalier': { min: 200, max: Infinity }
            };

            // Filtre les données en fonction de la catégorie d'altitude sélectionnée
            datas = datas.filter((e) => {
              if (e[this.viewItem] && typeof e[this.viewItem] === 'number') {
                // Trouver la catégorie correspondante à l'altitude
                let altitudeCategory = Object.keys(altitudeRanges).find(category => {
                  let range = altitudeRanges[category];
                  return e[this.viewItem] >= range.min && e[this.viewItem] < range.max;
                });

                // Vérifier si la catégorie d'altitude est incluse dans le filtre sélectionné
                return altitudeCategory && ph.includes(altitudeCategory.toLowerCase());
              }
              return false;
            });

          }
          // altitude  
          else if (this.viewItem == "altitude") {
            const altitudeRanges = {
              '0 - 200m (plaine et bas coteaux)': { min: 0, max: 200 },
              '200m - 500m (moyens coteaux)': { min: 200, max: 500 },
              '500m - 1000m (hauts coteaux)': { min: 500, max: 1000 },
              '> 1000m (montagneux)': { min: 1000, max: Infinity }
            };

            // Filtre les données en fonction de la catégorie d'altitude sélectionnée
            datas = datas.filter((e) => {
              if (e[this.viewItem] && typeof e[this.viewItem] === 'number') {
                // Trouver la catégorie correspondante à l'altitude
                let altitudeCategory = Object.keys(altitudeRanges).find(category => {
                  let range = altitudeRanges[category];
                  return e[this.viewItem] >= range.min && e[this.viewItem] < range.max;
                });

                // Vérifier si la catégorie d'altitude est incluse dans le filtre sélectionné
                return altitudeCategory && ph.includes(altitudeCategory.toLowerCase());
              }
              return false;
            });

          }
          // other: etat, foret, parcelle
          else {
            datas = datas.filter((e) => e[this.viewItem] && e[this.viewItem].length && ph.includes(e[this.viewItem].toLowerCase()))

          }



        }

      }

      console.log("datas", datas);
      return datas
    },
    search: {
      get() {
        return this.queryTerm
      },

      set(searchInput) {
        if (this.queryTerm !== searchInput) {
          this.queryTerm = searchInput
          this.loadEssences()
        }
      }
    },

    computedDateFormatted() {
      return this.formatDate(this.date);
    },

    usersFilter() {
      if (this.search.length > 2) {
        let reg = new RegExp(this.search, 'ig');
        return this.users.filter(
          (elt) =>
            reg.test(elt.email) ||
            reg.test(elt.nom) ||
            reg.test(elt.prenom) ||
            reg.test(elt.uid) | reg.test(elt.trial) ||
            reg.test(elt.token),
        );
      }
      return this.users;
    },
    popupCoordinates() {
      // Here we can do some work for calculate proper coordinates
      //...
      return [4.8342778, 45.763421];
    },
  },

};
</script>

<style lang="scss">
.v-icon__svg {
  width: 24px; /* Ajustez selon vos besoins */
  height: 24px;
}
.v-main__wrap {}

.custom-snackbar {
  z-index: 20500000000 !important;
  /* Assurez-vous que cette valeur est plus grande que celle du v-dialog */
}

#draggable-container {
  z-index: 99999999999999;
}

.pulse {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

#calculated-area {
  height: 75px;
  width: 150px;
  position: absolute;
  bottom: 40px;
  left: 10px;
  color: black;
  font-size: 18px;
  z-index: 1000000000;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  text-align: center;
}

p {
  font-family: 'Open Sans';
  margin: 0;
  font-size: 13px;
}

.dropzone-custom-content {}

.dropzone-custom-title {
  color: #00b782;
}

.subtitle {
  color: #314b5f;
}

@media (min-width: 1264px) {
  .col-lg-10 {
    flex: 0 0 82%;
    max-width: 82%;
  }
}

.mapboxgl-popup {
  padding: 0;
  font-size: 16px;
  background: #ffffff;
  border-radius: 15px !important;
  /* couleur de fond */
  border-radius: 4px;
  width: auto;
  padding: 5px;
  min-width: 500px;


  .v-btn-one {
    text-transform: capitalize;
    font-size: 12px;
    color: white;
    background-color: #f46370;
    padding: 10px !important;
    margin-top: 10px;
    display: block;
    width: 120px;
    float: right;
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.5);
    /* Ombre interne */
    cursor: pointer;
  }

  .v-btn-two {
    background-color: white;
    color: #AB3443;

    padding: 10px !important;
    margin-top: 10px;
    display: block;
    width: 150px;
    float: left;
    cursor: pointer;
  }
}

.mapboxgl-popup-close-button {
  font-size: 27px !important;
  color: #AB3443 !important;
}

.mapboxgl-popup-content {
  box-shadow: none;
  padding: 10px;


  h3 {
    font-size: 23px;
    margin: 0;
  }
}



.bottomDialog .v-card {
  height: 20% !important;
  /* Hauteur de la fenêtre de dialogue */
  margin-top: auto !important;

  /* Pousse la fenêtre vers le bas */
}

.v-dialog.bottomDialog {
  overflow: hidden;
  /* Cache le débordement de contenu */
}

.dragDialog {
  position: fixed !important;
}

.titleHover:hover {
  cursor: text;
}

.subtext-date {
  color: #757575;
  font-size: 0.675rem;
}

#menu_app {
  .mr-2 {
    margin-right: 17px !important;
  }
}

@media (min-width: 1024px) {
  .dialogParcelle {
    position: absolute !important;
    top: 0px !important;
    right: 0px !important;
  }
}
.sticky-row{
  position: fixed;
  top: 112px;
  z-index: 1000;
}
</style>
