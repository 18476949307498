<template>
    <v-card class="pa-2">

        <v-card-text>
            <v-row class="d-flex flex-column justify-center">
                <div v-if="medias.length" style="max-height: 400px; overflow: scroll;">

                    <v-list max-height="300" rounded>

                        <v-list-item v-for="media in medias" :key="media._id">
                            <v-list-item-icon>
                                <v-icon>mdi-download-circle</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>

                                <a :href="media.generated" target="_blank"><v-list-item-title
                                        v-text="media.original"></v-list-item-title></a>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn @click="removeMedia(media)" icon>
                                    <v-icon color="grey lighten-1">mdi-delete-empty</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>

                    </v-list>

                    <!-- <v-chip target="_blank" outlined :href="media.generated"  v-for="media in medias" :key="media._id" class="pa-2 ma-2" dark color="primary" label><v-icon>mdi-download-box</v-icon> {{  media.original }}</v-chip> -->
                </div>

                <v-col v-else>
                    <v-alert border="left" colored-border type="info" elevation="2">Vous n'avez pas encore de medias à cet
                        inventaire</v-alert>
                </v-col>

            </v-row>
        </v-card-text>

        <hr>

        <v-col>
            <vue-dropzone @vdropzone-file-added="fileAddedAsync" :maxFiles="Number(10000000000)" :multipleUpload="false"
                :parallelUpload="5" ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" :useCustomSlot=true>
                <div class="dropzone-custom-content">
                    <p><v-icon>mdi-file</v-icon>
                        Je dépose mon fichier ici</p>
                    <v-btn block rounded x-large color="primary" dark @click="handleFileImport">
                        <v-icon>mdi-upload</v-icon> Télécharger des documents pour Inventaire
                    </v-btn>
                    <input accept="application/csv" ref="uploader" class="d-none" type="file" @change="onFileChanged">
                </div>
            </vue-dropzone>
        </v-col>

    </v-card>
</template>

<script>
import xhr from '../plugins/axios';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';


export default {
    name: 'Todos',
    components: {
        vueDropzone: vue2Dropzone,
    },
    props: ['selectedInventaire', 'user'],
    data(vm) {
        return {
            selectedFile: null,
            newMsg: "",
            selected: [],
            medias: [],
            dropzoneOptions: {
                url: 'https://cartovia.fr/api/upload',
                thumbnailWidth: 80,
                acceptedFiles: '.png, .jpg, .pdf, .jpeg, .doc, .xls, .docx, .xlsx, .txt',
                autoProcessQueue: false,
                maxFilesize: 4,
                acceptedFiles: 'text/csv',
                dictDefaultMessage: "<i class='mdi mdi-upload'></i>Télécharger votre inventaire au format CSV"
            },
        }
    },
    async created() {
        let res = await xhr.post('/get-medias', { user: this.user.uid, inv: this.selectedInventaire._id });

        if (res.data) {
            this.medias = res.data
        }
    },
    watch: {

    },
    methods: {
        async removeMedia(media) {
            let res = await xhr.post('/remove-medias', {
                media,
                user: this.user
            })
            this.medias = res.data

        },
        handleFileImport() {
            this.$refs.uploader.click();
        },
        onFileChanged(e) {
            this.selectedFile = e.target.files[0];
            console.log("this.selectedFile", this.selectedFile);
        },
        async fileAddedAsync(file) {
            this.selectedFile = file

            let formData = new FormData();
            formData.append("file", this.selectedFile);
            formData.append("user", this.user.uid);
            formData.append("inv", this.selectedInventaire._id);

            let res = await xhr.post('/add-medias', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })

            this.medias = res.data

            this.$emit('closeMediasDialog')
            // await this.load(invt.data)
            // this.dialogInventaireLight = false

            // this.snack = "Votre arbres d'inventaire ont été mise à jour"
            // this.snackbar = true

        },
        async changeTask(arr) {

            let res = await xhr.post('/set-tasks', {
                tasks: arr,
                inv: this.selectedInventaire._id,
            });
        },
        async onEnter() {
            console.log("arr", this.selectedInventaire._id);
            let res = await xhr.post('/add-tasks', {
                inv: this.selectedInventaire._id,
                content: this.newMsg
            });
            this.tasks = res.data.reverse()
            this.newMsg = ""
        }
    }

}

</script>
<style lang="scss">
.v-input--checkbox.v-input--is-label-active {
    label {
        text-decoration: line-through;
    }
}
</style>