import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VSheet,{staticClass:"pa-5",attrs:{"elevation":"2"}},[_c(VSubheader,{staticClass:"display-1 pa-3 ma-3"},[_c(VIcon,[_vm._v("📄")]),_vm._v("Documentation en libre accès pour Vigneron")],1),_c(VExpansionPanels,_vm._l((_vm.documents),function(doc,index){return _c(VExpansionPanel,{key:index},[_c(VExpansionPanelHeader,[_c(VLayout,{attrs:{"align-center":""}},[_c(VFlex,{staticClass:"headline"},[_vm._v(_vm._s(doc.title))])],1)],1),_c(VExpansionPanelContent,[_c('p',{staticClass:"body-1"},[_vm._v(_vm._s(doc.description))]),_c(VBtn,{attrs:{"rouded":"","color":"primary","dark":"","href":doc.pdfUrl,"target":"_blank"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-download-circle")]),_vm._v("Ouvrir le Document ")],1)],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }