<template>
    <v-container>


        <v-dialog v-model="viewDetail">
            <v-card>
                <v-toolbar dark color="secondary white--text">
                    <v-toolbar-title><v-icon>mdi-eye</v-icon> Détail du Phytosanitaire</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="viewDetail = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text class="pt-2">
                    <v-container>
                        <v-row cols="12">
                            <v-col>
                                <h3>Maladie: <b>{{ vineyard.disease.name }}</b></h3>
                            </v-col>

                            <v-col cols="12">Première observation: {{ vineyard.disease.firstSighting }}</v-col>

                            <v-col cols="12">Domaine: <b>{{ vineyard.location.domain }}</b></v-col>
                            <v-col cols="12">Ville: <b>{{ vineyard.location.city.Nom_commune }}</b> Département: <b>{{
            vineyard.location.department }}</b></v-col>

                            <hr>
                            <v-col cols="12">
                                <h3>Nom du produit: {{ vineyard.treatment.productName }}</h3>
                            </v-col>
                            <v-col cols="12">Référence traitement: {{ vineyard.treatment.ref }}</v-col>
                            <v-col cols="12">Type de produit: {{ vineyard.treatment.productType }}</v-col>
                            <v-col cols="12">Lien du produit: <a :href="vineyard.treatment.urlProduct"
                                    target="_blank">Voir produit</a></v-col>
                            <v-col cols="12">Dose: <b>{{ vineyard.treatment.dose }}</b> L./ha</v-col>
                            <v-col cols="12">Informations supplémentaires: <i>{{ vineyard.additionalInfo }}</i></v-col>
                        </v-row>


                    </v-container>
                </v-card-text>

                <v-card-actions class="d-flex justify-center">
                    <v-btn color="primary" small rounded @click="viewDetail = false"><v-icon>mdi-close</v-icon> Fermer
                        cette
                        fenêtre</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar absolute top color="primary" shaped v-model="snackbarLauch">
            {{ snack }}
        </v-snackbar>

        <v-row>
            <v-col class="pa-3">

                <v-form @input="validateForm" ref="form" v-if="add">
                    <v-btn small rounded color="primary" @click="add = false"><v-icon>mdi-arrow-left</v-icon>Retour à la
                        liste</v-btn>
                    <h3><v-icon>mdi-package-variant</v-icon> Le produit phytosanitaure</h3>

                    <v-row>
                        <v-col cols="12" sm="8">
                            <v-text-field required :rules="[rules.required]" prepend-icon="mdi-pen-plus"
                                placeholder="Purin d'orties concentré" label="Nom commercial du produit utilisé"
                                v-model="vineyard.treatment.productName"></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="2">
                            <v-text-field required :rules="[rules.required]" placeholder="Ex: C43JAE"
                                label="Référence du produit utilisé" v-model="vineyard.treatment.ref"></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="2">
                            <v-select :items="productTypes" label="Type de produit" :rules="productTypeRules"
                                v-model="vineyard.treatment.productType"></v-select>
                        </v-col>

                        <v-col cols="12" sm="8">
                            <v-text-field prepend-icon="mdi-link-variant" placeholder="https://"
                                label="URL de la fiche technique du produit"
                                v-model="vineyard.treatment.urlProduct"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field prepend-icon="mdi-texture-box" :rules="[rules.required]" required
                                label="Nom du domaine ou forêt" placeholder="Domaine de Dupond"
                                v-model="vineyard.location.domain"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-select multiple v-model="vineyard.location.parcelles" :items="parcellesLoad" clearable
                                item-text="name" item-value="name" label="Choisissez le(s) parcelle(s) associée(s)"
                                return-object></v-select>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-autocomplete prepend-icon="mdi-city" :disabled="freeze" v-model="vineyard.location.city"
                                :items="cityNames" label="Choisissez une ville" item-text="Nom_commune"
                                item-value="coordonnees_gps" return-object @change="onCitySelect"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field :disabled="freeze" label="Département"
                                v-model="vineyard.location.department"></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field prepend-icon="mdi-map-marker-plus" label="Coordonnées GPS"
                                placeholder="45.89877678, 5.8769876786" v-model="vineyard.location.gps"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-btn color="primary" small rounded outlined @click="getGeoloc">
                                <v-icon>mdi-map-marker-radius</v-icon>
                                Récupérer un point sur la carte</v-btn>
                        </v-col>

                    </v-row>

                    <v-divider></v-divider>

                    <h3><v-icon>mdi-virus</v-icon> La maladie</h3>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-autocomplete :rules="diseaseRules" required prepend-icon="mdi-shield-bug-outline"
                                :items="diseases" label="Maladies détectées" v-model="vineyard.disease.name"
                                item-text="name" item-value="name" return-object @change="checkDisease"
                                :menu-props="{ bottom: true, offsetY: true }"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-menu ref="menu" v-model="menu3" :close-on-content-click="false"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field :rules="dateRules" v-model="vineyard.disease.firstSighting"
                                        label="Date du 1er constat" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="vineyard.disease.firstSighting" @input="menu3 = false"
                                    :format="dateFormat"></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>


                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-slider @input="updateDose" v-model="vineyard.treatment.dose" color="primary"
                                prepend-icon="mdi-battery-30" step="0.01" thumb-label="always" label="Dose"
                                persistent-hint hint="L./ha" max="1000" :thumb-size="50" min="0"></v-slider>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="vineyard.treatment.date" label="Date du traitement"
                                        prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="vineyard.treatment.date" @input="menu = false"
                                    :format="dateFormat"></v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea clearable clear-icon="mdi-close-circle" counter prepend-icon="mdi-comment" solo
                                label="Informations complémentaires" v-model="vineyard.additionalInfo"></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row class="ml-2">

                        <v-col cols="12" sm="2">
                            <v-btn big color="primary" @click="add = false" outlined>
                                Annuler</v-btn>
                        </v-col>

                        <v-col v-if="!edited" cols="12" sm="3">
                            <v-btn @click="addPhito" big color="info" dark>
                                <v-icon>mdi-plus-tick</v-icon>
                                Ajouter
                                un registre
                                phytosanitaire</v-btn>
                        </v-col>

                        <v-col v-else cols="12" sm="3">
                            <v-btn big color="success" @click="editing"><v-icon>mdi-pencil</v-icon>
                                Enregistrer</v-btn>
                        </v-col>
                        <v-col v-if="edited" cols="12" sm="3">
                            <v-btn big color="red" outlined @click="remove"><v-icon>mdi-trash-can-outline</v-icon>
                                Supprimer</v-btn>
                        </v-col>

                    </v-row>

                </v-form>

                <v-row v-else>

                    <v-text-field v-model="searchQuery" append-icon="mdi-magnify"
                        label="Rechercher un domaine, un produit, une référence ..." single-line
                        hide-details></v-text-field>
                    <v-col v-if="filteredPhytos.length" cols="12">
                        <v-list>
                            <v-list-item-group>
                                <template v-for="(registre, index) in filteredPhytos">
                                    <v-list-item :key="`item-${index}`">
                                        <v-list-item-content @click="edit(registre)">
                                            <v-list-item-title>Produit: <b>{{ registre.vineyard.treatment.productName
                                                    }}</b></v-list-item-title>
                                            <v-list-item-subtitle>Dose: <b>{{ registre.vineyard.treatment.dose
                                                    }} L./ha</b></v-list-item-subtitle>
                                            <v-list-item-subtitle>Référence: <b>{{ registre.vineyard.treatment.ref
                                                    }}</b></v-list-item-subtitle>
                                            <v-list-item-subtitle>Domaine: <b>{{ registre.vineyard.location.domain
                                                    }}</b></v-list-item-subtitle>

                                            <v-list-item-subtitle>Maladie: <b>{{ registre.vineyard.disease.name
                                                    }}</b></v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>

                                            <v-row>
                                                <v-col>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn v-on="on" color="secondary" fab small
                                                                @click="viewd(registre)"><v-icon>mdi-eye-outline</v-icon></v-btn>
                                                        </template>
                                                        Voir le phytosanitaire
                                                    </v-tooltip>
                                                </v-col>

                                                <v-col>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn v-on="on" color="secondary" outlined fab small
                                                                @click="duplicate(registre)"><v-icon>mdi-content-copy</v-icon></v-btn>
                                                        </template>
                                                        Dupliquer le phytosanitaire
                                                    </v-tooltip>
                                                </v-col>

                                            </v-row>


                                        </v-list-item-action>
                                    </v-list-item>
                                    <!-- Ajoutez un v-divider après chaque v-list-item sauf le dernier -->
                                    <v-divider :key="`divider-${index}`" v-if="index < phytos.length - 1"></v-divider>
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </v-col>

                    <v-col cols="12" v-else>
                        <v-alert type="info">
                            Vous n'avez pas encore de registre phytosanitaire
                        </v-alert>
                    </v-col>

                </v-row>


                <v-row class="d-flex justify-center">
                    <v-btn v-if="!add" big rounded color="success" @click="adding"><v-icon>mdi-plus</v-icon>
                        Ajouter
                        un
                        registre dans ma base</v-btn>
                </v-row>

            </v-col>
        </v-row>


    </v-container>
</template>

<script>
import xhr from '../plugins/axios';

export default {
    name: 'Phito',
    props: ['uid', 'inventaire', 'profil'],
    components: {
    },
    async created() {
        this.add = false
        this.edited = false
        let cities = await xhr.get('/cities');
        this.cityNames = cities.data;

        if (this.profil == "forestier") {
            this.diseases = ['Mildiou', 'Anthracnose', 'Brunissure', 'Chalarose', 'Criblure', 'Cylindrosporiose', 'Cyclaneusma', 'Champignon', 'Chancre',
                'Criblure', 'Collybie', 'Oidium', 'Pourriture grise', 'Rouilles', 'Cloque', 'Nodule noir', 'Flétrissements', 'Polypore', 'Autre']
        }

        await this.load()

        let parcellesAll = await xhr.post('/load-parcelles', { uid: this.uid });
        this.parcellesLoad = parcellesAll.data



    },
    data(vm) {
        return {
            viewDetail: false,
            valid: false,
            diseaseRules: [
                v => !!v || 'Une maladie doit être choisie.',
            ],
            productTypeRules: [
                v => !!v || 'Le type de produit est requis.',
            ],
            dateRules: [
                v => !!v || 'La date du 1er constat est requise.',
            ],
            rules: {
                required: value => !!value || 'Ce champ est requis.',
            },
            searchQuery: '',
            phytos: [],
            parcellesLoad: [],
            editPhito: {},
            snackbarLauch: false,
            snack: '',
            registres: [
                { titre: 'Registre 1' },
                { titre: 'Registre 2' },
                { titre: 'Registre 3' },
                // Ajoutez autant de registres fictifs que nécessaire
            ],
            add: false,
            menu: false,
            menu3: false,
            cityNames: [],
            varieties: ['Cabernet Sauvignon', 'Merlot', 'Chardonnay', 'Sauvignon Blanc', 'Pinot Noir', 'Syrah', 'Grenache', 'Cinsault',
                'Gamay', 'Cabernet Franc', 'Mourvèdre', 'Chenin Blanc', 'Riesling', 'Viognier', 'Grenache Blanc', 'Sémillon', 'Muscat',
                'Aligoté', 'Ugni Blanc'],
            diseases: ['Mildiou', 'Oïdium', 'Botrytis', 'Esca', 'Black Rot', 'Eutypiose', 'Flavescence dorée', 'Court-noué', 'Phylloxéra', 'Excoriose',
                'Rougeot parasitaire', 'Verticillium Wilt', 'Nécrose bactérienne', 'Autre'],
            productTypes: ['Fongicide', 'Herbicide', 'Insecticide', 'Acaricides', 'Nématicides', 'Molluscicides', 'Engrais chimiques',
                'Amendements organiques', 'Amendements calcaires', 'Biostimulants', 'Agents de lutte biologique', 'Régulateurs de croissance des plantes',
                'Produits de désinfection du sol', 'Attractifs et répulsifs', 'Produits de peinture pour troncs',
                'Autre'],
            dateFormat: 'dd/MM/yyyy',
            edited: false,
            freeze: false,
            viewDetailPhyto: {},
            vineyard: {
                location: {
                    parcelles: [],
                    domain: '',
                    city: '',
                    department: '',
                    gps: '',
                },
                disease: {
                    name: '',
                    firstSighting: '',
                },
                treatment: {
                    ref: '',
                    productName: '',
                    productType: '',
                    urlProduct: '',
                    dose: 0,
                },
                additionalInfo: '',
            },
        }
    },
    methods: {
        viewd(registre) {
            this.viewDetail = true
            this.viewDetailPhyto = registre
        },
        validateForm() {
        },
        adding() {
            this.reset()
            this.add = true
        },
        reset() {
            this.vineyard = {
                location: {
                    parcelles: [],
                    domain: '',
                    city: '',
                    department: '',
                    gps: '',
                },
                disease: {
                    name: '',
                    firstSighting: '',
                },
                treatment: {
                    ref: '',
                    productName: '',
                    productType: '',
                    dose: 0,
                },
                additionalInfo: '',
            }
        },
        async duplicate(phito) {
            await xhr.post('/duplicate-phito', {
                vineyard: phito.vineyard,
                uid: this.uid,
                invId: this.inventaire._id
            })
            this.add = false
            this.snack = 'Votre produit phytosanitaire a bien été dupliqué'
            this.snackbarLauch = true

            await this.load()
        },
        async addPhito() {
            if (this.$refs.form.validate()) {
                await xhr.post('/add-phito', {
                    vineyard: this.vineyard,
                    uid: this.uid,
                    invId: this.inventaire._id
                })
                this.reset()

                this.add = false
                this.snack = 'Votre produit phytosanitaire a bien été ajouté'
                this.snackbarLauch = true

                await this.load()
            }

        },
        nextstep(coords) {
            if (coords) {
                this.vineyard.location.gps = coords.lat + ", " + coords.lng
            }
        },
        getGeoloc() {
            this.freeze = true
            this.$emit('geolocation')
        },
        async load() {
            let phytos = await xhr.post('/load-phito', {
                uid: this.uid
            });
            this.phytos = phytos.data;
        },
        async remove() {
            let conf = confirm('Voulez-vous supprimer le registre phytosanitaire?')
            if (conf == true) {
                await xhr.post('/remove-phito', {
                    vineyard: this.editPhito,
                })

                await this.load()
                this.add = false
                this.snack = 'Votre produit phytosanitaire a bien été supprimé'
                this.snackbarLauch = true
            }
        },
        edit(registre) {
            this.editPhito = registre
            this.vineyard = registre.vineyard
            this.vineyard.treatment.dose = registre.vineyard.treatment.dose;
            this.edited = true
            this.add = true
        },
        async editing() {
            if (this.$refs.form.validate()) {

                await xhr.post('/edit-phito', {
                    vineyard: this.editPhito,
                    editVineyard: this.vineyard
                })
                await this.load()
                this.snack = 'Votre produit phytosanitaire a bien été modifié'
                this.snackbarLauch = true
                this.edited = false
                this.add = false
            }
        },
        onCitySelect(item) {
            this.vineyard.location.department = item.Code_postal
            this.vineyard.location.gps = item.coordonnees_gps
        },
        formatDate(date) {
            if (!date) return null;
            console.log("date", date);
            const [year, month, day] = date.split('-');
            return `${day}/${month}/${year}`;
        },
        updateDose(val) {
            this.vineyard.treatment.dose = val;
        }
    },
    watch: {
        'vineyard.treatment.date'(newVal) {
            this.vineyard.treatment.date = newVal;
        },

        'vineyard.disease.firstSighting'(newVal) {
            console.log("laaa", newVal);
            this.vineyard.disease.firstSighting = newVal;
        },
    },
    computed: {
        filteredPhytos() {
            // Filtre les phytos basé sur la chaîne de recherche
            const search = this.searchQuery.toLowerCase();
            console.log("this.phytos !!", this.phytos);
            if (search.length > 2 && this.phytos.length) {
                return this.phytos.filter((item) => {
                    return (
                        item.vineyard.treatment.productName.toLowerCase().includes(search) ||
                        item.vineyard.treatment.ref.toLowerCase().includes(search) ||
                        item.vineyard.location.domain.toLowerCase().includes(search) ||
                        item.vineyard.disease.name.toLowerCase().includes(search)
                    );
                });
            }

            return this.phytos

        }
    }
}
</script>