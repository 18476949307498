import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VSheet,{staticClass:"pa-2 mx-auto",attrs:{"width":"100%"}},[_c(VContainer,[_c(VRow,[_c(VCol,[_c(VAlert,{attrs:{"type":"success","dense":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-check-circle")])]},proxy:true}])},[_c('div',[_c('h3',[_vm._v("Félicitations pour votre abonnement !")]),_c('p',[_vm._v("Merci de vous être abonné à notre offre Premium/Entreprise. Vous avez fait un excellent choix pour votre entreprise.")]),_c('p',[_vm._v("Pour vous aider à tirer le meilleur parti de notre service, nous vous invitons à regarder notre vidéo de démonstration . Découvrez toutes les fonctionnalités et astuces pour maximiser votre expérience.")]),_c('p',[_vm._v("Redirection dans "+_vm._s(_vm.counter)+" secondes...")])])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }