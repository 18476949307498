<template>
    <v-sheet width="100%" class="pa-2 mx-auto">
        <v-container>
            <v-row>
                <v-col>
                    <v-alert type="success" dense>
                        <template v-slot:prepend>
                            <v-icon large>mdi-check-circle</v-icon>
                        </template>
                        <div>
                            <h3>Félicitations pour votre abonnement !</h3>
                            <p>Merci de vous être abonné à notre offre Premium/Entreprise. Vous avez fait un excellent choix
                                pour votre entreprise.</p>
                            <p>Pour vous aider à tirer le meilleur parti de notre service, nous vous invitons à regarder
                                notre vidéo de démonstration . Découvrez toutes les
                                fonctionnalités et astuces pour maximiser votre expérience.</p>
                            <p>Redirection dans {{ counter }} secondes...</p>
                        </div>
                    </v-alert>
                </v-col>
            </v-row>
        </v-container>

    </v-sheet>
</template>

<script>
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import xhr from '../plugins/axios';

export default {
    name: 'PremiumSuccess',
    components: {
        StripeElementCard,
        StripeCheckout
    },
    data() {
        return {
            counter: 5,
            publishableKey: "",
            token: null,
            loading: false,
            customerId: "cus_P7oOPrMpmmJ8Eh",
            customerEmail: "julien@taiwa.fr",
            loading: false,

            successURL: 'your-success-url',
            cancelURL: 'your-cancel-url',
            lineItems:
                [
                    [
                        {
                            price: 'price_1OJvBZIo5pmAhUysgj3BS7vJ', // The id of the recurring price you created in your Stripe dashboard
                            quantity: 1,
                        }],

                    [{
                        price: 'price_1OJvXoIo5pmAhUysKgAO6l2s', // The id of the recurring price you created in your Stripe dashboard
                        quantity: 1,
                    }],
                    [{
                        price: 'price_1OJvaQIo5pmAhUysFu7O6cbj', // The id of the recurring price you created in your Stripe dashboard
                        quantity: 1,
                    }],
                    [{
                        price: 'price_1OJvayIo5pmAhUysHJ7U9wGW', // The id of the recurring price you created in your Stripe dashboard
                        quantity: 1,
                    }],

                ],
            successURL: 'https://cartovia.fr/api/success-payment',
            cancelURL: 'https://cartovia.fr/api/cancel-payment',
        }
    },
    created() {
        console.log(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY, "process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;");
        this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    },
    mounted() {
        this.startCountdown();
    },
    methods: {
        async startCountdown() {
            const intervalId = setInterval(async () => {
                if (this.counter > 0) {
                    this.counter -= 1;
                } else {
                    clearInterval(intervalId);


                    let user = localStorage.getItem("user");
                    let uid = localStorage.getItem("uid");

                    if (user) {
                        user = JSON.parse(user)
                        console.log("uid logged: !! ", uid);
                        let res = await xhr.post('/load-user', {
                            uid: uid
                        })
                        let subscription = res.data.subscription
                        user.subscription = subscription
                        user.subscribed = true
                        localStorage.setItem("user", JSON.stringify(user));
                        window.location = '/'
                        // this.$router.push('/'); // Remplacez '/destination' par votre route cible
                    }

                }
            }, 1000);
        },
        submit() {
            // this will trigger the process
            // this.$refs.elementRef.submit();

            this.$refs.checkoutRef.redirectToCheckout();

        },
        tokenCreated(token) {
            console.log(token);
            // handle the token
            // send it to your server
        },
    }

}
</script>