<template>

<div>
    <v-sheet :loading="!inventaires.length" class="pa-2">
        <v-progress-linear v-if="!inventaires.length" indeterminate color="yellow darken-2"></v-progress-linear>
        <v-data-table  sortBy="dateInventaire" :search="search"  v-if="inventaires.length" 
          :loading="!inventaires.length" item-key="id"
           :sort-desc="true" :headers="headers" :items="inventaires" :items-per-page="50">
          <template v-slot:top>
            <v-text-field v-model="search" label="Rechercher un inventaire" class="mx-2"></v-text-field>
          </template>

          <template v-slot:item="{ item }">
            <v-card :width="350" @click="$router.push({ name: 'Detail', params: { id: item._id } })" class="pa-2 ma-2">
              <v-col class="py-2">
                <div><a>{{ item.name }}</a></div>
                <p><small>{{ item.description }}</small></p>
              </v-col>
              <v-col class="py-2">
                <v-chip><v-icon dark class="pa-2">mdi-calendar</v-icon>Fait le {{ formatDate(item.dateInventaire) }}</v-chip>
              </v-col>
          </v-card>
          <!-- ... autres colonnes ... -->
        </template>
      
          <!-- <template v-slot:item.action="{ item }">
            <v-btn @click="open(item)" color="primary" dark small outlined><v-icon dark>mdi-pencil</v-icon></v-btn>
            <v-btn @click="conf(item)" class="ma-2" color="red" small outlined>
              <v-icon dark>mdi-cancel</v-icon>
            </v-btn>
          </template> -->
        </v-data-table>
        
            

        <!-- <v-btn class="ma-2" dark color="secondary" @click="dialog = 'true'">Ajouter un arbre</v-btn> -->
    </v-sheet>
</div>
</template>

<script>

import xhr from '../plugins/axios';
import axios from 'axios';
import moment from 'moment';
moment.locale('fr');
const dt = new Date();

import './global.scss'
import datas from '../assets/forms.json' 

export default {
  name: 'Liste',
  components: {
  },
  data(vm) {
    return {
      inventaires: [],
      alreadyLoad: false,
      loading: false,
      selectedInventaire: "",
      filterEssence: [],
      filterQualite: [],
      filterHauteur: 0,
      filterDiametre: 0,
      dialogFilter: false,
      allessences: [],
      allqualites: [
        'Bonne', 'Brogneux', 'Chablis', 'Chauffage', 'Excellente',
        'Gélif', 'Mort à conserver', 'Mauvaise', 'Médiocre',
        'Sain', 'Sec', 'Semis', 'Taillis', 'Taré', 'Très bonne',
        'Très mauvaise', 'Très médiocre', 'Toutes', 'Alpha',
        'Bravo', 'Charlie', 'Delta', 'Plot', 'Poteau', 'Sciage',
        'Avivé', 'Merrain', 'Palette', 'Emballage', 'Déroulage',
        'Charpente', 'Traverse', 'Piquet', 'Canter', 'Bûche',
    ],
      inventairesSearch: [],
      inventairesLoaded: [],
      loadGeoloc: true,
      dialogLoad: false,
      connected: localStorage.getItem("user"),
      valid: false,
      dialog: false,
      nameLoc: null,
      geoloc: { lat: 45.764043, lng: 4.835659 },
      essences: [],
      dialogFilter: false,
      nomInventaire: "",
      geoInventaire: null,
      selectedFile : null,
      dateInventaire: new Date().toISOString().substr(0, 10),
      dialogInventaire: false,
      nameRules: [(v) => !!v || 'Champs est requis', (v) => v.length <= 2 || 'Champsd moins de 2 caractères'],
      arbre: {
        id: null,
        qualite: '',
        essence: '',
        diametre: '',
        hauteur: '',
        volume: '',
        hauteurmoyenne: '',
        tarif: '',
      },
      selectedInventaireObj : {},
      parcelles: [],
      licenses: [],
      licensesTwo: [],
      inventaire: null,
      license: '',
      verifyRes: '',
      queryTerm: '',
      valid: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      dateFormatted: vm.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      ),
      headers: [
      { text: 'Nom', value: 'name' },
      { text: 'Description', value: 'description' },
      { text: 'Date', value: 'dateInventaire' },
      // {
      //     text: 'Action',
      //     value: 'action',
      //     align: 'end',
      //     sortable: false,
      //   },
      ],
      user: {
        nom: '',
        prenom: '',
        email: '',
        password: '',
        idDevice: '',
        entreprise: '',
        statut: true,
        token: '',
      },
      rules: {
        required: (value) => !!value || 'Obligatoire.',
        min: (value) => (value && value.length > 3) || 'Pas assez long',
        password: (value) => (value && value.length > 7) || 'Pas assez long',
        email: (value) => email.test(value) || 'E-mail non valide',
        long: (value) => long.test(value) || 'Non valide',
        repass: (value) => (value && value.length > 7 && this.manager.password == value) || 'Confirmation non valide',
      },
      action: 'new',
      dialogManager: false,
      snackbar: false,
      dialog: false,
      snack: 'Votre compte a bien été activé',
      confirm: false,
      users: [],
   
      styles: {
        type: Object,
        default: () => {},
      },
      plugins: {
        type: Object,
        default: () => {},
      },
      areaData: {
        datasets: [
          { fill: 'origin' }, // 0: fill to 'origin'
          { fill: '+2' }, // 1: fill to dataset 3
          { fill: 1 }, // 2: fill to dataset 1
          { fill: false }, // 3: no fill
          { fill: '-2' }, // 4: fill to dataset 2
          { fill: { value: 25 } }, // 5: fill to axis value 25
        ],
      },
      draw : null,
    };
  },
  
  async created() {
        let user = localStorage.getItem("user");
    if (user) {
      this.user = JSON.parse(user)
      console.log("user", user, this.user.id);
      if (this.user.displayName) {
        this.user.displayName = this.user.displayName
      } else {
        this.user.displayName = this.user.name
      }

     
      let inventairesAll = await xhr.post('/inventaires-all', { user: this.user.uid });
      this.inventaires = inventairesAll.data

      this.inventairesSearch = inventairesAll.data.map((e) => {
        let mom = moment(e.dateInventaire)
        return {
          id: e._id,
          name: e.name,
          dateInventaire: mom.format('DD/MM/YYYY'),
          geo: e.geo,
          description: e.description
        }
      })
    }
  
  },
  mounted() {
    // this.$refs.calendar.checkChange()
  },
  methods: {

    
    formatDate(datIn) {
      return moment(datIn).format('DD/MM/YYYY');
    },
   
    async loadEssences() {
      let datas =  await xhr.get('/essences')
      this.allessences = datas.data.map((e) => e.essences)
    },
 
    async filterArbres(){

    },
    async changeAdress(){

      let res = await axios.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + this.geoInventaire +'&key=AIzaSyAFCIaImVWVYAWbciVU87hfC_p_Iw1J_3g&region=fr')
      if(res.data.status === "OK"){
        this.nameLoc = res.data.results[0].formatted_address
        this.geoloc = res.data.results[0].geometry.location
        this.loadGeoloc = false
        this.geoInventaire = this.nameLoc
        this.map.flyTo({
          center: [this.geoloc.lat, this.geoloc.lng,]
        });
      }
    },
    handleFileImport() {
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      console.log("this.selectedFile", this.selectedFile);
    },
    async createInventaire(){
      let formData = new FormData();
      formData.append("file", this.selectedFile);
      formData.append("user", this.user.uid);
      formData.append("nomLocat", this.nomInventaire);
      formData.append("geometry", this.geoloc);
      formData.append("geoLocat", this.geoInventaire);
      formData.append("dateInventaire", this.dateInventaire);

      await xhr.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      this.snackbar = true;
      this.snack = 'Votre arbre a bien été sauvegarder';

      let lic = await xhr.post("/save", { 
        inventaire: this.inventaire,
        datas: this.arbre,
        arbre: this.arbre.clef,
        user: 456 
      })
      this.dialogInventaire = false
      let inventaires = await xhr.post('/inventaires', { user: this.user.uid });
      this.inventaires = inventaires.data;

    },
    sended(file, xhr, formData) {
      formData.append('user', this.user.uid);
    },

    open(elt) {
      this.arbre.qualite = elt.qualite;
      this.arbre.essence = elt.essence;
      this.arbre.hauteur = elt.hauteur;
      this.arbre.volume = elt.volume;
      this.arbre.diametre = elt.diametre;
      this.arbre.foret = elt.foret;
      this.arbre.id = elt.id;
      this.arbre.tarif = elt['tarif'];
      this.arbre.hauteurmoyenne = elt['hauteurmoyenne'];

      this.dialog = true;
    },


    formatDate(datIn) {
      return moment(datIn).format('DD/MM/YYYY');
    },
    
  },
  watch: {
    filterHauteur(val){
    },
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  computed: {
    filterEssences(){
       let datas = this.inventaires

          console.log("Goo!!");

        if(this.filterHauteur > 1 || this.filterDiametre > 1){
          if (this.filterHauteur > 1) {
            datas = datas.filter((e) => parseInt(e.hauteur) >= this.filterHauteur)
          }

          if (this.filterDiametre > 1) {
            datas = datas.filter((e) => parseInt(e.diametre) >= this.filterHauteur)
          }
        }


      if (this.filterEssence.length ||
      this.filterQualite.length 
      ) {
        let ph = this.filterEssence.join(' ').toLowerCase()
        let ph2 = this.filterQualite.join(' ').toLowerCase()

        if(ph.length && ph2.length){
          datas = datas.filter((e) => ph.includes(e.essence.toLowerCase()) && ph2.includes(e.qualite.toLowerCase()))
        }else if(ph.length){
          datas = datas.filter((e) => ph.includes(e.essence.toLowerCase()))
        } else if (ph2.length) {
          datas =  datas.filter((e) => ph2.includes(e.essence.toLowerCase()))
        }
      } 

      return datas
    },
     search: {
      get() {
        return this.queryTerm
      },

      set(searchInput) {
        if (this.queryTerm !== searchInput) {
          this.queryTerm = searchInput
          this.loadEssences()
        }
      }
    },

    computedDateFormatted() {
      return this.formatDate(this.date);
    },

    usersFilter() {
      if (this.search.length > 2) {
        let reg = new RegExp(this.search, 'ig');
        return this.users.filter(
          (elt) =>
            reg.test(elt.email) ||
            reg.test(elt.nom) ||
            reg.test(elt.prenom) ||
            reg.test(elt.uid) | reg.test(elt.trial) ||
            reg.test(elt.token),
        );
      }
      return this.users;
    },
    popupCoordinates() {
      // Here we can do some work for calculate proper coordinates
      //...
      return [4.8342778, 45.763421];
    },
  },
 
};
</script>

<style>
#draggable-container {
  z-index: 99999999999999;
}
.pulse {
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
}
@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
#calculated-area {
  height: 75px;
  width: 150px;
  position: absolute;
  bottom: 40px;
  left: 10px;
  color: black;
  font-size: 18px;
      z-index: 1000000000;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  text-align: center;
}

p {
  font-family: 'Open Sans';
  margin: 0;
  font-size: 13px;
}
.dropzone-custom-content {
}

.dropzone-custom-title {
  color: #00b782;
}

.subtitle {
  color: #314b5f;
}

</style>
