<template>
    <v-container>

        <v-snackbar absolute top color="primary" shaped v-model="snackbarLauch">
            {{ snack }}
        </v-snackbar>

        <v-row dense>
            <v-col v-if="profil == 'forestier'" class="pa-3 d-flex justify-center align-center">
                <p><b>{{ inventaires.length }}</b> arbres dans les parcelles <b>"{{ parcelles.join(", ") }}"</b> avec
                    <b>{{ essencesForet.join(", ") }}</b> en essences d'arbres
                </p>
            </v-col>
        </v-row>
        <hr>
        <h3 class="pa-3 d-flex justify-center align-center"><v-icon>mdi-clipboard-list-outline</v-icon>Tableau des
            règles</h3>

        <v-row>

            <v-col cols="12">
                <v-list>
                    <v-list-item-group>
                        <template v-for="(rule, index) in rules">
                            <v-list-item :key="`item-${index}`">
                                <v-list-item-content @click="edit(rule)">
                                    <v-list-item-title>Règle <b>{{ rule.title }}</b></v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-btn @click="remove(rule)" icon outlined>
                                        <v-icon color="red">mdi-delete</v-icon>
                                    </v-btn>
                                </v-list-item-action>

                            </v-list-item>
                        </template>
                    </v-list-item-group>
                </v-list>
            </v-col>

        </v-row>
        <v-row>
            <v-col cols="12">
                <v-text-field label="Titre de la règle" class="d-flex justify-center align-center"
                    v-model="title"></v-text-field>
            </v-col>

            <v-col cols="12">
                <div class="d-flex justify-end mt-3">
                    <v-btn text dark rounded color="green" @click="addRow"><v-icon>mdi-plus</v-icon>Ajouter une
                        règle</v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-simple-table  class="fill-width">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Essence
                                </th>
                                <th class="text">
                                    Diamètre min.
                                </th>
                                <th class="text">
                                    Diamètres max.
                                </th>
                                <th class="text">
                                    Hauteur
                                </th>
                                <th class="text">
                                    Qualité
                                </th>
                                <th class="text">
                                    Prix €/m3
                                </th>
                                <th class="text">
                                    Coeff
                                </th>
                                <th class="text">
                                    Biais
                                </th>
                                <th class="text-right">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody cols="12">
                            <tr v-for="item, index in datas" :key="item.name">
                                <td>
                                    <v-select v-model="item.essence" :items="essences" dense solo flat>
                                    </v-select>
                                </td>
                                <td cols="2">
                                    <v-text-field v-model="item.diametremin" type="number" dense solo flat hint="cm." />
                                </td>
                                <td cols="2">
                                    <v-text-field v-model="item.diametremax" type="number" dense solo flat hint="cm." />
                                </td>
                                <td> <v-text-field v-model="item.hauteur" type="number" dense solo flat
                                        hint="m.">
                                    </v-text-field></td>
                                <td><v-select v-model="item.qualite" :items="qualites" dense solo flat>
                                    </v-select></td>
                                <td><v-text-field v-model="item.prix" dense solo flat hint="€" />
                                </td>
                                <td> <v-text-field v-model="item.coeff" type="number" dense solo flat hint="n">
                                    </v-text-field>
                                </td>
                                <td> <v-text-field v-model="item.biais" type="number" dense solo flat hint="k">
                                    </v-text-field>
                                </td>
                                <td><v-btn color="red" icon left
                                        @click="remove(item)"><v-icon>mdi-minus-circle</v-icon></v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>

        </v-row>
        <v-card class="pa-3">
            <v-row class="justify-end">
                <v-col cols="12" class="text-right">
                    <v-chip dark color="primary">Nb arbres: {{ nbArbresconcernes }} / {{ inventaires.length
                        }} arbres concernés</v-chip>
                </v-col>
            </v-row>
            <v-row class="justify-end">
                <v-col cols="2" class="text-right">
                    <v-select dense :items="tvaOptions" v-model="tvaSelected" label="Choisir la TVA"
                        @change="updateTotalTTC"></v-select>
                </v-col>
            </v-row>
            <v-row class="justify-end">
                <v-col cols="12" class="text-right">
                    <h4>Prix Total HT: <b>{{ total }}€</b></h4>
                </v-col>
            </v-row>
            <v-row class="justify-end">
                <v-col cols="12" class="text-right">
                    <h3>Prix Total TTC: <b>{{ totalTTC.toFixed(2) }}€</b></h3>
                </v-col>
            </v-row>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="!edited" big @click="saveRules" rounded
                    color="primary"><v-icon>mdi-content-save-check</v-icon>
                    Ajouter ce
                    tableau
                    de
                    règles</v-btn>

                <v-btn v-else big @click="editRules" rounded color="success"><v-icon>mdi-content-save-check</v-icon>
                    Editer ce
                    tableau
                    de
                    règles</v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import essencesImp from './essences.json'
import xhr from '../plugins/axios';

export default {
    name: 'Simulator',
    props: ['inventaires', 'invId', 'profil', 'uid'],
    components: {
    },

    data(vm) {
        return {
            snackbarLauch: false,
            snack: '',
            title: "",
            rules: [],
            tvaSelected: 20, // Valeur initiale de la TVA
            tvaOptions: [5, 10, 20], // Options de TVA disponibles
            nbArbresconcernes: 0,
            essences: [],
            qualites: [
                'Bonne', 'Brogneux', 'Chablis', 'Chauffage', 'Excellente',
                'Moyenne',
                'Gélif', 'Mort à conserver', 'Mauvaise', 'Médiocre',
                'Sain', 'Sec', 'Semis', 'Taillis', 'Taré', 'Très bonne',
                'Très mauvaise', 'Très médiocre', 'Toutes', 'Alpha',
                'Bravo', 'Charlie', 'Delta', 'Plot', 'Poteau', 'Sciage',
                'Avivé', 'Merrain', 'Palette', 'Emballage', 'Déroulage',
                'Charpente', 'Traverse', 'Piquet', 'Canter', 'Bûche',
            ],
            datas: [
            ],
            edited: false,
            add: true,
            editedRule: {},
        }
    },
    async created() {
        this.essences = essencesImp.map((e) => e.essences)
        this.datas = this.essencesForet.map((e) => {
            return {
                essence: e, diametremin: 0, diametremax: 200, qualite: "Bonne", hauteur: 0, prix: 0, coeff: 1, biais: 0
            }
        })
        await this.load()
    },
    mounted() {
        // this.updateTotalTTC();
    },
    computed: {
        totalTTC() {
            return this.total * (1 + this.tvaSelected / 100);
        },
        parcelles() {
            let essences = this.inventaires.map((e) => e.par)
            let uniqueEssences = [...new Set(essences)];
            return uniqueEssences
        },
        essencesForet() {
            let essences = this.inventaires.map((e) => e.essence)
            let uniqueEssences = [...new Set(essences)];
            return uniqueEssences

        },
        total() {
            let tot = 0;
            this.nbArbresconcernes = 0
            this.datas.forEach((regle) => {
                // Pré-conversion des valeurs numériques de la règle
                let hauteurRegle = parseFloat(regle.hauteur) || 0;
                let diametreMinRegle = parseFloat(regle.diametremin) || 0;
                let diametreMaxRegle = parseFloat(regle.diametremax) || 200;
                let prixRegle = parseFloat(regle.prix) || 0;
                let coeffRegle = parseFloat(regle.coeff) || 0;
                let biais = parseFloat(regle.biais) || 0;
                let essence = regle.essence || null
                let qualite = regle.qualite || null
                let nbInt = 0
                let nbArbre = this.inventaires.reduce((acc, arbre) => {
                    let hauteurArbre = parseFloat(arbre.hauteur);
                    let diametreArbre = parseFloat(arbre.diametre);

                    let correspondEssence = arbre.essence === essence && arbre.qualite === qualite;
                    let correspondHauteur = hauteurRegle > 0 && hauteurArbre === hauteurRegle;
                    let correspondDiametre = diametreMaxRegle > 0 && diametreArbre < diametreMaxRegle && diametreArbre >= diametreMinRegle;
                    if (correspondEssence && correspondHauteur && correspondDiametre) {
                        nbInt++
                    }
                    return acc + (correspondEssence && correspondHauteur && correspondDiametre ? 1 : 0);
                }, 0);
                this.nbArbresconcernes += nbInt
                tot += prixRegle * nbArbre * coeffRegle + biais;
            });

            return tot;
        }
    },

    methods: {

        edit(rule) {

            this.editedRule = rule
            this.datas = rule.datas
            this.title = rule.title
            this.edited = true
            this.add = true
        },

        async remove(rule) {
            let conf = confirm('Voulez-vous supprimer le tableau de règle?')
            if (conf == true) {
                await xhr.post('/remove-rule', {
                    rid: rule._id,
                })

                await this.load()
                this.add = false
                this.snack = 'Votre règle a bien été supprimée'
                this.snackbarLauch = true
            }
        },
        async editRules() {
            await xhr.post('/edit-rule', {
                ris: this.editedRule._id,
                title: this.title,
                datas: this.datas,
            })

            this.add = false
            this.snack = 'Votre produit phytosanitaire a bien été ajouté'
            this.snackbarLauch = true
            this.title = ""
            this.datas = this.essencesForet.map((e) => {
                return {
                    essence: e, diametremin: 0, diametremax: 200, qualite: "Bonne", hauteur: 0, prix: 0, coeff: 1, biais: 0
                }
            })
            await this.load()
        },
        async saveRules() {
            await xhr.post('/add-rule', {
                datas: this.datas,
                uid: this.uid,
                title: this.title,
                invId: this.invId
            })

            this.add = false
            this.snack = 'Votre produit phytosanitaire a bien été ajouté'
            this.snackbarLauch = true
            this.title = ""
            this.datas = this.essencesForet.map((e) => {
                return {
                    essence: e, diametremin: 0, diametremax: 200, qualite: "Bonne", hauteur: 0, prix: 0, coeff: 1, biais: 0
                }
            })
            await this.load()
        },
        async load() {
            let rules = await xhr.post('/load-rules', {
                uid: this.uid
            });
            this.rules = rules.data;
        },
        addRow() {
            this.datas.push({ essence: "Chêne commun", qualite: "Bonne", prix: 0, diametremin: 0, diametremax: 200, hauteur: 0, min: 0, max: 0, coeff: 1, biais: 0 });
        },


    }

}
</script>