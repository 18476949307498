<template>
  <v-sheet class="pa-2">

    <v-card class="mx-auto" max-width="550" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-overline mb-4">
            <v-icon>mdi-account</v-icon> Mon Compte
          </div>
          <v-list-item-title class="text-h5 mb-1">
            {{ user.displayName }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          <v-list-item-subtitle>Professionel</v-list-item-subtitle>
        </v-list-item-content>

        <v-img
          :src="user.photoURL ? user.photoURL : 'https://e7.pngegg.com/pngimages/715/371/png-clipart-youtube-google-logo-google-s-google-account-youtube-text-trademark.png'"></v-img>
      </v-list-item>

      <v-card-actions>
        <v-btn outlined rounded text color="primary">
          <v-icon>mdi-pencil</v-icon> Editer mon profil
        </v-btn>
        <v-btn outlined rounded text color="danger">
          <v-icon>mdi-pencil</v-icon> Se deconneter
        </v-btn>
      </v-card-actions>
    </v-card>


  </v-sheet>
</template>

<script>
export default {
  name: 'Account',
  components: {
  },
  data() {
    return {
      user: {}
    }
  },
  created() {
    let user = localStorage.getItem("user");
    if (user) {
      this.user = JSON.parse(user)
    }

  },


}
</script>