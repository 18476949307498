<template>
    <v-container>


        <v-sheet>
            <v-row dense>
                <v-col v-if="profil == 'forestier'" class="pa-3 d-flex justify-center align-center">
                    <p><b>{{ inventaires.length }}</b> arbres dans les parcelles <b>"{{ parcelles.join(", ") }}"</b>
                        avec
                        <b>{{ essencesForet.join(", ") }}</b> en essences d'arbres
                    </p>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6" v-if="user.roles == 'fredon'">
                    <h3>Evolution temporelle de l'inventaires par secteur</h3>
                    <Bar :chart-data="chartDataH" :chart-options="chartOptionsH"></Bar>
                </v-col>

                <v-col cols="12" md="6" v-if="user.roles == 'fredon'">
                    <h3>Evolution temporelle de l'inventaires par membre</h3>
                    <Bar :chart-data="chartDataByOperator" :chart-options="chartOptionsI"></Bar>
                </v-col>
            </v-row>

            <v-row dense>
                <!-- <div v-for="(stats, property) in calculateStatistics()" :key="property">
                <h3>{{ property }}</h3>
                <p>Minimum: {{ stats.min }}</p>
                <p>Maximum: {{ stats.max }}</p>
                <p>Average: {{ stats.average }}</p>
            </div> -->
                <v-col cols="12" md="4" v-if="profil == 'forestier' && !isNaN(moyenneDiametre)">
                    <v-card black color="orange" class="pa-3 d-flex justify-center align-center white--text">
                        <v-card-title>Diamètre moyen</v-card-title>
                        <v-chip primary black label>{{ moyenneDiametre }} cm.</v-chip>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4" v-if="profil == 'forestier' && !isNaN(maxDiametre)">
                    <v-card black color="orange" class="pa-3 d-flex justify-center align-center white--text">
                        <v-card-title>Diamètre max</v-card-title>
                        <v-chip primary black label>{{ maxDiametre }} cm.</v-chip>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4" v-if="profil == 'forestier' && !isNaN(minDiametre)">
                    <v-card black color="orange" class="pa-3 d-flex justify-center align-center white--text">
                        <v-card-title>Diamètre min</v-card-title>
                        <v-chip primary black label>{{ minDiametre }} cm.</v-chip>
                    </v-card>
                </v-col>
            </v-row>

            <v-row dense>
                <v-col cols="12" md="4" v-if="profil == 'forestier' && !isNaN(moyenneHauteur)">
                    <v-card black color="secondary" class="pa-3 d-flex justify-center align-center white--text">
                        <v-card-title>Hauteur moyen</v-card-title>
                        <v-chip primary black label>{{ moyenneHauteur }} m.</v-chip>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4" v-if="profil == 'forestier' && !isNaN(maxHauteur)">
                    <v-card black color="secondary" class="pa-3 d-flex justify-center align-center white--text">
                        <v-card-title>Hauteur max</v-card-title>
                        <v-chip primary black label>{{ maxHauteur }} m.</v-chip>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4" v-if="profil == 'forestier' && !isNaN(minHauteur)">
                    <v-card black color="secondary" class="pa-3 d-flex justify-center align-center white--text">
                        <v-card-title>Hauteur min</v-card-title>
                        <v-chip primary black label>{{ minHauteur }} m.</v-chip>
                    </v-card>
                </v-col>
            </v-row>

            <v-row dense>
                <v-col cols="12" md="4" v-if="profil == 'forestier' && !isNaN(moyenneVolume)">
                    <v-card black color="primary" class="pa-3 d-flex justify-center align-center white--text">
                        <v-card-title>Volume moyen</v-card-title>
                        <v-chip primary black label>{{ moyenneVolume }} m3</v-chip>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4" v-if="profil == 'forestier' && !isNaN(maxVolume)">
                    <v-card black color="primary" class="pa-3 d-flex justify-center align-center white--text">
                        <v-card-title>Volume max</v-card-title>
                        <v-chip primary black label>{{ maxVolume }} m3</v-chip>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4" v-if="profil == 'forestier' && !isNaN(minVolume)">
                    <v-card black color="primary" class="pa-3 d-flex justify-center align-center white--text">
                        <v-card-title>Volume min</v-card-title>
                        <v-chip primary black label>{{ minVolume }} m3</v-chip>
                    </v-card>
                </v-col>
            </v-row>

            <v-row dense>
                <v-col cols="12" md="6" v-for="prop in presentProperties" :key="prop" v-if="generateChartData(prop)">
                    <Stat :datas="generateChartData(prop)" :prop="prop" />
                </v-col>


                <!-- <v-col cols="12" md="6" v-if="profil == 'forestier'">
                <v-card class="pa-3">
                    <v-card-title>Les traitements</v-card-title>
                </v-card>
            </v-col> -->
                <v-col cols="12" md="6" v-if="profil == 'forestier' && chartData2.length">
                    <v-card class="pa-3">
                        <v-card-title>Les essences
                        </v-card-title>
                        <Doughnut :chartData="chartData2" :chartOptions="chartOptions" />
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" v-if="profil == 'vigneron' && chartData2Vign.length">
                    <v-card class="pa-3">
                        <v-card-title>Les états</v-card-title>
                        <Doughnut :chartData="chartData2Vign" :chartOptions="chartOptions" />
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" v-if="profil == 'forestier' && chartData3.length">
                    <v-card class="pa-3">
                        <v-card-title>Les qualités</v-card-title>
                        <Doughnut :chartData="chartData3" :chartOptions="chartOptions" />
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" v-if="profil == 'vigneron' && chartData3Vign.length">
                    <v-card class="pa-3">
                        <v-card-title>Les cépages</v-card-title>
                        <Doughnut :chartData="chartData3Vign" :chartOptions="chartOptions" />
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" v-if="profil == 'vigneron' && chartData4Vign.length">
                    <v-card class="pa-3">
                        <v-card-title>Les domaines</v-card-title>
                        <Doughnut :chartData="chartData4Vign" :chartOptions="chartOptions" />
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" v-if="profil == 'vigneron' && chartData4.length">
                    <v-card class="pa-3">
                        <v-card-title>Les parcelles</v-card-title>
                        <Doughnut :chartData="chartData4" :chartOptions="chartOptions" />
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" v-if="profil == 'forestier' && chartData8.length">
                    <v-card class="pa-3">
                        <v-card-title>Les volumes</v-card-title>
                        <Doughnut :chartData="chartData8" :chartOptions="chartOptions" />
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" v-if="profil == 'forestier' && chartData9.length">
                    <v-card class="pa-3">
                        <v-card-title>Les diamètres</v-card-title>
                        <Doughnut :chartData="chartData9" :chartOptions="chartOptions" />
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" v-if="profil == 'forestier' && chartData10.length">
                    <v-card class="pa-3">
                        <v-card-title>Les tarifs chaudés</v-card-title>
                        <Doughnut :chartData="chartData10" :chartOptions="chartOptions" />
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" v-if="profil == 'forestier' && chartData11.length">
                    <v-card class="pa-3">
                        <v-card-title>Les hauteurs</v-card-title>
                        <Doughnut :chartData="chartData11" :chartOptions="chartOptions" />
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" v-if="profil == 'forestier' && chartData12.length">
                    <v-card class="pa-3">
                        <v-card-title>Les hauteurs moyennes</v-card-title>
                        <Doughnut :chartData="chartData12" :chartOptions="chartOptions" />
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" v-if="profil == 'forestier' && chartData11.length">
                    <v-card class="pa-3">
                        <v-card-title>Les hauteurs</v-card-title>
                        <Doughnut :chartData="chartData11" :chartOptions="chartOptions" />
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" v-if="profil == 'forestier' && chartData5bis.length">
                    <v-card class="pa-3">
                        <v-card-title>Les forêts</v-card-title>
                        <Doughnut :chartData="chartData5bis" :chartOptions="chartOptions" />
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" v-if="profil == 'forestier' && chartData5.length">
                    <v-card class="pa-3">
                        <v-card-title>Les sous-parcelles</v-card-title>
                        <Doughnut :chartData="chartData5" :chartOptions="chartOptions" />
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" v-if="profil == 'vigneron' && chartData5Vign.length">
                    <v-card class="pa-3">
                        <v-card-title>Les sous-parcelles</v-card-title>
                        <Doughnut :chartData="chartData5Vign" :chartOptions="chartOptions" />
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" v-if="profil == 'vigneron' && chartData6.length">
                    <v-card class="pa-3">
                        <v-card-title>Les utilisateurs</v-card-title>
                        <Doughnut :chartData="chartData6" :chartOptions="chartOptions" />
                    </v-card>
                </v-col>
            </v-row>

        </v-sheet>

    </v-container>
</template>

<script>
import Stat from './Stat'
import { Bar, Doughnut, Radar, Line,} from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend,  BarElement, LineElement, RadialLinearScale, PointElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'
import 'chart.js/auto';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LineElement, LinearScale, RadialLinearScale,  PointElement,  ArcElement)


export default {
    name: 'Stats',
    props: ['inventaires', 'profil', 'user'],
    components: {
        Doughnut,
        Bar,
        Radar,
        Line: Line,
        Stat
    },
    data(vm) {
        return {
         
            chartOptionsH: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top'
                    },
                    title: {
                        display: true,
                        text: 'Nombre de points par secteur et par heure'
                    }
                },
                scales: {
                    x: {
                        stacked: true,
                        title: {
                            display: true,
                            text: 'Heure de la journée'
                        }
                    },
                    y: {
                        stacked: true,
                        title: {
                            display: true,
                            text: "Nombre de points"
                        },
                        ticks: {
                            stepSize: 1,
                            precision: 0
                        }
                    }
                }
            },
            chartOptionsI: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top'
                    },
                    title: {
                        display: true,
                        text: 'Nombre de points par membre et par heure'
                    },
                    
                },
                scales: {
                    x: {
                        stacked: true,
                        title: {
                            display: true,
                            text: 'Heure de la journée'
                        }
                    },
                    y: {
                        stacked: true,
                        title: {
                            display: true,
                            text: 'Nombre de points'
                        },
                        ticks:{
                            stepSize: 1, 
                            precision: 0
                        }

                    }
                }
            },
            knowns: [
                "serie",
                "lieux",
                "commune",
                "region",
                "etat",
                "cepage",
                "parcelle",
                "domaine",
                "essence",
                "annee",
                "materiel",
                "operation",
                "diametre",
                "hauteur",
                "largeur",
                "longueur",
                "volume",
                "surface",
                "stock",
                "price",
                "cépage",
                "type",
                "prix",
                "produit",
                "model",
                "personne",
                "utilisateur",
            ],
            dialogPrices: false,
            globalPrix: 1,
            //  chartData: {
            //     labels: ['January', 'February', 'March'],
            //     datasets: [{ data: [40, 20, 12] }]
            // },
            // chartOptions: {
            //     responsive: true
            // }
        }
    },
    created() {
        if (this.user.roles == "fredon") {
            this.knowns = [
                'operateur',
                'etat',
                'commune',
                'secteur',
                'nature',
                'nom',
                'date',
                'heure',
                
            ]
        }

    },
    computed: {
        chartDataByOperator() {
            const hours = [
                '00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00',
                '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'
            ];
            const inventaires = this.inventaires; // Remplacez ceci par vos données réelles

            // Initialisation des données
            const dataByHour = {};
            const operators = new Set();

            hours.forEach(hour => {
                dataByHour[hour] = {};
            });

            inventaires.forEach(item => {
                let hour = item.heure.split(':');
                hour = hour[0] + ':00';
                const operator = item.nom;

                if (!dataByHour[hour][operator]) {
                    dataByHour[hour][operator] = 0;
                }

                dataByHour[hour][operator]++;
                operators.add(operator);
            });

            // Générer une couleur aléatoire pour chaque opérateur
            const operatorColors = {};
            Array.from(operators).forEach(operator => {
                operatorColors[operator] = this.getRandomColor();
            });

            const datasets = Array.from(operators).map(operator => {
                return {
                    label: operator,
                    data: hours.map(hour => dataByHour[hour][operator] || 0),
                    backgroundColor: operatorColors[operator]
                };
            });

            return {
                labels: hours,
                datasets: datasets
            };
        },
        chartDataH() {
            const hours = [
                '00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00',
                '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'
            ];
            const inventaires = this.inventaires; // Remplacez ceci par vos données réelles

            // Initialisation des données
            const dataByHour = {};
            const sectors = new Set();

            hours.forEach(hour => {
                dataByHour[hour] = {};
            });

            inventaires.forEach(item => {
                let hour = item.heure.split(':');
                hour = hour[0] + ':00';
                const sector = item.secteur;

                if (!dataByHour[hour][sector]) {
                    dataByHour[hour][sector] = 0;
                }

                dataByHour[hour][sector]++;
                sectors.add(sector);
            });

            // Générer une couleur aléatoire pour chaque secteur
            const sectorColors = {};
            Array.from(sectors).forEach(sector => {
                sectorColors[sector] = this.getRandomColor();
            });

            const datasets = Array.from(sectors).map(sector => {
                return {
                    label: sector,
                    data: hours.map(hour => dataByHour[hour][sector] || 0),
                    backgroundColor: sectorColors[sector]
                };
            });

            return {
                labels: hours,
                datasets: datasets
            };
        },
        essences() {
            let essences = this.inventaires.map((e) => e.etat)
            let uniqueEssences = [...new Set(essences)];
            let res = uniqueEssences.map((e) => {
                return {
                    name: e,
                    price: 1
                }
            })
            return res

        },
        essencesForet() {
            let essences = this.inventaires.map((e) => e.essence)
            let uniqueEssences = [...new Set(essences)];
            return uniqueEssences

        },
        parcelles() {
            let essences = this.inventaires.map((e) => e.par)
            let uniqueEssences = [...new Set(essences)];
            return uniqueEssences
        },
        price() {
            return this.globalPrix * this.volume
        },
        volume() {
            // let volumes = this.inventaires.map((e) => parseFloat(e.volume.replace(',', '.')))
            // const sum = volumes.reduce((partialSum, a) => partialSum + a, 0).toFixed(2);
            const sum = 0
            return sum
        },
        minDiametre() {
            let diametres = this.inventaires.map((e) => e.diametre && parseFloat(e.diametre.replace(',', '.')))
            Array.prototype.min = function () {
                return Math.min.apply(null, this);
            };
            return diametres.min()
        },
        maxDiametre() {
            let diametres = this.inventaires.map((e) => e.diametre && parseFloat(e.diametre.replace(',', '.')))
            Array.prototype.max = function () {
                return Math.max.apply(null, this);
            };
            return diametres.max()
        },
        minHauteur() {
            let hauteurs = this.inventaires.map((e) => e.hauteur && parseFloat(e.hauteur.replace(',', '.')))
            Array.prototype.min = function () {
                return Math.min.apply(null, this);
            };
            return hauteurs.min()
        },
        maxHauteur() {
            let hauteurs = this.inventaires.map((e) => e.hauteur && parseFloat(e.hauteur.replace(',', '.')))
            Array.prototype.max = function () {
                return Math.max.apply(null, this);
            };
            return hauteurs.max()
        },
        moyenneHauteur() {
            let hauteurs = this.inventaires.map((e) => e.hauteur && parseFloat(e.hauteur.replace(',', '.')))
            let sum = hauteurs.reduce((partialSum, a) => partialSum + a, 0)
            sum = (sum / this.inventaires.length).toFixed(2);
            return sum
        },
        minVolume() {
            let hauteurs = this.inventaires.map((e) => e.volume && parseFloat(e.volume.replace(',', '.')))
            Array.prototype.min = function () {
                return Math.min.apply(null, this);
            };
            return hauteurs.min()
        },
        maxVolume() {
            let hauteurs = this.inventaires.map((e) => e.volume && parseFloat(e.volume.replace(',', '.')))
            Array.prototype.max = function () {
                return Math.max.apply(null, this);
            };
            return hauteurs.max()
        },
        moyenneVolume() {
            let hauteurs = this.inventaires.map((e) => e.volume && parseFloat(e.volume.replace(',', '.')))
            let sum = hauteurs.reduce((partialSum, a) => partialSum + a, 0)
            sum = (sum / this.inventaires.length).toFixed(2);
            return sum
        },
        moyenneDiametre() {
            let diametres = this.inventaires.filter((e) => e.diametre && e.diametre).map((e) => e.diametre && parseFloat(e.diametre.replace(',', '.')))
            let sum = diametres.reduce((partialSum, a) => partialSum + a, 0)
            sum = "0.00" ? undefined : (sum / this.inventaires.length).toFixed(2);
            return sum
        },
        varianceHauteur() {
            let hauteurs = this.inventaires.map((e) => e.hauteur && parseFloat(e.hauteur.replace(',', '.')))

            return this.variance(hauteurs).toFixed(2)
        },
        varianceDiametre() {
            let diametres = this.inventaires.map((e) => e.diametre && parseFloat(e.diametre  && e.diametre.replace(',', '.')))

            return this.variance(diametres).toFixed(2)
        },
        chartData2Vign() {

            let tabs = []
            let total = this.inventaires.length
            let counts = this.inventaires.reduce((p, c) => {
                var name = c.etat;
                if (!p.hasOwnProperty(name)) {
                    p[name] = 0;
                }
                p[name]++;
                return p;
            }, {});

            for (let item in counts) {
                tabs.push({ nb: counts[item], title: item })
            }

            return {
                labels: tabs.map((e) => e.title),
                datasets: [
                    {
                        label: 'Les états',
                        data: tabs.map((e) => e.nb),
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)',
                            'rgb(134, 105, 26)',
                            'rgb(104, 105, 126)',
                            'rgb(10, 102, 16)',
                        ],
                        hoverOffset: 4
                    }
                ]
            }
        },
        chartData2() {
            // let essences = this.inventaires.map((e) => e.etat)

            let tabs = []
            let total = this.inventaires.length
            let counts = this.inventaires.reduce((p, c) => {
                var name = c.essence;
                if (!p.hasOwnProperty(name)) {
                    p[name] = 0;
                }
                p[name]++;
                return p;
            }, {});

            for (let item in counts) {
                tabs.push({ nb: counts[item], title: item })
            }

            return {
                labels: tabs.map((e) => e.title),
                datasets: [
                    {
                        label: 'Les essences',
                        data: tabs.map((e) => e.nb),
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)',
                            'rgb(134, 105, 26)',
                            'rgb(104, 105, 126)',
                            'rgb(10, 102, 16)',
                        ],
                        hoverOffset: 4
                    }
                ]
            }
        },
        chartData3() {
            // let essences = this.inventaires.map((e) => e.etat)
            let uniqueEssences = [];

            let tabs = []
            let total = this.inventaires.length
            let counts = this.inventaires.filter(e => e.qualite != undefined).reduce((p, c) => {
                var name = c.qualite;
                if (!p.hasOwnProperty(name)) {
                    p[name] = 0;
                }
                p[name]++;
                return p;
            }, {});

            for (let item in counts) {
                tabs.push({ nb: counts[item], title: item })
            }

            return {
                labels: tabs.map((e) => e.title),
                datasets: [
                    {
                        label: 'Répartition des essences',
                        data: tabs.map((e) => e.nb),
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)',
                            'rgb(134, 105, 26)',
                            'rgb(104, 105, 126)',
                            'rgb(10, 102, 16)',
                        ],
                        hoverOffset: 4
                    }
                ]
            }
        },
        chartData3Vign() {
            // let essences = this.inventaires.map((e) => e.etat)

            let tabs = []
            let total = this.inventaires.length
            let counts = this.inventaires.filter(e => e.cepage != undefined).reduce((p, c) => {
                var name = c.cepage;
                if (!p.hasOwnProperty(name)) {
                    p[name] = 0;
                }
                p[name]++;
                return p;
            }, {});

            for (let item in counts) {
                tabs.push({ nb: counts[item], title: item })
            }

            return {
                labels: tabs.map((e) => e.title),
                datasets: [
                    {
                        label: 'Répartition des essences',
                        data: tabs.map((e) => e.nb),
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)',
                            'rgb(134, 105, 26)',
                            'rgb(104, 105, 126)',
                            'rgb(10, 102, 16)',
                        ],
                        hoverOffset: 4
                    }
                ]
            }
        },
        chartData4() {
            // let essences = this.inventaires.map((e) => e.etat)
            let uniqueEssences = [];

            let tabs = []
            let total = this.inventaires.length
            let counts = this.inventaires.filter(e => e.parcelle != undefined).reduce((p, c) => {
                var name = c.parcelle;
                if (!p.hasOwnProperty(name)) {
                    p[name] = 0;
                }
                p[name]++;
                return p;
            }, {});

            for (let item in counts) {
                tabs.push({ nb: counts[item], title: item })
            }

            return {
                labels: tabs.map((e) => e.title),
                datasets: [
                    {
                        label: 'Répartition des essences',
                        data: tabs.map((e) => e.nb),
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)',
                            'rgb(134, 105, 26)',
                            'rgb(104, 105, 126)',
                            'rgb(10, 102, 16)',
                        ],
                        hoverOffset: 4
                    }
                ]
            }
        },
        chartData4Vign() {
            // let essences = this.inventaires.map((e) => e.etat)
            let uniqueEssences = [];

            let tabs = []
            let total = this.inventaires.length
            let counts = this.inventaires.filter(e => e.domaine != undefined).reduce((p, c) => {
                var name = c.domaine;
                if (!p.hasOwnProperty(name)) {
                    p[name] = 0;
                }
                p[name]++;
                return p;
            }, {});

            for (let item in counts) {
                tabs.push({ nb: counts[item], title: item })
            }

            return {
                labels: tabs.map((e) => e.title),
                datasets: [
                    {
                        label: 'Répartition des essences',
                        data: tabs.map((e) => e.nb),
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)',
                            'rgb(134, 105, 26)',
                            'rgb(104, 105, 126)',
                            'rgb(10, 102, 16)',
                        ],
                        hoverOffset: 4
                    }
                ]
            }
        },
        chartData5bis() {
            // let essences = this.inventaires.map((e) => e.etat)
            let uniqueEssences = [];

            let tabs = []
            let total = this.inventaires.length
            let counts = this.inventaires.filter(e => e.frt != undefined).reduce((p, c) => {
                var name = c.frt;
                if (!p.hasOwnProperty(name)) {
                    p[name] = 0;
                }
                p[name]++;
                return p;
            }, {});

            for (let item in counts) {
                tabs.push({ nb: counts[item], title: item })
            }

            return {
                labels: tabs.map((e) => e.title),
                datasets: [
                    {
                        label: 'Répartition des essences',
                        data: tabs.map((e) => e.nb),
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)',
                            'rgb(134, 105, 26)',
                            'rgb(104, 105, 126)',
                            'rgb(10, 102, 16)',
                        ],
                        hoverOffset: 4
                    }
                ]
            }
        },
        chartData5() {
            // let essences = this.inventaires.map((e) => e.etat)
            let uniqueEssences = [];

            let tabs = []
            let total = this.inventaires.length
            let counts = this.inventaires.filter(e => e.spar != undefined).reduce((p, c) => {
                var name = c.spar;
                if (!p.hasOwnProperty(name)) {
                    p[name] = 0;
                }
                p[name]++;
                return p;
            }, {});

            for (let item in counts) {
                tabs.push({ nb: counts[item], title: item })
            }

            return {
                labels: tabs.map((e) => e.title),
                datasets: [
                    {
                        label: 'Répartition des essences',
                        data: tabs.map((e) => e.nb),
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)',
                            'rgb(134, 105, 26)',
                            'rgb(104, 105, 126)',
                            'rgb(10, 102, 16)',
                        ],
                        hoverOffset: 4
                    }
                ]
            }
        },
        chartData5Vign() {
            // let essences = this.inventaires.map((e) => e.etat)
            let uniqueEssences = [];

            let tabs = []
            let total = this.inventaires.length
            let counts = this.inventaires.filter(e => e.sous_parcelle != undefined).reduce((p, c) => {
                var name = c.sous_parcelle;
                if (!p.hasOwnProperty(name)) {
                    p[name] = 0;
                }
                p[name]++;
                return p;
            }, {});

            for (let item in counts) {
                tabs.push({ nb: counts[item], title: item })
            }

            return {
                labels: tabs.map((e) => e.title),
                datasets: [
                    {
                        label: 'Répartition des essences',
                        data: tabs.map((e) => e.nb),
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)',
                            'rgb(134, 105, 26)',
                            'rgb(104, 105, 126)',
                            'rgb(10, 102, 16)',
                        ],
                        hoverOffset: 4
                    }
                ]
            }
        },
        chartData6() {
            // let essences = this.inventaires.map((e) => e.etat)
            let uniqueEssences = [];

            let tabs = []
            let total = this.inventaires.length
            let counts = this.inventaires.filter(e => e.operateur != undefined).reduce((p, c) => {
                var name = c.operateur;
                if (!p.hasOwnProperty(name)) {
                    p[name] = 0;
                }
                p[name]++;
                return p;
            }, {});

            for (let item in counts) {
                tabs.push({ nb: counts[item], title: item })
            }

            return {
                labels: tabs.map((e) => e.title),
                datasets: [
                    {
                        label: 'Les Essences',
                        data: tabs.map((e) => e.nb),
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)',
                            'rgb(134, 105, 26)',
                            'rgb(104, 105, 126)',
                            'rgb(10, 102, 16)',
                        ],
                        hoverOffset: 4
                    }
                ]
            }
        },
        chartData6Bis() {
            // let essences = this.inventaires.map((e) => e.etat)
            let uniqueEssences = [];

            let tabs = []
            let total = this.inventaires.length
            let counts = this.inventaires.filter(e => e.utilisateur != undefined).reduce((p, c) => {
                var name = c.utilisateur;
                if (!p.hasOwnProperty(name)) {
                    p[name] = 0;
                }
                p[name]++;
                return p;
            }, {});

            for (let item in counts) {
                tabs.push({ nb: counts[item], title: item })
            }

            return {
                labels: tabs.map((e) => e.title),
                datasets: [
                    {
                        label: 'Les Essences',
                        data: tabs.map((e) => e.nb),
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)',
                            'rgb(134, 105, 26)',
                            'rgb(104, 105, 126)',
                            'rgb(10, 102, 16)',
                        ],
                        hoverOffset: 4
                    }
                ]
            }
        },
        chartData8() {
            // let essences = this.inventaires.map((e) => e.etat)
            let uniqueEssences = [];

            let tabs = []
            let total = this.inventaires.length
            let counts = this.inventaires.filter(e => e.volume != undefined).reduce((p, c) => {
                var name = c.volume;
                if (!p.hasOwnProperty(name)) {
                    p[name] = 0;
                }
                p[name]++;
                return p;
            }, {});

            for (let item in counts) {
                tabs.push({ nb: counts[item], title: item })
            }

            return {
                labels: tabs.map((e) => e.title),
                datasets: [
                    {
                        label: 'Répartition des essences',
                        data: tabs.map((e) => e.nb),
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)',
                            'rgb(134, 105, 26)',
                            'rgb(104, 105, 126)',
                            'rgb(10, 102, 16)',
                        ],
                        hoverOffset: 4
                    }
                ]
            }
        },
        chartData9() {
            // let essences = this.inventaires.map((e) => e.etat)
            let uniqueEssences = [];

            let tabs = []
            let total = this.inventaires.length
            let counts = this.inventaires.filter(e => e.diametre != undefined).reduce((p, c) => {
                var name = c.diametre;
                if (!p.hasOwnProperty(name)) {
                    p[name] = 0;
                }
                p[name]++;
                return p;
            }, {});

            for (let item in counts) {
                tabs.push({ nb: counts[item], title: item })
            }

            return {
                labels: tabs.map((e) => e.title),
                datasets: [
                    {
                        label: 'Répartition des essences',
                        data: tabs.map((e) => e.nb),
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)',
                            'rgb(134, 105, 26)',
                            'rgb(104, 105, 126)',
                            'rgb(10, 102, 16)',
                        ],
                        hoverOffset: 4
                    }
                ]
            }
        },
        chartData10() {
            // let essences = this.inventaires.map((e) => e.etat)
            let uniqueEssences = [];

            let tabs = []
            let total = this.inventaires.length
            let counts = this.inventaires.filter(e => e.trfchaude != undefined).reduce((p, c) => {
                var name = c.trfchaude;
                if (!p.hasOwnProperty(name)) {
                    p[name] = 0;
                }
                p[name]++;
                return p;
            }, {});

            for (let item in counts) {
                tabs.push({ nb: counts[item], title: item })
            }

            return {
                labels: tabs.map((e) => e.title),
                datasets: [
                    {
                        label: 'Répartition des essences',
                        data: tabs.map((e) => e.nb),
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)',
                            'rgb(134, 105, 26)',
                            'rgb(104, 105, 126)',
                            'rgb(10, 102, 16)',
                        ],
                        hoverOffset: 4
                    }
                ]
            }
        },
        chartData11() {
            // let essences = this.inventaires.map((e) => e.etat)
            let uniqueEssences = [];

            let tabs = []
            let total = this.inventaires.length
            let counts = this.inventaires.filter(e => e.hauteur != undefined).reduce((p, c) => {
                var name = c.hauteur;
                if (!p.hasOwnProperty(name)) {
                    p[name] = 0;
                }
                p[name]++;
                return p;
            }, {});

            for (let item in counts) {
                tabs.push({ nb: counts[item], title: item })
            }

            return {
                labels: tabs.map((e) => e.title),
                datasets: [
                    {
                        label: 'Répartition des essences',
                        data: tabs.map((e) => e.nb),
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)',
                            'rgb(134, 105, 26)',
                            'rgb(104, 105, 126)',
                            'rgb(10, 102, 16)',
                        ],
                        hoverOffset: 4
                    }
                ]
            }
        },
        chartData12() {
            // let essences = this.inventaires.map((e) => e.etat)
            let uniqueEssences = [];

            let tabs = []
            let total = this.inventaires.length
            let counts = this.inventaires.filter(e => e.hautmoy != undefined).reduce((p, c) => {
                var name = c.hautmoy;
                if (!p.hasOwnProperty(name)) {
                    p[name] = 0;
                }
                p[name]++;
                return p;
            }, {});

            for (let item in counts) {
                tabs.push({ nb: counts[item], title: item })
            }

            return {
                labels: tabs.map((e) => e.title),
                datasets: [
                    {
                        label: 'Répartition des essences',
                        data: tabs.map((e) => e.nb),
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)',
                            'rgb(134, 105, 26)',
                            'rgb(104, 105, 126)',
                            'rgb(10, 102, 16)',
                        ],
                        hoverOffset: 4
                    }
                ]
            }
        },
        chartOptions() {
            return {
                responsive: true,
                maintainAspectRatio: false
            }
        },
        chartOptions2() {
            return {
                responsive: true,
                maintainAspectRatio: false
            }
        },
        presentProperties() {
            const inventoryProperties = new Set();
            this.inventaires.forEach(inventory => {
                Object.keys(inventory).forEach(key => {
                    if (this.knowns.includes(key) && inventory[key] != null) {
                        inventoryProperties.add(key);
                    }
                });
            });
            return Array.from(inventoryProperties);
        }
    },
    methods: {
        getRandomColor() {
            const r = Math.floor(Math.random() * 255);
            const g = Math.floor(Math.random() * 255);
            const b = Math.floor(Math.random() * 255);
            return `rgba(${r}, ${g}, ${b}, 0.5)`;
        },
        generateChartData(property) {
            if (!this.inventaires || !property) return null;
           
            let counts = this.inventaires.reduce((acc, item) => {
                const key = item[property];
                if (key) { // Assure que la clé existe et n'est pas undefined
                    if (!acc.hasOwnProperty(key)) {
                        acc[key] = 0;
                    }
                    acc[key]++;
                }
                return acc;
            }, {});


            let chartData = {
                labels: Object.keys(counts),
                datasets: [{
                    label: `Répartition des ${property}`,
                    data: Object.values(counts),
                    backgroundColor: [
                        'rgb(255, 99, 132)',
                        'rgb(54, 162, 235)',
                        'rgb(255, 205, 86)',
                        'rgb(134, 105, 26)',
                        'rgb(104, 105, 126)',
                        'rgb(10, 102, 16)',
                    ],
                    hoverOffset: 4
                }]
            };

            return chartData;
        },
        removeColumn(){

        },
      
        getPropertyValues(property) {
            return this.inventaires.map(e => {
                const value = e[property];
                if (typeof value === 'string') {
                    return value.length;  // Retourne la longueur de la chaîne
                } else if (value) {
                    return parseFloat(value.toString().replace(',', '.'));
                }
                return null;
            }).filter(e => e !== null);
        },
        calculateStatistics() {
            return this.presentProperties.reduce((acc, prop) => {
                const values = this.getPropertyValues(prop);
                if (values.length > 0) {
                    const sum = values.reduce((sum, num) => sum + num, 0);
                    const average = (sum / values.length).toFixed(2);
                    const min = Math.min(...values);
                    const max = Math.max(...values);

                    acc[prop] = { min, max, average };
                } else {
                    acc[prop] = { min: null, max: null, average: null };
                }
                return acc;
            }, {});
        },
        getStringFrequencies(property) {
            const counts = {};
            this.inventaires.forEach(item => {
                const value = item[property];
                if (typeof value === 'string') {
                    counts[value] = (counts[value] || 0) + 1;
                }
            });
            return counts;
        },
        calculateStringMetrics(property) {
            const values = this.inventaires.map(e => e[property]).filter(e => typeof e === 'string');
            const frequency = this.getStringFrequencies(property);
            const mostCommon = Object.keys(frequency).reduce((a, b) => frequency[a] > frequency[b] ? a : b, null);

            return {
                mostCommon,
                frequency,
                longest: values.reduce((a, b) => a.length > b.length ? a : b, '')
            };
        },
        minProperty(property) {
            const values = this.getPropertyValues(property);
            return values.length ? Math.min(...values) : null;
        },
        maxProperty(property) {
            const values = this.getPropertyValues(property);
            return values.length ? Math.max(...values) : null;
        },
        averageProperty(property) {
            const values = this.getPropertyValues(property);
            const sum = values.reduce((partialSum, a) => partialSum + a, 0);
            return values.length ? (sum / values.length).toFixed(2) : null;
        },
        variance(arr) {
            const labels = Utils.months({ count: 7 });
            if (!arr.length) {
                return 0;
            };
            const sum = arr.reduce((acc, val) => acc + val);
            const { length: num } = arr;
            const median = sum / num;
            let variance = 0;
            arr.forEach(num => {
                variance += ((num - median) * (num - median));
            });
            variance /= num;
            return Math.sqrt(variance);
        }

    }

}
</script>