<template>
    <div>
        <v-snackbar absolute top color="primary" shaped v-model="snackbar">
            {{ snack }}
        </v-snackbar>

        <v-dialog v-model="showDialog" max-width="600px">
            <v-card>
                <v-toolbar class="mb-2" color="primary" dark>
                    <v-card-title><v-icon>mdi-land-fields</v-icon> <span class="headline">{{ edit ? 'Editer Domaine' :
            'Nouveau Domaine' }}</span></v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn dark icon @click="showDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field append-icon="mdi-pencil" v-model="domain.name" label="Nom"
                                    required></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea append-icon="mdi-pencil" v-model="domain.description" label="Description"
                                    required></v-textarea>
                            </v-col>

                            <v-col cols="12" sm="6">
                                <v-select prepend-icon="mdi-view-list" multiple v-model="domain.inventaires"
                                    :items="inventairesLoad" clearable item-text="name" item-value="name"
                                    label="Choisissez le(s) inventaire(s) associée(s)" return-object></v-select>
                            </v-col>

                            <v-col cols="12" sm="6">
                                <v-select append-icon="mdi-land-fields" multiple v-model="domain.parcelles"
                                    :items="parcellesLoad" clearable item-text="name" item-value="name"
                                    label="Choisissez le(s) parcelle(s) associée(s)" return-object></v-select>
                            </v-col>

                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="info" text @click="showDialog = false">Annuler</v-btn>
                    <v-btn color="secondary" @click="save"><v-icon>mdi-content-save-check</v-icon>
                        {{ edit ? "Editer" : "Ajouter" }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-text-field v-model="search" append-icon="mdi-magnify"
            label="Rechercher un domaine, un inventaire, une parcelle etc.." single-line hide-details></v-text-field>
        <v-data-table :loading="loading" :headers="headersDomaines" :items="filteredDomaines" class="elevation-1 ma-2">
            <template v-slot:item.inventaires="{ item }">
                <div>
                    <v-btn x-small color="secondary" rounded v-for="inventaire in item.inventaires"
                        :key="inventaire._id" @click="go(inventaire)" tag="v-chip" class="ma-1">
                        {{ inventaire.name }}
                    </v-btn>
                </div>
            </template>
            <template v-slot:item.parcelles="{ item }">
                <div>
                    {{ item.parcelles.map(parcelle => parcelle.name).join(', ') }}
                </div>
            </template>
            <template v-slot:item.action="{ item }">
                <v-btn color="success" @click="editDomain(item)" small class="mr-2"> <v-icon>mdi-pencil</v-icon>
                    Editer</v-btn>
                <v-btn color="red" @click="deleteDomain(item)" outlined small><v-icon>mdi-close</v-icon>
                    Supprimer</v-btn>
            </template>
        </v-data-table>

        <v-btn color="primary" class="ma-2" @click="showDialog = true"><v-icon>mdi-plus-circle</v-icon> Ajouter un
            domaine</v-btn>
    </div>
</template>


<script>
import moment from 'moment';
import xhr from '../plugins/axios';

moment.locale('fr');


export default {
    props: ['user',],
    data(vm) {
        return {
            edit: false,
            loading: false,
            search: '',
            domaines: [],
            parcellesLoad: [],
            inventairesLoad: [],
            domain: {
                name: '',
                description: '',
                parcelles: [],
                inventaires: []
            },
            showDialog: false,
            headersDomaines: [
                { text: 'Domaine', value: 'name' },
                { text: 'Description', value: 'description' },
                { text: 'Parcelles', value: 'parcelles' },
                { text: 'Inventaires', value: 'inventaires' },

                { text: 'Actions', value: 'action', sortable: false },


            ],
        }
    },
    async created() {
        await this.load();
        let inventairesLoad = await xhr.post('/inventaires-all', { user: this.user.uid });
        this.inventairesLoad = inventairesLoad.data


        let parcellesAll = await xhr.post('/load-parcelles', { uid: this.user.uid });
        this.parcellesLoad = parcellesAll.data

    },

    computed: {
        filteredDomaines() {
            if (!this.search) return this.domaines;

            const searchTerm = this.search.toLowerCase();

            return this.domaines.filter(domain => {
                return (
                    (domain.name && domain.name.toLowerCase().includes(searchTerm)) ||
                    (domain.description && domain.description.toLowerCase().includes(searchTerm)) ||
                    (domain.parcelles && domain.parcelles.some(parcelle => parcelle.name.toLowerCase().includes(searchTerm))) ||
                    (domain.inventaires && domain.inventaires.some(inventaire => inventaire.name.toLowerCase().includes(searchTerm)))
                );
            });
        }
    },
    methods: {
        go(inv) {
            this.$emit("goInv", inv)
        },
        async editDomain(item) {
            this.domain = item
            this.showDialog = true
            this.edit = true
        },
        async deleteDomain(item) {
            let conf = confirm("Etes-vous sure de supprimer le domaine ?")

            if (conf) {
                let domaines = await xhr.post('/remove-domaines', {
                    domain: item
                });
                this.showDialog = false
                this.snackbar = true
                this.snack = 'Votre domaine a été supprimé'
                await this.load()
            }

        },
        async load() {
            this.loading = true
            let domaines = await xhr.post('/load-domaines', {
                uid: this.user.uid
            });
            this.domaines = domaines.data.map((e) => { return { ...e.domain, _id: e._id } });
            this.loading = false
        },
        async save() {
            if (!this.edit) {
                let domain = await xhr.post('/add-domaines', {
                    uid: this.user.uid,
                    domain: this.domain
                });

                this.snack = 'Votre domaine a été crée'
            } else {
                let domain = await xhr.post('/edit-domaines', {
                    domain: this.domain
                });
                this.snack = 'Votre domaine a été édité'
            }
            await this.load()
            this.showDialog = false
            this.snackbar = true
            this.edit = false
        }
    }

}

</script>