<template>
    <v-container class="pa-5">
        <v-alert border="top" colored-border type="info" elevation="2">
            Vous pouvez inviter jusqu'à 5 personnes à contribuer à cet inventaire.
        </v-alert>

        <v-row justify="center">
            <v-col cols="12" md="12">
                <v-form ref="form" v-model="isValid">

                    <v-row justify="center" class="pt-5" v-for="(user, index) in users" :key="index">
                        <v-sheet class="pa-1">

                            <v-col cols="12">
                                <v-chip label outlined color="success">Personne {{ index + 1 }}</v-chip>
                            </v-col>
                            <v-col cols="12">
                                <v-row align="center">
                                    <v-col cols="12" lg="2">
                                        <v-text-field solo v-model="user.name" :label="`Nom de la personne ${index + 1}`"
                                            :rules="nomRules" required></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="3">
                                        <v-text-field solo v-model="user.email"
                                            :label="`E-mail de la personne ${index + 1}`" :rules="emailRules"
                                            required></v-text-field>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                        <v-text-field solo v-model="user.message" counter="255"
                                            :label="`Petit message pour la personne ${index + 1}`" :rules="nomRules"
                                            required></v-text-field>
                                    </v-col>
                                    <v-col cols="1">
                                        <v-btn icon @click="removeUser(index)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-sheet>

                    </v-row>

                    <v-row justify="center">
                        <v-btn class="ma-2" :disabled="users.length >= 5" outlined color="secondary" dark @click="addUser">
                            <v-icon>mdi-plus</v-icon> Ajouter un autre compte
                        </v-btn>

                        <v-btn class="ma-2" :disabled="!isValid" color="success" dark @click="submitForm">
                            <v-icon>mdi-check</v-icon> Envoyer l'invitation
                        </v-btn>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { debounce } from 'lodash';

export default {
    props: ['uid', 'inv'],
    data: () => ({
        isValid: false,
        users: [{ name: '', email: '', message: '' }],
        nomRules: [v => !!v || 'Nom est requis',],
        emailRules: [
            v => !!v || 'E-mail est requis',
            v => /.+@.+\..+/.test(v) || 'E-mail doit être valide'
        ]
    }),
    methods: {
        verifyEmail: debounce(async function () {
            const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

            if (this.email.length > 2 && regex.test(this.email)) {
                this.existEmail = false
                this.loading = true
                let res = await xhr.post("/check-email", { email: this.email.trim() })

                if (res.data == true) {
                    this.existEmail = true
                } else {
                    this.existEmail = false
                    this.updateEmail(this.email);
                }
                this.loading = false

            }

        }, 500),
        addUser() {
            this.users.push({ name: '', email: '', message: '' });
        },
        removeUser(index) {
            this.users.splice(index, 1);
        },
        validateForm() {
            this.isValid = this.$refs.form.validate();
        },
        submitForm() {
            if (this.isValid) {
                this.$emit("sendedUsers", this.users)
                this.users = [{ name: '', email: '', message: '' }]
            }
        }
    }
};
</script>