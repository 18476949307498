<template>
    <v-container>
        <v-snackbar absolute top color="primary" shaped v-model="snackbarLauch">
            {{ snack }}
        </v-snackbar>


        <v-card class="pa-2" v-if="add">
            <v-form ref="form" v-model="valid">
                <v-btn small rounded color="primary" @click="add = false"><v-icon>mdi-arrow-left</v-icon>Retour
                    à la
                    liste</v-btn>

                <v-card-title><v-icon>mdi-account-multiple-plus</v-icon> Créer un membre de
                    l'équipe</v-card-title>



                <v-row>
                    <v-col cols="12" sm="4">
                        <v-text-field required :rules="[rules.required]" prepend-icon="mdi-lead-pencil"
                            placeholder="Michel" label="Nom du membre" v-model="user.firstname"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="4">
                        <v-text-field required :rules="[rules.required]" prepend-icon="mdi-lead-pencil"
                            placeholder="Dupont" label="Prénom du membre" v-model="user.lastname"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="4">
                        <v-select chips :small-chips="true" hint="Visibilité sur les inventaires" clearable outlined
                            color="secondary" multiple :items="inventairesAll" v-model="user.inventaires"
                            item-text="name" item-value="_id" :rules="inventaireRules"
                            label="Choisissez le(s) inventaire(s) associée(s)"></v-select>
                    </v-col>


                    <v-col cols="12" sm="12">
                        <v-autocomplete chips :small-chips="true" hint="Visibilité sur les inventaires" clearable
                            outlined color="secondary" multiple :items="formattedCommunes" v-model="user.communes"
                            item-text="name" :rules="communesRules" :search-input.sync="search" return-object
                            label="Choisissez le(s) secteurs(s) associée(s)"></v-autocomplete>
                    </v-col>




                    <!-- <v-col cols="12" sm="4">
                        <v-select v-model="selectedRole" :items="roles" label="Sélectionnez votre rôle"></v-select>
                    </v-col> -->
                </v-row>
                <!-- <v-alert type="warning">Un mot de passe sera généré et envoyé au membre crée pour se connecter à
                    Cartovia</v-alert> -->
                <v-row>
                    <v-col cols="12" sm="4">
                        <v-text-field prepend-icon="mdi-email" type="email" placeholder="dupong123@gmail.com"
                            label="E-mail du membre" :rules="emailRules" v-model="user.email"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-text-field prepend-icon="mdi-phone-dial" type="tel" placeholder="06XXXXXXXX"
                            label="Téléphone du membre" :rules="telRules" v-model="user.tel"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-select outlined chips :small-chips="true" multiple v-model="user.parcelles"
                            :items="parcellesLoad" clearable item-text="name" item-value="_id"
                            label="Choisissez le(s) parcelle(s) associée(s)"></v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="12">
                        <v-textarea prepend-icon="mdi-pencil" v-model="user.comment"
                            label="Rédigez un commentaire"></v-textarea>
                    </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-row align="center" justify="center" cols="12">
                    <v-alert type="info">Avertir le membre des notifications, des tâches etc. par
                        <v-radio-group v-model="user.notification">
                            <v-radio icon="mdi-email" label="Par e-mail" value="email"></v-radio>
                            <v-radio icon="mdi-phone" label="Par SMS" value="tel"></v-radio>
                        </v-radio-group>
                    </v-alert>
                </v-row>


                <v-card-actions>
                    <v-row class="ml-2">

                        <v-col cols="12" sm="2">
                            <v-btn big color="primary" @click="add = false" outlined>
                                Annuler</v-btn>
                        </v-col>

                        <v-col v-if="!edited" cols="12" sm="3">
                            <v-btn @click="addMember" big color="success" dark>
                                <v-icon>mdi-account-arrow-right</v-icon>
                                Ajouter </v-btn>
                        </v-col>

                        <v-col v-else cols="12" sm="3">
                            <v-btn big color="success" @click="editing"><v-icon>mdi-pencil</v-icon>
                                Editer </v-btn>
                        </v-col>
                        <v-col v-if="edited" cols="12" sm="3">
                            <v-btn big color="red" outlined @click="remove"><v-icon>mdi-trash-can-outline</v-icon>
                                Supprimer</v-btn>
                        </v-col>

                    </v-row>
                </v-card-actions>
            </v-form>
        </v-card>

        <v-list v-else-if="users.length">
            <v-list-item-group>
                <template v-for="(user, index) in users">
                    <v-list-item @click="edit(user)" :key="`item-${index}`">
                        <v-list-item-content>
                            <v-list-item-title>Contact: <b>{{ user.firstname
                                    }} {{ user.lastname
                                    }}</b></v-list-item-title>
                            <v-list-item-subtitle>Email: <b>{{ user.email
                                    }}</b></v-list-item-subtitle>
                            <v-list-item-subtitle>Tel: <b>{{ user.tel
                                    }}</b></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn color="primary" fab small @click="load(user)"><v-icon>mdi-pencil</v-icon></v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    <!-- Ajoutez un v-divider après chaque v-list-item sauf le dernier -->
                    <v-divider :key="`divider-${index}`" v-if="index < users.length - 1"></v-divider>
                </template>
            </v-list-item-group>
        </v-list>

        <v-alert v-else type="info">
            Vous n'avez pas encore de membre à votre équipe
        </v-alert>

        <v-row class="d-flex justify-center">
            <v-btn v-if="!add" big rounded color="success" @click="adding"><v-icon>mdi-plus</v-icon>
                Ajouter
                un
                membre à mon équipe</v-btn>
        </v-row>


    </v-container>
</template>

<script>
import xhr from '../plugins/axios';

export default {
    name: 'Phito',
    props: ['uid', 'inventaire'],
    components: {
    },
    async created() {
        this.add = false
        this.edited = false


        await this.load()

        let parcellesAll = await xhr.post('/load-parcelles', { uid: this.uid });
        this.parcellesLoad = parcellesAll.data

        let inventairesAll = await xhr.post('/inventaires-all', { user: this.uid });
        this.inventairesAll = inventairesAll.data
        this.user.inventaires = this.inventairesAll;

        
        let communesAll = await xhr.post('/get-secteurs', { user: this.uid });
        this.communesAll = communesAll.data
        this.user.communes = this.communesAll;

    },
    data(vm) {
        return {
            search: "",
            communesAll: [],
            valid: false,
            parcelles: [],
            inventaireRules: [
                v => (v && v.length > 0) || 'Au moins un inventaire doit être choisi.',
            ],
            communesRules: [
                v => (v && v.length > 0) || 'Au moins une commune doit être choisi.',
            ],
            secteursRules: [
                v => (v && v.length > 0) || 'Au moins un secteur doit être choisi.',
            ],
            rules: {
                required: value => !!value || 'Ce champ est requis.',
                emailOrPhone: () => {
                    if (!this.user.email && !this.user.tel) {
                        return 'Email ou téléphone est requis.';
                    }
                    return true;
                },
            },
            telRules: [
                (v) =>
                    (v.length == 0 || /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/.test(v)) ||
                    "Le téléphone doit être valide",
            ],
            emailRules: [
                (v) => !!v || "L'e-mail obligatoire",
                (v) => /.+@.+\..+/.test(v) || "L'e-mail doit être valide",
            ],
            selectedRole: 'Observateur', // Définir "Observateur" comme valeur par défaut
            roles: ['Observateur', 'Modérateur'], // Les options disponibles
            phytos: [],
            parcellesLoad: [],
            editPhito: {},
            snackbarLauch: false,
            snack: '',
            add: false,
            menu: false,
            menu3: false,
            users: [],
            cityNames: [],
            edited: false,
            user: {
                firstname: '',
                lastname: '',
                email: '',
                tel: '',
                notification: 'email',
                parcelles: [],
                inventaires: [],
                communes: [],
                comment: "",
            },
        }
    },
    methods: {
        adding() {
            this.edited = false
            this.reset()
            this.add = true
        },
        reset() {
            this.user = {
                firstname: '',
                lastname: '',
                email: '',
                tel: '',
                notification: 'email',
                parcelles: [],
                inventaires: []
            }
        },
        async addMember() {
            if (this.$refs.form.validate()) {
                await xhr.post('/add-member', {
                    user: this.user,
                    uid: this.uid,
                })
                this.reset()

                this.add = false
                this.snack = 'Votre utilisateur a bien été ajouté'
                this.snackbarLauch = true

                await this.load()


            }
        },

        nextstep(coords) {
            if (coords) {
                this.vineyard.location.gps = coords.lat + ", " + coords.lng
            }
        },
        getGeoloc() {
            this.$emit('geolocation')
        },
        async load() {
            let users = await xhr.post('/load-members', {
                uid: this.uid
            });
            this.users = users.data;
        },
        async remove() {
            let conf = confirm('Voulez-vous supprimer l\'utilisateur ?')
            if (conf == true) {
                await xhr.post('/remove-member', {
                    user: this.user
                })

                await this.load()
                this.add = false
                this.snack = 'Votre produit phytosanitaire a bien été supprimé'
                this.snackbarLauch = true
            }
        },
        edit(user) {
            this.editPhito = user
            this.user = user
            this.edited = true
            this.add = true
        },
        async editing() {
            if (this.$refs.form.validate()) {
                await xhr.post('/edit-member', {
                    uid: this.uid,
                    user: this.user
                })
                await this.load()
                this.snack = 'Votre membre a bien été modifié'
                this.snackbarLauch = true
                this.edited = false
                this.add = false
            }
        },

    },
    watch: {
    },
    computed: {
        formattedCommunes() {
            return this.communesAll.map(commune => {
                let name = `${commune.COMMUNE} | Secteur: ${commune.SECTEUR}`;
                if (commune.NOM_SECTEU) {
                    name += ` (${commune.NOM_SECTEU})`;
                }
                return {
                    ...commune,
                    name: name
                };
            }).sort((a, b) => a.COMMUNE.localeCompare(b.COMMUNE));
        }
    }
}
</script>