<template>
    <v-sheet :width="800" class="mx-auto pa-2">
        <h3>Informations</h3>


        <p>
            La plateforme Cartovia est une solution innovante pour la gestion et la visualisation de données géolocalisées.
        </p>
        <p>
            C'est une plateforme géo-décisionnel intelligente et intuitive, cartocentré sur l'inventaire,
            avec des traitements sur des données géographiques.
        </p>
        <h4>APPROCHE UNIVERSELLE</h4>
        <p> Les technologies et solutions sont universelles et s'adaptent à tous les métiers, sans concession technique ou
            fonctionnelle.</p>
        <h4>INTEROPÉRABILITÉ TOTALE</h4>

        <p> Cartovia se connecte directement et simultanément à tous types de données spatiales, contextuelles et métier
            présentées dans des formats normalisés.
        </p>
        <h4>DATAMART SUR MESURE</h4>


        <p>Cartovia, fondé sur un modèle de données en étoile visualisable à l'écran et éditable à la souris, inclut la
            préparation de jeux de données sur mesure.
        </p>
        <h4>PERFORMANCES PLATEFORME</h4>

        <p>Cartovia est déployable en mode serveurs clusterisés, permettant de traiter efficacement de très gros volumes de
            données locales et distantes.
        </p>
        <h4>RICHESSE FONCTIONNELLE</h4>

        <p>Cartovia hérite de toute la richesse fonctionnelle offerte sur la plateforme GEO, utilisée par des dizaines de
            milliers d'utilisateurs dans le monde.
        </p>
        <h4>INTUITIVITÉ SOPHISTIQUÉE</h4>

        <p>Les principes ergonomiques de GEO s'appliquent naturellement à Cartovia pour générer sans coder de puissants
            tableaux de bord cartocentrés.
        </p>
        <h4>ÉVOLUTIVITÉ PERMANENTE</h4>

        <p>Cartovia évolue constamment, en intégrant les meilleures pratiques géo-décisionnelles du moment ainsi que les
            nouveaux besoins de ses utilisateurs.
        </p>

        <p> Voici un résumé des fonctionnalités clés que vous avez mentionnées :
        </p>
        <pre>
            Taiwa / Cartovia
            21 rue du Commandant Fuzier
            69003 Lyon, France
        </pre>
        <p>
            <v-btn small rounded color="primary" href="tel:0665267940"><v-icon>mdi-phone</v-icon> 06.65.26.79.40</v-btn>
        </p>
        <p>
            <v-btn small rounded color="secondary" href="mailto:contact@cartovia.fr"><v-icon>mdi-email</v-icon>
                contact@conessence.fr</v-btn>
        </p>
        <ul>

        </ul>

    </v-sheet>
</template>

<script>
export default {
    name: 'Infos',
    components: {},
    created() {

    },
    data() {
        return {

        }
    }

}
</script>

<style scoped>
h4 {
    margin-top: 40px;
    margin-bottom: 20px;
}
</style>