<template>
    <div>
        <div v-if="message.isBot" class="bot-message">
            <div class="mt-2 mb-2 contentchip" color=" blue" dark>
                <div class=" contentchipresp" v-html="message.text"></div>
            </div>
        </div>
        <div v-else class="user-message">
            <div class="mt-2 mb-2 contentchip contentchipuser" dark color="green">
                <v-icon left>mdi-account-circle</v-icon>
                {{ message.text }}
            </div>
        </div>
        <div class="d-flex justify-space-between">
            <div v-if="message.isBot" class="dateMess"><v-icon left small class="mr-1">mdi-timer</v-icon> Lu le {{
            formatDate(message.date) }}
            </div>
            <div v-else class="dateMess"><v-icon left small class="mr-1">mdi-timer</v-icon> Ecris
                le {{
            formatDate(message.date) }}
            </div>
            <div v-if="message.isBot" class="mr-6"><v-btn elevation="1" @click="toggleLike" class="text--right" x-small
                    fab>
                    <v-icon color="success" v-if="!isLiked">mdi-thumb-up</v-icon>
                    <v-icon color="primary" v-else>mdi-thumb-down</v-icon>
                </v-btn>
            </div>
        </div>
        <div v-if="message.isBot" class="suggest-message">
            <v-slide-group show-arrows center-active v-model="selectEssence">
                <v-slide-item class="mr-2" v-for="ask, index in asks" :key="key" v-slot="{ active, toggle }">
                    <v-scroll-y-transition>
                        <v-chip class="pa-3" x-small @click="selectAsk(ask)" color="teal" outlined link pill dark>
                            <div v-html="ask"></div>
                        </v-chip>
                    </v-scroll-y-transition>
                </v-slide-item>
            </v-slide-group>
        </div>

    </div>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
let contextQuestions = {
    "lutter": ["Comment je reconnais cette maladie ?", "Quelle est la prévention à prendre", "Quels traitements existent t-il ?"],
    "prevention": ["Quels produits efficaces pour protéger mes vignes ?", "Que faut t-il savoir sur cette maladie ?", "Quels sont les symptômes visibles ?"],
    "reconnaitre": ["Comment lutter contre ?", "Quels traitements utilser ?", "Que faut t'il savoir la dessus?"],
    "important": ["Comment je peux anticiper ?", "Quels produits existent t'ils ?", "Comment observer cette maladie?"],
    "traitement": ["Comment reconnaître les signes ?", "Quels produits biologiques ou naturels ?", "Quels sont les risques ?"],
}
export default {
    data() {
        return {
            like: false,
            asks: []
        };
    },
    props: ["message", "context"],
    created() {

        if (this.message.isBot && this.context && this.context.category) {
            this.asks = contextQuestions[this.context.category];
        }
    },
    methods: {
        selectAsk(ask) {
            this.$emit("selectAsk", ask)
        },
        formatDate(date) {
            return moment(date).format('DD/MM/YYYY à HH:mm');
        },
        toggleLike() {
            this.$store.dispatch('toggleBookmark', this.message);
        }
    },
    computed: {
        isLiked() {
            return this.$store.state.chat.bookmarks.some(m => m.id === this.message.id);
        }
    }

}

</script>

<style lang="scss" scoped>
.contentchip {
    height: auto !important;
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
    max-width: 100%;
}

.contentchipuser {
    background-color: cornflowerblue !important;
    color: white;
    padding: 10px;
    border-radius: 10px;
    margin-right: 20px;
}

.contentchipresp {
    background-color: #f46370 !important;
    color: white;
    margin-left: 20px;
    padding: 10px;
    border-radius: 10px;
}

.v-chip .v-chip__content {
    padding: 10px !important;
}

.theme--dark.v-chip {
    height: auto !important;
}
</style>
