import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VSheet,{staticClass:"pa-2"},[_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"550","outlined":""}},[_c(VListItem,{attrs:{"three-line":""}},[_c(VListItemContent,[_c('div',{staticClass:"text-overline mb-4"},[_c(VIcon,[_vm._v("mdi-account")]),_vm._v(" Mon Compte ")],1),_c(VListItemTitle,{staticClass:"text-h5 mb-1"},[_vm._v(" "+_vm._s(_vm.user.displayName)+" ")]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.user.email))]),_c(VListItemSubtitle,[_vm._v("Professionel")])],1),_c(VImg,{attrs:{"src":_vm.user.photoURL ? _vm.user.photoURL : 'https://e7.pngegg.com/pngimages/715/371/png-clipart-youtube-google-logo-google-s-google-account-youtube-text-trademark.png'}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"outlined":"","rounded":"","text":"","color":"primary"}},[_c(VIcon,[_vm._v("mdi-pencil")]),_vm._v(" Editer mon profil ")],1),_c(VBtn,{attrs:{"outlined":"","rounded":"","text":"","color":"danger"}},[_c(VIcon,[_vm._v("mdi-pencil")]),_vm._v(" Se deconneter ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }