<template>
    <div class="pa-2">

        <v-card class="elevation-3 mb-2">
            <v-toolbar color="primary" dark flat>
                <v-toolbar-title><b>Etape 2</b> Choix du plan</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn icon large target="_blank" v-on="on">
                            <v-icon>mdi-account</v-icon>
                        </v-btn>
                    </template>
                    <span class="white--text">Se connecter</span>
                </v-tooltip>
            </v-toolbar>

            <v-stepper v-model="e1" horizontal>
                <v-stepper-header>
                    <v-stepper-step :complete="e1 > 1" step="1">Inscription du compte</v-stepper-step>
                    <v-stepper-step step="2">Choix du plan</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="3">Importation des données</v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="2" class="pa-2">

                        <v-row justify="center">
                            <v-col md="auto">
                                <v-alert type="info">
                                    Une période d'essaie de 15 jours gratuite et sans engagement est mise en place dès
                                    l'inscription
                                </v-alert>
                            </v-col>
                        </v-row>

                        <!-- Switch pour changer entre mensuel et annuel pour toutes les offres -->
                        <div class="d-flex justify-center">
                            <v-radio-group v-model="isAnnual" row class="justify-center radio-group-mobile-center">
                                <v-radio class="yellow-highlight" label="Abonnement Mensuel" :value="false"></v-radio>
                                <v-radio class="green-highlight" :value="true">
                                    <template v-slot:label>
                                        <span>Abonnement Annuel</span>
                                        <v-chip outlined rounded small class="gold pa-2 ml-2 pt-2 pb-2"><v-icon
                                                color="secondary">mdi-ticket</v-icon> (-20
                                            %)</v-chip>
                                    </template>
                                    <v-chip label outlined
                                        color="secondary"><v-icon>mdi-tag</v-icon><b>-20%</b></v-chip>

                                </v-radio>
                            </v-radio-group>
                        </div>

                        

                        <v-row>

                            <v-col cols="12" md="4">
                                <v-card :color="getOffre == 'Offre Starter' ? 'success' : ''" :height="700"
                                    class="offer-card pt-4 mb-2 custom-hover">
                                    <v-row justify="center">
                                        <v-card-title :class="getOffre == 'Offre Starter' ? 'white--text' : null" class=" headline
                                        text-center"><strong>🚀
                                                Starter</strong>
                                        </v-card-title>
                                        <v-chip class="pa-4" small v-if="getOffre == 'Offre Starter'" label color="pink"
                                            dark><v-icon>mdi-tag</v-icon> Votre
                                            Offre actuelle</v-chip>

                                        <v-alert class="text-center pa-3 pt-5" :icon="false" style="border-radius: 0;"
                                            dense border="top" :type="isAnnual ? 'info' : 'success'">
                                            <p>Offre adaptée aux besoins des exploitants indépendants pour gérer
                                                efficacement quelques parcelles avec simplicité
                                            </p>
                                            <v-chip label><span>{{
                                                    isAnnual ? 480 : 50 }}€ HT / {{ isAnnual ? "An" : "Mois"
                                                    }}</span></v-chip>

                                        </v-alert>
                                    </v-row>
                                    <v-list dense class="mt-6">
                                        <v-list-item>
                                            <v-icon color="primary">⚡</v-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Importation <b>illimitées</b> part IA 🪄: Excel,
                                                    CSV, KML...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-icon color="primary">⚡</v-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Visualisation <b>personnalisée
                                                        et cartocentrée</b></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-icon color="primary">⚡</v-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Cartographie <b>haute résolution HD</b>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-icon color="primary">⚡</v-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Gestion de l'<b>Historique</b> de vos
                                                    inventaires</b></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-icon color="primary">⚡</v-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Filtres <b>intelligents</b> sur vos données et
                                                    <b>temporels</b></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-icon color="primary">⚡</v-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Registre des produits <b>phytosanitaires</b>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-icon color="primary">⚡</v-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Gestion des parcelles <b>personnalisée</b>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-icon color="primary">⚡</v-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Cadastres et parcellaires <b>existants</b> IGN
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-icon color="primary">⚡</v-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Export cartographie sous format <b>Image
                                                        HD</b></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                    <v-card-actions class="mt-4">
                                        <v-row justify="center">
                                            <v-btn class="pa-6" :loading="loadingStarter"
                                                :disabled="getOffre == 'Offre Starter'"
                                                @click="loadingStarter = true; subscribe('starter', isAnnual)" check
                                                rounded large :color="!isAnnual ? 'secondary' : 'info'" elevation="1">
                                                <v-icon left>
                                                    {{ isAnnual ? "mdi-star" : "mdi-check" }}
                                                </v-icon>
                                                <span v-if="!getOffre || getOffre != 'Offre Starter'">Je m'abonne
                                                    maintenant</span>
                                                <span v-else-if="getOffre == 'Offre Starter'">Déjà abonné</span>
                                            </v-btn>
                                        </v-row>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                            <v-col cols="12" md="4">
                                <v-card :color="getOffre == 'Offre Pro' ? 'success' : ''" :height="700"
                                    class="offer-card pt-4 mb-2 custom-hover">
                                    <div class="most-popular-banner">La plus achetée</div>

                                    <v-row justify="center">
                                        <v-card-title :class="getOffre == 'Offre Pro' ? 'white--text' : null"
                                            class="headline text-center"><strong>🥇
                                                Pro</strong></v-card-title>
                                        <v-chip outlined class="pa-4" small v-if="getOffre == 'Offre Pro'" label
                                            color="white" dark><v-icon>mdi-tag</v-icon> Votre
                                            Offre actuelle</v-chip>

                                        <v-alert :icon="false" class="text-center pa-3 pt-5" style="border-radius: 0;"
                                            border="top" dense :type="isAnnual ? 'info' : 'success'">
                                            <p>Petite / Moyenne entreprise avec gestion multi-inventaires,
                                                gestion de l'équipe et des
                                                tâches, alertes en temps réel.
                                            </p>
                                            <v-chip label><span>{{ isAnnual ? 960 : 100 }}€ HT / {{
                                                    isAnnual ? "An" : "Mois" }}</span></v-chip>
                                        </v-alert>
                                    </v-row>

                                    <v-list dense class="mt-6">

                                        <v-list-item>
                                            <v-icon color="primary">⚡</v-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Avantages de <b>l'Offre Starter</b>
                                                    👈</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>


                                        <v-list-item>
                                            <v-icon color="primary">⚡</v-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Création de <b>rapport PDF</b> </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-icon color="primary">⚡</v-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Valorisation de votre parcelle par <b>Simulateur</b>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-card style="margin: 0 !important;" class="ma-2" color="secondary" dark>
                                            <v-card-title class="text-center"><v-icon left>mdi-account-group</v-icon>
                                                Travail
                                                Collaboratif</v-card-title>

                                            <v-card-text>

                                                <v-list-item>
                                                    <v-icon color="primary">⚡</v-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title>Gestion du <b>Calendrier des
                                                                Tâches</b></v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>

                                                <v-list-item>
                                                    <v-icon color="primary">⚡</v-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title>Gestion des <b>Notifications
                                                                géolocalisées</b></v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>

                                                <v-list-item>
                                                    <v-icon color="primary">⚡</v-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title>Gestion de l'équipe <b>par inventaires et
                                                                parcelles</b></v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>

                                                <v-list-item>
                                                    <v-icon color="primary">⚡</v-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title>Gestion <b> des
                                                                tâches par parcelles et
                                                                utilisateurs</b></v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>

                                            </v-card-text>
                                        </v-card>

                                    </v-list>
                                    <v-card-actions class="mt-4">
                                        <v-row justify="center">
                                            <v-btn :disabled="getOffre == 'Offre Pro'" :loading="loadingPro"
                                                @click="loadingPro = true; subscribe('pro', isAnnual)" check
                                                class="pa-6" rounded large :color="!isAnnual ? 'secondary' : 'info'"
                                                elevation="1">
                                                <v-icon left>{{ isAnnual ? "mdi-star" : "mdi-check"
                                                    }}</v-icon>
                                                <span v-if="!getOffre || getOffre != 'Offre Pro'">Je m'abonne
                                                    maintenant</span>
                                                <span v-else-if="getOffre == 'Offre Pro'">Déjà abonné</span>
                                            </v-btn>
                                        </v-row>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-card disabled :color="getOffre == 'Offre Entreprise' ? 'success' : ''" ::height="700"
                                    class="offer-card pt-4 custom-hover">
                                    <v-row justify="center">
                                        <v-card-title :class="getOffre == 'Offre Entreprise' ? 'white--text' : null"
                                            class="headline text-center"><strong>🏨
                                                Entreprise</strong></v-card-title>
                                        <v-chip class="pa-4" small v-if="getOffre == 'Offre Entreprise'" label
                                            color="pink" dark><v-icon>mdi-tag</v-icon> Votre
                                            Offre actuelle</v-chip>
                                        <v-alert class="text-center pa-3 pt-5" :icon="false" style=" border-radius: 0; "
                                            border="top" dense :type="isAnnual ? 'info' : 'success'">
                                            <p>Conçu sur-mesure pour les entreprises moyennes ou grosses avec services
                                                et capteurs
                                                connectés</p>
                                            <v-chip label outlined color="white"><span><v-icon>mdi-auto-fix</v-icon>
                                                    Sur-Mesure</span></v-chip>

                                        </v-alert>
                                    </v-row>

                                    <v-list dense class="mt-6">
                                        <v-list-item>
                                            <v-icon color="primary">⚡</v-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Avantages de l'<b>Offre Pro</b>
                                                    👈</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>


                                        <v-list-item>
                                            <v-icon color="primary">⚡</v-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Inventaires connectés <b> aux capteurs
                                                        sur terrain</b></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-icon color="primary">⚡</v-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Données connectées <b> aux CRM/ERP</b>
                                                    existants</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-icon color="primary">⚡</v-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Import des ressources fichiers <b>Shapefile,
                                                        Rasters</b></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-icon color="primary">⚡</v-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Cartographie <b>collaborative en
                                                        équipe</b></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-icon color="primary">⚡</v-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Prédictions par IA</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                    </v-list>
                                    <!-- <v-card-actions class="mt-4">
                                    <v-row justify="center">
                                        <v-btn :loading="loadingEntreprise" :disabled="getOffre == 'Offre Entreprise'"
                                            @click="loadingEntreprise = true; subscribe('entreprise', isAnnual)" check
                                            class="pa-6 bubbly-button" rounded large
                                            :color="!isAnnual ? 'secondary' : 'info'" :elevation="1">
                                            <v-icon left>{{ isAnnual ? "mdi-star" : "mdi-check" }}</v-icon>
                                            <span v-if="!getOffre || getOffre != 'Offre Entreprise'">Je m'abonne
                                                maintenant</span>
                                            <span v-else-if="getOffre == 'Offre Entreprise'">Déjà abonné</span>
                                        </v-btn>
                                    </v-row>
                                </v-card-actions> -->
                                    <v-card-actions class="mt-4">
                                        <v-row justify="center">
                                            <v-alert type="info">
                                                Bientôt disponible
                                            </v-alert>
                                        </v-row>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                        </v-row>

                        <stripe-checkout :pk="publishableKey" :session-id="sessionIdresStarter"
                            @loading="v => loading = v" ref="checkoutRefStarter"></stripe-checkout>
                        <stripe-checkout :pk="publishableKey" :session-id="sessionIdresStarterAnnuel"
                            @loading="v => loading = v" ref="checkoutRefStarterAnnuel"></stripe-checkout>
                        <stripe-checkout :pk="publishableKey" :session-id="sessionIdresPremium"
                            @loading="v => loading = v" ref="checkoutRefPremium"></stripe-checkout>
                        <stripe-checkout :pk="publishableKey" :session-id="sessionIdresPremiumAnnuel"
                            @loading="v => loading = v" ref="checkoutRefPremiumAnnuel"></stripe-checkout>
                        <stripe-checkout :pk="publishableKey" :session-id="sessionIdresEntreprise"
                            @loading="v => loading = v" ref="checkoutRefEntreprise"></stripe-checkout>
                        <stripe-checkout :pk="publishableKey" :session-id="sessionIdresEntrepriseAnnuel"
                            @loading="v => loading = v" ref="checkoutRefEntrepriseAnnuel"></stripe-checkout>

                        <!-- <stripe-pricing-table pricing-table-id="prctbl_1OKHYKIo5pmAhUyspFeWHS7S"
                    publishable-key="pk_test_51M6cFOIo5pmAhUysw9aM7gy7bP5Ctva63NSqyGVoakuxT0HHJf176cGKKd11S4OBoYnHFzOCCVaQVphi3qbV7Cse00kCRCxgti">
                </stripe-pricing-table> -->
                        <!-- <stripe-checkout ref="checkoutRef" mode="subscription" :pk="publishableKey" :line-items="lineItems"
                    :success-url="successURL" :cancel-url="cancelURL" @loading="v => loading = v" /> 
                <stripe-element-card ref="elementRef" :pk="publishableKey" @token="tokenCreated" /> -->

                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-card>

    </div>
</template>

<style lang="scss">
button.v-btn {
    &:hover {
        transform: scale(1.05);
    }
}


@keyframes topBubbles {
    0% {
        background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
    }

    50% {
        background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
    }

    100% {
        background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
        background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
}

@keyframes bottomBubbles {
    0% {
        background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
    }

    50% {
        background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
    }

    100% {
        background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
        background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
}

.custom-hover:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.5) 0px 3px 7px -3px !important;
    transition: box-shadow 0.8s !important;
}

.yellow-highlight .v-label {
    border-bottom: 3px solid yellow;
}

.green-highlight .v-label {
    border-bottom: 3px solid lightgreen;
}

.offer-card {
    position: relative;
    overflow: hidden;
    /* Assure que le bandeau ne dépasse pas de la carte */
}

.most-popular-banner {
    position: absolute;
    top: 85px;
    right: -8px;
    background-color: #FFD700;
    color: black;
    padding: 5px 15px;
    transform: translate(0%, -50%) rotate(45deg);
    transform-origin: top right;
    z-index: 1;
    font-weight: bold;
    font-size: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
</style>

<script>
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import xhr from '../plugins/axios'

export default {
    name: 'Premium',
    components: {
        StripeElementCard,
        StripeCheckout
    },
    computed: {
        getOffre() {
            if (this.user.subscribed) {
                return this.user.subscription.productDetails.name
            }
            return null;
        },
    },
    data() {
        return {
            loading: false,
            e1: 2,
            loadingEntreprise: false,
            loadingPro: false,
            loadingStarter: false,
            isAnnual: false,
            entrepriseFeatures: [
                { icon: 'mdi-check-circle', text: "Avantages de l'offre Pro" },
                { icon: 'mdi-check-circle', text: 'Statistiques avancée de ma parcelle' },
                { icon: 'mdi-check-circle', text: 'Stockage dans le Cloud' },
                { icon: 'mdi-check-circle', text: 'Inventaires croisés' },
                { icon: 'mdi-check-circle', text: 'Import des shapefile, rasters Vectorielles / Matricielles' },
                { icon: 'mdi-check-circle', text: 'Cartographie collaborative en Team' }
                // Autres fonctionnalités basiques...
            ],
            premiumFeatures: [
                { icon: 'mdi-check-circle', text: "Avantages de l'offre Starter" },
                { icon: 'mdi-check-circle', text: 'Fonctionnalité Premium 1' },
                { icon: 'mdi-check-circle', text: 'Gestion des tâches' },
                { icon: 'mdi-check-circle', text: 'Gestion des utilisateurs' },
                { icon: 'mdi-check-circle', text: 'Gestion des parcelles' },
                { icon: 'mdi-check-circle', text: 'Import avec IA: Excel, CSV, GEOJSON/Json, KML, Text ... ' }
                // Autres fonctionnalités premium...
            ],
            starterFeatures: [
                { icon: 'mdi-check-circle', text: 'Cartographie personnalisée' },
                { icon: 'mdi-check-circle', text: '3 Profils: Agriculteur, Vigneron et Forestier' },
                { icon: 'mdi-check-circle', text: 'Historique avec plusieurs inventaire' },
                { icon: 'mdi-check-circle', text: 'Import avec IA: Excel, CSV, GEOJSON/Json, KML, Text ... ' },
                { icon: 'mdi-check-circle', text: 'Filtres intelligents selon données' },
                { icon: 'mdi-check-circle', text: 'Export sous format Image (PNG, Jpeg)' },
                // Autres fonctionnalités premium...
            ],
            sessionIdresPremium: null,
            sessionIdresPremiumAnnuel: null,
            sessionIdresEntreprise: null,
            sessionIdresEntrepriseAnnuel: null,
            sessionIdresStarter: null,
            sessionIdresStarterAnnuel: null,
            publishableKey: "",
            token: null,
            loading: false,
            customerId: "cus_P7oOPrMpmmJ8Eh",
            customerEmail: "julien@taiwa.fr",
            loading: false,
            customer: 'cus_P7oOPrMpmmJ8Eh',
            successURL: 'your-success-url',
            cancelURL: 'your-cancel-url',
            line_items: [
                {
                    price: 'price_1OJvBZIo5pmAhUysgj3BS7vJ', // The id of the recurring price you created in your Stripe dashboard
                    quantity: 1,
                }],
            line_items2: [
                {
                    price: 'price_1PQ751Io5pmAhUys6O6dScAS', // The id of the recurring price you created in your Stripe dashboard
                    quantity: 1,
                }],
            successURL: `${process.env.VUE_APP_FRONT_URL}premium-success`,
            cancelURL: `${process.env.VUE_APP_FRONT_URL}premium-cancel`,
        }
    },
    async created() {
        this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
        let user = localStorage.getItem("user");
        if (user) {
            this.user = JSON.parse(user)

            let resStarter = await xhr.post(`${process.env.VUE_APP_STRIPE_ML}create-session`, {
                id: this.user.uid, tarif: 'price_1PBHFaIo5pmAhUysrukON8rb', uri: 'import?first=true', uriFallback: 'register-premium'
            })
            console.log("session  created", resStarter.data);
            this.sessionIdresStarter = resStarter.data.id

            let resStarterAnnuel = await xhr.post(`${process.env.VUE_APP_STRIPE_ML}create-session`,
                { id: this.user.uid, tarif: 'price_1PBHFaIo5pmAhUysVeH07FGt', uri: 'import?first=true', uriFallback: 'register-premium' })
            console.log("session  created", resStarterAnnuel.data);
            this.sessionIdresStarterAnnuel = resStarterAnnuel.data.id

            let resPremium = await xhr.post(`${process.env.VUE_APP_STRIPE_ML}create-session`, 
                { id: this.user.uid, tarif: 'price_1PBHMyIo5pmAhUysgs4FmA2E', uri: 'import?first=true', uriFallback: 'register-premium' })
            console.log("session  created", resPremium.data);
            this.sessionIdresPremium = resPremium.data.id

            let resPremiumAnnuel = await xhr.post(`${process.env.VUE_APP_STRIPE_ML}create-session`, 
                { id: this.user.uid, tarif: 'price_1PBHMyIo5pmAhUysDPtiuDuv', uri: 'import?first=true', uriFallback: 'register-premium' })
            console.log("session  created", resPremiumAnnuel.data);
            this.sessionIdresPremiumAnnuel = resPremiumAnnuel.data.id

            let resEntreprise = await xhr.post(`${process.env.VUE_APP_STRIPE_ML}create-session`, 
            { id: this.user.uid, tarif: 'price_1OKl5EIo5pmAhUysIt89VJgb', uri: 'import?first=true', uriFallback: 'register-premium' })
            console.log("session  created", resEntreprise.data);
            this.sessionIdresEntreprise = resEntreprise.data.id

            let resEntrepriseAnnuel = await xhr.post(`${process.env.VUE_APP_STRIPE_ML}create-session`, 
            { id: this.user.uid, tarif: 'price_1OKl5ZIo5pmAhUysqr0idzHe', uri: 'import?first=true', uriFallback: 'register-premium' })
            console.log("session  created", resEntrepriseAnnuel.data);
            this.sessionIdresEntrepriseAnnuel = resEntrepriseAnnuel.data.id
        }

    },
    methods: {
        subscribe(offer, period) {

            if (offer == "starter" && period == true) {
                this.$refs.checkoutRefStarterAnnuel.redirectToCheckout();

            } else if (offer == "starter" && period == false) {
                this.$refs.checkoutRefStarter.redirectToCheckout();

            } else if (offer == "pro" && period == true) {
                this.$refs.checkoutRefPremiumAnnuel.redirectToCheckout();

            } else if (offer == "pro" && period == false) {
                this.$refs.checkoutRefPremium.redirectToCheckout();

            } else if (offer == "entreprise" && period == true) {
                this.$refs.checkoutRefEntrepriseAnnuel.redirectToCheckout();

            } else if (offer == "entreprise" && period == false) {
                this.$refs.checkoutRefEntreprise.redirectToCheckout();

            }

        },

    }

}
</script>

<style scoped>
.coming-soon-badge {
    position: absolute;
    top: 120px;
    /* Adjust based on your layout */
    right: 120px;
    /* Adjust based on your layout */
    background-color: amber;
    z-index: 9990000000;
    color: white;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-weight: bold;
}

.v-icon {
    margin-right: 5px;
}

@media only screen and (max-width: 600px) {
    .radio-group-mobile-center {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
</style>