<template>
    <div class="chat-component pa-1">
        <v-container>
            <v-row>
                <v-col cols="12" class="d-flex justify-space-between">
                    <v-btn outlined @click="clearDiscussion" color="secondary" small>
                        <v-icon>mdi-plus</v-icon>
                        Nouvelle discussion
                    </v-btn>
                    <v-btn v-if="!viewFavoris && bookmarks.length" class="ml-2" dark @click="seeFavoris" color="pink"
                        small>
                        <v-icon>mdi-heart-circle</v-icon>
                        Voir mes favoris
                    </v-btn>
                    <v-btn v-else-if="viewFavoris && messages.length" class="ml-2" dark @click="viewFavoris = false"
                        color="info" small rounded>
                        <v-icon>mdi-arrow-left-circle</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12">
                    <v-card class="pa-2">
                        <v-card-title v-if="context.category && !viewFavoris && messages.length">
                            <v-icon large color="primary">mdi-wechat</v-icon>
                            <span class="title-style"><span>Sujet: {{
                        context.maladie
                    }} <b> sur {{ context.category }}</b></span>
                                <span v-if="context.location"> sur {{ context.location }}</span>
                                <span v-if="context.saison"> sur {{ context.saison }}</span>
                            </span>
                        </v-card-title>
                        <v-card-title v-else-if="viewFavoris && bookmarks.length">
                            <v-icon large color="primary" class="pr-2">mdi-heart-multiple</v-icon>
                            <span class="title-style">Vos discussions favoris</span>
                        </v-card-title>
                        <div ref="messageContainer" class="messages"
                            :style="bigAssistant == true ? 'max-height:  800px' : 'max-height: 300px'">
                            <!-- <transition-group name="fade" tag="div" > -->

                            <div v-for="(message, index) in messages" :key="index" class="message-item">
                                <Message @selectAsk="selectAsk" :context="context" :message="message"></Message>
                            </div>
                            <div v-if="loading" style="display: flex; justify-content: flex-start;">
                                <img class="ml-2" width="60" src="@/assets/typing.gif" alt="Chargement...">
                                <p>
                                    {{ messageLoading }}
                                </p>
                            </div>

                            <!-- </transition-group> -->
                        </div>
                        <div v-if="!viewFavoris">
                            <v-row>
                                <v-col cols="12" lg="8">
                                    <v-text-field v-if="!$vuetify.breakpoint.mobile" :disabled="loading"
                                        prepend-icon="mdi-chat-question" v-model="newMessage"
                                        placeholder="Ex: Quel est la couleur de la feuille de mon pied de vigne?"
                                        label="Poser une question ici" @keyup.enter="sendMessage"></v-text-field>
                                    <v-textarea v-else :disabled="loading" prepend-icon="mdi-chat-question"
                                        v-model="newMessage"
                                        placeholder="Ex: C'est quoi les traitements contre le Mildiou?"
                                        label="Poser une question ici" rows="3" no-resize></v-textarea>
                                </v-col>
                                <v-col cols="12" lg="4" class="d-flex align-center justify-center lg:justify-end">
                                    <v-btn rounded color="primary" dark class="ma-2 pa-4" @click="sendMessage">
                                        <v-icon left>mdi-chat-question</v-icon>
                                        Poser ma question</v-btn>
                                </v-col>


                            </v-row>
                        </div>

                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import xhr from '../plugins/axios';
import { mapActions } from 'vuex';
import moment from 'moment';
import Message from './Message.vue'


export default {
    props: ['bigAssistant', 'profil'],
    data() {
        return {
            step: 0,
            messageLoading: "Recherche des information ...🚀",
            newMessage: '',
            loading: false,
            viewFavoris: false,
            conversationHistory: [
                { role: "system", content: "Tu es un assistant professionnel pour un vigneron ou forestier avec des parcelles. Réponds en français et de la manière la plus précise et professionnelle." },
            ]

        };
    },
    created() {
        this.newMessage = this.profil == "vigneron" ? "En tant que Vigneron, " : "En tant que Forestier, "
    },
    computed: {
        context: {
            get() {
                return this.$store.state.chat.context;
            },
            set(value) {
                this.$store.dispatch('updateContext', value);
            }
        },
        messages: {
            get() {
                return this.$store.state.chat.messages;
            },
            set(value) {
                this.$store.dispatch('updateMessages', value);
            }
        },
        bookmarks: {
            get() {
                return this.$store.state.chat.bookmarks;
            },
            set(value) {
                this.$store.dispatch('updateBookmarks', value);
            }
        },
    },
    components: {
        Message: Message
    },
    methods: {
        goDiag() {
            this.$emit("goDiag")
        },
        ...mapActions({
            addMessage: 'addMessage',
            updateContext: 'updateContext',
            updateMessages: 'updateMessages'

        }),
        clearDiscussion() {
            this.loading = false
            this.updateMessages([])
            this.messageLoading = "Recherche des information ...🚀"
            this.conversationHistory = [
                { role: "system", content: "Tu es un assistant professionnel pour un vigneron ou forestier avec des parcelles. Réponds en français et de la manière la plus précise et professionnelle." },
            ]
            this.viewFavoris = false
            this.newMessage = this.profil == "vigneron" ? "En tant que Vigneron, " : "En tant que Forestier, "
        },
        seeFavoris() {
            this.updateMessages(this.bookmarks)
            this.viewFavoris = true
        },
        formatDate(date) {
            return moment(date).format('DD/MM/YYYY à HH:mm');
        },
        formatTextForDisplay(text) {
            // Remplacer les points par des points suivis d'un saut de ligne
            // let formattedText = text.replace(/(\. )/g, ".<br><br>");
            let formattedText = text
            // let formattedText = text.replace(/(\d+\.\s)/g, '<br><br>$1');
            // Ajouter un saut de ligne avant chaque tiret de liste, par exemple "- "
            formattedText = formattedText.replace(/(- [A-Z])/g, '<br>$1');
            formattedText = formattedText.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
            // formattedText = formattedText.replace(/(?<=\n)-\s*(?=[A-Z])/g, '<br> - ');

            // let sentenceCount = 0;
            // formattedText = formattedText.replace(/(\. )/g, function (match) {
            //     sentenceCount++;
            //     if (sentenceCount % 3 === 0) {
            //         return ".<br><br>"; // Revient à la ligne après la troisième phrase
            //     } else {
            //         return ". "; // Garde un espace après les autres phrases
            //     }
            // });


            // Ajouter un saut de ligne après chaque numéro de liste, par exemple "1. "
            return formattedText.replace(/(\d+\.\s)/g, '<br>$1');
        },
        async selectAsk(ask) {
            if (ask.length > 2) {
                this.processMessage(ask)
            }
        },
        async processMessage(text) {

            // Afficher le loader
            this.loading = true;
            setTimeout(() => {
                this.messageLoading = "Recherche de la réponse ⌛"
            }, 3000)

            setTimeout(() => {
                this.messageLoading = "Collecte des données 📒"
            }, 5000)

            setTimeout(() => {
                this.messageLoading = "Génération de la réponse 🚀"
            }, 7000)

            setTimeout(() => {
                this.messageLoading = "Votre réponse arrive 🪄"
            }, 9000)

            setTimeout(() => {
                this.messageLoading = "Recherche des information ...🚀"
            }, 12000)

            // Ajouter le message de l'utilisateur
            this.addMessage({ text, isBot: false, date: new Date() })
            this.$nextTick(async () => {
                const messageContainer = this.$refs.messageContainer;
                if (messageContainer) {
                    const scrollHeight = messageContainer.scrollHeight + 700;
                    messageContainer.scrollTo({ top: scrollHeight, behavior: 'smooth' });
                }
            });

            let response = {}
            let conversationHistory = [
                { role: "system", content: "Tu es un assistant professionnel pour un vigneron ou forestier avec des parcelles. Réponds en français et de la manière la plus précise et professionnelle." },
            ]
            let messages = this.messages.map((e) => { return { "role": e.isBot == true ? 'assistant' : 'user', content: e.text } })
            let mergedArray = conversationHistory.concat(messages);
            response = await xhr.post(`/tchat`, {
                conversationHistory: mergedArray
            });

            if (response.data && response.data.message) {
                console.log("response.data", response.data.message);
                let resp = response.data.message

                let formatRes = this.formatTextForDisplay(resp)
                const botResponse = { text: formatRes, isBot: true };

                this.addMessage({ text: formatRes, isBot: true, date: new Date() })
                this.loading = false;
                this.messageLoading = "Recherche des information ...🚀",
                    this.$nextTick(() => {

                        const messageContainer = this.$refs.messageContainer;
                        if (messageContainer) {
                            const scrollHeight = messageContainer.scrollHeight;
                            messageContainer.scrollTo({ top: scrollHeight, behavior: 'smooth' });
                        }
                    })
                this.newMessage = '';
            }

        },
        async sendMessage() {
            if (this.newMessage.trim()) {
                this.processMessage(this.newMessage)
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.v-chip .v-chip__content {
    padding: 10px !important;
}

.messages {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 300px; // Hauteur maximale avant de déclencher le défilement
    overflow-y: auto;
    scroll-behavior: smooth;
    /* Ajoute un effet de défilement doux */

}

.message-item {
    // display: flex;
    // flex-wrap: wrap;
}

.message-item .bot-message {
    justify-content: flex-end;
    display: flex;
}

.message-item .suggest-message {
    justify-content: flex-end;
    display: flex;

    .v-chip.v-chip--outlined.v-chip.v-chip {
        padding: 3px !important;
        padding-left: 7px !important;
        padding-right: 14px !important;
        border: 1px solid #eee !important;

    }

}

.message-item .user-message {
    justify-content: flex-start;
    display: flex;
}

.v-chip {
    display: flex;
    align-items: center;
    max-width: 100%; // 
    white-space: normal; // Permet le retour à la ligne
    word-break: break-word;
    height: auto !important;
    padding: 10px !important;
    margin: 10px !important;

    .v-icon {
        margin-right: 8px; // Espace entre l'icône et le texte
    }
}
</style>
