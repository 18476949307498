<template>
    <v-card dark dense color="secondary" class="py-4">
        <v-card-title @click="changeInv" class="text-h6"><v-icon class="mr-4 pa-1">mdi-clipboard-list-outline</v-icon>{{
            inventaire.name }}
            <v-spacer></v-spacer>
            <v-btn icon @click="changeInv">
                <v-icon>mdi-eye-settings</v-icon>
            </v-btn>
        </v-card-title>
        <v-chip label color="primary">
            <v-icon>mdi-texture-box</v-icon> {{ inventaire.parcelles.length }} parcelles
        </v-chip>
        <v-chip label color="orange">
            <v-icon>mdi-texture-box</v-icon> {{ inventaire.tasks.length }} tâches
        </v-chip>



        <v-card-text @click="editDescription" v-if="!editingDesc">
            <v-icon>mdi-pencil-circle</v-icon> {{ description }}
        </v-card-text>



        <v-row v-else>
            <v-col cols="12">
                <v-text-field label="Description de l'inventaire" multi-line rows="5" class="pa-2 ma-2"
                    append-icon="mdi-pencil" ref="descriptionInput" v-model="description"
                    @blur="updateDescription(inventaire.id, inventaire.description)"
                    @keydown.enter="updateDescription(inventaire.id, inventaire.description)"></v-text-field>
            </v-col>
        </v-row>

        <v-card-text color="primary--text" @click="editForet" v-if="!editingForet">
            <v-icon>mdi-fence</v-icon> {{ foret }}
        </v-card-text>


        <v-row v-else>
            <v-col cols="12">
                <v-text-field label="Domaine de l'inventaire" multi-line rows="5" class="pa-2 ma-2"
                    append-icon="mdi-pencil" ref="foretInput" v-model="foret"
                    @blur="updateForet(inventaire.id, inventaire.foret)"
                    @keydown.enter="updateForet(inventaire.id, inventaire.foret)"></v-text-field>
            </v-col>
        </v-row>

        <v-row class="pl-2">
            <v-col>
                <v-tooltip bottom v-for="user in inventaire.members" :key="user._id">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="ma-1" v-on="on" fab small color="white" outlined>
                            {{ user.firstname[0] + user.lastname[0] }}
                        </v-btn>
                    </template>
                    <span> {{ user.firstname + user.lastname }}</span>
                </v-tooltip>
            </v-col>
        </v-row>


    </v-card>
</template>

<script>
import moment from 'moment';
import xhr from '../plugins/axios';

moment.locale('fr');


export default {
    data(vm) {
        return {
            editingDesc: false,
            editingForet: false,
            description: "",
            foret: "",
        }
    },
    props: ['inventaire'],
    async created() {
        console.log("this.inventaire", this.inventaire);
        this.description = this.inventaire.description
        this.foret = this.inventaire.domaine
    },
    methods: {

        changeInv() {
            this.$router.push({ name: 'Detail', params: { id: this.inventaire.id } });
            this.$emit("closeDialog")
            this.$store.dispatch('toggleToolbar', false);
        },
        editDescription() {
            this.editingDesc = true;
            this.$nextTick(() => {
                this.$refs.descriptionInput.focus();
            });
        },
        editForet() {
            this.editingForet = true;
            this.$nextTick(() => {
                this.$refs.foretInput.focus();
            });
        },
        async updateDescription() {
            this.editingDesc = false;
            let inventaire = await xhr.post('/edit-description', {
                id: this.inventaire.id,
                description: this.description
            });
            console.log("inventaire.data", inventaire.data);

            // this.description = inventaire.data.description
        },
        async updateForet() {
            this.editingForet = false;
            let inventaire = await xhr.post('/edit-foret', {
                id: this.inventaire.id,
                foret: this.foret
            });

            console.log("inventaire.data", inventaire.data);
            // this.foret = inventaire.data.foret
        },
        formatDate(datIn) {
            // Expression régulière pour vérifier le format DD/MM/YYYY
            const regex = /^\d{2}\/\d{2}\/\d{4}$/;

            // Vérifie si la date d'entrée est déjà au format DD/MM/YYYY
            if (regex.test(datIn)) {
                // Si c'est le cas, retourne la date telle quelle
                return datIn;
            } else {
                // Sinon, utilise Moment.js pour formater la date
                return moment(datIn).format('DD/MM/YYYY');
            }
        },
    }

}
</script>